import React, { useState, useEffect } from 'react'
import { useWakeLock } from 'react-screen-wake-lock';
import './App.css'
import LeftMenu from './LeftMenu'
import Products from './Products'
import ProductsList from './ProductsList'

function App() {
  
  const { isSupported, released, request, release } = useWakeLock({
    onRequest: () => console.log('Screen Wake Lock: requested!'),
    onError: () => {
      console.log('An error happened 💥')
      setTimeout(() => {
        request()
      }, 5000)
    },
    onRelease: () => {
      console.log('Screen Wake Lock: released!')
      setTimeout(() => {
        request()
      }, 5000)      
    }
  });

  useEffect(() => {
    request()
  }, [])

  const allProducts = ProductsList

  const [products, setProducts] = useState( allProducts['MACELLERIA'] )

  function userChangeCategory(categoryName) {
    setProducts( allProducts[categoryName] )
  }

  return (
    <div className="App">
      <LeftMenu changeCategory={ userChangeCategory }/>
      <Products products={ products }/>
    </div>
  );
}

export default App
