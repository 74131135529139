const ProductsList = {
    "MACELLERIA": [
        {
            "_id": "106268",
            "name": "HAMBURGER POLLO E TACCHINO",
            "ingredients": "INGREDIENTI: pollo tacchino aromi naturali sale e pepe. Può contenere LATTEUOVA GLUTINE. PREPARAZIONE: IN PADELLA AL FORNO ALLA PIASTRA",
            "plu": "031643"
        },
        {
            "_id": "106269",
            "name": "SALSICCIA POLLO E TACCHINO",
            "ingredients": "INGREDIENTI: pollo tacchino aromi naturali sale e pepe. Può contenere LATTEUOVA GLUTINE. PREPARAZIONE: IN PADELLA AL FORNO ALLA PIASTRA ALLA GRIGLIA",
            "plu": "031642"
        },
        {
            "_id": "177755",
            "name": "SALSICCIA SUINO NERO AL KG",
            "ingredients": "INGREDIENTI: Carne suino nero acqua sale pepe Aromi naturali Antiossidante: E 300 E 301 Correttore di acidità: E 331 E 262. Può contenere LATTE UOVA GLUTINE. PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031664"
        },
        {
            "_id": "177756",
            "name": "PROSCIUTTO SUINO NERO",
            "ingredients": "INGREDIENTI: suino e aromi naturali. PREPARAZIONE: AI FERRI O AL FORNO",
            "plu": "031665"
        },
        {
            "_id": "177757",
            "name": "COSTOLETTE SUINO NERO",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031666"
        },
        {
            "_id": "177759",
            "name": "COPPA CON OSSO SUINO NERO",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031667"
        },
        {
            "_id": "177760",
            "name": "PUNTINE SUINO NERO",
            "ingredients": "PREPARAZIONE: AI FERRI O AL FORNO",
            "plu": "031668"
        },
        {
            "_id": "177761",
            "name": "PANCETTA FETTE SUINO NERO",
            "ingredients": "PREPARAZIONE: AI FERRI O AL FORNO",
            "plu": "031669"
        },
        {
            "_id": "177762",
            "name": "HAMBURGER SUINO NERO",
            "ingredients": "INGREDIENTI: suino sale e pepe. Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: IN PADELLA O AL FORNO",
            "plu": "031670"
        },
        {
            "_id": "231647",
            "name": "AIA WURSTEL SFUSI 6KG",
            "ingredients": "carni di pollo e tacchino separate meccanicamente 94%sale aromi esaltatori di sapidità:glutammato monosodicoantiossidanti:ascorbato di sodio stabilizzanti:difosfati-polifosfaticonservanti: nitrito di sodioaroma di affumicatura.",
            "plu": "031910"
        },
        {
            "_id": "233882",
            "name": "AIA WURSTEL FORMAGGIO SF.6KG",
            "ingredients": "carni di pollo e tacchino separate meccanicamente 73%FORMAGGIO 9%LATTE scremato in polvere reidratatoproteine del LATTEsaleBURRO aromicorrettori di acidità:acido citrico esaltatori di sapidità:glutammato monosodico antiossidanti:ascorbato di sodio stabilizzanti: difosfati-polifosfaticonservanti:nitrito di sodio",
            "plu": "031911"
        },
        {
            "_id": "740461",
            "name": "AMADORI POLLO BUSTO ROST.CAMPESE",
            "ingredients": "Busto di pollo rosticceria allevato allaper to senza uso di antibiotici fresco classe A",
            "plu": "032505"
        },
        {
            "_id": "760459",
            "name": "AMADORI SPIED.POLLO-ALLORO MAX",
            "ingredients": "Ingredienti: Carne di pollo 43 % salsiccia con tacchino e pollo carne di tacchino 45 % carne di pollo 19 % carne di suino 18 % acqua sale iodato (sale iodato di potassio 0 007 %); destrosio spezie piante aromatiche aromi naturali fibre vegetali: bamboo lime limone; correttore di acidità: diacetato di sodio; antiossidante: ascorbato di sodio; amido di pata ta budello edibile -alginato di sodio- carne di tacchino 21 % pancetta di suino 10 % alloro 07 %. Può contenere GRANO LATTE UOVO.",
            "plu": "032359"
        },
        {
            "_id": "790000",
            "name": "BEEF BOVINO ADULTO (SCOTTONA)",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031001"
        },
        {
            "_id": "790003",
            "name": "GIRELLO BOVINO ADULTO (SCOTTONA)",
            "ingredients": "SCOTTONA PREPARAZIONE: COTOLETTA O IN UMIDO",
            "plu": "031004"
        },
        {
            "_id": "790004",
            "name": "SOTTOFILETTO BOVINO ADULTO (SCOTTONA)",
            "ingredients": "SCOTTONA PREPARAZIONE: SCALOPPINE",
            "plu": "031005"
        },
        {
            "_id": "790005",
            "name": "COLARDA BOVINO ADULTO (SCOTTONA)",
            "ingredients": "SCOTTONA PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031006"
        },
        {
            "_id": "790007",
            "name": "NATICA BOVINO ADULTO (SCOTTONA)",
            "ingredients": "PREPARAZIONE: COTOLETTA SCALOPPINE O IN PADELLA",
            "plu": "031007"
        },
        {
            "_id": "790008",
            "name": "PEZZO A CANNELLA BOVINO AD. (SCOTTONA)",
            "ingredients": "SCOTTONA PREPARAZIONE: COTOLETTE AI FERRI O ALLA PIZZAIOLA",
            "plu": "031008"
        },
        {
            "_id": "790009",
            "name": "DIETRO COSCIA BOVINO AD. (SCOTTONA)",
            "ingredients": "SCOTTONA PREPARAZIONE: COTOLETTA O STRACCETTI",
            "plu": "031009"
        },
        {
            "_id": "790010",
            "name": "ASADO BOVINO ADULTO (SCOTTONA)",
            "ingredients": "PREPARAZIONE: AI FERRI O AL FORNO",
            "plu": "031010"
        },
        {
            "_id": "790011",
            "name": "OSSOBUCO BOVINO ADULTO (SCOTTONA)",
            "ingredients": "SCOTTONA PREPARAZIONE: AL FORNO O IN PADELLA",
            "plu": "031011"
        },
        {
            "_id": "790012",
            "name": "HAMBURGER BOVINO (SCOTTONA) II°TAGLIO",
            "ingredients": "INGREDIENTI: Bovino e sale. Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031012"
        },
        {
            "_id": "790013",
            "name": "MUSCOLO BOVINO ADULTO (SCOTTONA)",
            "ingredients": "SCOTTONA PREPARAZIONE: LESSO O AL RAGU",
            "plu": "031013"
        },
        {
            "_id": "790015",
            "name": "COSTATA CON OSSO BOVINO AD. (SCOTTONA)",
            "ingredients": "SCOTTONA PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031014"
        },
        {
            "_id": "790016",
            "name": "ARROSTO DISOSSATO BOVINO ADULTO (SCOTTONA)",
            "ingredients": "PREPARAZIONE: ALLA PIZZAIOLA O AI FERRI",
            "plu": "031015"
        },
        {
            "_id": "790017",
            "name": "PALETTINA BOVINO ADULTO (SCOTTONA)",
            "ingredients": "SCOTTONA PREPARAZIONE: IN PADELLA O AI FERRI",
            "plu": "031016"
        },
        {
            "_id": "790018",
            "name": "LACERTO BOVINO ADULTO (SCOTTONA)",
            "ingredients": "SCOTTONA PREPARAZIONE: IN UMIDO O AL FORNO",
            "plu": "031017"
        },
        {
            "_id": "790019",
            "name": "CORAZZA/COPERTA BOVINO AD. (SCOTTONA)",
            "ingredients": "SCOTTONA PREPARAZIONE: CARNE TRITA E LESSA",
            "plu": "031018"
        },
        {
            "_id": "790020",
            "name": "PUNTA COLARDA BOVINO AD. (SCOTTONA)",
            "ingredients": "SCOTTONA PREPARAZIONE: ALLA GENOVESE O AL RAGU",
            "plu": "031019"
        },
        {
            "_id": "790021",
            "name": "BOCCONCINI I TAGLIO BOVINO AD. (SCOTTONA)",
            "ingredients": "SCOTTONA PREPARAZIONE: ALLA GENOVESE O AL RAGU",
            "plu": "031020"
        },
        {
            "_id": "790023",
            "name": "SPEZZATINO II TAGLIO BOV. AD. (SCOTTONA)",
            "ingredients": "SCOTTONA PREPARAZIONE: ALLA GENOVESE O AL RAGU",
            "plu": "031021"
        },
        {
            "_id": "790024",
            "name": "BIANCOSTATO C/O BOVINO AD. (SCOTTONA)",
            "ingredients": "SCOTTONA PREPARAZIONE: LESSO",
            "plu": "031022"
        },
        {
            "_id": "790025",
            "name": "PUNTA PETTO BOVINO AD. (SCOTTONA)",
            "ingredients": "SCOTTONA PREPARAZIONE: ALLA GENOVESE O AL RAGU",
            "plu": "031023"
        },
        {
            "_id": "790026",
            "name": "MACINATO I TAGLIO BOVINO AD. (SCOTTONA)",
            "ingredients": "SCOTTONA Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: BOLOGNESE O POLPETTE",
            "plu": "031024"
        },
        {
            "_id": "790027",
            "name": "MACINATO II TAGLIO BOVINO AD. (SCOTTONA)",
            "ingredients": "SCOTTONA Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: BOLOGNESE O POLPETTE",
            "plu": "031025"
        },
        {
            "_id": "790028",
            "name": "POLPA SCELTA BOVINO ADULTO (SCOTTONA)",
            "ingredients": "SCOTTONA Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: ALLA GENOVESE O AL RAGU",
            "plu": "031026"
        },
        {
            "_id": "790029",
            "name": "COTOLETTE BOVINO ADULTO (SCOTTONA)",
            "ingredients": "SCOTTONA PREPARAZIONE: COTOLETTE",
            "plu": "031027"
        },
        {
            "_id": "790031",
            "name": "SCALOPPINE BOVINO ADULTO (SCOTTONA)",
            "ingredients": "SCOTTONA PREPARAZIONE: SCALOPPINE",
            "plu": "031028"
        },
        {
            "_id": "790032",
            "name": "STRACCETTI BOVINO ADULTO (SCOTTONA)",
            "ingredients": "SCOTTONA PREPARAZIONE: STRACCETTI",
            "plu": "031029"
        },
        {
            "_id": "790033",
            "name": "CARPACCIO BOVINO ADULTO (SCOTTONA)",
            "ingredients": "INGREDIENTI: carne bovina Può contenere GLUTINE UOVA LATTE. PREPARAZIONE: CARPACCIO",
            "plu": "031030"
        },
        {
            "_id": "790034",
            "name": "FEGATO BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031050"
        },
        {
            "_id": "790035",
            "name": "CUORE BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031051"
        },
        {
            "_id": "790036",
            "name": "LINGUA BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: LESSO O AL RAGU",
            "plu": "031052"
        },
        {
            "_id": "790037",
            "name": "LOMBATELLO BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031053"
        },
        {
            "_id": "790038",
            "name": "CODA BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: LESSO O AL RAGU",
            "plu": "031054"
        },
        {
            "_id": "790039",
            "name": "MILZA BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: LESSO CON ACETO",
            "plu": "031055"
        },
        {
            "_id": "790040",
            "name": "MUSETTI COTTI BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: AL LIMONE",
            "plu": "031056"
        },
        {
            "_id": "790041",
            "name": "ZAMPE COTTE BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: AL LIMONE",
            "plu": "031057"
        },
        {
            "_id": "790042",
            "name": "TRIPPA COTTA BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: AL LIMONE AL SUGO O CON FAGIOLI",
            "plu": "031058"
        },
        {
            "_id": "790043",
            "name": "CENTOPELLE BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: AL LIMONE O AL SUGO",
            "plu": "031059"
        },
        {
            "_id": "790044",
            "name": "RENE BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: CON CIPOLLA",
            "plu": "031060"
        },
        {
            "_id": "790045",
            "name": "ENTRECOTE BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031070"
        },
        {
            "_id": "790046",
            "name": "ENTRECOTE BOV.AD.ARGENTINO SV",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031071"
        },
        {
            "_id": "790047",
            "name": "LOMBATA 5COSTE BOV.AD.IRL. SV",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031072"
        },
        {
            "_id": "790049",
            "name": "LOMBATA BOVINO ADULTO 5 COSTE SCOZIA IGP SV",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031073"
        },
        {
            "_id": "790050",
            "name": "ENTRECOTE IRLANDA SV",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031074"
        },
        {
            "_id": "790051",
            "name": "MUSCOLO BOV.ADULTO IRLANDA SV",
            "ingredients": "PREPARAZIONE: RAGU GENOVESE LESSO.",
            "plu": "031075"
        },
        {
            "_id": "790052",
            "name": "GIRELLO BOV.AD.IRLANDA SV",
            "ingredients": "PREPARAZIONE: COTOLETTA O IN UMIDO",
            "plu": "031076"
        },
        {
            "_id": "790053",
            "name": "FILONE SUINO SV ITALIA",
            "ingredients": "PREPARAZIONE: COTOLETTA O IN UMIDO",
            "plu": "031077"
        },
        {
            "_id": "790054",
            "name": "GIRELLO BOVINO AD.USA BLACK ANGUS 100%",
            "ingredients": "PREPARAZIONE: COTOLETTA O IN UMIDO",
            "plu": "031078"
        },
        {
            "_id": "790056",
            "name": "GIRELLO BOV.AD.ANGUS AUSTRA.SV",
            "ingredients": "PREPARAZIONE: COTOLETTA O IN UMIDO",
            "plu": "031079"
        },
        {
            "_id": "790057",
            "name": "REALE BOVINO ADULTO IRLANDESE",
            "ingredients": "PREPARAZIONE: ALLA PIZZAIOLA O AI FERRI",
            "plu": "031080"
        },
        {
            "_id": "790058",
            "name": "SPENCER ROLL ANGUS BOV.AD.IRL.",
            "ingredients": "PREPARAZIONE: ALLA PIZZAIOLA O AI FERRI",
            "plu": "031081"
        },
        {
            "_id": "790059",
            "name": "REALE SQUADRATO BOV.AD.USA SV",
            "ingredients": "PREPARAZIONE: ALLA PIZZAIOLA O AI FERRI",
            "plu": "031082"
        },
        {
            "_id": "790060",
            "name": "REALE SPENC.ROLL BOV.AD.IRL.SV",
            "ingredients": "PREPARAZIONE: ALLA PIZZAIOLA O AI FERRI",
            "plu": "031083"
        },
        {
            "_id": "790061",
            "name": "REALE BOVINO ADULTO SCOZIA IGP",
            "ingredients": "PREPARAZIONE: ALLA PIZZAIOLA O AI FERRI",
            "plu": "031084"
        },
        {
            "_id": "790062",
            "name": "ROAST BEEF IRLANDESE",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031085"
        },
        {
            "_id": "790064",
            "name": "BEEF VITELLO",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031110"
        },
        {
            "_id": "790065",
            "name": "COSTATA C/O VITELLO",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031111"
        },
        {
            "_id": "790066",
            "name": "FILETTO VITELLO",
            "ingredients": "PREPARAZIONE: AL PEPE O AI FERRI",
            "plu": "031112"
        },
        {
            "_id": "790067",
            "name": "GIRELLO VITELLO",
            "ingredients": "PREPARAZIONE: COTOLETTA O IN UMIDO",
            "plu": "031113"
        },
        {
            "_id": "790068",
            "name": "COLARDA VITELLO",
            "ingredients": "PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031114"
        },
        {
            "_id": "790069",
            "name": "PALETTINA VITELLO",
            "ingredients": "PREPARAZIONE: IN PADELLA O AI FERRI",
            "plu": "031115"
        },
        {
            "_id": "790070",
            "name": "BOCCONCINI VITELLO I TAGLIO",
            "ingredients": "PREPARAZIONE: CON PISELLIALLA GENOVESE O AL RAGU",
            "plu": "031116"
        },
        {
            "_id": "790072",
            "name": "OSSOBUCO VITELLO",
            "ingredients": "PREPARAZIONE: AL FORNO O IN PADELLA",
            "plu": "031117"
        },
        {
            "_id": "790073",
            "name": "NATICA VITELLO",
            "ingredients": "PREPARAZIONE: COTOLETTA SCALOPPINE O IN PADELLA",
            "plu": "031118"
        },
        {
            "_id": "790074",
            "name": "PEZZO A CANNELLA VITELLO",
            "ingredients": "PREPARAZIONE: COTOLETTE AI FERRI O ALLA PIZZAIOLA",
            "plu": "031119"
        },
        {
            "_id": "790075",
            "name": "DIETROCOSCIA VITELLO",
            "ingredients": "PREPARAZIONE: COTOLETTA O STRACCETTI",
            "plu": "031120"
        },
        {
            "_id": "790091",
            "name": "SALSICCIA CON FINOCCHIETTO",
            "ingredients": "INGREDIENTI: suino sale pepe finocchietto e vino bianco(SOLFITI). Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031145"
        },
        {
            "_id": "790110",
            "name": "STRUTTO",
            "ingredients": "PREPARAZIONE: DA CONDIMENTO",
            "plu": "031162"
        },
        {
            "_id": "790111",
            "name": "SALSICCIA SUINO CONF.FAMIGLIA",
            "ingredients": "INGREDIENTI: suino sale pepe. Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031180"
        },
        {
            "_id": "790112",
            "name": "CAPICOLLO SUINO S/OSSO CONF.FAMIGLIA",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031181"
        },
        {
            "_id": "790114",
            "name": "COSTOLETTE (BRACIOLA) SUINO CONF. FAMIGLIA",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031182"
        },
        {
            "_id": "790115",
            "name": "PROSCIUTTO SUINO CONF.FAMIGLIA",
            "ingredients": "PREPARAZIONE: COTOLETTA SCALOPPINE O IN PADELLA",
            "plu": "031183"
        },
        {
            "_id": "790116",
            "name": "CAPICOLLO/COPPA SUINO C/O CONF.FAM.",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031184"
        },
        {
            "_id": "790117",
            "name": "ARROSTICINI AGNELLO",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031200"
        },
        {
            "_id": "790118",
            "name": "COSTOLETTE SCOTTADITO AGNELLO",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031201"
        },
        {
            "_id": "790119",
            "name": "COSCIOTTO AGNELLO",
            "ingredients": "PREPARAZIONE: AL FORNO CON PATATE E PISELLI",
            "plu": "031202"
        },
        {
            "_id": "790120",
            "name": "SPALLA AGNELLO AL KG",
            "ingredients": "PREPARAZIONE: AL FORNO CON PATATE E PISELLI",
            "plu": "031203"
        },
        {
            "_id": "790122",
            "name": "SPEZZATINO AGNELLO",
            "ingredients": "PREPARAZIONE: AL FORNO CON PATATE E PISELLI",
            "plu": "031204"
        },
        {
            "_id": "790123",
            "name": "AGNELLO DISOSSATO KG",
            "ingredients": "PREPARAZIONE: DA IMBOTTIRE",
            "plu": "031205"
        },
        {
            "_id": "790124",
            "name": "GRIGLIATA MISTA AGNELLO",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031206"
        },
        {
            "_id": "790125",
            "name": "AGNELLINO LATTANTE INTERO",
            "ingredients": "PREPARAZIONE: AL FORNO CON PATATE E PISELLI",
            "plu": "031207"
        },
        {
            "_id": "790126",
            "name": "AGNELLINO LATTANTE META",
            "ingredients": "PREPARAZIONE: AL FORNO CON PATATE E PISELLI",
            "plu": "031208"
        },
        {
            "_id": "790127",
            "name": "CAPRETTO INTERO 4/7 ITALIA",
            "ingredients": "PREPARAZIONE: AL FORNO CON PATATE E PISELLI",
            "plu": "031209"
        },
        {
            "_id": "790128",
            "name": "AGNELLO INTERO TESTA E CORATA 7/9 KG ITALIA",
            "ingredients": "PREPARAZIONE: AL FORNO CON PATATE E PISELLI",
            "plu": "031210"
        },
        {
            "_id": "790129",
            "name": "SPALLA AGNELLO EUROPEO",
            "ingredients": "PREPARAZIONE: AL FORNO CON PATATE E PISELLI",
            "plu": "031211"
        },
        {
            "_id": "790171",
            "name": "SPIEDINI TACCHINO",
            "ingredients": "INGREDIENTI: tacchino e peperoni. PREPARAZIONE: AL FORNO O AI FERRI",
            "plu": "031264"
        },
        {
            "_id": "790172",
            "name": "COTOLETTA TACCHINO",
            "ingredients": "INGREDIENTI: tacchino sale pepe Farina di FRUMENTO tipo 00 amido di patata amido di FRUMENTOdestrosio agenti lievitanti: bicarbonato di sodioE551maisestratto di malto dORZOspezie lievito paprika curcuma. Può contenere LATTE UOVA SOIA SENAPE.",
            "plu": "031265"
        },
        {
            "_id": "790173",
            "name": "CERVELLATA TACCHINO",
            "ingredients": "INGREDIENTI: tacchino sale e pepe. PREPARAZIONE: AL FORNO O AI FERRI",
            "plu": "031266"
        },
        {
            "_id": "790174",
            "name": "SOVRACOSCIA TACCHINO",
            "ingredients": "PREPARAZIONE: IN UMIDO O AL FORNO",
            "plu": "031267"
        },
        {
            "_id": "790175",
            "name": "BISTECCA TACCHINO",
            "ingredients": "PREPARAZIONE: ALLA BRACE O AI FERRI",
            "plu": "031268"
        },
        {
            "_id": "790176",
            "name": "TACCHINA INTERA",
            "ingredients": "PREPARAZIONE: RIPIENA AL FORNO",
            "plu": "031269"
        },
        {
            "_id": "790177",
            "name": "CONIGLIO A META",
            "ingredients": "PREPARAZIONE: ALLA CACCIATORA",
            "plu": "031270"
        },
        {
            "_id": "790178",
            "name": "CONIGLIO INTERO",
            "ingredients": "PREPARAZIONE: ALLA CACCIATORA",
            "plu": "031271"
        },
        {
            "_id": "790179",
            "name": "COSCE CONIGLIO",
            "ingredients": "PREPARAZIONE: IN PADELLA O ALLA CACCIATORA",
            "plu": "031272"
        },
        {
            "_id": "790180",
            "name": "SPALLE CONIGLIO",
            "ingredients": "PREPARAZIONE: ALLA CACCIATORA",
            "plu": "031273"
        },
        {
            "_id": "790181",
            "name": "FILETTO CONIGLIO",
            "ingredients": "PREPARAZIONE: ALLA BRACE O AI FERRI",
            "plu": "031274"
        },
        {
            "_id": "790182",
            "name": "TACCHINELLA",
            "ingredients": "PREPARAZIONE: RIPIENA AL FORNO",
            "plu": "031275"
        },
        {
            "_id": "790183",
            "name": "CONIGLIO DISOSSATO",
            "ingredients": "PREPARAZIONE: RIPIENO AL FORNO.",
            "plu": "031276"
        },
        {
            "_id": "790184",
            "name": "FAGIANO",
            "ingredients": "PREPARAZIONE: LESSO O AL FORNO",
            "plu": "031277"
        },
        {
            "_id": "790185",
            "name": "LEPRE",
            "ingredients": "PREPARAZIONE: ALLA CACCIATORA",
            "plu": "031278"
        },
        {
            "_id": "790186",
            "name": "ARROSTO DISOSSATO BOVINO CONF.FAM.",
            "ingredients": "PREPARAZIONE: ALLA PIZZAIOLA O AI FERRI",
            "plu": "031300"
        },
        {
            "_id": "790187",
            "name": "PALETTINA BOVINO ADULTO CONF.FAM.",
            "ingredients": "PREPARAZIONE: IN PADELLA O AI FERRI",
            "plu": "031301"
        },
        {
            "_id": "790189",
            "name": "SPEZZATINO II TAGLIO BOV. AD. CONF.FAM.",
            "ingredients": "PREPARAZIONE: ALLA GENOVESE O AL RAGU",
            "plu": "031302"
        },
        {
            "_id": "790190",
            "name": "MACINATO II TAGLIO BOV. AD. CONF.FAM.",
            "ingredients": "Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: BOLOGNESE O POLPETTE",
            "plu": "031303"
        },
        {
            "_id": "790191",
            "name": "BIANCOSTATO C/O BOV. AD. CONF.FAM.",
            "ingredients": "PREPARAZIONE: LESSO",
            "plu": "031304"
        },
        {
            "_id": "790192",
            "name": "COLARDA BOVINO ADULTO CONF.FAMIGLIA",
            "ingredients": "PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031305"
        },
        {
            "_id": "790193",
            "name": "NATICA BOVINO ADULTO CONF.FAMIGLIA",
            "ingredients": "PREPARAZIONE: COTOLETTA SCALOPPINE O IN PADELLA",
            "plu": "031306"
        },
        {
            "_id": "790194",
            "name": "PEZZO A CANNELLA BOV. AD. CONF.FAM.",
            "ingredients": "PREPARAZIONE: COTOLETTE AI FERRI O ALLA PIZZAIOLA",
            "plu": "031307"
        },
        {
            "_id": "790195",
            "name": "DIETRO COSCIA BOV. AD. CONF.FAM.",
            "ingredients": "PREPARAZIONE: COTOLETTA O STRACCETTI",
            "plu": "031308"
        },
        {
            "_id": "790197",
            "name": "BEEF BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031320"
        },
        {
            "_id": "790198",
            "name": "SCORZETTE/ BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031321"
        },
        {
            "_id": "790199",
            "name": "FILETTO BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: AL PEPE O AI FERRI",
            "plu": "031322"
        },
        {
            "_id": "790200",
            "name": "GIRELLO BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: COTOLETTA O IN UMIDO",
            "plu": "031323"
        },
        {
            "_id": "790201",
            "name": "SOTTOFILETTO BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: SCALOPPINE",
            "plu": "031324"
        },
        {
            "_id": "790202",
            "name": "COLARDA BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031325"
        },
        {
            "_id": "790203",
            "name": "NATICA BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: COTOLETTA SCALOPPINE O IN PADELLA",
            "plu": "031326"
        },
        {
            "_id": "790205",
            "name": "PEZZO A CANNELLA BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: COTOLETTE AI FERRI O ALLA PIZZAIOLA",
            "plu": "031327"
        },
        {
            "_id": "790206",
            "name": "DIETRO COSCIA BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: COTOLETTA O STRACCETTI",
            "plu": "031328"
        },
        {
            "_id": "790207",
            "name": "ASADO BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: AI FERRI O AL FORNO",
            "plu": "031329"
        },
        {
            "_id": "790357",
            "name": "ASADO CHIANINA BOVINO ADULTO",
            "ingredients": "AUT.MIN. IT 045 ET PREPARAZIONE: AI FERRI O AL FORNO",
            "plu": "031509"
        },
        {
            "_id": "790358",
            "name": "OSSOBUCO CHIANINA BOVINO ADULTO",
            "ingredients": "AUT.MIN. IT 045 ET PREPARAZIONE: AL FORNO O IN PADELLA",
            "plu": "031510"
        },
        {
            "_id": "790359",
            "name": "HAMBURGER CHIANINA BOVINO ADULTO",
            "ingredients": "INGREDIENTI: bovino e sale. Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031511"
        },
        {
            "_id": "790361",
            "name": "MUSCOLO CHIANINA BOVINO ADULTO",
            "ingredients": "AUT.MIN. IT 045 ET PREPARAZIONE: LESSO",
            "plu": "031512"
        },
        {
            "_id": "790362",
            "name": "COSTATA CON OSSO CHIANINA BOVINO ADULTO",
            "ingredients": "AUT.MIN. IT 045 ET PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031513"
        },
        {
            "_id": "790363",
            "name": "ARROSTO DISOSSATO CHIANINA BOVINO ADULTO",
            "ingredients": "AUT.MIN. IT 045 ET PREPARAZIONE: ALLA PIZZAIOLA O AI FERRI",
            "plu": "031514"
        },
        {
            "_id": "790364",
            "name": "PALETTINA CHIANINA BOVINO ADULTO",
            "ingredients": "AUT.MIN. IT 045 ET PREPARAZIONE: IN PADELLA O AI FERRI",
            "plu": "031515"
        },
        {
            "_id": "790365",
            "name": "LACERTO CHIANINA BOVINO ADULTO",
            "ingredients": "AUT.MIN. IT 045 ET PREPARAZIONE: IN UMIDO O AL FORNO",
            "plu": "031516"
        },
        {
            "_id": "790366",
            "name": "CORAZZA/COPERTA CHIANINA BOVINO ADULTO",
            "ingredients": "AUT.MIN. IT 045 ET PREPARAZIONE: CARNE TRITA",
            "plu": "031517"
        },
        {
            "_id": "790367",
            "name": "PUNTA COLARDA CHIANINA BOVINO ADULTO",
            "ingredients": "AUT.MIN. IT 045 ET PREPARAZIONE: ALLA GENOVESE O AL RAGU",
            "plu": "031518"
        },
        {
            "_id": "790368",
            "name": "BOCCONCINI I TAGLIO CHIANINA BOVINO ADULTO",
            "ingredients": "RAZZA: CHIANINA AUT.MIN. IT 045 ET PREPARAZIONE: ALLA GENOVESE O AL RAGU",
            "plu": "031519"
        },
        {
            "_id": "790369",
            "name": "SPEZZATINO II TAGLIO CHIANINA BOVINO ADULTO",
            "ingredients": "AUT.MIN. IT 045 ET PREPARAZIONE: ALLA GENOVESE O AL RAGU",
            "plu": "031520"
        },
        {
            "_id": "790370",
            "name": "BIANCOSTATO CON OSSO CHIANINA BOVINO ADULTO",
            "ingredients": "AUT.MIN. IT 045 ET PREPARAZIONE: LESSO",
            "plu": "031521"
        },
        {
            "_id": "790371",
            "name": "PUNTA PETTO CHIANINA BOVINO ADULTO",
            "ingredients": "AUT.MIN. IT 045 ET PREPARAZIONE: ALLA GENOVESE O AL RAGU",
            "plu": "031522"
        },
        {
            "_id": "790372",
            "name": "MACINATO I TAGLIO CHIANINA BOVINO ADULTO",
            "ingredients": "(CHIANINA) AUT.MIN. IT 045 ET Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: BOLOGNESE O POLPETTE",
            "plu": "031523"
        },
        {
            "_id": "790373",
            "name": "MACINATO II TAGLIO CHIANINA BOVINO ADULTO",
            "ingredients": "Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: BOLOGNESE O POLPETTE",
            "plu": "031524"
        },
        {
            "_id": "790374",
            "name": "POLPA SCELTA CHIANINA BOVINO ADULTO",
            "ingredients": "AUT.MIN. IT 045 ET PREPARAZIONE: ALLA GENOVESE O AL RAGU",
            "plu": "031525"
        },
        {
            "_id": "790375",
            "name": "COTOLETTE CHIANINA BOVINO ADULTO",
            "ingredients": "AUT.MIN. IT 045 ET PREPARAZIONE: COTOLETTE",
            "plu": "031526"
        },
        {
            "_id": "790376",
            "name": "SCALOPPINE CHIANINA BOVINO ADULTO",
            "ingredients": "AUT.MIN. IT 045 ET PREPARAZIONE: SCALOPPINE",
            "plu": "031527"
        },
        {
            "_id": "790377",
            "name": "STRACCETTI CHIANINA BOVINO ADULTO",
            "ingredients": "AUT.MIN. IT 045 ET PREPARAZIONE: STRACCETTI",
            "plu": "031528"
        },
        {
            "_id": "790379",
            "name": "CARPACCIO CHIANINA BOVINO ADULTO",
            "ingredients": "AUT.MIN. IT 045 ET PREPARAZIONE: CARPACCIO",
            "plu": "031529"
        },
        {
            "_id": "790380",
            "name": "ROAST BEEF BOVINO ADULTO (SCOTTONA)",
            "ingredients": "SCOTTONA PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031031"
        },
        {
            "_id": "790382",
            "name": "LINGUA SUINO",
            "ingredients": "PREPARAZIONE: AL FORNO",
            "plu": "031163"
        },
        {
            "_id": "790384",
            "name": "PETTO POLLO INTERO GIALLO",
            "ingredients": "PREPARAZIONE: AL FORNO",
            "plu": "031279"
        },
        {
            "_id": "790385",
            "name": "PETTO POLLO A FETTE GIALLO",
            "ingredients": "PREPARAZIONE: COTOLETTE SCALOPPINE O IN PADELLA.",
            "plu": "031280"
        },
        {
            "_id": "790387",
            "name": "COSCIOTTO POLLO GIALLO",
            "ingredients": "PREPARAZIONE: AL FORNO O LESSO",
            "plu": "031281"
        },
        {
            "_id": "790388",
            "name": "ROAST BEEF BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: AI FERRI ALLA BRACE O AL FORNO.",
            "plu": "031349"
        },
        {
            "_id": "790389",
            "name": "CROCCHE FARCITI BOVINO ADULTO (SCOTTONA)",
            "ingredients": "INGREDIENTI:bovino adulto prosc.cotto(può contenere proteine del LATTE) FORMAGGIOPANE grattato sale pepe UOVA farina di mais PANE raffermo prezzemolo. Può contenere SOIA SENAPE. PREPARAZIONE: IN PADELLA O AL FORNO",
            "plu": "031466"
        },
        {
            "_id": "790406",
            "name": "BEEF VITELLONE",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031530"
        },
        {
            "_id": "790407",
            "name": "SCORZETTA VITELLONE",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031531"
        },
        {
            "_id": "790408",
            "name": "FILETTO VITELLONE",
            "ingredients": "PREPARAZIONE: AL PEPE O AI FERRI",
            "plu": "031532"
        },
        {
            "_id": "790409",
            "name": "GIRELLO VITELLONE",
            "ingredients": "PREPARAZIONE: COTOLETTA O IN UMIDO",
            "plu": "031533"
        },
        {
            "_id": "790411",
            "name": "SOTTOFILETTO VITELLONE",
            "ingredients": "PREPARAZIONE: SCALOPPINE",
            "plu": "031534"
        },
        {
            "_id": "790412",
            "name": "COLARDA VITELLONE",
            "ingredients": "PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031535"
        },
        {
            "_id": "790413",
            "name": "NATICA VITELLONE",
            "ingredients": "PREPARAZIONE: COTOLETTA SCALOPPINE O IN PADELLA",
            "plu": "031536"
        },
        {
            "_id": "790414",
            "name": "PEZZO A CANNELLA VITELLONE",
            "ingredients": "PREPARAZIONE: COTOLETTE AI FERRI O ALLA PIZZAIOLA",
            "plu": "031537"
        },
        {
            "_id": "790415",
            "name": "DIETRO COSCIA VITELLONE",
            "ingredients": "PREPARAZIONE: COTOLETTA O STRACCETTI",
            "plu": "031538"
        },
        {
            "_id": "790416",
            "name": "ASADO VITELLONE",
            "ingredients": "PREPARAZIONE: AI FERRI O AL FORNO",
            "plu": "031539"
        },
        {
            "_id": "790417",
            "name": "OSSOBUCO VITELLONE",
            "ingredients": "PREPARAZIONE: AL FORNO O IN PADELLA",
            "plu": "031540"
        },
        {
            "_id": "790418",
            "name": "HAMBURGER VITELLONE",
            "ingredients": "INGREDIENTI: vitellone e sale. Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031541"
        },
        {
            "_id": "790419",
            "name": "MUSCOLO VITELLONE",
            "ingredients": "PREPARAZIONE: LESSO",
            "plu": "031542"
        },
        {
            "_id": "790420",
            "name": "COSTATA CON OSSO VITELLONE",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031543"
        },
        {
            "_id": "790421",
            "name": "ARROSTO DISOSSATO VITELLONE",
            "ingredients": "PREPARAZIONE: ALLA PIZZAIOLA O AI FERRI",
            "plu": "031544"
        },
        {
            "_id": "790422",
            "name": "PALETTINA VITELLONE",
            "ingredients": "PREPARAZIONE: IN PADELLA O AI FERRI",
            "plu": "031545"
        },
        {
            "_id": "790423",
            "name": "LACERTO VITELLONE",
            "ingredients": "PREPARAZIONE: IN UMIDO O AL FORNO",
            "plu": "031546"
        },
        {
            "_id": "790424",
            "name": "CORAZZA/COPERTA VITELLONE",
            "ingredients": "PREPARAZIONE: CARNE TRITA",
            "plu": "031547"
        },
        {
            "_id": "790425",
            "name": "PUNTA COLARDA DI VITELLONE",
            "ingredients": "PREPARAZIONE: ALLA GENOVESE O AL RAGU",
            "plu": "031548"
        },
        {
            "_id": "790426",
            "name": "BOCCONCINI I TAGLIO VITELLONE",
            "ingredients": "PREPARAZIONE: ALLA GENOVESE O AL RAGU",
            "plu": "031549"
        },
        {
            "_id": "790427",
            "name": "SPEZZATINO II TAGLIO VITELLONE",
            "ingredients": "PREPARAZIONE: ALLA GENOVESE O AL RAGU",
            "plu": "031550"
        },
        {
            "_id": "790429",
            "name": "BIANCOSTATO C/O VITELLONE",
            "ingredients": "PREPARAZIONE: LESSO",
            "plu": "031551"
        },
        {
            "_id": "790430",
            "name": "PUNTA PETTO VITELLONE",
            "ingredients": "PREPARAZIONE: ALLA GENOVESE O AL RAGU",
            "plu": "031552"
        },
        {
            "_id": "790431",
            "name": "MACINATO I TAGLIO VITELLONE",
            "ingredients": "Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: BOLOGNESE O POLPETTE",
            "plu": "031553"
        },
        {
            "_id": "790432",
            "name": "MACINATO II TAGLIO VITELLONE",
            "ingredients": "Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: BOLOGNESE O POLPETTE",
            "plu": "031554"
        },
        {
            "_id": "790433",
            "name": "POLPA SCELTA VITELLONE",
            "ingredients": "PREPARAZIONE: ALLA GENOVESE O AL RAGU",
            "plu": "031555"
        },
        {
            "_id": "790434",
            "name": "STRACCETTI VITELLONE",
            "ingredients": "PREPARAZIONE: STRACCETTI",
            "plu": "031556"
        },
        {
            "_id": "790435",
            "name": "CARPACCIO VITELLONE",
            "ingredients": "PREPARAZIONE: CARPACCIO",
            "plu": "031557"
        },
        {
            "_id": "790437",
            "name": "BISTECCA TIPO FIORENTINA VITELLONE",
            "ingredients": "PREPARAZIONE: ALLA BRACE O AI FERRI",
            "plu": "031558"
        },
        {
            "_id": "790438",
            "name": "ROAST BEEF VITELLONE",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031559"
        },
        {
            "_id": "790439",
            "name": "PETTO POLLO A FETTE CONF. FAM",
            "ingredients": "PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031600"
        },
        {
            "_id": "790440",
            "name": "COSCIOTTO POLLO CONF. FAM.",
            "ingredients": "PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031601"
        },
        {
            "_id": "790441",
            "name": "COSCETTE ANATOM.POLLO CONF.FAM",
            "ingredients": "PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031602"
        },
        {
            "_id": "790442",
            "name": "FUSELLI POLLO CONF. FAM.",
            "ingredients": "PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031603"
        },
        {
            "_id": "790443",
            "name": "SOVRACOSCE POLLO CONF. FAM.",
            "ingredients": "PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031604"
        },
        {
            "_id": "790445",
            "name": "MACINATO EQUINO",
            "ingredients": "Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: BOLOGNESE O POLPETTE",
            "plu": "031570"
        },
        {
            "_id": "790446",
            "name": "FESA EQUINO",
            "ingredients": "PREPARAZIONE: COTOLETTE O SCALOPPINE",
            "plu": "031571"
        },
        {
            "_id": "790447",
            "name": "GIRELLO DI COSCIA EQUINO",
            "ingredients": "PREPARAZIONE: COTOLETTA O IN UMIDO",
            "plu": "031572"
        },
        {
            "_id": "790448",
            "name": "DIETRO COSCIA EQUINO",
            "ingredients": "PREPARAZIONE: COTOLETTA O STRACCETTI",
            "plu": "031573"
        },
        {
            "_id": "790449",
            "name": "COLARDA EQUINO",
            "ingredients": "PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031574"
        },
        {
            "_id": "790450",
            "name": "NOCE EQUINO",
            "ingredients": "PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031575"
        },
        {
            "_id": "790452",
            "name": "ARROSTO DI BEEF EQUINO",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031576"
        },
        {
            "_id": "790453",
            "name": "COSTATA CON OSSO EQUINO",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031577"
        },
        {
            "_id": "790454",
            "name": "FILETTO EQUINO",
            "ingredients": "PREPARAZIONE: AL PEPE O AI FERRI",
            "plu": "031578"
        },
        {
            "_id": "790455",
            "name": "SPEZZATINO EQUINO",
            "ingredients": "PREPARAZIONE: AL RAGU",
            "plu": "031579"
        },
        {
            "_id": "790456",
            "name": "BOLLITO CON OSSO EQUINO",
            "ingredients": "PREPARAZIONE: LESSO",
            "plu": "031580"
        },
        {
            "_id": "790457",
            "name": "MUSCOLO EQUINO",
            "ingredients": "PREPARAZIONE: LESSO",
            "plu": "031581"
        },
        {
            "_id": "790458",
            "name": "LACERTO DI SPALLA EQUINO",
            "ingredients": "PREPARAZIONE: IN UMIDO O AL FORNO",
            "plu": "031582"
        },
        {
            "_id": "790459",
            "name": "PALETTINA EQUINO",
            "ingredients": "PREPARAZIONE: IN PADELLA O AI FERRI",
            "plu": "031583"
        },
        {
            "_id": "790460",
            "name": "ARROSTO DISOSSATO EQUINO",
            "ingredients": "PREPARAZIONE: ALLA PIZZAIOLA O AI FERRI",
            "plu": "031584"
        },
        {
            "_id": "790461",
            "name": "PUNTA DI PETTO EQUINO",
            "ingredients": "PREPARAZIONE: IN PADELLA O AI FERRI",
            "plu": "031585"
        },
        {
            "_id": "790462",
            "name": "SALSICCIA EQUINO",
            "ingredients": "Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031586"
        },
        {
            "_id": "790463",
            "name": "HAMBURGER EQUINO",
            "ingredients": "Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031587"
        },
        {
            "_id": "790464",
            "name": "SALSICCIA PICCANTE SUINO",
            "ingredients": "INGREDIENTI: suino sale pepe e peperoncino. Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031481"
        },
        {
            "_id": "790468",
            "name": "SALSICCIA SUINO RUCOLA&PROVOLA",
            "ingredients": "INGREDIENTI: suino sale pepe rucola e FORMAGGIO(PROVOLA). Può contenere UOVAGLUTINE. PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031607"
        },
        {
            "_id": "790470",
            "name": "SCALOPPINE BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: SCALOPPINE",
            "plu": "031608"
        },
        {
            "_id": "790471",
            "name": "SALSICCIA SUINO MIGNON",
            "ingredients": "INGREDIENTI: suino sale e pepe. Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031609"
        },
        {
            "_id": "790472",
            "name": "MACINATO MISTO",
            "ingredients": "INGREDIENTI: bovino e suino. Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: BOLOGNESE O POLPETTE",
            "plu": "031610"
        },
        {
            "_id": "790473",
            "name": "SALSICCIA LUGANEGA DI SUINO",
            "ingredients": "INGREDIENTI: carne di suinosale vino bianco(SOLFITI)antiossidante acido ascorbico estratto vegetale concentrato di barbabietola Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031611"
        },
        {
            "_id": "790512",
            "name": "CUORE REALE LEGAT BOV.A.IRL SV",
            "ingredients": "PREPARAZIONE: AI FERRI O AL FORNO",
            "plu": "031647"
        },
        {
            "_id": "790513",
            "name": "HAMBURGER BOVINO ADULTO 1°TAGLIO",
            "ingredients": "INGREDIENTI:bovino adulto sale e pepe. Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031646"
        },
        {
            "_id": "790514",
            "name": "CUORE REALE LEGATO B.A. UK SV",
            "ingredients": "PREPARAZIONE: ALLA BRACE O ALLA PIASTRA",
            "plu": "031648"
        },
        {
            "_id": "790515",
            "name": "FUSELLO BOV.ADULTO IRLANDA SV",
            "ingredients": "PREPARAZIONE: IN UMIDO O AL FORNO",
            "plu": "031649"
        },
        {
            "_id": "790516",
            "name": "NOCE BOVINO ADULTO IRLANDA SV",
            "ingredients": "PREPARAZIONE: PER TAGLIATA/PADELLA/COTOLETTA",
            "plu": "031650"
        },
        {
            "_id": "790520",
            "name": "ARROSTO DI BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: IN UMIDO O AL FORNO",
            "plu": "031653"
        },
        {
            "_id": "790521",
            "name": "TAGLIATA DI BOVINO ADULTO",
            "ingredients": "PREPARAZIONE: AI FERRI O ALLA BRACE",
            "plu": "031654"
        },
        {
            "_id": "790522",
            "name": "FETTINE DI COSCIA BOV.ADULTO",
            "ingredients": "PREPARAZIONE: AI FERRI PADELLA O COTOLETTA",
            "plu": "031655"
        },
        {
            "_id": "790529",
            "name": "MAXI BURGER BOV.ADULTO AL KG",
            "ingredients": "INGREDIENTI: bovino adulto sale GRANA PADANO. Può contenere UOVAGLUTINE. PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031661"
        },
        {
            "_id": "790530",
            "name": "MAXI BURGER BOV.AD.SCOTTONA KG",
            "ingredients": "INGREDIENTI: bovino adulto sale GRANA PADANO. Può contenere UOVAGLUTINE. PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031662"
        },
        {
            "_id": "790532",
            "name": "TOMAHAWK INTERO IRLANDA SV",
            "ingredients": "PREPARAZIONE: ALLA BRACE",
            "plu": "031671"
        },
        {
            "_id": "790533",
            "name": "TOMAHAWK PORZIONATO IRLANDA SV",
            "ingredients": "PREPARAZIONE: ALLA BRACE",
            "plu": "031672"
        },
        {
            "_id": "790539",
            "name": "LOMBATA A.ANGUS SCOZIA 5C SV",
            "ingredients": "PREPARAZIONE: ALLA BRACE",
            "plu": "031678"
        },
        {
            "_id": "790548",
            "name": "LOMBATA VITELLO LATTE OLANDA",
            "ingredients": "PREPARAZIONE: PER LA BRACE",
            "plu": "031688"
        },
        {
            "_id": "790549",
            "name": "NOCE VITELLO DA LATTE OLANDA",
            "ingredients": "PREPARAZIONE: IN PADELLA",
            "plu": "031689"
        },
        {
            "_id": "790551",
            "name": "REALE S/OSSO VITELLO DA LATTE",
            "ingredients": "PREPARAZIONE: IN PADELLA",
            "plu": "031690"
        },
        {
            "_id": "790559",
            "name": "FILETTO MARCHIGIANA BOVINO ADULTO",
            "ingredients": "RAZZA:MARCHIGIANA AUT.MIN. IT 045 ET -AGRIFAP SRL PREPARAZIONE: AL PEPE O AI FERRI",
            "plu": "031700"
        },
        {
            "_id": "790560",
            "name": "COSTATA CON OSSO MARCHIGIANA BOVINO ADULTO",
            "ingredients": "RAZZA:MARCHIGIANA AUT.MIN. IT 045 ET -AGRIFAP SRL PREPARAZIONE: AI FERRI",
            "plu": "031701"
        },
        {
            "_id": "790561",
            "name": "LOMBATA MARCHIGIANA BOVINO ADULTO",
            "ingredients": "RAZZA:MARCHIGIANA AUT.MIN. IT 045 ET -AGRIFAP SRL PREPARAZIONE: AI FERRI",
            "plu": "031702"
        },
        {
            "_id": "790578",
            "name": "FETTINE ANTERIORE BOVINO ADULTO BLACK ANGUS IRLAND",
            "ingredients": "PREPARAZIONE: PER LA PIASTRA",
            "plu": "031718"
        },
        {
            "_id": "790579",
            "name": "HAMBURGER BOV.AD.BL.ANGUS IRL.",
            "ingredients": "Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: IN PADELLA O ALLA GRIGLIA",
            "plu": "031719"
        },
        {
            "_id": "790582",
            "name": "HAMBURGER BLACK ANGUS USA A KG",
            "ingredients": "INGREDIENTI: bovino adulto sale. Può contenere LATTEUOVAGLUTINE. PREPARAZIONE: AI FERRI O IN PADELLA",
            "plu": "031722"
        },
        {
            "_id": "790583",
            "name": "GIRELLO E NOCE BOV.AD.BIO IRL.",
            "ingredients": "PREPARAZIONE: SCALOPPINE O COTOLETTA",
            "plu": "031723"
        },
        {
            "_id": "790586",
            "name": "FETTINE COSCIA BOV.AD.(SCOTTONA)",
            "ingredients": "PREPARAZIONE: AI FERRI O IN PADELLA.",
            "plu": "031033"
        },
        {
            "_id": "790589",
            "name": "INVOLTINI POLLO",
            "ingredients": "INGREDIENTI: AROMI NATURALI",
            "plu": "031035"
        },
        {
            "_id": "790684",
            "name": "MARTELLI SALAMELLA CHICCHI BUSTA ATM",
            "ingredients": "carne di suino sale destrosio aromi naturali correttore di acidità: E262 antiossidanti: E301 E300",
            "plu": "031789"
        },
        {
            "_id": "790685",
            "name": "MARTELLI SALAMELLA BUSTA ATM",
            "ingredients": "carne di suino sale destrosio aromi naturali correttore di acidità: E262 antiossidanti:E301 E300",
            "plu": "031790"
        },
        {
            "_id": "790734",
            "name": "COA HAMBURGER CON PANCETTA",
            "ingredients": "INGREDIENTI:Carne di suino 50.3% carne di bovino 26% pancetta fresca di suino 11.4% acquasalefibra vegetalefarina di pisello correttori di acidità:E262 antiossidanti:E301 E300barbabietola in polverearomaestratti di spezieestratto di patatefibre di paprica fiocchi di patate(patate disidratate 99% emulsionante:E471 stabilizzante:E450spezie conservante:E223 METABISOLFITO di sodio antiossidante:E304 aroma naturalecorrettori di acidità:E330)E325E331rosmarino 04%. Può contenere LATTE UOVAGLUTINE.",
            "plu": "031829"
        },
        {
            "_id": "790735",
            "name": "COA HAMBURGER SUINO RUCOLA",
            "ingredients": "INGREDIENTI:Carne di suino 77%FORMAGGIO 9% (LATTEsalecagliofermenti lattici)acqua rucola 35%salefibra vegetalefarina di pisellocorrettori di acidità:E262 antiossidanti:E301E300barbabietola in polvere aromaestratti di spezieestratto di paprica fiocchi di patate (patate disidratate 99% emulsionante:E471)E325E331cipolla disidratata. Può contenere GLUTINE UOVA.",
            "plu": "031830"
        },
        {
            "_id": "790736",
            "name": "GC HAMBUR PROSCIUT E FORMAG",
            "ingredients": "INGREDIENTI:Carne di suino 54%carne di bovino 23% acqua prosciutto cotto 6% (coscia suina 86%acquasaleamidi di patata aromi e spezieantiossidante: E316 conservante: E250)FORMAGGIO affumicato 3% (LATTE vaccino salecagliofumo naturale)FORMAGGIO Emmental 3% (LATTE vaccinofermenti latticicagliosale) FORMAGGIO grattugiato (LATTE vaccinosalecaglio conservante:lisozima da UOVO)fiocchi di patatefibre vegetalii amidodestrosio saccarosio aromi naturaliantiossidanti:E300E301 correttore di acidità:E331sale prezzemolo disid. Può contenere SOIA SESAMO e GLUTINE.",
            "plu": "031831"
        },
        {
            "_id": "790737",
            "name": "COA TURBANTI DI ZUCCHINE",
            "ingredients": "INGREDIENTI:Impasto di carne 36% (bovino adulto 44.5%carne di suino 29.8% misto UOVA acquaFORMAGGIO(LATTEsalecagliofermenti lattici) salefibra vegetale farina di pisellocorrettori di acidità:E262antiossidanti:E301E300 barbabietola in polvere aromaestratti di spezie estratto di papricafiocchi di patate(patate disidratate in fiocchiemulsionante:E471)AGLIO) pancetta di suino 33.6% zucchine 27.9%pangrattato (farina di FRUMENTO(GLUTINE)acquasalelievito estratti di spezie: curcuma e paprica<2%). Può contenere: SOIASENAPE.",
            "plu": "031832"
        },
        {
            "_id": "790739",
            "name": "GC HAMBURGER AI FRIARIELLI",
            "ingredients": "INGREDIENTI:Carne di suino 47% carne di bovino 21%broccoli precotti 18% (broccoli acqua olio di semi di girasoleaglio(SOLFITI) peperoncinosale)acquaFORMAGGIO grattugiato (LATTE vaccinosalecaglioconservante:lisozima da UOVO)salefiocchi di patatefibre vegetali alimentariamidoaromi naturaliantiossidanti: E300E301aroma.Può contenere SOIASESAMO e GLUTINE.",
            "plu": "031834"
        },
        {
            "_id": "790740",
            "name": "COA SPIEDINI RUSTICI",
            "ingredients": "INGREDIENTI:Carne di suino 28% impasto di salsiccia 28% (carne di suino 87% sale non iodato fibra vegetale stabilizzanti: E331 correttore di acidità: E262destrosio barbabietola antiossidanti: E301 E300sciroppo di glucosio estratto di paprica estratti di spezie pepe nero pepe biancoaglio (contiene SOLFITI)) coscia di pollo 21% coscia di tacchino 21%alloro. Può contenere GLUTINELATTEUOVA. Origine suino: UE.",
            "plu": "031835"
        },
        {
            "_id": "790848",
            "name": "IMPASTO SALSIC SUINO KG SV",
            "ingredients": "INGREDIENTI: Carne di suino sale saccarosio destrosio spezie vino bianco (SOLFITI) aromi Naturali aroma antiossidanti: E300. Può contenere GLUTINELATTEUOVA",
            "plu": "031875"
        },
        {
            "_id": "790849",
            "name": "SALSICCIA SUINO AL KG",
            "ingredients": "INGREDIENTI: Carne di suino sale saccarosio destrosio spezie vino bianco (SOLFITI) aromi naturali aroma. antiossidanti: E300. Può contenere GLUTINELATTEUOVA",
            "plu": "031872"
        },
        {
            "_id": "790850",
            "name": "SALSICCIA C/FINOC SUINO KG",
            "ingredients": "INGREDIENTI:Carne di suino sale saccarosio destrosio spezie; finocchio (02%) vino bianco (SOLFITI) aromi naturali aromi. antiossidanti: E 300. Può contenere GLUTINE LATTEUOVA",
            "plu": "031873"
        },
        {
            "_id": "790851",
            "name": "SALSICCIA CERVELLATA SUINO KF",
            "ingredients": "INGREDIENTI: Carne di suino sale saccarosio destrosio spezie vino bianco (SOLFITI) aromi naturali aroma antiossidanti: E300. Può contenere GLUTINELATTEUOVA",
            "plu": "031874"
        },
        {
            "_id": "790888",
            "name": "HAMBURG VACA RUBIA GALLEGA KG",
            "ingredients": "INGREDIENTI: Carne di bovino adulto Rubia Gallega 84% acqua fiocchi di patate fibre vegetali sale destrosio saccarosio antiossidanti: E300E301 aromi naturali correttore di acidità: E331. Può contenere UOVASOIASESAMOLATTEGLUTINE.",
            "plu": "031908"
        },
        {
            "_id": "790900",
            "name": "COA HAMBURGER RUBIA GALLEGA SKIN AL KG",
            "ingredients": "INGREDIENTI: Carne di bovino adulto Rubia Gallega 84% acqua fiocchi di patate fibre vegetali sale destrosio saccarosio antiossidanti: E300E301 aromi naturali correttore di acidità: E331. Può contenere UOVASOIASESAMOLATTEGLUTINE.",
            "plu": "032507"
        },
        {
            "_id": "790977",
            "name": "GC COTOLETTA POLLO MULTICEREALI",
            "ingredients": "INGREDIENTI: Carne di pollo 54% farina di GRANO tenero tipo 0 lievito semi di: ORZO AVENA grano saraceno FARRO lino girasole farina di mais crema di riso misto d'UOVO polvere.Addensanti/Gelificanti:E412. Può contenere SOIA SEMI DI SESAMOLATTE.",
            "plu": "031933"
        },
        {
            "_id": "790981",
            "name": "HAMBURGER SUINO NERO AL KG",
            "ingredients": "INGREDIENTI: Carne di suino 87% acqua sale destrosio saccarosio antiossidanti: E300 E301 aromi naturali correttore di acidità: E331 pepe nero. Può contenere UOVA SOIA SEMI DI SESAMO LATTE GLUTINE.",
            "plu": "031936"
        },
        {
            "_id": "791099",
            "name": "GC SALSICCIA SUINO",
            "ingredients": "INGREDIENTI: Carne di suino 83% acqua destrosio saccarosio antiossidanti: E300 E301 aromi naturali correttore di acidità: E331 sale pepe nero. Può contenere GLUTINE SOIA SEMI DI SESAMO UOVA e LATTE",
            "plu": "031954"
        },
        {
            "_id": "791100",
            "name": "GC SALSICCIA C/FINOC SUINO",
            "ingredients": "INGREDIENTI: Carne di suino 82% acqua destrosio saccarosio sale antiossidanti: E300 E301 aromi naturali correttore di acidità: E331 semi di finocchio volgare 025% pepe nero. Può contenere GLUTINE SOIA SEMI DI SESAMO UOVA e LATTE",
            "plu": "031955"
        },
        {
            "_id": "791119",
            "name": "COA INVOLTINI POLLO",
            "ingredients": "INGREDIENTI: Carne di pollo 50% carne di suino rosmarino pepe nero. Può contenere GLUTINE UOVA LATTE SOLFITI. Origine suino: UE",
            "plu": "031958"
        },
        {
            "_id": "791121",
            "name": "COA INVOLTINI TACCHINO AL KG",
            "ingredients": "INGREDIENTI:carne di tacchino 80.6%pancetta di suino 17%rosmarino sale ginepro origano salvia prezzemolo alloro timo AGLIO (contiene SOLFITI). Può contenere: GLUTINE UOVA LATTE. .",
            "plu": "031960"
        },
        {
            "_id": "791124",
            "name": "GC MACINATO TACCHINO AL KG",
            "ingredients": "INGREDIENTI: Carne di tacchino 95% acqua destrosio saccarosio antiossidanti: E300 E301 aromi naturali correttore di acidità: E331. Può contenere GLUTINE SOIA SESAMO UOVA e PROT. LATTE Conservare tra 0-4°C",
            "plu": "031963"
        },
        {
            "_id": "791125",
            "name": "COA ROLLE TACCHINO",
            "ingredients": "INGREDIENTI:Carne di tacchino 48%impasto di salsiccia 29%(carne di suino 87%acquasale non iodatofibra vegetalestabilizzanti:E331 correttore di acidità:E262destrosio barbabietola antiossidanti:E301E300sciroppo di glucosio estratto di papricaestratti di speziepepe neropepe biancoAGLIO (contiene SOLFITI) pancetta di suino 22.5% rosmarino. Può contenere:GLUTINEUOVALATTE.",
            "plu": "031964"
        },
        {
            "_id": "791126",
            "name": "GC SOVRACOSCIA TACCHINO AL KG",
            "ingredients": "INGREDIENTI: Carne di tacchino Può contenere GLUTINE SOIA SESAMO UOVA e PROT. LATTE Conservare tra 0-4°C",
            "plu": "031965"
        },
        {
            "_id": "791155",
            "name": "GC MACINATO POLLO AL KG",
            "ingredients": "INGREDIENTI: Carne di pollo 98% destrosio fibre vegetali sale (NaCl) antiossidanti: E 300 E301. Correttore di acidità: E 262 E 331. Può contenere GLUTINE SOIA SESAMO UOVA e PROT. LATTE",
            "plu": "031978"
        },
        {
            "_id": "791156",
            "name": "COA BISTECCA POLLO SKIN AL KG",
            "ingredients": "INGREDIENTI: Coscia di pollo 98%salsa marinata mediterranea(grassi ed oli vegetalisale non iodatospeziearomazucchero). Può contenere GLUTINEUOVALATTE.",
            "plu": "031979"
        },
        {
            "_id": "791158",
            "name": "GC SALSICCIA TACCHINO",
            "ingredients": "INGREDIENTI: Carne di tacchino 86% acqua sale patate disidratate in fiocchi destrosio antiossidanti: E300 E301 aromi saccarosio regolatore di acidità: E262 E331 aromi naturali. Può contenere GLUTINE SOIA SESAMO UOVA e PROT. LATTE",
            "plu": "031980"
        },
        {
            "_id": "791184",
            "name": "BOMBETTA PUGLIESE AL KG",
            "ingredients": "INGREDIENTI: Carne suina ( coppa e pancetta) provolone semistagionato (LATTEsale caglio) sale spezie. Può contenere GLUTINEUOVA.",
            "plu": "032007"
        },
        {
            "_id": "791204",
            "name": "HAMBURGER SUINO ITALIA",
            "ingredients": "INGREDIENTI: Suino sale pepe. Può contenere LATTE UOVA GLUTINE.",
            "plu": "032026"
        },
        {
            "_id": "791213",
            "name": "SALSICCIA SUINO ITALIANO",
            "ingredients": "INGREDIENTI: Suino sale pepe. Può contenere LATTE UOVA GLUTINE.",
            "plu": "032033"
        },
        {
            "_id": "791214",
            "name": "SALSICCIA SUINO ITALIANO CONFEZIONE FAMIGLIA",
            "ingredients": "INGREDIENTI: Suinosalepepe. Può contenere LATTE UOVA GLUTINE.",
            "plu": "032034"
        },
        {
            "_id": "791487",
            "name": "SALSICCIA SUINO NERO PUNTA COLTELLO KG",
            "ingredients": "INGREDIENTI: Carne suino sale destrosio aromi e spezie antiossidanti (E300) correttore di acidità (E262 E331) fibre alimentaribudello naturali.Può contenere LATTE GLUTINEUOVA.",
            "plu": "032407"
        },
        {
            "_id": "791488",
            "name": "SALSICCIA SUINO NERO AL KG",
            "ingredients": "INGREDIENTI: Carne suino sale destrosio aromi e spezie antiossidanti (E300) correttore di acidità (E262 E331) fibre alimentaribudello naturale.Può contenere LATTE GLUTINEUOVA.",
            "plu": "032406"
        },
        {
            "_id": "791547",
            "name": "COA ROLLE VITELLO DA LATTE SOTTOVUOTO",
            "ingredients": "INGREDIENTI:Carne di vitello 90%ripieno mortadella(carne di suinotrippino di suino acquaamidisalearomi naturalidestrosio spezieantiossidanti: E316 conservante: E250) carotaSEDANOsalegineprooriganorosmarino salviaprezzemoloallorotimoaglio (contiene SOLFITI).Può contenere PISTACCHIOLATTEUOVA GLUTINE.",
            "plu": "032446"
        },
        {
            "_id": "791552",
            "name": "COA INVOLTINI MELANZANA",
            "ingredients": "INGREDIENTI:Impasto di carne 60% (bovino adulto 445%carne di suino 298% misto UOVA acqua formaggio(LATTEsalecagliofermenti lattici) salefibra vegetale farina di pisellocorrettori di acidità:E262antiossidanti:E301E300barbabietola in polvere aromaestratti di spezie estratto di papricafiocchi di patate(patate disidratate in emulsionante:E471)aglio (contine SOLFITI) melanzane 18%PANE grattugiato(farina di GRANO tipo0acquasalelievito).Può contenere SOIASENAPE. Origine suino: UE",
            "plu": "032449"
        },
        {
            "_id": "791553",
            "name": "COA VERZA RIPIENA",
            "ingredients": "INGREDIENTI:Impasto di carne 67%(carne di bovino adulto 445%carne di suino 298% acqua misto UOVA formaggio(LATTEsalecagliofermenti lattici) sale fibra vegetale farina di pisello correttori di acidità:E262antiossidanti:E301 E300 barbabietola in polvere aroma estratti di spezie estratto di paprica fiocchi di patate (patate disidratate in fiocchiemulsionante:E471)aglio (contiene SOLFITI)) pancetta di suino 23%verza 10%.Può contenere GLUTINE. Origine suino: UE",
            "plu": "032454"
        },
        {
            "_id": "791554",
            "name": "COA SACOTTINO PROSCIUTTO E FORMAGGIO",
            "ingredients": "INGREDIENTI:Carne di suino 66%PANE grattugiato (farina di GRANO tenero tipo 0acqua sale lievito)prosciutto cotto 92%(carne di suino 76% acquaamidi salearomidestrosio zucchero stabilizzante:E407gelatina alimentareantiossidante: E316conservante:E250) formaggio 71%(LATTE salecagliofermenti lattici)misto UOVAfarina di GRANO tenero tipo 00. Può contenere SOIASENAPE SOLFITI. Origine suino:UE.",
            "plu": "032452"
        },
        {
            "_id": "791555",
            "name": "COA POLPETTON PROSCIUTTO E FORMAGGIO",
            "ingredients": "INGREDIENTI:Impasto di carne 75%(carne di bovino adulto 445% carne di suino 298% acqua misto UOVA formaggio(LATTEsalecaglio fermenti lattici) sale fibra vegetale farina di pisellocorrettori di acidità: E262 antiossidanti:E301E300 barbabietola in polvere aromaestratti di spezieestratto di paprica fiocchi di patate(patate disidratate in fiocchi emulsionante:E471)aglio (contiene SOLFITI) prosciutto cotto15%(carne di suino76%acqua acquaamidisalearomidestrosiozucchero stabilizzante:E407gelatina alimentareantiossidante: E316conservante:E250) formaggio10%(LATTEsale fermenti latticicaglio).Può contenere GLUTINE. Origine suino: UE",
            "plu": "032451"
        },
        {
            "_id": "791556",
            "name": "COA SCHIACCIATINE",
            "ingredients": "INGREDIENTI:Impasto di carne 87%(carne di bovino adulto 445%carne di suino 298% acqua misto UOVAformaggio (LATTEsalecagliofermenti lattici) sale fibra vegetale farina di pisellocorrettori di acidità:E262 antiossidanti:E301E300barbabietola in polvere aromaestratti di spezie estratto di paprica fiocchi di patate(patate disidratate in fiocchi emulsionante:E471)aglio (contiene SOLFITI) PANE grattugiato (farina di GRANO tenero tipo0acqua salelievito). Può contenere SOIA SENAPE. Origine suino: UE.",
            "plu": "032453"
        },
        {
            "_id": "791557",
            "name": "COA COSCIA POLLO PANATA",
            "ingredients": "INGREDIENTI:coscia di pollo 80%misto UOVA PANE grattugiato (farina di GRANO tenero tipo 0 acqua sale lievito) farina di GRANO tenero tipo 00 sale.Può contenere LATTE SOLFITISOIASENAPE.",
            "plu": "032433"
        },
        {
            "_id": "791563",
            "name": "CIOLI EGIDIO SALSICCIA ARICCIA MACINATA ATM",
            "ingredients": "INGREDIENTI: Carne suina 88% (Origine italia) acquasalearomiaromi naturalispezie destrosio fibra vegetale (pisello) pepeaglio (contiene SOLFITI) peperoncino. Può contenere UOVALATTE GLUTINE.",
            "plu": "032459"
        },
        {
            "_id": "791564",
            "name": "CIOLI EGIDIO SALSICCIA ARICCIA PUNTA COLTELLO KG",
            "ingredients": "INGREDIENTI: Carne suina 88% (Origine italia) acquasalearomiaromi naturalispezie destrosio fibra vegetale (pisello) pepeaglio (contiene SOLFITI) peperoncino. Può contenere UOVALATTE GLUTINE.",
            "plu": "032463"
        },
        {
            "_id": "791565",
            "name": "CIOLI EGIDIO SALSICCIA ARICCIA PUNTA COLTELLO ATM",
            "ingredients": "INGREDIENTI: Carne suina 88% (Origine italia) acquasalearomiaromi naturalispezie destrosio fibra vegetale (pisello) pepeaglio (contiene SOLFITI) peperoncino. Può contenere UOVALATTE GLUTINE.",
            "plu": "032460"
        },
        {
            "_id": "791567",
            "name": "CIOLI EGIDIO SALSICCIA ARICCIA CON PROSCIUTTO KG",
            "ingredients": "INGREDIENTI: Carne suina 88% (Origine italia) acquasalearomiaromi naturalispezie destrosio fibra vegetale (pisello) pepeaglio (contiene SOLFITI) peperoncino. Può contenere UOVALATTE GLUTINE.",
            "plu": "032464"
        },
        {
            "_id": "791568",
            "name": "CIOLI EGIDIO SALSICCIA ARICCIA CON PROSCIUTTO ATM",
            "ingredients": "INGREDIENTI: Carne suina 88% (Origine italia) acquasalearomiaromi naturalispezie destrosio fibra vegetale (pisello) pepeaglio (contiene SOLFITI) peperoncino. Può contenere UOVALATTE GLUTINE.",
            "plu": "032461"
        },
        {
            "_id": "791569",
            "name": "CIOLI EGIDIO SALSICCIA ARICCIA MACINATA KG",
            "ingredients": "INGREDIENTI: Carne suina 88% (Origine italia) acquasalearomiaromi naturalispezie destrosio fibra vegetale (pisello) pepeaglio (contiene SOLFITI) peperoncino. Può contenere UOVALATTE GLUTINE.",
            "plu": "032462"
        },
        {
            "_id": "791577",
            "name": "SALSICCIA NORCIA MACINATA KG",
            "ingredients": "INGREDIENTI:Carne di suino(Origine Italia) saledestrosio aromi naturali spezie Antiossidanti: E 300 Correttori di acidità: E 331 E 262. Può contenere: LATTE GLUTINE UOVA.",
            "plu": "032469"
        },
        {
            "_id": "791581",
            "name": "SALSICCIA NORCIA PUNTA COLTELLO KG",
            "ingredients": "INGREDIENTI:Carne di suino(Origine Italia) saledestrosio aromi naturali spezie Antiossidanti: E 300 Correttori di acidità: E 331 E 262. Può contenere: LATTE GLUTINE UOVA.",
            "plu": "032483"
        },
        {
            "_id": "791642",
            "name": "COA COSTINE-RIBS SUINO BBQ SV",
            "ingredients": "INGREDIENTI:carne di suino 92% SENAPE (acqua SENAPEaceto di brendyzuccherosale spezie aromi)salsa BBQ 2% (grassi vegetali(colza sal) spezie maltodestrina sale non iodato miele zucchero estratto di paprica aroma fumo aroma) paprica sale pepe nero aglio (contiene SOLFITI)rosmarino. Origine suino: UE.",
            "plu": "032515"
        },
        {
            "_id": "791647",
            "name": "COA HAMBURGER BOVINO X4 ATM",
            "ingredients": "INGREDIENTI:Carne di bovino adulto 86.3% acquasalefibra vegetalefarina di pisello correttori di acidità:E262antiossidanti: antiossidanti:E301E300barbabietola in polvere aromaestratti di spezieestratto di paprica fiocchi di patate (patate disidratate 99% emulsionante:E471stabilizzante:E450spezie conservante:E223 metabiSOLFITO di sodio antiossidante:E304aroma naturalecorrettori di acidità:E330)E325E331.Può contenere GLUTINE.",
            "plu": "032522"
        },
        {
            "_id": "791648",
            "name": "COA POLPETTE PANATE",
            "ingredients": "INGREDIENTI: Carne di bovino adulto 44.5% carne di suino 298%acquamisto UOVA formaggio(LATTE sale caglio fermenti lattici) fibra vegetalefarina di pisello correttori di acidità: E262antiossidanti:E301E300barbabietola in polverearoma estratti di spezie estratto di papricafiocchi di patate (patate disidratate in fiocchi emulsionante:E471) aglio (contiene SOLFITI). Può contenere GLUTINE. Origine suino: UE.",
            "plu": "032521"
        },
        {
            "_id": "791649",
            "name": "COA INVOLTINO COTENNA SUINO",
            "ingredients": "INGREDIENTI:Cotenna suino 82.5% FORMAGGIO grattugiato (LATTE vaccino LATTE ovino sale correttore di acidità: E300 conservante: E200 E202)prezzemolo disidratato pepe nero sale. Origine suino: UE. Può contenere UOVA SOIA SEMI DI SESAMO GLUTINE.",
            "plu": "032520"
        },
        {
            "_id": "791650",
            "name": "COA ROLLE BOSCAIOLA",
            "ingredients": "INGREDIENTI: Carne suino 81.3%,funghi champignon,pancetta di suino 6.2%, rosmarino fresco. Origine suino: UE Può contenere UOVA, SOIA, SEMI DI SESAMO, GLUTINE.",
            "plu": "032519"
        },
        {
            "_id": "791709",
            "name": "CIOLI SALSICCIA ARICCIA LUGANEGA AL KG",
            "ingredients": "INGREDIENTI: Carne suina 88%(origine Italia), acqua,sale,aromi,aromi naturali, spezie, destrosio,fibra vegetale (pisello), pepe,aglio(SOLFITI),peperoncino. PuÃ² contenere: LATTE, GLUTINE, UOVA.",
            "plu": "032555"
        }  
    ],
    "PESCHERIA": [
        {
            "_id": "802207",
            "name": "ANELLI TOTANO GIG.DEL PACIFICOPescato Zona FAO 87",
            "ingredients": "INGREDIENTI: TOTANO Gigante del Pacifico (Dosidicus gigas), pescato nel Pacifico Sud orientale (FAO 87) con ami e palangari, acqua, sale, E330, E331, E332. Può contenere SOLFITI. Prodotto congelato all'origine. Conservare a -18°C. Consumare previa cottura. Non ricongelare.",
            "plu": "060392"
        },
        {
            "_id": "802298",
            "name": "CALAMARO INDOPACIFICO Pescato FAO 51/57",
            "ingredients": "INGREDIENTI: CALAMARO (Loliolus japonica) pescato nell'Oceano Pacifico del Nord (zona FAO 61) con reti da traino, acqua, sale. Puo' contenere MOLLUSCHI. Conservare a -18°C. Prodotto decongelato, consumare previa cottura. Non ricongelare.",
            "plu": "060401"
        },
        {
            "_id": "802421",
            "name": "FANTASIA_DI_MARE Da Kg 5",
            "ingredients": "INGREDIENTI: TOTANO GIGAS 60%, PULPO GABEZUDO Guinea C/G 40%, acqua, sale, additivi: E330, E331, E501, glucosio, aceto di VINO bianco, additivi: E575, E211. Prodotto congelato all'origine. Non ricongelare.",
            "plu": "060441"
        },
        {
            "_id": "802439",
            "name": "INSALATA DI POLPO Fao 87/41 Kg 5",
            "ingredients": "INGREDIENTI: TOTANO GIGAS 60%, PULPO GABEZUDO Guinea C/G 40%, acqua, sale, additivi: E330, E331, E501, glucosio, aceto di VINO bianco (SOLFITI), additivi: E575",
            "plu": "060442"
        },
        {
            "_id": "802488",
            "name": "ORATA 400/600 EVISCERATA Allevato",
            "ingredients": "INGREDIENTI: ORATA (Sparus aurata) METODO DI PRODUZIONE: ALLEVATO PROVENIENZA: GRECIA ALLERGENI: PESCE LOTTO: Prodotto FRESCO",
            "plu": "060453"
        },
        {
            "_id": "802511",
            "name": "SPIEDINI DI SPADA/SEPPIA/POLPO",
            "ingredients": "INGREDIENTI: PESCE SPADA ( Xiphias Gladius), pescato nell'Oceano Indiano Orientale, zona FAO 57 con ami e palangari,acqua,E331,E301,sale; SEPPIA indopacifica (Sepiella inermis), pescato nell'Oceano Pacifico centro ovest,zona FAO 71 con reti da traino;POLPO (Octopus vulgaris),pescato nel Mar Ionio, zona FAO 37.2.2 con nasse e trappole; peperone. Può contenere CROSTACEI,SOLFITI. PRODOTTI DECONGELATI",
            "plu": "060451"
        },
        {
            "_id": "802512",
            "name": "SPIEDINI DI TONNO/SEPPIA/POLPO",
            "ingredients": "INGREDIENTI: TONNO a pinna gialla (Thunnus Albacares), pescato nell'Oceano Indiano Occidentale, zona FAO 51 con ami e palangari,acqua,E311,E301; SEPPIA indopacifica (Sepiella inermis),pescato nell'Oceano Pacifico centro ovest,zona FAO 71 con reti da traino;POLPO (Octopus vulgaris),pescato nel Mar Ionio, zona FAO 37.2.2 con nasse e trappole; peperone. Può contenere CROSTACEI,SOLFITI. PRODOTTI DECONGELATI",
            "plu": "060456"
        },
        {
            "_id": "802520",
            "name": "BRANZINO 400/600 EVISCERATO Allevato Grecia",
            "ingredients": "INGREDIENTI: SPIGOLA (Dicentrarchus labrax) METODO DI PRODUZIONE: ALLEVATO PROVENIENZA: GRECIA ALLERGENI: PESCE LOTTO: Prodotto FRESCO",
            "plu": "060457"
        },
        {
            "_id": "802561",
            "name": "ALICI SPINATE Pescato",
            "ingredients": "INGREDIENTI: ALICI (Engraulis encrasicolus) METODO DI PRODUZIONE: PESCATO PROVENIENZA: FAO 37 ATTREZZO DI PESCA: ALLERGENI: PESCE LOTTO: Prodotto FRESCO",
            "plu": "060461"
        },
        {
            "_id": "802579",
            "name": "ALICI EVISCERATE PESCATO",
            "ingredients": "INGREDIENTI: ALICI (Engraulis encrasicolus) METODO DI PRODUZIONE: PESCATO PROVENIENZA: FAO 37 ATTREZZO DI PESCA: ALLERGENI: PESCE LOTTO: Prodotto FRESCO",
            "plu": "060462"
        },
        {
            "_id": "803395",
            "name": "FILONE DI TONNO SV Pescato Decongelato",
            "ingredients": "Ingredienti: TONNO pinna gialla, Acqua(10%) Correttore di acidità (E331), Antiossidante (E301), sale. Allergeni: Pesce Il prodotto deve essere conservato a temperatura 0°C +3°C fino al consumo. Consumare il prodotto previa cottura entro due giorni dall'apertura della confezione. PRODOTTO DECONGELATO",
            "plu": "060575"
        },
        {
            "_id": "803437",
            "name": "FILONE DI PESCE SPADA SV Pescato decongelato",
            "ingredients": "INGREDIENTI: PESCE SPADA (XIPHIAS GLADIUS), acqua (<5%), sale, correttore di acidità: E331, antiossidante: E301. Allergeni: Pesce Il prodotto deve essere conservato a temperatura 0°C +3°C fino al consumo. Consumare il prodotto previa cottura entro due giorni dall'apertura della confezione. pescato: ami e palangari PRODOTTO DECONGELATO",
            "plu": "060579"
        },
        {
            "_id": "803445",
            "name": "FILONE DI TONNO SV Pescato Decongelato",
            "ingredients": "Ingredienti: TONNO pinna gialla, Acqua(10%) Correttore di acidità (E331), Antiossidante (E301), sale. Allergeni: Pesce Il prodotto deve essere conservato a temperatura 0°C +3°C fino al consumo. Consumare il prodotto previa cottura entro due giorni dall'apertura della confezione. PRODOTTO DECONGELATO",
            "plu": "060580"
        },
        {
            "_id": "803446",
            "name": "FILONE DI TONNO (Lingotto) Pescato Decongelato",
            "ingredients": "Ingredienti: TONNO pinna gialla, Acqua(10%) Correttore di acidità (E331), Antiossidante (E301), sale. Allergeni: Pesce Il prodotto deve essere conservato a temperatura 0°C +3°C fino al consumo. Consumare il prodotto previa cottura entro due giorni dall'apertura della confezione. PRODOTTO DECONGELATO",
            "plu": "060588"
        },
        {
            "_id": "803447",
            "name": "FILONE DI TONNO SV Pescato Decongelato",
            "ingredients": "Ingredienti: TONNO pinna gialla, Acqua(10%) Correttore di acidità (E331), Antiossidante (E301), sale. Allergeni: Pesce Il prodotto deve essere conservato a temperatura 0°C +3°C fino al consumo. Consumare il prodotto previa cottura entro due giorni dall'apertura della confezione. PRODOTTO DECONGELATO",
            "plu": "060589"
        },
        {
            "_id": "803449",
            "name": "FILONE DI TONNO SV (G.Verde) Pescato Decongelato",
            "ingredients": "Ingredienti: TONNO pinna gialla, Acqua(10%) Correttore di acidità (E331), Antiossidante (E301), sale. Allergeni: Pesce Il prodotto deve essere conservato a temperatura 0°C +3°C fino al consumo. Consumare il prodotto previa cottura entro due giorni dall'apertura della confezione. PRODOTTO DECONGELATO",
            "plu": "060614"
        },
        {
            "_id": "803450",
            "name": "FILONE DI TONNO SV Pescato Decongelato",
            "ingredients": "Ingredienti: TONNO pinna gialla, Acqua(10%) Correttore di acidità (E331), Antiossidante (E301), sale. Allergeni: Pesce Il prodotto deve essere conservato a temperatura 0°C +3°C fino al consumo. Consumare il prodotto previa cottura entro due giorni dall'apertura della confezione. PRODOTTO DECONGELATO",
            "plu": "060405"
        },
        {
            "_id": "803451",
            "name": "FILONE DI TONNO (Garza nera) Pescato Decongelato",
            "ingredients": "Ingredienti: TONNO pinna gialla, Acqua(10%) Correttore di acidità (E331), Antiossidante (E301), sale. Allergeni: Pesce Il prodotto deve essere conservato a temperatura 0°C +3°C fino al consumo. Consumare il prodotto previa cottura entro due giorni dall'apertura della confezione. PRODOTTO DECONGELATO",
            "plu": "060408"
        },
        {
            "_id": "803479",
            "name": "FILONE DI PESCE SPADA SV Pescato Decongelato",
            "ingredients": "INGREDIENTI: PESCE SPADA (XIPHIAS GLADIUS), acqua (<5%), sale, correttore di acidità: E331, antiossidante: E301. Allergeni: Pesce Il prodotto deve essere conservato a temperatura 0°C +3°C fino al consumo. Consumare il prodotto previa cottura entro due giorni dall'apertura della confezione. pescato: ami e palangari PRODOTTO DECONGELATO",
            "plu": "060407"
        },
        {
            "_id": "803480",
            "name": "FILONE DI PESCE SPADA SV Pescato Decongelato",
            "ingredients": "INGREDIENTI: PESCE SPADA (XIPHIAS GLADIUS), acqua (<5%), sale, correttore di acidità: E331, antiossidante: E301. Allergeni: Pesce Il prodotto deve essere conservato a temperatura 0°C +3°C fino al consumo. Consumare il prodotto previa cottura entro due giorni dall'apertura della confezione. pescato: ami e palangari PRODOTTO DECONGELATO",
            "plu": "060406"
        },
        {
            "_id": "803494",
            "name": "FILONE DI PESCE SPADA SV Pescato Decongelato",
            "ingredients": "INGREDIENTI: PESCE SPADA (XIPHIAS GLADIUS), acqua (<5%), sale, correttore di acidità: E331, antiossidante: E301. Allergeni: Pesce centr est Il prodotto deve essere conservato a temperatura 0°C +3°C fino al consumo. Consumare il prodotto previa cottura entro due giorni dall'apertura della confezione. pescato: ami e palangari PRODOTTO DECONGELATO",
            "plu": "060585"
        },
        {
            "_id": "803537",
            "name": "FILETTO DI PERSICO AFRICANO Lago vittoria Africa",
            "ingredients": "INGREDIENTI: FILETTO DI PERSICO (Lates niloticus) METODO DI PRODUZIONE: Pescato PROVENIENZA:Lago vittoria Africa ATTREZZO DI PESCA: ALLERGENI: LOTTO: Prodotto FRESCO",
            "plu": "060612"
        },
        {
            "_id": "803544",
            "name": "FILETTO DI PLATESSA (lavorato)Pescato Zona FAO 27",
            "ingredients": "INGREDIENTI:Filetto di Platessa(Pleuronectes platessas) METODO DI PRODUZIONE: Pescato PROVENIENZA: ATTREZZO DI PESCA: ALLERGENI: LOTTO: Prodotto FRESCO",
            "plu": "060594"
        },
        {
            "_id": "803551",
            "name": "FILETTO DI SGOMBRO Pescato Zona FAO 27",
            "ingredients": "INGREDIENTI: SGOMBRO (Scomber scombrus) METODO DI PRODUZIONE: PESCATO PROVENIENZA: FAO 27 ALLERGENI: PESCE LOTTO: Prodotto FRESCO",
            "plu": "060595"
        },
        {
            "_id": "803569",
            "name": "FILETTO DI TROTA SALMONATA Allevato Italia",
            "ingredients": "INGREDIENTI: TROTA SALMONATA (O.mykiss) METODO DI PRODUZIONE: ALLEVATO PROVENIENZA: ITALIA ALLERGENI: PESCE LOTTO: Prodotto FRESCO",
            "plu": "060596"
        },
        {
            "_id": "803577",
            "name": "FILETTO DI SALMONE Allevato",
            "ingredients": "INGREDIENTI: SALMONE (Salmo salar) METODO DI PRODUZIONE: ALLEVATO PROVENIENZA: NORVEGIA ALLERGENI: PESCE LOTTO: Prodotto FRESCO",
            "plu": "060597"
        },
        {
            "_id": "803619",
            "name": "FILETTO DI ORATA Allevato",
            "ingredients": "INGREDIENTI: ORATA (Sparus aurata) METODO DI PRODUZIONE: ALLEVATO PROVENIENZA: GRECIA ALLERGENI: PESCE LOTTO: Prodotto FRESCO",
            "plu": "060601"
        },
        {
            "_id": "803643",
            "name": "FILETTO DI BRANZINO Allevato Grecia",
            "ingredients": "INGREDIENTI: SPIGOLA (Dicentrarchus labrax) METODO DI PRODUZIONE: ALLEVATO PROVENIENZA: GRECIA ALLERGENI: PESCE LOTTO: Prodotto FRESCO",
            "plu": "060604"
        },
        {
            "_id": "803676",
            "name": "FILETTO DI GALLINELLA Pescato Zona FAO 27",
            "ingredients": "INGREDIENTI: GALLINELLA (C.lucerna) METODO DI PRODUZIONE: PESCATO PROVENIENZA: FAO 27 ALLERGENI: PESCE LOTTO: Prodotto FRESCO",
            "plu": "060607"
        },
        {
            "_id": "803726",
            "name": "CARPACCIO DI SALMONE Allevato Norvegia",
            "ingredients": "INGREDIENTI: SALMONE (Salmo salar) METODO DI PRODUZIONE: ALLEVATO PROVENIENZA: NORVEGIA ALLERGENI: PESCE LOTTO: Prodotto FRESCO",
            "plu": "060620"
        },
        {
            "_id": "803734",
            "name": "CARPACCIO DI PESCE SPADA Pescato Decongelato",
            "ingredients": "INGREDIENTI: PESCE SPADA, olio di semi di girasole, aglio (SOLFITI),prezzemolo, peperoncino.",
            "plu": "060621"
        },
        {
            "_id": "803742",
            "name": "CARPACCIO DI TONNO Pescato Decongelato",
            "ingredients": "INGREDIENTI: TONNO, olio di semi di girasole, aglio(SOLFITI),prezzemolo,peperoncino.",
            "plu": "060622"
        },
        {
            "_id": "804120",
            "name": "VONGOLA VERACE Cf. 1000gr ALLEVATO ITALIA",
            "ingredients": "INGREDIENTI: VONGOLA VERACE GR. 1000 (Tapes semidecussatus) Allevato in Italia",
            "plu": "060696"
        },
        {
            "_id": "804559",
            "name": "GAMBERO ROSA Pescato Zona FAO 37",
            "ingredients": "INGREDIENTI: GAMBERO ROSA fresco (Parapenaeus Longirostris) Pescato in Zona FAO 37,1/37, 2/37,3 con reti da traino. Prodotto Fresco.",
            "plu": "060788"
        },
        {
            "_id": "804560",
            "name": "CODE DI GAMBERO Pescato Zona FAO 37",
            "ingredients": "INGREDIENTI:GAMBERO ROSA (Parapenaeus Longirostris) METODO DI PRODUZIONE: PESCATO PROVENIENZA: FAO 37 ATTREZZO DI PESCA: ALLERGENI: CROSTACEI LOTTO: Prodotto FRESCO",
            "plu": "060792"
        },
        {
            "_id": "805960",
            "name": "FILETTO DI PAGRO Allevato Grecia",
            "ingredients": "INGREDIENTI: PAGRO (Pagrus major) METODO DI PRODUZIONE: ALLEVATO PROVENIENZA: GRECIA ALLERGENI: PESCE LOTTO: Prodotto FRESCO",
            "plu": "060917"
        },
        {
            "_id": "805994",
            "name": "FILETTO DI ROMBO CHIODATO Allevato Spagna",
            "ingredients": "INGREDIENTI: ROMBO CHIODATO (Psetta maxima) METODO DI PRODUZIONE: ALLEVATO PROVENIENZA: SPAGNA ALLERGENI: PESCE LOTTO: Prodotto FRESCO",
            "plu": "060920"
        },
        {
            "_id": "806000",
            "name": "FILETTO DI RICCIOLA OCEANICA Allevato Olanda",
            "ingredients": "INGREDIENTI: RICCIOLA OCEANICA (S.lalandi) METODO DI PRODUZIONE: ALLEVATO PROVENIENZA: OLANDA ALLERGENI: PESCE LOTTO: Prodotto FRESCO",
            "plu": "060921"
        },
        {
            "_id": "806018",
            "name": "FILETTO DI PAGELLO/PEZZOGNA Pescato Zona FAO 27",
            "ingredients": "INGREDIENTI: PAGELLO/PEZZOGNA (P.bogaraveo) METODO DI PRODUZIONE: PESCATOO PROVENIENZA: FAO 27 ALLERGENI: PESCE LOTTO: Prodotto FRESCO",
            "plu": "060922"
        },
        {
            "_id": "806182",
            "name": "CODE DI GAMBERO ARGENTINO Pescato Zona FAO 41",
            "ingredients": "INGREDIENTI: GAMBERO ARGENTINO (Pleoticus muelleri) METODO DI PRODUZIONE: PESCATO PROVENIENZA: FAO 41 ALLERGENI: LOTTO: Prodotto DECONGELATO non ricongelare",
            "plu": "060952"
        },
        {
            "_id": "808485",
            "name": "CODE DI MAZZANCOLLA TROPICALE Allevato ECUADOR",
            "ingredients": "INGREDIENTI: MAZZANCOLLA TROPICALE (Penaeus vannamei) METODO DI PRODUZIONE: ALLEVATO PROVENIENZA: ALLERGENI: LOTTO: Prodotto DECONGELATO non ricongelare",
            "plu": "060348"
        },
        {
            "_id": "MU0385",
            "name": "TONNO MARINATO",
            "ingredients": "TONNO (Thunnus albacares, FAO 87, rete di circuizione), ACETO, sale, prezzemolo, peperoncino, olio di girasole, E301, E331. Prodotto decongelato deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato all'origine.",
            "plu": "067018"
        }
    ],
    "SALUMERIA": [
        {
            "_id": "031856",
            "name": "KRAFT LINDENBERGER BL 2500GR",
            "ingredients": "INGREDIENTI: LATTE vaccino pastorizzato sale FERMENTI LATTICI Può contenere GLUTINE.",
            "plu": "010561"
        },
        {
            "_id": "032078",
            "name": "PINNA CACIOTTA BRIGANTE SV",
            "ingredients": "INGREDIENTI: LATTE di pecora sale caglio Conservante sulla crosta: E235 NO OGM Può contenere GLUTINE.",
            "plu": "010661"
        },
        {
            "_id": "032086",
            "name": "PINNA PECORINO ROMANO 1/4 SV",
            "ingredients": "INGREDIENTI: LATTE di pecora termizzato caglio sale. NO OGM Può contenere GLUTINE.",
            "plu": "010985"
        },
        {
            "_id": "032284",
            "name": "GRANA PADANO FORME DOP 16 MESI",
            "ingredients": "INGREDIENTI: LATTE vaccino sale caglio Conservante: lisozima da UOVA Può contenere GLUTINE.",
            "plu": "010251"
        },
        {
            "_id": "032359",
            "name": "PARMIGIANO REGGIANO 24 MESI",
            "ingredients": "INGREDIENTI: LATTE vaccino sale caglio Può contenere GLUTINE.",
            "plu": "010252"
        },
        {
            "_id": "033449",
            "name": "CAMOSCIO D ORO AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccino pastorizzato sale FERMENTI LATTICI caglio Può contenere GLUTINE.",
            "plu": "010823"
        },
        {
            "_id": "033498",
            "name": "FOLEPI AL KG",
            "ingredients": "INGREDIENTI:LATTE vaccinosaleFERMENTI LATTICI Conservanti sulla crosta:E235 acetato di polivinile Colorante:E160BE180farina di riso tostata Crosta non commestibile Può contenere FRUTTA A GUSCIO SEDANO GLUTINE.",
            "plu": "010826"
        },
        {
            "_id": "042309",
            "name": "GALBANI S.LUCIA RICOTTA AL KG",
            "ingredients": "INGREDIENTI: siero di LATTE vaccino Correttore di acidità:acido citrico e LATTICO. Può contenere GLUTINE.",
            "plu": "010401"
        },
        {
            "_id": "042960",
            "name": "GALBANI BEL PAESE AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccino  caglio  sale. Conservanti sulla crosta:sorbato di potassio E252 Può contenere GLUTINE.",
            "plu": "010905"
        },
        {
            "_id": "043075",
            "name": "GALBANI GALBANONE KG.5 AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccino  sale  caglio Può contenere GLUTINE.",
            "plu": "010753"
        },
        {
            "_id": "043489",
            "name": "GALBANI PROS.COTTO F.GOLOSA KG",
            "ingredients": "INGREDIENTI:coscia di suinosalearomidestrosio Gelificante: carrageninesaccarosio cloruro di potassio Antiossidante:E301 Correttore di acidità:E331 Conservante:E250 Esaltatore di sapidità:E621 Può contenere LATTE GLUTINE. Origine suino: UE",
            "plu": "013070"
        },
        {
            "_id": "069047",
            "name": "FIORUCCI MORT.SUPREMA C/PISTAC",
            "ingredients": "INGREDIENTI: carne suinotrippino di suinosale zuccherospezie aromi naturali PISTACCHIO (04%) Antiossidante: E301E300 Conservante: E250 Può contenere FRUTTA A GUSCIO LATTE GLUTINE. Origine suino: UE",
            "plu": "011650"
        },
        {
            "_id": "071472",
            "name": "SWITZERLAND EMMENTALER ORIGINA",
            "ingredients": "INGREDIENTI: LATTE vaccinoFERMENTI LATTICIsale Può contenere GLUTINE.",
            "plu": "010562"
        },
        {
            "_id": "075432",
            "name": "RIGAMONTI BRESAOLA P.ANCA IGP",
            "ingredients": "INGREDIENTI:carne bovinasaledestrosioaromi. Conservante:E250 E251 Può contenere LATTE GLUTINE.",
            "plu": "015351"
        },
        {
            "_id": "077859",
            "name": "PLAC CACIOCAVALLO AFFUMIC.KG",
            "ingredients": "INGREDIENTI: LATTE vaccinosalecaglio. Trattato in superfie con aromatizzante affumicatura Può contenere GLUTINE.",
            "plu": "010761"
        },
        {
            "_id": "077917",
            "name": "MAASDAMMER AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccino sale caglio FERMENTI LATTICI Conservante :E251 Conservante sulla crosta:E235 Può contenere GLUTINE.",
            "plu": "010563"
        },
        {
            "_id": "078675",
            "name": "AURICCHIO PRO.DOLCE SALAME 20",
            "ingredients": "INGREDIENTI: LATTE vaccino sale caglio Conservante sulla crosta:E203E235. Può contenere GLUTINE.",
            "plu": "010763"
        },
        {
            "_id": "078972",
            "name": "EDAM OLANDESE BOCCE 2KG",
            "ingredients": "INGREDIENTI: LATTE vaccinosaleFERMENTIcaglio Colorante sulla crosta:E160B. Può contenere GLUTINE.",
            "plu": "010830"
        },
        {
            "_id": "079012",
            "name": "BLD FRAGO FONTAL 1/4 PLAST.3KG",
            "ingredients": "INGREDIENTI:LATTE vaccinosale cagliofermenti lattici Conservante: E251 Conservante sulla crosta:E235 Può contenere GLUTINE. Crosta non edibile.",
            "plu": "010832"
        },
        {
            "_id": "079624",
            "name": "PLAC PROV.PICC. S.V.TRIANGOLO",
            "ingredients": "INGREDIENTI: LATTE vaccino salecaglio Trattamento sulla crosta: E202E235 cera microcristallina NO OGM Può contenere GLUTINE.",
            "plu": "010765"
        },
        {
            "_id": "079855",
            "name": "FIORUCCI SALAME NAPOLI 15KG",
            "ingredients": "INGREDIENTI: carne di suinosale destrosio aromi spezieLATTE scremato in polvere zucchero aromatizzanti di affumicatura Antiossidante: E301 Conservante: E250E252 Può contenere GLUTINE. Origine suino: UE",
            "plu": "014351"
        },
        {
            "_id": "082891",
            "name": "LEERDAMMER FORMA INTERA",
            "ingredients": "INGREDIENTI: LATTE vaccinoFERMENTI LATTICI salecaglio. Conservante sulla crosta:E235 Colorante sulla crosta:E160B Crosta non edibile Può contenere GLUTINE.",
            "plu": "010565"
        },
        {
            "_id": "083824",
            "name": "BECHELLI SPALLA COTTA AL KG",
            "ingredients": "INGREDIENTI: carne suina ricompostaacqua sale destrosio aromi naturalifecola di patate Stabilizzante:polifosfati Esaltatore di sapidità:E621 Antiossidante:E300 Conservante:E250 Gelificante:E407 Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "011561"
        },
        {
            "_id": "084202",
            "name": "MIRAGLIA OLIVE NERE ITRANE FUS",
            "ingredients": "INGREDIENTI: olive nere acqua sale antiossidanti: E300 E330. Può contenere LUPINI MANDORLE",
            "plu": "015951"
        },
        {
            "_id": "084210",
            "name": "MIRAGLIA OLIVE NERE PASSOLONI",
            "ingredients": "INGREDIENTI:Olive nere di Sicilia capperi sale olio di semi di girasole peperoncino origano AGLIO(contiene SOLFITI) Può contenere MANDORLE LUPINI e SEDANO.",
            "plu": "015952"
        },
        {
            "_id": "084228",
            "name": "MIRAGLIA OLIVE VERDI SCHIACC.",
            "ingredients": "INGREDIENTI: olive verdiacquasale antiossidanti:E300E330. Può contenere:LUPINI MANDORLESEDANO.",
            "plu": "015953"
        },
        {
            "_id": "084277",
            "name": "TOGIMA CAPPERI CAL.7 SALATI 5K",
            "ingredients": "INGREDIENTI: Capperi (Origine:Marocco)sale. Può contenere SOLFITI.",
            "plu": "015955"
        },
        {
            "_id": "084285",
            "name": "TOGIMA ALICI SAL.II BARRA",
            "ingredients": "INGREDIENTI:ACCIUGHE (Engraulis encrasicolus) sale",
            "plu": "015956"
        },
        {
            "_id": "087981",
            "name": "PARMACOTTO PROSC.COTTO ALTA Q.",
            "ingredients": "INGREDIENTI:coscia suinasaledestrosioaromi Antiossidante:E301 Conservante:E250 Origine suino: UE Può contenere GLUTINELATTE.",
            "plu": "013063"
        },
        {
            "_id": "088450",
            "name": "FRANCHI SAL.NAPOLI GOLFO 15KG",
            "ingredients": "INGREDIENTI: carne di suinosale destrosioaromi speziesaccarosiopolvere di LATTE scremato Antiossidante:ascorbato di sodio (E301) Conservante:nitrato di potassio (E252) nitrato di sodio (E250) Può contenere GLUTINE. Origine suino: ITALIA",
            "plu": "014352"
        },
        {
            "_id": "088468",
            "name": "FRANCHI SALS.NAP.DOLCE S/V400G",
            "ingredients": "INGREDIENTI: carne di suinosaledestrosio aromispeziesaccarosiopolvere di LATTE scremato Antiossidante:E301 Conservante: E250E252 Può contenere GLUTINE. Origine suino: ITALIA",
            "plu": "014067"
        },
        {
            "_id": "088476",
            "name": "FRANCHI SALS.NAP.FORTE SV 400G",
            "ingredients": "INGREDIENTI:carne di suino saledestrosio aromi speziepolvere di LATTE scremato peperoncinosaccarosio Antiossidante: E301 Conservante: E250E252 Può contenere GLUTINE. Origine suino: ITALIA",
            "plu": "014068"
        },
        {
            "_id": "091470",
            "name": "PLAC CACIOCAVALLI BIANCHI",
            "ingredients": "INGREDIENTI: LATTE vaccino sale caglio Può contenere GLUTINE.",
            "plu": "010768"
        },
        {
            "_id": "093351",
            "name": "FIORUCCI MORT.SUPREMA 15 KG ST",
            "ingredients": "INGREDIENTI: carne suinotrippino di suino zuccherospezie aromi naturalisale Antiossidante: E301E300 Conservante: E250 Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "011617"
        },
        {
            "_id": "093435",
            "name": "FIORUCCI SALAME CORALLINA",
            "ingredients": "INGREDIENTI: carne di suinosaledestrosio aromispezieLATTE scremato in polvere pepe saccarosio. Origine suino: UE Antiossidante:ascorbato di sodio (E301) Conservante:nitrato di potassio (E252) Nitrato di sodio (E250) Può contenere GLUTINE.",
            "plu": "011914"
        },
        {
            "_id": "094136",
            "name": "PINNA CACIOTTA SARDA BRIGANTE",
            "ingredients": "INGREDIENTI: LATTE di pecorasale caglio. Conservante sulla crosta:E235 NO OGM Può contenere GLUTINE.",
            "plu": "010668"
        },
        {
            "_id": "094474",
            "name": "CADEMARTORI PROVOLONE 35KG",
            "ingredients": "INGREDIENTI:LATTE crudo sale caglio Conservanti in crosta:Natamicina (E235) Sorbato di potassio(E202) Protetto in superficie con cera microcristallina Crosta non edibile Può contenere GLUTINE.",
            "plu": "010769"
        },
        {
            "_id": "094490",
            "name": "AURICCHIO PROV.PICC MAND.KG.20",
            "ingredients": "INGREDIENTI: LATTE vaccino sale caglio Conservante sulla crosta:E202E235. Può contenere GLUTINE.",
            "plu": "010770"
        },
        {
            "_id": "094839",
            "name": "AURICCHIO PROVOL.TONDE 800GR",
            "ingredients": "INGREDIENTI: LATTE vaccino sale caglio Conservante sulla crosta:E202E235. Può contenere GLUTINE.",
            "plu": "010771"
        },
        {
            "_id": "094847",
            "name": "AURICCHIO SALAMINI 800GR",
            "ingredients": ". INGREDIENTI: LATTE vaccino sale caglio Conservante sulla crosta:E202E235. Può contenere GLUTINE.",
            "plu": "010772"
        },
        {
            "_id": "094896",
            "name": "FRANCHI SALAME NAPOLI 800GR",
            "ingredients": "INGREDIENTI: carne di suinosale destrosio VINO(SOLFITI) spezie saccarosio polvere di LATTE scrematoAntiossidante: E301 Conservante:E250E252 Può contenere GLUTINE Origine suino: ITALIA",
            "plu": "014353"
        },
        {
            "_id": "095323",
            "name": "PINNA PECORINO ROMANO FORME",
            "ingredients": "INGREDIENTI: LATTE di pecora termizzato cagliosale. NO OGM Può contenere GLUTINE.",
            "plu": "010988"
        },
        {
            "_id": "095331",
            "name": "AURICCHIO AURETTA AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccino e di pecora sale caglio. Conservante sulla crosta:E202E235. Colorante sulla crosta:E172. Può contenere GLUTINE.",
            "plu": "010669"
        },
        {
            "_id": "095745",
            "name": "SENFTER PROS.PRAGA MON.SV KG",
            "ingredients": "INGREDIENTI: coscia di suino sale aromidestrosioaroma di affumicatura. Gelificante: carragenineE451. Conservante:nitrito di sodio E250. Antiossidante:ascorbato di sodio Può contenere GLUTINELATTE.",
            "plu": "013071"
        },
        {
            "_id": "097873",
            "name": "SARDAFORMAGGI PEC.GRAZIOLA DOP",
            "ingredients": "INGREDIENTI: LATTE di pecora pastorizzato caglio sale fermenti lattici. Conservanti sulla crosta: E 202 E 235. Crosta non edibile. Coloranti: E160b",
            "plu": "010671"
        },
        {
            "_id": "097907",
            "name": "SARDAFORMAGGI PECOR.SOLE SARD",
            "ingredients": "INGREDIENTI: LATTE di pecora pastorizzato saleFERMENTI LATTICIcaglio Conservante sulla crosta: E202E235 Colorante sulla crosta: E160B Può contenere GLUTINE.",
            "plu": "010672"
        },
        {
            "_id": "097923",
            "name": "SARDAFORMAGGI PEC.MORO SARDEGN",
            "ingredients": "INGREDIENTI:LATTE di pecora pastorizzato caglio FERMENTI LATTICIsale Conservante sulla crosta: E202E235 Colorante sulla crosta: E150D E160B Può contenere GLUTINE.",
            "plu": "010673"
        },
        {
            "_id": "100006",
            "name": "MICCIO OL.NERE COND.PASSOLONI",
            "ingredients": "Olive olio di semi di girasole prezzemolo finocchietto AGLIO(SOLFITI)origano peperoncino. Possono contenere noccioli",
            "plu": "016019"
        },
        {
            "_id": "100473",
            "name": "ALCISA PROSC.S.DANIELE DISOSS",
            "ingredients": "INGREDIENTI:coscia di suino sale. Può contenere GLUTINELATTE.",
            "plu": "012472"
        },
        {
            "_id": "100513",
            "name": "VALTIDONE PANCETTA COPPATA KG",
            "ingredients": "INGREDIENTI:Pancetta di suino sale destrosio aromi spezie antiossidante: ascorbato di sodio- E301 conservanti: nitrito di sodio - E250 nitrato di potassio- E252 Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "013276"
        },
        {
            "_id": "100794",
            "name": "*ALCISA FILETTO STAGIONATO KG",
            "ingredients": "INGREDIENTI: carne di suinosale destrosio aromi spezie saccarosio. Conservante:nitrato di potassio E252 nitrato di sodio E250.",
            "plu": "None"
        },
        {
            "_id": "101013",
            "name": "T.DUCALI PANCETTA ARR.AL PEPE",
            "ingredients": "INGREDIENTI:carne suinosale aromi naturali destrosio spezie (pepe 010%) Conservante: E250E252. Antiossidante: E300 Può contenere GLUTINELATTE. Origine suino: ITALIA",
            "plu": "013476"
        },
        {
            "_id": "101023",
            "name": "PARMIGIANO REGG.GR.RISERVA 30M",
            "ingredients": "INGREDIENTI: LATTE vaccinosale caglio. Può contenere GLUTINE.",
            "plu": "010270"
        },
        {
            "_id": "101873",
            "name": "CADEMARTORI TALEGGIO 1/2DOP KG",
            "ingredients": "INGREDIENTI: LATTE vaccino caglio sale Può contenere GLUTINE.",
            "plu": "011066"
        },
        {
            "_id": "101881",
            "name": "CADEMARTORI QUARTIR.1/2 1.5KG",
            "ingredients": ". INGREDIENTI: LATTE vaccino caglio sale Può contenere GLUTINE.",
            "plu": "011067"
        },
        {
            "_id": "101932",
            "name": "MICCIO ALICI SAL.II BARRA",
            "ingredients": "INGREDIENTI:ALICI (Engraulis Encrasicolus) sale salamoia",
            "plu": "016374"
        },
        {
            "_id": "102305",
            "name": "LE VIE DEL GR.MEZ.LUNA A KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI:farina di GRANO Tenero 00 acqua lievito naturale(contiene GLUTINE) farina di FARROsale. Può contenere SOIA SEMI DI SESAMO FRUTTA A GUSCIO LATTE UOVASENAPE. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto: ottenuto da prodotto prontoforno congelato",
            "plu": "016072"
        },
        {
            "_id": "102530",
            "name": "LUCANA SOPPRESSATA AL KG",
            "ingredients": "INGREDIENTI: carne di suinosale destrosio aromi naturalispezie saccarosio Antiossidante: E301 E300. Conservante: E252E250 Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "015881"
        },
        {
            "_id": "103135",
            "name": "GALBANI SALAME NAPOLI",
            "ingredients": "INGREDIENTI: carne di suinosale destrosio aromi spezie. Origine suino: UE Antiossidante: E301 Conservante: E252E250 Può contenere GLUTINELATTE.",
            "plu": "014355"
        },
        {
            "_id": "103483",
            "name": "MONT.PROSC.STAG.DIS.MATT.AL KG",
            "ingredients": "INGREDIENTI: carne di suinosale Conservante:nitrato di potassio (E252) Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "012099"
        },
        {
            "_id": "103484",
            "name": "MONT.CULAT.BUONM.SV FIORE",
            "ingredients": "INGREDIENTI:Carne di suino sale conservante: nitrato di potassio - E252 Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "012674"
        },
        {
            "_id": "103485",
            "name": "GIRAU RICOTTA SALATA AL KG",
            "ingredients": "INGREDIENTI: siero di LATTE di pecorasale. Può contenere GLUTINE.",
            "plu": "010441"
        },
        {
            "_id": "103510",
            "name": "VOGLIAZZI VITELLO TONNE 1KG",
            "ingredients": "INGREDIENTI: MAIONESETONNETTO striato 9% vino(SOLFITI)marsalacapperipasta di ACCIUGHE amido modificato di maisE1422E412E415 saleLATTOSIOsciroppo di glucosio siero di LATTEE200salsa Worchester (contiene GLUTINEPESCE)preparato di carne 30%. Può contenere SOIAFRUTTA A GUSCIOSEDANO SENAPEMOLLUSCHI.",
            "plu": "015756"
        },
        {
            "_id": "104045",
            "name": "PALMIERI MORTADELLA FAV.S/V KG",
            "ingredients": "INGREDIENTI: carne di suinotrippino di suino salemiele pepearomi naturalispezie Esaltataore di sapidità:E621 Antiossidante: E301 Conservante: E250 Può contenere GLUTINE LATTE. Origine suino: ITALIA",
            "plu": "011638"
        },
        {
            "_id": "104276",
            "name": "RASPINI SALAME MILANO AL KG",
            "ingredients": "INGREDIENTI: carne suinasaledestrosio aromi naturalispezie Antiossidanti: ascorbato di sodio (E301) Conservanti: nitrato di potassio (E252) nitrito di sodio (E250) Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "014649"
        },
        {
            "_id": "104278",
            "name": "RASPINI SALAME UNGHERESE AL KG",
            "ingredients": "INGREDIENTI: carne suinasale destrosio spezie aromi naturaliaromi di affumicatura Antiossidanti: E301 Conservanti: E250E252 Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "014847"
        },
        {
            "_id": "104311",
            "name": "BOTALLA CACIOTTA SBIRRO AL KG",
            "ingredients": "INGREDIENTI: FORMAGGIO (LATTE vaccinosale caglio birra menabrea 0.5% malto dORZO) Può contenere SOIASENAPE.",
            "plu": "010736"
        },
        {
            "_id": "104312",
            "name": "BOTALLA CACIOTTA CAPRINA AL KG",
            "ingredients": "INGREDIENTI: LATTE di caprasale caglio Può contenere GLUTINE.",
            "plu": "010726"
        },
        {
            "_id": "104373",
            "name": "CARSODO SALAME SPAGNOLO AL KG",
            "ingredients": "INGREDIENTI:carne e grasso di suinozuccheri LATTOSIOsaleproteine della SOIAspezie fecola di patatefarina di aglio(SOLFITI)aromi proteine del LATTE. Origine suino: UE Stabilizzante: E450E451 Antiossidante: E301 Conservante: E252E250E262 Colorante: E120 E160 Correttore di acidità: E331 Può contenere GLUTINE.",
            "plu": "012052"
        },
        {
            "_id": "104394",
            "name": "MICCIO OLIVE PAESANELLA DENOCC",
            "ingredients": "INGREDIENTI: olive acquasale acido citrico Può contenere noccioli o tracce di essi.",
            "plu": "015984"
        },
        {
            "_id": "104657",
            "name": "BLD EMMENTHAL S/L 48% 25KG",
            "ingredients": "INGREDIENTI: LATTE saleFERMENTI LATTICI caglioContenuto di LATTOSIO <010%. Privo di LATTOSIO per naturale processo di produzione. Può contenere GLUTINE.",
            "plu": "010574"
        },
        {
            "_id": "104767",
            "name": "LANTERNA FOCACCIA TRADIZ.AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI:farina di GRANO tenero tipo 00 acqua olio di oliva olio extravergine di oliva lievito di birra sale fiocchi di patate estratto di malto(ORZO). Può contenere LATTESOIASESAMOSENAPE. Prodotto parz. cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016078"
        },
        {
            "_id": "104768",
            "name": "LANTERNA FOCACCIA POMODO.AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI:farina di GRANO tenero tipo 00 pomodorini a rondelle28% acqua olio extravergine di oliva lievito di birra sale fiocchi di patate estratto di malto(ORZO) origano. Può contenere LATTESOIASESAMO SENAPE. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016079"
        },
        {
            "_id": "105536",
            "name": "EMMENTAL BAVARESE AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccinoFERMENTI LATTICI caglio sale Può contenere GLUTINE.",
            "plu": "010566"
        },
        {
            "_id": "107209",
            "name": "FIORUCCI SALAMELLA ROMANA 450G",
            "ingredients": "INGREDIENTI: Carne di suino (Origine: UE)sale LATTE scremato in polvere aromi destrosio zucchero pepe antiossidante: E301 conservanti: E250E252. Può contenere GLUTINE.",
            "plu": "012037"
        },
        {
            "_id": "107213",
            "name": "PODDA PECORINO ROMANO 1/4SV KG",
            "ingredients": "INGREDIENTI:LATTE di pecora intero sale caglio fermenti lattici autoctoni Può contenere GLUTINE.",
            "plu": "011004"
        },
        {
            "_id": "108811",
            "name": "RAMBOL DECORATO NOCI 12KG",
            "ingredients": "INGREDIENTI: LATTE scrematoFORMAGGI(28%) materia grassa vegetale(12%)saleSOIA proteine del LATTEBURROacquaaroma FRUTTA A GUSCIO (NOCI 8%) Sale di fusione:E339E450 452 Addensante:carregeninafarina di carruba Conservante: E200E202 Colorante: E150A Caramello Può contenere GLUTINESEDANO",
            "plu": "010835"
        },
        {
            "_id": "108837",
            "name": "RAMBOL TRONCHETTO NOCI 500GR",
            "ingredients": "INGREDIENTI:LATTE scrematoFORMAGGI 23%BURRO NOCI(6%)proteine del LATTEsaleSEDANO acqua Sale di fusione:E339E450E451E452 Addensante:carregeninafarina di carruba Conservante: E200E202 Può contenere GLUTINE",
            "plu": "010918"
        },
        {
            "_id": "109489",
            "name": "DEL GIUDICE TRECCE INCART.1KG",
            "ingredients": "INGREDIENTI:LATTE pastorizzato caglio sale fermenti lattici vivi. Può contenere GLUTINE.",
            "plu": "010013"
        },
        {
            "_id": "109501",
            "name": "DEL GIUDICE SCAMOR.PASS.AFF.1K",
            "ingredients": "INGREDIENTI:LATTE pastorizzato caglio sale fermenti lattici viviaroma fumo. Può contenere GLUTINE.",
            "plu": "010107"
        },
        {
            "_id": "109502",
            "name": "DEL GIUDICE SCAMORZ.PAS.600GR",
            "ingredients": "INGREDIENTI:LATTE pastorizzato caglio sale fermenti lattici vivi. Può contenere GLUTINE.",
            "plu": "010108"
        },
        {
            "_id": "109503",
            "name": "DEL GIUDICE RICOTTA 1800GR",
            "ingredients": "INGREDIENTI: LATTE pastorizzato siero di LATTE sale. Può contenere GLUTINE.",
            "plu": "010416"
        },
        {
            "_id": "109504",
            "name": "DEL GIUDICE BURRATA 350GR",
            "ingredients": "INGREDIENTI:LATTE pastorizzato PANNA pastorizzata caglio sale fermenti lattici vivi. Può contenere GLUTINE.",
            "plu": "010015"
        },
        {
            "_id": "109505",
            "name": "DEL GIUDICE CACIO MOL.BIA.15K",
            "ingredients": "INGREDIENTI: LATTE pastorizzato caglio sale fermenti lattici vivi. Può contenere GLUTINE.",
            "plu": "010109"
        },
        {
            "_id": "109506",
            "name": "DEL GIUDICE CACIO MOL.AFF.15K",
            "ingredients": "INGREDIENTI:LATTE pastorizzato caglio sale fermenti lattici viviaroma fumo. Può contenere GLUTINE.",
            "plu": "010110"
        },
        {
            "_id": "109507",
            "name": "DEL GIUDICE CACIOTTE 900GR",
            "ingredients": "INGREDIENTI:LATTE pastorizzato caglio sale fermenti lattici vivi. Può contenere GLUTINE.",
            "plu": "010111"
        },
        {
            "_id": "110971",
            "name": "VALLELATA PRIMO SALE AL KG",
            "ingredients": "INGREDIENTI:LATTE sale caglio. Può contenere GLUTINE",
            "plu": "011394"
        },
        {
            "_id": "110974",
            "name": "SENFTER SPECK IGP INTERO AL KG",
            "ingredients": "INGREDIENTI:carne (coscia) di suino sale spezie piante aromatiche antiossidante: ascorbato di sodio; conservante: nitrito di sodio. Affumicatura naturale. Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "015568"
        },
        {
            "_id": "111028",
            "name": "BIFFI PESTO GENOVESE 15KG",
            "ingredients": "INGREDIENTI: Olio di semi di girasole basilico olio di semi di girasoleANACARDIolio di oliva GRANA PADANO (LATTEsalecagliolisozima da UOVA) amido modificato di patata PINOLIsalefibra veg. aglio(SOLFITI)correttore di acidità: acido lattico antiossidante:acido ascorbico. Può contenere altra FRUTTA A GUSCIO.",
            "plu": "015846"
        },
        {
            "_id": "111497",
            "name": "DELIFRANCE PANFIBRE AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: farina di GRANO tenero acqua semi di lino giallo e scuro semi di papavero semi di miglio semi di zucca semi di girasole sale crusca di GRANO tenero tostato lievito malto dORZO tostato. Semi 20%. Può contenere LATTE SEMI DI SESAMOSOIASENAPE. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016116"
        },
        {
            "_id": "111536",
            "name": "FORNO D.R.SCHIOCCO AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI:Farina di GRANO TENERO tipo0 acquamargarina vegetale oli e grassi vegetali non idrogenati 80%(grassi(palma) oli(girasole)) acquasaleemulsionante:mono-e digliceridi degli acidi grassi correttore di acidità: acido citricoaromi colorante: betacarotene saccarosiosale lievito farina di GRANO TENERO maltato. Può contenere SESAMOSOIASENAPELATTEPESCE. Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato",
            "plu": "016117"
        },
        {
            "_id": "111537",
            "name": "FORNO D.R.SCHIOCCO MULTIC. KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI:Farina di GRANO tenero tipo 0 acquamargarina vegetale oli e grassi vegetali non idrogenati 80% (grassi(palma) oli (girasole)) acqua sale emulsionante:digliceridi degli acidi grassi correttore di acidità: acido citricoaromicolorante: betacarotenemix di semi e cereali(semi di lino SEMI DI SESAMOsaledecorticatosemi di girasoleSOIAfiocchi di AVENA 20%fiocchi di FARRO 1%)saccarosiomiscela con farina di malto tostato ed estratto di malto(farina precotta di GRANO tenero farina di malto tostato di GRANO non tenero estratto di malto (malto d ORZO acqua))lievitosale. Può contenere SENAPELATTEPESCE. Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016118"
        },
        {
            "_id": "111543",
            "name": "*AGRITECH FIGOTTA PROSCIUT. KG",
            "ingredients": "INGREDIENTI:Farina di GRANO tenero tipo 0acqua prosciutto cotto affumicato(carne suinaacqua destrosioaromiE452E301E250aromatizzanti di affumicatura)lievito madre in polvere(Farina di GRANO tenero tipo 0)olio di olivasale farina di FRUMENTO maltato. Può contenere tracce di LATTE(compreso il LATTOSIO ) SEMI DI SESAMOSOIAFRUTTA A GUSCIO (NOCI) E DERIVATI. Prodotto parz.cotto surgelato da non ricongelare",
            "plu": "None"
        },
        {
            "_id": "111546",
            "name": "*LANTER.PAGNOT.GR.KHORASAN KG",
            "ingredients": "INGREDIENTI:Farina di GRANO khorasanacqua lievito di birra sale farina di GRANO maltato. Può contenere tracce di LATTE FRUTTA A GUSCIO (NOCI) PESCE SOIA SESAMO e prodotti derivati Prodotto parz.cotto surgelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da pane parzialmente cotto.",
            "plu": "None"
        },
        {
            "_id": "111698",
            "name": "MIRAGLIA OLIVE CON MANDORLE 5K",
            "ingredients": "INGREDIENTI:Olive verdi denocciolate (calcidica) MANDORLE sgusciate e spellate olio di semi di girasole olio doliva spezie disidratate: (aglio(SOLFITI)prezzemolo peperoncino) conservante: acido L. ascorbico - E300330 correttore di acidità: acido citrico - E330",
            "plu": "015992"
        },
        {
            "_id": "113496",
            "name": "ZORBAS FETA GRECA AL KG",
            "ingredients": "INGREDIENTI: LATTE di pecora e capra sale caglio fermenti lattici vivi Può contenere GLUTINE",
            "plu": "015773"
        },
        {
            "_id": "113675",
            "name": "DEL G.DELI RICOT.NA S/LAT.500G",
            "ingredients": "INGREDIENTI: LATTE pastorizzato delattosato siero di LATTE saleCorrettore di acidità: acido lattico. Può contenere GLUTINE.",
            "plu": "010025"
        },
        {
            "_id": "113677",
            "name": "PANE DI MONTEVERGINE AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: farina di FRUMENTO tipo 00 acqua lievito naturale(contiene FRUMENTO) sale lievito di birra. Può contenere SOIA SESAMO NOCISENAPE MANDORLE NOCCIOLE e LATTE. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016061"
        },
        {
            "_id": "114052",
            "name": "PANE ALLA SEGALE AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: Farina di GRANO tenero tipo 0 acqua farina di SEGALE 23% GLUTINE di FRUMENTO sale lievito farina dORZO maltato tostato lievito disattivato farina di CEREALI maltati (FRUMENTO) agenti di trattamento della farina: acido ascorbico. Può contenere FRUTTA A GUSCIO SESAMOSOIA SENAPE. Conservare a T -18°C. Una volta scongelato non ricongelare il prodotto e consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016122"
        },
        {
            "_id": "114054",
            "name": "BAGUETTE PAYSANNE 280GR",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: Farina di GRANO tenero tipo 0 acqua lievito madre GLUTINE di FRUMENTO lievito madre di GRANO disattivato farina di CEREALI maltati tostati (FRUMENTO) fermenti agenti di trattamento della farina: acido ascorbico. Può contenere FRUTTA A GUSCIO SESAMOSENAPESOIA. Conservare a T -18°C. Una volta scongelato non ricongelare il prodotto e consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016120"
        },
        {
            "_id": "115077",
            "name": "CROCE GORGONZOLA PANNA VER.DOP",
            "ingredients": "INGREDIENTI: LATTEsale caglio di vitello. Coadiuvanti: fermenti lattici selezionati muffe. Crosta non edibile. Può contenere: GLUTINE.",
            "plu": "010500"
        },
        {
            "_id": "115273",
            "name": "PINNA BRIGANTE FOR.DI CAPRA KG",
            "ingredients": "INGREDIENTI: LATTE di capra pastorizzato caglio (<1%) sale (n.p.salato per via umida) Trattato in superficie con conservante E235 Crosta non edibile Può contenere GLUTINE.",
            "plu": "010536"
        },
        {
            "_id": "115334",
            "name": "SANO GUANCIALE AMATRICIANO KG",
            "ingredients": "INGREDIENTI: guancia di suino saccarosio aromi naturali spezie ANTIOSSIDANTE: E 300 CONSERVANTI: E 252 E 250 Può contenere GLUTINELATTE. Origine suino: ITALIA",
            "plu": "012477"
        },
        {
            "_id": "115776",
            "name": "FALODE CACIOCAVALLO RAZ.BRUNA",
            "ingredients": "INGREDIENTI:LATTE bovino fermenti lattici caglio Può contenere GLUTINE.",
            "plu": "010152"
        },
        {
            "_id": "115778",
            "name": "VOGLIAZZI INS.RUSSA SALM.17KG",
            "ingredients": "INGREDIENTI: insalata russa 60% (patate olio di semi di girasole UOVA pastorizzate aceto di vino (contiene SOLFITI) sale zucchero SENAPE succo di limone E412E415E270 E200)gelatina MAIONESESALMONE affumicato 9% (contiene LATTE) arance condite pasta di peperoni preparazione alimentare a base di uova di LOMPO. Può contenere GLUTINE CROSTACEI SOIA FRUTTA A GUSCIO SEDANO MOLLUCHI SEMI DI SESAMO LUPINO ARACHIDI.",
            "plu": "015768"
        },
        {
            "_id": "115848",
            "name": "SAN GIOVANNI PR.COTTO AL MIELE",
            "ingredients": "INGREDIENTI : carne di suino sale miele 2.4% Antiossidante : sodio L-ascorbato Conservante : sodio nitrito aromi naturali Può contenere GLUTINELATTE. Origine suino: ITALIA",
            "plu": "013676"
        },
        {
            "_id": "115948",
            "name": "GUSTO DECO PECORINO PIENZA KG",
            "ingredients": "INGREDIENTI:LATTE di ovino pastorizzato sale caglio FERMENTI LATTICI Trattato in superficie con concentrato di pomodoro e conservanti E202E235 Può contenere GLUTINE.",
            "plu": "011399"
        },
        {
            "_id": "115950",
            "name": "GUSTO DECO FORMAGGIO FOSSA KG",
            "ingredients": "INGREDIENTI:LATTE di pecora pastorizzato FERMENTI LATTICI SELEZIONATI caglio (di vitello) sale. Può contenere GLUTINE.",
            "plu": "011400"
        },
        {
            "_id": "115951",
            "name": "GUSTO DECO COPPA PARMA IGP KG",
            "ingredients": "INGREDIENTI:carne suina sale destrosio aromi naturali. Conservanti:nitrato di potassio nitrito di sodio Può contenere GLUTINELATTE.",
            "plu": "015572"
        },
        {
            "_id": "115952",
            "name": "GUSTO DECO PECOR.ROMANO DOP1/4",
            "ingredients": "INGREDIENTI:LATTE ovino pastorizzato sale caglio Può contenere GLUTINE",
            "plu": "011401"
        },
        {
            "_id": "115953",
            "name": "GUSTO DECO GORGO.DOP CUCCHIAIO",
            "ingredients": "INGREDIENTI:LATTE di vacca intero sale e caglio Può contenere GLUTINE",
            "plu": "011402"
        },
        {
            "_id": "115954",
            "name": "GUSTO DECO GORGONZOLA DOP 1/8K",
            "ingredients": "INGREDIENTI:LATTE di vacca intero sale e caglio Può contenere GLUTINE",
            "plu": "011403"
        },
        {
            "_id": "115955",
            "name": "GUSTO DECO SALAME FELINO IGP K",
            "ingredients": "INGREDIENTI:carne di suino sale speziedestrosio Antiossidante: E300 Conservanti: E252 E250 Può contenere GLUTINELATTE. Origine del suino: ITALIA",
            "plu": "015573"
        },
        {
            "_id": "115980",
            "name": "MIRAGLIA OL.VER.DENOC.SAL.5KG",
            "ingredients": "INGREDIENTI:Peperoni rossi gialli e verdi a filetto olio di semi di girasoleaceto di vino(SOLFITI) sale spezie disidratate (aglio (SOLFITI)prezzemolo peperoncino)ant. a. ascorbico - E300 acitrico - E330. Può contenere MANDORLE LUPINI e SEDANO.",
            "plu": "015960"
        },
        {
            "_id": "116077",
            "name": "CROCE GORGONZOLA MALGHESE DOP",
            "ingredients": "INGREDIENTI: LATTEsale caglio di vitello. Coadiuvanti: fermenti lattici selezionati muffe. Crosta non edibile. Può contenere: GLUTINE.",
            "plu": "010499"
        },
        {
            "_id": "116155",
            "name": "BOTALLA FORMAGGIO VERACAPRA KG",
            "ingredients": "INGREDIENTI:LATTE CRUDO DI CAPRA fermenti latticicagliosale",
            "plu": "010504"
        },
        {
            "_id": "116156",
            "name": "BOTALLA TOMA AL PEPERONCINO KG",
            "ingredients": "INGREDIENTI:LATTEsalecaglio peperoncino 05%-trattato in suoerficie con coloranti E 172 e con conservanti E 282 - E 285",
            "plu": "010505"
        },
        {
            "_id": "116157",
            "name": "LEONCINI PANCETTA COPPATA 1/2K",
            "ingredients": "INGREDIENTI:pancetta di suinocoppa di suinosalesaccarosioaromi Antiossidante:E301 Conservanti:E250E252 Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "013283"
        },
        {
            "_id": "116359",
            "name": "ROMAGNA T.PECORINO GINEPRO KG",
            "ingredients": "INGREDIENTI:LATTE di pecora pastorizzato sale caglio FERMENTI LATTICI Trattato in superficie con:olio di vinacciolo bacche di ginepro in polvere (4%)aceto balsamico di Modena IGP (SOLFITI) carbone vegetale. Crosta non edibile Può contenere: FRUTTA A GUSCIO GLUTINE",
            "plu": "010508"
        },
        {
            "_id": "116360",
            "name": "ROMAGNA T.PECORINO ERICA KG",
            "ingredients": "INGREDIENTI:LATTE di pecora pastorizzato sale caglio FERMENTI LATTICI Ricoperto in superficie con fiori di erica (2%) Crosta non edibile Può contenere: FRUTTA A GUSCIO GLUTINE",
            "plu": "010507"
        },
        {
            "_id": "116362",
            "name": "DALL AVA PRO.S.DANIELE DOP 18M",
            "ingredients": "INGREDIENTI:coscia di suino sale Può contenere GLUTINELATTE.",
            "plu": "013287"
        },
        {
            "_id": "116522",
            "name": "GUSTO DECO SPECK IGP SV AL KG.",
            "ingredients": "INGREDIENTI:carne (coscia) di suino sale spezie piante aromatiche Antiossidante:E301 Conservante:E250 Affumicatura naturale. Può contenere GLUTINELATTE.",
            "plu": "012080"
        },
        {
            "_id": "116842",
            "name": "INVERNIZZI GORGONZOLA DOP KG",
            "ingredients": "INGREDIENTI: LATTE sale caglio. Può contenere GLUTINE.",
            "plu": "010541"
        },
        {
            "_id": "117311",
            "name": "MIRAGLIA PICCANTONI CAMPANI 5K",
            "ingredients": "INGREDIENTI:Olive denocciolate peperoncini rossi olio di semi di girasoleaceto di vino (SOLFITI) acqua sale spezie disidratate (aglio(SOLFITI)origano peperoncino) antiossidanti: E300 E330. Può contenere: MANDORLE LUPINI SEDANO NOCCIOLA.",
            "plu": "010300"
        },
        {
            "_id": "117312",
            "name": "MIRAGLIA OL.PUTTANESCA 5KG",
            "ingredients": "INGREDIENTI: Olive nere olio di semi di girasole sale spezie antiossidanti: E300E330. Può contenere LUPINI FRUTTA A GUSCIO SEDANO. Può contenere noccioli o frammenti di essi.",
            "plu": "010301"
        },
        {
            "_id": "117642",
            "name": "C.L.AQUILA CACIOTTINA MORB.KG",
            "ingredients": "INGREDIENTI: LATTE vaccino caglio fermenti latticisale.Trattata in superficie con conservante E235. Crosta non edibile. Può contenere GLUTINE.",
            "plu": "011412"
        },
        {
            "_id": "117643",
            "name": "C.L.AQUILA CACIO MISTO 3KG",
            "ingredients": "INGREDIENTI: LATTE vaccino e ovinocagliofermenti latticisale.Crosta non edibile. Può contenere GLUTINE.",
            "plu": "011411"
        },
        {
            "_id": "117645",
            "name": "C.L.AQUILA CACIOTTA AQUIL.KG",
            "ingredients": "INGREDIENTI: LATTE vaccino caglio fermenti latticisale.Trattata in superficie con conservante E235. Crosta non edibile. Può contenere GLUTINE.",
            "plu": "011413"
        },
        {
            "_id": "117733",
            "name": "TELLO PR.JAMON SERRANO DIS.KG",
            "ingredients": "INGREDIENTI: prosciutto di suino sale antiossidante (E-316) conservanti (E-252E-250) Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "012100"
        },
        {
            "_id": "117770",
            "name": "PANINI CASARECCIO AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: farina di FRUMENTO tipo 00 acqua lievito naturale (contiene FRUMENTO) farina di FARRO 1% sale. Può contenere SOIA SEMI DI SESAMOSENAPE. FRUTTA A GUSCIO LATTE UOVA. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016091"
        },
        {
            "_id": "117771",
            "name": "PANINI CASARECCIO OLIVE AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: Farina di FRUMENTO tipo 00 acqua olive30% lievito naturale(contiene FRUMENTO) lievito di birra pomodori pelati. Può contenere SOIA SEMI DI SESAMO FRUTTA A GUSCIO LATTE UOVASENAPE. Prodotto parz. cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016090"
        },
        {
            "_id": "117772",
            "name": "PANINI CASARECCIO NOCI AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: Farina di FRUMENTO tipo 00 acqua NOCI 27% lievito naturale(contiene FRUMENTO) sale miele. Può contenere SOIA SESAMO NOCISENAPE MANDORLE NOCCIOLE LATTE e UOVA. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016089"
        },
        {
            "_id": "118694",
            "name": "BOCCEA RICOTTA MARELLA AL KG",
            "ingredients": "INGREDIENTI:Siero di LATTE di Pecora LATTE di pecora e sale. Può contenere GLUTINE.",
            "plu": "010631"
        },
        {
            "_id": "118853",
            "name": "FICACCI OLIVE TAGGIASCHE 3KG",
            "ingredients": "INGREDIENTI:Olive (90%) olio extra vergine di oliva (10%) sale. Acidificanti:E270 Acido lattico",
            "plu": "010656"
        },
        {
            "_id": "119326",
            "name": "PANE AZZIMO AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: Farina di FRUMENTO tipo OO acqua sale farina di CEREALI maltati. Può contenere SOIASESAMONOCISENAPE MANDORLE NOCCIOLELATTEUOVAAVENA. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016126"
        },
        {
            "_id": "119327",
            "name": "PANINI AL FORMAGGIO AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI:Farina di FRUMENTO tipo OO acqua misto FORMAGGI lievito naturale (contiene GLUTINE) UOVA strutto zucchero sale destrosio lievito di birra. Conservante: propianato di calcio. Emulsionante: E471 Può contenere: SOIA SESAMO SENAPEFRUTTA A GUSCIO. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016127"
        },
        {
            "_id": "120298",
            "name": "GUSTO DECO TALEGGIO DOP 1/2 KG",
            "ingredients": "INGREDIENTI: LATTE sale caglio Può contenere GLUTINE.",
            "plu": "010658"
        },
        {
            "_id": "120858",
            "name": "CAO PEC.PASTORE SARDO KG",
            "ingredients": "INGREDIENTI:LATTE di pecorasalecaglio.Trattato in crosta con conservante E235 (antimuffa); colorante naturale caramello E150b. Può contenere GLUTINE",
            "plu": "016207"
        },
        {
            "_id": "121426",
            "name": "WESTLAND TRENTA FORME 5KG",
            "ingredients": "INGREDIENTI: LATTE vaccino pastorizzato 79% olio vegetale di mais 19%sale fermenti lattici caglio microbico. Può contenere GLUTINE",
            "plu": "010837"
        },
        {
            "_id": "121517",
            "name": "CACIOTTONE PASTA FILATA KG5",
            "ingredients": "INGREDIENTI: LATTE vaccinosalecaglioFERMENTI. Può contenere GLUTINE",
            "plu": "010774"
        },
        {
            "_id": "121624",
            "name": "PINNA PECOR.ROMANO CAPPATO NER",
            "ingredients": "INGREDIENTI: LATTE di pecora  caglio sale Può contenere GLUTINE",
            "plu": "010989"
        },
        {
            "_id": "121822",
            "name": "BLD EDAM TEDESCO 40% 3KG",
            "ingredients": "INGREDIENTI: LATTE vaccinosale fermenti lattici caglio. Può contenere GLUTINE.",
            "plu": "010838"
        },
        {
            "_id": "122043",
            "name": "GUSTO DECO PEC.TOSCANO DOP KG.",
            "ingredients": "INGREDIENTI: LATTE ovino pastorizzato sale caglio e fermenti autoctoni. Trattato in superficie con olio di oliva e conservanti E202. Può contenere FRUTTA A GUSCIOGLUTINE",
            "plu": "016213"
        },
        {
            "_id": "122497",
            "name": "TOGIMA OLIVE MISTO MEDITER.KG",
            "ingredients": "INGREDIENTI:muschio shii take olive verdi denocciolate peperoni a filetti(Italia) carciofini a spicchioolio di semi di girasole.",
            "plu": "015989"
        },
        {
            "_id": "122610",
            "name": "LA PECORELLA CACIOTTA MISTA KG",
            "ingredients": "INGREDIENTI: LATTE di vacca pastorizzato LATTE di pecora pastorizzato sale caglio FERMENTI LATTICI. Conservanti in superficie: E202 E235. Coloranti in superficie: E172. Origine del latte: ITALIA Può contenere GLUTINE.",
            "plu": "016235"
        },
        {
            "_id": "122678",
            "name": "BALISTRERI ALICI SAL.II BARRA",
            "ingredients": "INGREDIENTI: ACCIUGHE(Engrauli encrasiocolus) Zona FAO 371.3 - 372.1-372.2 Mar Med Centrale pescato con Reti da circuizione sale salamoia.",
            "plu": "016236"
        },
        {
            "_id": "122806",
            "name": "GUSTO DECO GUANCIALE STAG.KG",
            "ingredients": "INGREDIENTI: carne di suino sale destrosio saccarosio spezie aromi aromi naturali antiossidante (E300) conservanti (E252 E250). Può contenere GLUTINELATTE. Origine suino: ITALIA",
            "plu": "016237"
        },
        {
            "_id": "122900",
            "name": "LEONCINI MORT.GARDESELLA AL KG",
            "ingredients": "INGREDIENTI:Carne di suino LATTE scremato in polvere sale saccarosioantiossidante: ascorbato di sodioconservanti: nitrito di sodiospezie aromi naturali. Può contenere: PISTACCHIGLUTINE. Origine suino: UE",
            "plu": "016242"
        },
        {
            "_id": "122906",
            "name": "VEROPANE PANE S.LINO/GIRAS.KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: Farina integrale di GRANO tenero acquafarina di GRANO tenero 0semi di lino 7%semi di girasole di 5%farina di GRANO maltatolievito naturalesalezuccheroagente di trattamento delle farine E300.Può contenere:UOVASOIASENAPE. Conservare a -18°C.Una volta scongelatoil prodotto non può essere ricongelato. Se cotto:Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016241"
        },
        {
            "_id": "122907",
            "name": "VEROPANE SPACCATINE AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI:semola rimacinata di GRANO durofarina di GRANO tenero 00 acqualievito madresalefarina di GRANO maltatolievito naturale agente di trattamento delle farine E300. . Può contenere: UOVASENAPESOIA. Conservare a -18°C.Una volta scongelatoil prodotto non deve essere ricongelato. Se cotto:Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016239"
        },
        {
            "_id": "122908",
            "name": "VEROPANE PANINI SPECK/PANC.KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: Farina di GRANO tenero 0 semola rimacinata di GRANO duro speck 15% (carne di suinopiante aromatiche e spezie antiossidante: E301conservanti:E250E252)pancetta 15% (carne di suinopiante aromatiche e spezieantiossidante:E301conservanti:E250E252)sale lievito naturalefarina di GRANO maltato. Può contenere: UOVA. Può contenere: UOVASOIASENAPE. Conservare a-18°C.Una volta scongelatoil prodotto non deve essere ricongelato. Se cotto:Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016240"
        },
        {
            "_id": "123683",
            "name": "A PALATELLA AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI:Farina di FRUMENTO tipo OO acqua farina di FARRO LIEVITO naturale sale.Può contenere: SOIA SESAMO SENAPE FRUTTA A GUSCIO LATTE UOVA. Conservare ad una temperatura compresa tra -18°C e -21°C. Se cotto: ottenuto da prodotto prontoforno congelato.",
            "plu": "016129"
        },
        {
            "_id": "123882",
            "name": "CARMASCIANDO PEC.FORMICOSO KG",
            "ingredients": "INGREDIENTI:LATTE di pecora pastorizzato sale caglio. Trattato di superfice: OLIO di semi Crosta non edibile Può contenere GLUTINE",
            "plu": "016134"
        },
        {
            "_id": "123883",
            "name": "CARMASCIANDO PEC.QUADRINO KG",
            "ingredients": "INGREDIENTI:LATTE ovino pastorizzato sale caglio fermenti lattici. Può contenere GLUTINE",
            "plu": "016135"
        },
        {
            "_id": "124071",
            "name": "PIZZIGHETTONE PROVOLONE 6KG",
            "ingredients": "INGREDIENTI: LATTE sale caglio conservante:E239. Trattato in superficie con conservanti E202E235. Può contenere GLUTINE.",
            "plu": "016139"
        },
        {
            "_id": "124901",
            "name": "CARPENEDO PECORINO BASAJO KG",
            "ingredients": "INGREDIENTI: LATTE crudo ovino sale caglio fermenti lattici Penicillium Roqueforti VINO passito bianco e uva cilena disidratata (uva cilena olio di semi di girasole) (min. 01%) in crosta. Può contenere GLUTINE",
            "plu": "016168"
        },
        {
            "_id": "124939",
            "name": "PIZZIGHETTONE PROV.BABBONE KG",
            "ingredients": "INGREDIENTI: Mandarino da 20/45kg cappato: LATTE sale caglio E239 trattato in superficie con: E202 E235 E180. Fiaschetto da 0.6/1.5Kg cappato nero: LATTE sale caglio trattato in superficie con: E153. Può contenere GLUTINE.",
            "plu": "016169"
        },
        {
            "_id": "125038",
            "name": "LA CAVA FOR.CANES.AI SAPORI KG",
            "ingredients": "INGREDIENTI: LATTE ovino pastorizzato LATTE pastorizzato olive farcite (pasta di peperone: peperoni acqua sale addensanti:alginato di sodio gomma diguar agente di resistenza: cloruro di calcio correttore di acidità: acido citrico conservante sorbato di potassio) peperoncino 1%rucola 1%sale caglio fermenti lattici. Può contenere GLUTINE.",
            "plu": "016180"
        },
        {
            "_id": "125112",
            "name": "PINNA PECOR.ROMANO NERO 1/4 SV",
            "ingredients": "INGREDIENTI: LATTE di pecoracaglio sale Può contenere GLUTINE.",
            "plu": "010990"
        },
        {
            "_id": "125161",
            "name": "RENZINI PR.CRUDO L UMBRIACO KG",
            "ingredients": "INGREDIENTI: coscia di suino sale vino 1% (SOLFITI)Ricoperto di vinacce 05%. Può contenere LATTEGLUTINE. Origine suino: ITALIA",
            "plu": "016432"
        },
        {
            "_id": "125497",
            "name": "BAGUETTE AL KG(280GR)",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONSERVATO INGREDIENTI: Farina di FRUMENTO tipo OO acqua sale Emulsionante: E471 lievito di birra farina di CEREALI maltati. Può contenere SOIA SEMI DI SESAMO LATTEUOVAaltra FRUTTA A GUSCIOSENAPE. Ottenuto da prodotto precotto.",
            "plu": "016422"
        },
        {
            "_id": "125498",
            "name": "CIABATTA AL KG(180GR)",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONSERVATO INGREDIENTI: Farina di FRUMENTO tipo OO acqua sale lievito di birra farina di CEREALI maltati. Può contenere SOIA SEMI DI SESAMO LATTEUOVAaltra FRUTTA A GUSCIOSENAPE. Ottenuto da prodotto precotto.",
            "plu": "016190"
        },
        {
            "_id": "125499",
            "name": "PANINI MORTADELLA AL KG(45GR)",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONSERVATO INGREDIENTI: Farina di FRUMENTO tipo 00 acqua mortadella 38% (carne di suino sale PISTACCHIO destrosio aromi e spezie antiossidante: E301 Conservante: E250) lievito naturale di FRUMENTO olio di semi di girasole destrosio sale lievito di birra malto di FRUMENTO. Conservante: propianato di calcio Emulsionante: E471. Può contenere SOIA SEMI DI SESAMO LATTEUOVA altra FRUTTA A GUSCIOSENAPE. Ottenuto da prodotto precotto.",
            "plu": "016425"
        },
        {
            "_id": "125500",
            "name": "PANINI CASERECCI AL KG(380GR)",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONSERVATO INGREDIENTI: Farina di FRUMENTO tipo OO acqua lievito naturale (contiene GLUTINE) sale farina di FARRO farina di CEREALI maltati. Può contenere SOIA SEMI DI SESAMO LATTEUOVAaltra FRUTTA A GUSCIOSENAPE. Ottenuto da prodotto precotto.",
            "plu": "016189"
        },
        {
            "_id": "125501",
            "name": "MEZZALUNA AL KG(750) VF",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONSERVATO INGREDIENTI: Farina di FRUMENTO tipo OO acqua farina di FARRO lievito naturale (contiene GLUTINE) sale. Può contenere SOIA SEMI DI SESAMO LATTEUOVAaltra FRUTTA A GUSCIOSENAPE. Ottenuto da prodotto precotto.",
            "plu": "016188"
        },
        {
            "_id": "125502",
            "name": "PANINI INTEGRALI AL KG(100GR)",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONSERVATO INGREDIENTI:Farina di FRUMENTO integrale acqua sale lievito di birra farina di CEREALI maltati SEMI DI SESAMO. Può contenere SOIA SENAPE LATTEUOVAaltra FRUTTA A GUSCIO Ottenuto da prodotto precotto.",
            "plu": "016424"
        },
        {
            "_id": "125503",
            "name": "PANINI MULTICEREALI KG(80GR)",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONSERVATO INGREDIENTI:Farina di FRUMENTO tipo OO farina di FRUMENTO INTEGRALE di SEGALEsemi di lino acqua semi di girasole decorticatoSEMI DI SESAMO pasta acida di SEGALE farina di fiocchi dORZO farina di fiocchi dAVENA sale lievito di birra farina di SOIA GLUTINE di FRUMENTO farina di CEREALI maltati destrosio pasta acida di farina di FRUMENTO alfa milasi agente trattante: acido L-ascorbico. Può contenere SOIA LATTEUOVA altra FRUTTA A GUSCIOSENAPE. Ottenuto da prodotto precotto.",
            "plu": "016423"
        },
        {
            "_id": "125504",
            "name": "PANINI SALAME AL KG(45GR)",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONSERVATO INGREDIENTI:Farina di FRUMENTO tipo OO acqua salame 31% (carne di suino sale LATTE scremato in polvere aromi naturali destrosio antiossidante: E300 Conservante: E252 E250) lievito naturale di FRUMENTO (contiene GLUTINE) olio di semi di girasole destrosio sale lievito di birra malto di FRUMENTO. Conservante: propianato di calcio. Emulsionante: E471. Può contenere SOIA SEMI DI SESAMO LATTEUOVAaltra FRUTTA A GUSCIOSENAPE. Ottenuto da prodotto precotto.",
            "plu": "016426"
        },
        {
            "_id": "125505",
            "name": "SFILATINI AL KG(250GR)",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONSERVATO INGREDIENTI: Farina di FRUMENTO tipo OO acqua sale farina di CEREALI maltati lievito di birra. Può contenere SOIA SEMI DI SESAMO LATTEUOVA altra FRUTTA A GUSCIOSENAPE. Ottenuto da prodotto precotto.",
            "plu": "016421"
        },
        {
            "_id": "125506",
            "name": "PANINI SOFFIATI AL KG(100GR)",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONSERVATO INGREDIENTI: Farina di FRUMENTO tipo OO acqua salefarina di CEREALI maltatilievito di birra. Può contenere SOIA SEMI DI SESAMO SENAPE LATTEUOVAaltra FRUTTA A GUSCIO. Ottenuto da prodotto precotto.",
            "plu": "016419"
        },
        {
            "_id": "125507",
            "name": "TARTARUGA AL KG(100GR)",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONSERVATO INGREDIENTI: Farina di FRUMENTO tipo OO acqua salefarina di CEREALI maltatilievito di birra. Può contenere SOIA SEMI DI SESAMO LATTEUOVAaltra FRUTTA A GUSCIOSENAPE. Ottenuto da prodotto precotto.",
            "plu": "016420"
        },
        {
            "_id": "125951",
            "name": "BRAZZALE FORMAGGIO VERENA KG",
            "ingredients": "INGREDIENTI: LATTE sale caglio Trattato in superficie vinil acetato e maleato di butile. Crosta non edibile. Puo contenere GLUTINE",
            "plu": "016343"
        },
        {
            "_id": "126343",
            "name": "FORNO D.R.SCHIOCCO OLIVE AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI:Farina di GRANO TENERO tipo 0 acqua olive verdi 13% (olive sale) margarina vegetale oli e grassi vegetali non idrogenati 80% (grassi (palma) oli (girasole)) acqua sale emulsionante: mono- e digliceridi degli acidi grassi correttore di acidità: acido citrico aromi colorante: betacarotene saccarosio lievito sale farina di GRANO tenero maltato. Può contenere SEMI DI SESAMO SOIA SENAPE LATTE PESCE. Può contenere frammenti di noccioli. Prodotto parz. cotto congelato da non ricongelare Cons. a T-18°C. Consumare previa cottura. Se cotto: Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016429"
        },
        {
            "_id": "126516",
            "name": "GALBANI GALBANETTO AL KG",
            "ingredients": "INGREDIENTI: carne di suinosalezucchero destrosiospeziearomi Conservante:E250E252 Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "011919"
        },
        {
            "_id": "126522",
            "name": "FULVI PECORINO ROMANO DOP KG",
            "ingredients": "INGREDIENTI: LATTE di pecora termizzato caglio sale fermenti lattici. CROSTA NON EDIBILE. Puo contenere GLUTINE.",
            "plu": "016316"
        },
        {
            "_id": "126523",
            "name": "FULVI RICOTTA ROMANA DOP AL KG",
            "ingredients": "INGREDIENTI: siero di LATTE di pecora pastorizzato LATTE di pecora sale.",
            "plu": "016435"
        },
        {
            "_id": "126931",
            "name": "BRAZZALE PROVOLONE BOCC.AL KG",
            "ingredients": "INGREDIENTI: LATTE sale caglio Può contenere GLUTINE.",
            "plu": "015840"
        },
        {
            "_id": "126955",
            "name": "SANO PROSC.AMATRICIANO IGP KG",
            "ingredients": "INGREDIENTI: Coscia di suino sale saccarosio. Conservante: E 252. La sugna di copertura: grasso peri-renale (sugna) farina di riso sale pepe Può contenere LATTE GLUTINE",
            "plu": "016352"
        },
        {
            "_id": "127039",
            "name": "IGOR BLU DI CAPRA ERBORINATO K",
            "ingredients": "INGREDIENTI: LATTE di capra intero pastorizzato fermenti lattici sale marino essiccato caglio animale muffe (Pennicillium). Può contenere GLUTINE.",
            "plu": "016366"
        },
        {
            "_id": "128319",
            "name": "LATTEBUSCHE MONTASIO DOP AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccino sale caglio. Crosta non edibile. Può contenere GLUTINE.",
            "plu": "016310"
        },
        {
            "_id": "128448",
            "name": "BUSTI FORM.TRE LATTI LARI KG",
            "ingredients": "INGREDIENTI: LATTE caprino vaccino e ovino pastorizzato sale di Volterra caglio vegetale (ottenuto da cynara cardunculus) e fermenti lattici.Trattato in superficie con conservanti E235 E202 e colorante carbone vegetale. Origine del latte: ITALIA Può contenere GLUTINE",
            "plu": "016379"
        },
        {
            "_id": "128655",
            "name": "PALMIERI MORT.FAVOLA C/PIST.KG",
            "ingredients": "INGREDIENTI: Carne di suinotrippino suinosale mielePISTACCHIO (08%)aromi naturali pepespezie antiossidante:ascorbato di sodio conservante: nitrito di sodio. Può contenere LATTEGLUTINE. Origine suino: ITALIA",
            "plu": "016389"
        },
        {
            "_id": "128656",
            "name": "QUERCETE PROS.SUINO CAS.C/O KG",
            "ingredients": "INGREDIENTI: Coscia di suino di maiale razza casertano sale marino italiano. Può contenere GLUTINE LATTE. Origine suino: ITALIA",
            "plu": "016386"
        },
        {
            "_id": "128657",
            "name": "QUERCETE SALS.SUINO CASERT.KG",
            "ingredients": "INGREDIENTI: Carne di suino di razza casertana sale spezie aromi. Conservante: E250 Budello: naturale. Budellina di suino Può contenere: GLUTINE LATTE. Origine suino: ITALIA",
            "plu": "016385"
        },
        {
            "_id": "128670",
            "name": "QUERCETE CAPOC.SUINO CASERT.KG",
            "ingredients": "INGREDIENTI: Carne di suino di razza casertana sale spezie. Budello: NATURALE Può contenere: GLUTINE LATTE. Origine suino: ITALIA",
            "plu": "016387"
        },
        {
            "_id": "128671",
            "name": "QUERCETE PANC.SUINO CASERT.KG",
            "ingredients": "INGREDIENTI: Carne di suino di razza casertana sale spezie. Origine suino: ITALIA Può contenere GLUTINE LATTE.",
            "plu": "016388"
        },
        {
            "_id": "129061",
            "name": "GALLI NOCE DI PROSC.PEPE KG",
            "ingredients": "INGREDIENTI: Noce di suino; Sale; Zuccheri: destrosio Antiossidante: ascorbato di sodio; Spezie Conservanti: potassio nitrato sodio nitrito. Origine suino: UE Prodotto ricoperto da miscela non edibile di granella di pepe e stucco per prosciutti da rimuovere prima del consumo. Può contenere GLUTINELATTE.",
            "plu": "016391"
        },
        {
            "_id": "130093",
            "name": "GALBANI MORT.AUGUSTISSIMA 80KG",
            "ingredients": "INGREDIENTI:Carne di suino trippino di suino sale aromi PISTACCHI (04%) correttore di acidità: citrati di sodio zucchero esaltatore di sapidità: glutammato monosodico spezieantiossidante: ascorbato di sodioconservante: nitrito di sodio. Può contenere: LATTE GLUTINE.",
            "plu": "016406"
        },
        {
            "_id": "130890",
            "name": "FRANCIA FIORD.TESTINA AL KG",
            "ingredients": "INGREDIENTI: LATTE pastorizzatosale coagulante microbicofermenti lattici vivi. Origine del latte Italia. Può contenere: GLUTINE.",
            "plu": "010160"
        },
        {
            "_id": "130892",
            "name": "FRANCIA FIORD.BOCCONCINO AL KG",
            "ingredients": "INGREDIENTI: LATTE pastorizzato sale coagulante microbicofermenti lattivi vivi. Origine del latte: Italia. Può contenere: GLUTINE",
            "plu": "010161"
        },
        {
            "_id": "130899",
            "name": "FRANCIA PROV.AFF.IN ACQUA25KG",
            "ingredients": "INGREDIENTI: LATTE pastorizzato sale coagulante microbicofermenti lattici vivi fumo liquido.Origine del latte : Italia. Può contenere: GLUTINE",
            "plu": "010184"
        },
        {
            "_id": "130901",
            "name": "FRANCIA GIUNCATINA 250GR",
            "ingredients": "INGREDIENTI: LATTE pastorizzato sale coagulante microbicofermenti lattici vivi. Origine del latte: Italia. Può contenere: GLUTINE",
            "plu": "010185"
        },
        {
            "_id": "130902",
            "name": "FRANCIA OVOLO BUF.DOP AL KG",
            "ingredients": "INGREDIENTI: LATTE di bufala pastorizzato siero innesto naturalesalecaglio. Può contenere: GLUTINE",
            "plu": "010163"
        },
        {
            "_id": "130907",
            "name": "FRANCIA RIC.VAC.FUSCELLA AL KG",
            "ingredients": "INGREDIENTI: siero di LATTE vaccino pastorizzato LATTE vaccino pastorizzato sali minerali. Può contenere: GLUTINE",
            "plu": "010164"
        },
        {
            "_id": "131023",
            "name": "MIRAGLIA PEPER.VERDI DOLCI 4KG",
            "ingredients": "INGREDIENTI: Papaccelle verdi aceto di vino (SOLFITI) acqua sale. Può contenere MANDORLE LUPINI.",
            "plu": "010197"
        },
        {
            "_id": "131027",
            "name": "MIRAGLIA PEP.PICC.ACETO 4KG",
            "ingredients": "INGREDIENTI: Papaccelle rosse piccanti aceto di vino (SOLFITI) acqua. Può?contenere MANDORLE LUPINI.",
            "plu": "010198"
        },
        {
            "_id": "131028",
            "name": "MIRAGLIA PEP.ROS.DOL.ACETO 4KG",
            "ingredients": "INGREDIENTI: Papaccelle rosse aceto di vino(SOLFITI)acqua sale. Può contenere MANDORLE LUPINI.",
            "plu": "010196"
        },
        {
            "_id": "131049",
            "name": "OCCELLI CON FRUTTA E GRAPPA K",
            "ingredients": "INGREDIENTI:LATTE di pecora 36% (origine: Italia) LATTE di vacca 36% (origine: Italia) frutta disidratata 20% in proporzione variabile: uva (contiene ANIDRIDE SOLFOROSA) albicocche (contiene ANIDRIDE SOLFOROSA) misto frutti di bosco (zucchero mirtilli rossi lamponi mirtilli ribes nero acidificante: acido citrico stabilizzante: cloruro di calcio conservante: sorbato di potassio) fichi Grappa di Moscato 7% sale caglio. Può contenere GLUTINE. Conservare a 6-8°C.",
            "plu": "010186"
        },
        {
            "_id": "131109",
            "name": "BRAZZALE PROV.BOCC.PUP.NEVE5KG",
            "ingredients": "INGREDIENTI: LATTE sale caglio. Può contenere GLUTINE.",
            "plu": "010189"
        },
        {
            "_id": "131566",
            "name": "FILET.NI BACC.MORHUA 700/1000",
            "ingredients": "INGREDIENTI:MERLUZZO NORDICO (Gadus macrocephalus)sale Stabilizzanti: E450 E451 Zona FAO67 Attrezzo di pesca: ami e palangari Contiene PESCE",
            "plu": "015791"
        },
        {
            "_id": "131916",
            "name": "FRIESLAND CHEDDAR AL KG.",
            "ingredients": "INGREDIENTI: LATTE sale fermenti lattici caglio microbico colorante: E160b. Può contenere GLUTINE. Conservare tra 4-6°C.",
            "plu": "015806"
        },
        {
            "_id": "131960",
            "name": "ARTIGIAN PANCETTA COPPATA KG",
            "ingredients": "INGREDIENTI: Carne suina sale alimentare destrosio aromi spezie antiossidante (E301) conservanti (E250E252). Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "015811"
        },
        {
            "_id": "131961",
            "name": "ARTIGIAN PANCETTA TESA KG",
            "ingredients": "INGREDIENTI:Carne suina sale alimentare destrosio aromi spezie antiossidante (E301) conservanti (E250E252). Origine suino: UE Aromatizzazione: Pepe Nero 1% oppure Peperoncino 1% oppure Aromatizzante Fumo 1% Può contenere GLUTINELATTE.",
            "plu": "015812"
        },
        {
            "_id": "131962",
            "name": "ARTIGIAN PANCETTA ARR.DOLCE KG",
            "ingredients": "INGREDIENTI:Carne suina sale alimentare destrosio aromi naturali spezieantiossidante (E301) conservanti (E250E252) Origine suino: UE Può contenere GLUTINELATTE.",
            "plu": "015810"
        },
        {
            "_id": "131963",
            "name": "ARTIGIAN PANCETTA ARR.PEPE KG",
            "ingredients": "INGREDIENTI:Carne suina sale alimentare destrosio aromi naturali spezieantiossidante (E301) conservanti (E250 E252). Origine suino: UE Aromatizzazione: Pepe Nero 1% Può contenere GLUTINELATTE.",
            "plu": "015809"
        },
        {
            "_id": "132012",
            "name": "VALCOLATTE RICCOTTA 15KG",
            "ingredients": "INGREDIENTI: Siero di LATTE vaccino pastorizzato* Crema di LATTE pastorizzata Sale (NaCl) *: *: Origine del latte: Italia",
            "plu": "015808"
        },
        {
            "_id": "132067",
            "name": "VIANI SALAME TOSCANO AL KG",
            "ingredients": "INGREDIENTI: Carne di suino sale destrosio saccarosio spezie antiossidanti (ascorbato di sodio aglio(SOLFITI) conservanti (nitrato di potassio nitrito di sodio). Origine suino: UE Può contenere LATTEGLUTINE.",
            "plu": "015813"
        },
        {
            "_id": "132068",
            "name": "VIANI SALAME FINOCCHIONA IGP",
            "ingredients": "INGREDIENTI: Carne di suino sale destrosio saccarosiosemi e fiori di finocchio pepe spezzato e macinato antiossidanti (ascorbato di sodio aglio(SOLFITI) conservanti (nitrato di potassionitrito di sodio). Può contenere LATTEGLUTINE.",
            "plu": "015814"
        },
        {
            "_id": "132092",
            "name": "MIRAGLIA OLIVE GRECHE MAMMUT",
            "ingredients": "INGREDIENTI:Olive verdi acqua sale antiossidanti: acido ascorbico - E300 acido citrico - E330. Può contenere MANDORLE LUPINI e SEDANO",
            "plu": "015961"
        },
        {
            "_id": "132207",
            "name": "ARTIGIAN PANCETTA STECCATA KG",
            "ingredients": "INGREDIENTI:Carne suina sale alimentare spezie aromi antiossidanti (E301E300) conservanti (E252 E250). Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "015816"
        },
        {
            "_id": "132208",
            "name": "ARTIGIAN PANCETTA PRES.1/2 SV",
            "ingredients": "INGREDIENTI: Carne suina sale alimentare destrosio aromi naturali spezie antiossidante (E301) conservanti (E252 E250).Origine suino: UE Può contenere GLUTINE LATTE.",
            "plu": "015815"
        },
        {
            "_id": "132210",
            "name": "ARTIGIAN PANCETTA PEPER.1/2 SV",
            "ingredients": "INGREDIENTI: Carne suina sale alimentare destrosio aromi naturali spezie antiossidante (E301) conservanti (E252 E250).Origine suino: UE Aromatizzazione: Peperoncino 1%. Può contenere GLUTINELATTE.",
            "plu": "015817"
        },
        {
            "_id": "132284",
            "name": "GRANAROLO CACIOTTA LOLA AL KG",
            "ingredients": "INGREDIENTI: LATTE sale caglio fermenti lattici. Trattato in superficie con E235. Può contenere GLUTINE. Crosta non edibile. Conservare a max 4°C.",
            "plu": "015824"
        },
        {
            "_id": "132344",
            "name": "BRETZEL AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: farina di FRUMENTO acqua olio di colza lievito sale iodato (sale iodato di sodio) emulsionanti (E472e) correttori di acidità (E524) agenti di trattamento della farina (E300). Può contenere SOIASENAPE. Prodotto parz.cotto congelato da non ricongelare. Cons. a T-18°C.Consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "015832"
        },
        {
            "_id": "132649",
            "name": "IAQUILAT CACIOCAVALLO AL TART.",
            "ingredients": "INGREDIENTI: LATTE vaccino pastorizzato sale caglio fermenti latticitartufo 1%. Conservante: lisozima da UOVO. Può contenere GLUTINE. Conservare tra +4-6°C.",
            "plu": "015835"
        },
        {
            "_id": "133392",
            "name": "CAVAZZUTI PROS.DOLCE REGIO 5KG",
            "ingredients": "INGREDIENTI Carne suina sale destrosio saccarosio aromi naturali spezie conservanti:E250 Può contenere LATTEGLUTINE. Conservare max a 18°C Origine suino: UE",
            "plu": "015836"
        },
        {
            "_id": "133564",
            "name": "SIFOR PECORINO CON OLIVE AL KG",
            "ingredients": "INGREDIENTI:LATTE di pecora pastorizzato olive 3% sale caglio fermenti lattici. Può contenere GLUTINE FRUTTA A GUSCIO.",
            "plu": "015839"
        },
        {
            "_id": "133565",
            "name": "SIFOR PECORINO CON NOCI AL KG",
            "ingredients": "INGREDIENTI:LATTE di pecora pastorizzato NOCI 25% sale caglio fermenti lattici. Può contenere GLUTINE altra FRUTTA A GUSCIO.",
            "plu": "015838"
        },
        {
            "_id": "133566",
            "name": "SIFOR PECORINO C/PISTACCHIO KG",
            "ingredients": "INGREDIENTI: LATTE di pecora pastorizzato PISTACCHIO di Sicilia 2% min. sale caglio fermenti lattici. In crosta. granella di PISTACCHIO di SICILIA 5% min. olio EVO. Può contenere GLUTINE altra FRUTTA A GUSCIO.",
            "plu": "015837"
        },
        {
            "_id": "133633",
            "name": "CARMASCIANDO PECORINO APINO KG",
            "ingredients": "INGREDIENTI: LATTE ovino  sale caglio cera d api (in superficie). Crosta non edibile. Può contenere GLUTINE. Conservare a 4°C.",
            "plu": "015843"
        },
        {
            "_id": "133634",
            "name": "CARMASCIANDO CAC.PODOLICO KG",
            "ingredients": "INGREDIENTI: LATTE vaccino sale caglio. Crosta non edibile. Può contenere GLUTINE. Conservare a 4°C",
            "plu": "015842"
        },
        {
            "_id": "133851",
            "name": "AGRITECH BASTONCINO NOCI 100GR",
            "ingredients": "PRODOTTO PRONTOFORNO CONGELATO INGREDIENTI:FARINA (di GRANO tenero;GRANO maltato);acqua; NOCI (145%); olio extra vergine di oliva (2%); sale; lievito; lievito madre in polvere (farina di GRANO tenero 0). Può contenere residui di gusci rotti. Può contenere SOIA LATTE SEMI DI SESAMOSENAPE. Prodotto surgelato allorigine. Conservare a -18°C. Non ricongelare e consumare previa cottura. Se cotto ottenuto da prodotto prontoforno congelato.",
            "plu": "015844"
        },
        {
            "_id": "134262",
            "name": "MIRAGLIA OLIVE TONDE SPAGNA 00",
            "ingredients": "INGREDIENTI:Olive verdi acqua sale antiossidanti: acido ascorbico - E300 acido citrico - E330. Può contenere MANDORLE LUPINI e SEDANO",
            "plu": "015962"
        },
        {
            "_id": "134779",
            "name": "TOGIMA OLIVE VER.SICILIA EXTRA",
            "ingredients": "INGREDIENTI: Olive acqua sale acido citrico (E330)",
            "plu": "015770"
        },
        {
            "_id": "134877",
            "name": "TOGIMA OLIVE VERDI MAMMUT",
            "ingredients": "INGREDIENTI:Olive acqua sale correttore di acidità: acido citrico - E330",
            "plu": "015771"
        },
        {
            "_id": "134886",
            "name": "AMATRICE CACIOTTA KG",
            "ingredients": "INGREDIENTI: LATTE vaccino pastorizzato (origine:ITALIA) caglio sale fermenti lattici. Trattamento in superficie: E235E202. Crosta non edibile. Può contenere GLUTINEFRUTTA A GUSCIO.",
            "plu": "015850"
        },
        {
            "_id": "134989",
            "name": "MICCIO OL.VERDI GRE.GIGANTI KG",
            "ingredients": "INGREDIENTI: Olive acqua sale correttore di acidità:E270 antiossidanta:E300 Contengono noccioli.",
            "plu": "015853"
        },
        {
            "_id": "134991",
            "name": "MICCIO OL.VERDI SICILIA 000 KG",
            "ingredients": "INGREDIENTI: Olive acqua sale correttore di acidità:E270 antiossidanta:E300 Contengono noccioli. Conservare in frigo dopo lapertura.",
            "plu": "015852"
        },
        {
            "_id": "135069",
            "name": "BOTALLA TOMA CAPRETTA AL KG",
            "ingredients": "INGREDIENTI: LATTE di caprasalecaglio. Può contenere GLUTINE. Crosta non edibile. ORIGINE DEL LATTE: UE.",
            "plu": "015858"
        },
        {
            "_id": "135160",
            "name": "NEGRONI SAL.NAPOLI ATM 1KG",
            "ingredients": "INGREDIENTI: carne di suino sale destrosio spezie antiossidanti: ascorbato di sodio conservanti:nitrato di potassio nitrito di sodio. Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "014357"
        },
        {
            "_id": "135278",
            "name": "GRANA PADANO DOP OLTRE 12 MESI",
            "ingredients": "INGREDIENTI: LATTE sale caglio lisozima da UOVO. Può contenere GLUTINE. Conservare tra 4-8°C. ORIGINE DEL LATTE:ITALIA",
            "plu": "015862"
        },
        {
            "_id": "135308",
            "name": "AIA AEQ ARROSTO TACCHINO KG",
            "ingredients": "INGREDIENTI: fesa di tacchino sale aromi naturali contenenti conservanti (nitriti di origine vegetale) sciroppo dacero amido di patata spezie erbe aromatiche. Può contenere LATTEGLUTINEUOVA. Conservare tra 0-4°C.",
            "plu": "015860"
        },
        {
            "_id": "135525",
            "name": "PINI BRES.SOTTOF.1/2 SV FAV.",
            "ingredients": "INGREDIENTI:carne bovinasale destrosio aromi naturali Conservante: sodio nitrito (E250) sodio nitrato(E251) Può contenere GLUTINELATTE.",
            "plu": "015349"
        },
        {
            "_id": "137177",
            "name": "RENZINI PR.COTTO ALLA BRACE KG",
            "ingredients": "INGREDIENTI: coscia di suinoacquasale destrosioaromispeziesaccarosio Conservante:E250 Antiossidante:E301 Può contenere GLUTINELATTE. Origine suino: ITALIA",
            "plu": "013881"
        },
        {
            "_id": "137377",
            "name": "RENZINI LOMBO STAG.ALL ARAN.KG",
            "ingredients": "INGREDIENTI: lombo di suinoarancia e olio essenziale di arancia 1%destrosioaromi saccarosiospezie aceto di vino Antiossidante:E300 Conservanti:E250E252 Può contenere GLUTINELATTE. Origine suino: ITALIA",
            "plu": "013878"
        },
        {
            "_id": "137441",
            "name": "SENFTER LYONER C/OLIVE 1/2 SV",
            "ingredients": "INGREDIENTI:Carne e grasso di suino (Origine UE) acqua olive verdi farcite con pasta al peperone 10% (olive verdi acquasale pasta di peperoni (peperone salesodio alginato farina di guarcloruro di calcio E330E270)E330E270E300salespeziedestrosioaromi polifosfatieritorbato di sodionitrito di sodio. Può contenere LATTEGLUTINE. Conservare tra 0 A 7°C.",
            "plu": "015871"
        },
        {
            "_id": "137453",
            "name": "CAREZZE MARE ALICI MARINAT.1KG",
            "ingredients": "INGREDIENTI: ALICI (Engraulis encrasicolus) pescate in zona FAO 3721 con reti da traino olio di semi di girasoleaceto di vino(SOLFITI) sale prezzemolo acidificante: acido citrico.",
            "plu": "015874"
        },
        {
            "_id": "137477",
            "name": "RENZINI CAPOCOLLO STAGIONATO",
            "ingredients": "INGREDIENTI: carne di suinosale vino(SOLFITI)saccarosiopepearomi destrosio Antiossidante:E300 Conservanti:E252E250 Budello non edibile Può contenere GLUTINELATTE. Origine suino: ITALIA",
            "plu": "013879"
        },
        {
            "_id": "137540",
            "name": "MICCIO OLIVE VERDI GRAN.SIC.KG",
            "ingredients": "INGREDIENTI: Olive acqua sale correttore di acidità: acido lattico antiossidante: acido L-ascorbico.",
            "plu": "015872"
        },
        {
            "_id": "137577",
            "name": "RENZINI GUANCIALE STAGIONATO",
            "ingredients": "INGREDIENTI: carne di suinosalevino(SOLFITI) destrosiosaccarosiospeziearomi naturali Antiossidante:E300 Conservanti:E252E250 Può contenere GLUTINELATTE. Origine suino: ITALIA",
            "plu": "013880"
        },
        {
            "_id": "137596",
            "name": "KING S PROSC.S.DANIELE C/OSSO",
            "ingredients": "INGREDIENTI:coscia di suinosale marino Può contenere GLUTINELATTE. Origine suino: ITALIA",
            "plu": "012458"
        },
        {
            "_id": "137950",
            "name": "CERULLO CACIOTTA AL PEPERONCIN",
            "ingredients": "INGREDIENTI: LATTE di pecoracaglio sale peperoncino Può contenere GLUTINE.",
            "plu": "010675"
        },
        {
            "_id": "138350",
            "name": "CHIOLA PROS.PAR.DOP C/OSSO KG",
            "ingredients": "INGREDIENTI: Carne di suino sale. Può contenere LATTEGLUTINE.",
            "plu": "015879"
        },
        {
            "_id": "138368",
            "name": "CHIOLA PROS.PAR.DOP DISOS.KG",
            "ingredients": "INGREDIENTI: Carne di suino sale. Può contenere GLUTINE LATTE.",
            "plu": "015880"
        },
        {
            "_id": "138393",
            "name": "GALBANI SAL.NAP.LEG.MANO KG",
            "ingredients": "INGREDIENTI: Carne di suino (Origine: UE)destrosio sale spezie conservanti: nitrato di potassio nitrito di sodio Può contenere GLUTINELATTE.",
            "plu": "015882"
        },
        {
            "_id": "138460",
            "name": "ANTICA MURGIA RICOT.LIM/CIOCC.",
            "ingredients": "INGREDIENTI: RICOTTA di vacca e bufala zucchero PANNA UHTgocce di cioccolato fondente 5% amido modif. di patata crema di limone addensante: carragenina emulsionanti: mono e digliceridi degli acidi grassiconservante: acido sorbico curcuma. Può contenere SOIA e FRUTTA A GUSCIO. Conservare a 4°C.",
            "plu": "015887"
        },
        {
            "_id": "138709",
            "name": "RUOCCO FIOR DI LAT.AGER.AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccinosalecaglio. Può contenere GLUTINE.",
            "plu": "015889"
        },
        {
            "_id": "138710",
            "name": "RUOCCO PROVOLA AGEROLA AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccinosalecaglio. Può contenere GLUTINE.",
            "plu": "015890"
        },
        {
            "_id": "138711",
            "name": "RUOCCO PROVOLON.AGEROLA AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccinosalecaglio. Può contenere GLUTINE.",
            "plu": "015892"
        },
        {
            "_id": "138712",
            "name": "RUOCCO PROVOLON.AGER.AFF.AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccinosalecaglioaroma di affumicatura. Può contenere GLUTINE.",
            "plu": "015891"
        },
        {
            "_id": "138713",
            "name": "RUOCCO RICOTTA VACCINA AL KG",
            "ingredients": "INGREDIENTI: Siero di LATTE LATTE vaccino sale. Può contenere GLUTINE.",
            "plu": "015893"
        },
        {
            "_id": "138714",
            "name": "RUOCCO CACIOTTINE MISTE/BIA KG",
            "ingredients": "INGREDIENTI: LATTE vaccino sale caglio rucola peperoncino. Può contenere GLUTINE.",
            "plu": "010133"
        },
        {
            "_id": "138767",
            "name": "BOSCHI SPALLA COTTA S.SECON.KG",
            "ingredients": "INGREDIENTI: Carne di suino (Origine: ITALIA) sale destrosio spezie aromi antiossidante: E301 conservante: E250. Può contenere GLUTINELATTE.",
            "plu": "015888"
        },
        {
            "_id": "138975",
            "name": "ANSUINI NURSINELLE S/V AL KG",
            "ingredients": "INGREDIENTI: carne di suino (Origine: UE) sale destrosio saccarosio aromi pepe Conservanti: E250E252 antiossidante:E301. Può contenere GLUTINELATTESOLFITI.",
            "plu": "015894"
        },
        {
            "_id": "138976",
            "name": "ANSUINI CINGHIALINA S/V AL KG",
            "ingredients": "INGREDIENTI: carne di suino (Origine: UE) carne di cinghiale (min 5%) sale destrosio saccarosio aromi pepe aglio(SOLFITI)peperoncino aromatizzante di affumicatura Conservanti: E250E252. Può contenere GLUTINELATTE.",
            "plu": "015895"
        },
        {
            "_id": "138978",
            "name": "ANSUINI MORTADEL.CINGHIALE KG",
            "ingredients": "INGREDIENTI: Carne di cinghiale 51% carne di suino (Orgine: UE) sale PINOLI destrosio aromi (MANDORLA) spezie antiossidante: E301 Conservanti: E250 Può contenere LATTE GLUTINE e altra FRUTTA A GUSCIO.",
            "plu": "015896"
        },
        {
            "_id": "138979",
            "name": "ANSUINI MORTADEL.TARTUFO KG",
            "ingredients": "INGREDIENTI: Carne di suino (Origine: UE) sale tartufo nero estivo (tuber aestivum) 1% aromi (MANDORLA)destrosio spezie antiossidante: E301 Conservanti: E250. Può contenere LATTEGLUTINE e altra FRUTTA A GUSCIO.",
            "plu": "015897"
        },
        {
            "_id": "139015",
            "name": "DE LUCA SALSICCIA F/C DOLCE KG",
            "ingredients": "INGREDIENTI: Carne di suino (origine UE) aromi naturali ed estratti vegetali spezie vino liquoroso (contiene SOLFITI) destrosio cloruro di sodio saccarosio E301 E250 E252. Può contenere LATTE GLUTINE.",
            "plu": "015898"
        },
        {
            "_id": "139016",
            "name": "DE LUCA SALSIC.F/C PICCANTE KG",
            "ingredients": "INGREDIENTI: Carne di suino (origine UE) aromi naturali ed estratti vegetali spezie vino liquoroso (contiene SOLFITI) destrosio cloruro di sodio saccarosio E301 E250 E252. Può contenere LATTE GLUTINE.",
            "plu": "015899"
        },
        {
            "_id": "139017",
            "name": "DE LUCA PROSC FAETO C/OSSO KG",
            "ingredients": "INGREDIENTI: Carne di suino sale. Origine del suino: Italia. Può contenere: GLUTINE LATTE.",
            "plu": "015900"
        },
        {
            "_id": "139019",
            "name": "RODEZ FORMAGGIO AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccino crudo LATTE vaccino scremato e pastorizzato grassi di origine lattiera sale fermenti lattici caglio. può contenere GLUTINE.",
            "plu": "015901"
        },
        {
            "_id": "139022",
            "name": "PARMIGIANO REGG.TRANCIO 1/8 KG",
            "ingredients": "INGREDIENTI: LATTE vaccino caglio sale Può contenere GLUTINE.",
            "plu": "010253"
        },
        {
            "_id": "139063",
            "name": "SEGATA SALAME NAP.1/2 S/V 400G",
            "ingredients": "INGREDIENTI: Carne di suino (Origine: UE) sale saccarosio destrosio aromi spezie antiossidante: E301; conservanti: E252E250. Può contenere LATTEGLUTINE.",
            "plu": "010305"
        },
        {
            "_id": "139103",
            "name": "DALL AVA PROSC FUMATO C/OS KG",
            "ingredients": "INGREDIENTI: Coscia di suino (Origine Italia)sale. Può contenere LATTEGLUTINE.",
            "plu": "015902"
        },
        {
            "_id": "139117",
            "name": "ARTIGIAN PANCET.TESA AFFUM.KG",
            "ingredients": "INGREDIENTI: Carne suina (Origine UE) sale destrosio aromi spezie antiossidante: E301 CONSERVANTI: E250E252 aromatizzante fumo 1%. Può contenere GLUTINELATTE.",
            "plu": "015903"
        },
        {
            "_id": "139222",
            "name": "AURICCHIO GORGONZOLA DOP KG",
            "ingredients": "INGREDIENTI: LATTE di vacca intero sale caglio di vitello. Può contenere GLUTINE.",
            "plu": "015905"
        },
        {
            "_id": "139225",
            "name": "GOLFERA SALAME DOLCEMAGRO KG",
            "ingredients": "INGREDIENTI: Carne di suino (Origine Italia) sale marino iodato LATTE scremato in polvere destrosio saccarosio spezie piante aromatiche antiossidanti: E301E300 CONSERVANTI:E252E250.",
            "plu": "015904"
        },
        {
            "_id": "139365",
            "name": "ZORBAS SALSA YOG.TZATZIKI KG",
            "ingredients": "INGREDIENTI: Salsa di yogurt 345% acqua LATTE scremato in polvere proteine del LATTE in polveregrassi vegetali (palma) fermenti lattici amido modificato di mais gelatina cetrioli* 20%yogurt greco* 10% (LATTE scremato crema di LATTE fermenti lattici) oli vegetali (girasole soia colza) amido di FRUMENTO acqua aglio 24%aceto (SOLFITI) sale zucchero tuorlo dUOVO in polvere stabilizzanti (gomma di guar gomma di xanthan farina di semi di carrube) conservanti (sorbato di potassio sodio benzoato). Può contenere: PESCE SEDANOFRUTTA A GUSCIO SENAPE SEMI DI SESAMO.",
            "plu": "015907"
        },
        {
            "_id": "139384",
            "name": "MONTRONE SCAMORZA AFFUM.AL KG",
            "ingredients": "INGREDIENTI: : LATTE crudo sale caglio e fermenti lattici Può contenere GLUTINE.",
            "plu": "010035"
        },
        {
            "_id": "139385",
            "name": "MONTRONE RICOTTA MUCCA AL KG",
            "ingredients": "INGREDIENTI:: Siero di LATTE LATTE sale Correttore di acidità E330. Può contenere GLUTINE.",
            "plu": "010036"
        },
        {
            "_id": "139389",
            "name": "MONTRONE MOZZARELLA VASCH.1KG",
            "ingredients": "INGREDIENTI:LATTE crudo sale caglio correttore di acidità: acido citrico. Può contenere GLUTINE",
            "plu": "010367"
        },
        {
            "_id": "139390",
            "name": "MONTRONE MOZZARELLA VASCH.3KG",
            "ingredients": "INGREDIENTI:LATTE crudo sale caglio correttore di acidità: acido citrico. Può contenere GLUTINE",
            "plu": "010368"
        },
        {
            "_id": "139397",
            "name": "MONTRONE TRECCIONE VASCH.3KG",
            "ingredients": "INGREDIENTI: : LATTE crudo sale caglio correttore di acidità: acido citrico Può contenere GLUTINE.",
            "plu": "010397"
        },
        {
            "_id": "139400",
            "name": "MONTRONE RICOTTA GR 100 AL KG",
            "ingredients": "INGREDIENTI:: Siero di LATTE LATTE sale Correttore di acidità E330. Può contenere GLUTINE.",
            "plu": "010037"
        },
        {
            "_id": "139401",
            "name": "MONTRONE STRACCIAT.VASCH.1KG",
            "ingredients": "INGREDIENTI:LATTE crudo PANNA sale caglio e correttore di acidità: acido citrico. Può contenere GLUTINE.",
            "plu": "010998"
        },
        {
            "_id": "139403",
            "name": "MONTRONE BURRATA IGP FOGL.KG",
            "ingredients": "INGREDIENTI: LATTE crudo PANNA sale caglio correttore di acidità: acido citrico. Può contenere GLUTINE.",
            "plu": "010038"
        },
        {
            "_id": "139404",
            "name": "MONTRONE SCAMORZA BIANCA AL KG",
            "ingredients": "INGREDIENTI: LATTE crudo sale caglio fermenti lattici. Può contenere GLUTINE.",
            "plu": "010034"
        },
        {
            "_id": "139416",
            "name": "SAPOROSA FIOR DI LATTE 1KG",
            "ingredients": "INGREDIENTI: LATTEsalecaglio correttore di acidità: E330. Può contenere GLUTINE.",
            "plu": "010041"
        },
        {
            "_id": "139418",
            "name": "SAPOROSA FIOR DI LATTE AL KG",
            "ingredients": "INGREDIENTI: LATTEsalecaglio correttore di acidità: E330. Può contenere GLUTINE.",
            "plu": "010042"
        },
        {
            "_id": "139430",
            "name": "SAPOROSA TRECCIA SCAMORZA 1KG",
            "ingredients": "INGREDIENTI: LATTEsalecaglio correttore di acidità: E330. Può contenere GLUTINE.",
            "plu": "010074"
        },
        {
            "_id": "139437",
            "name": "SAPOROSA SCAMORZA AFF.AL KG",
            "ingredients": "INGREDIENTI: LATTEsalecaglio correttore di acidità: E330. Può contenere GLUTINE.",
            "plu": "010069"
        },
        {
            "_id": "139442",
            "name": "SAPOROSA BOCCONCINI AFF.1KG",
            "ingredients": "INGREDIENTI: LATTEsalecaglio correttore di acidità: E330. Può contenere GLUTINE.",
            "plu": "010061"
        },
        {
            "_id": "139443",
            "name": "SAPOROSA FILANO AFFUMICATO 1KG",
            "ingredients": "INGREDIENTI: LATTEsalecaglio correttore di acidità: E330. Può contenere GLUTINE.",
            "plu": "010080"
        },
        {
            "_id": "139446",
            "name": "SAPOROSA PIADINA AFF.1KG",
            "ingredients": "INGREDIENTI: LATTEsalecaglio correttore di acidità: E330. Può contenere GLUTINE.",
            "plu": "010062"
        },
        {
            "_id": "139447",
            "name": "SAPOROSA BASTONCINI AFF.1KG",
            "ingredients": "INGREDIENTI: LATTEsalecaglio correttore di acidità: E330. Può contenere GLUTINE.",
            "plu": "010063"
        },
        {
            "_id": "139455",
            "name": "SAPOROSA BURRATA 1KG",
            "ingredients": "INGREDIENTI: LATTE pastorizzatoPANNA salecaglio correttore di acidità: E330 Può contenere GLUTINE.",
            "plu": "010065"
        },
        {
            "_id": "139456",
            "name": "SAPOROSA CUOR DI BURRATA 1KG",
            "ingredients": "INGREDIENTI: LATTE pastorizzatoPANNA salecaglio correttore di acidità: E330 Può contenere GLUTINE.",
            "plu": "010039"
        },
        {
            "_id": "139458",
            "name": "SAPOROSA BURRATA AL KG",
            "ingredients": "INGREDIENTI: LATTE pastorizzatoPANNA salecaglio correttore di acidità: E330 Può contenere GLUTINE.",
            "plu": "010067"
        },
        {
            "_id": "139464",
            "name": "SAPOROSA FORMAGGIO FRESCO AL K",
            "ingredients": "INGREDIENTI: LATTE pastorizzato salecaglio.Può contenere GLUTINE.",
            "plu": "010040"
        },
        {
            "_id": "139465",
            "name": "SAPOROSA RICOTTA 250GR AL KG",
            "ingredients": "INGREDIENTI: Siero di LATTE sale correttore di acidità: E509. Può contenere GLUTINE.",
            "plu": "010071"
        },
        {
            "_id": "139466",
            "name": "SAPOROSA RICOTTA 500GR AL KG",
            "ingredients": "INGREDIENTI: Siero di LATTE sale correttore di acidità: E509. Può contenere GLUTINE.",
            "plu": "010072"
        },
        {
            "_id": "139475",
            "name": "SAPOROSA FIOR DI LATTE VASC.KG",
            "ingredients": "INGREDIENTI: LATTE pastorizzato SIERO INNESTO sale caglio Può contenere GLUTINE.",
            "plu": "010044"
        },
        {
            "_id": "139481",
            "name": "NONCE CACIOTTA S/LAT AL KG",
            "ingredients": "INGREDIENTI: LATTE delattosato sale caglio fermenti lattici. Può contenere GLUTINE.",
            "plu": "010068"
        },
        {
            "_id": "139487",
            "name": "NONCE CACIOCAVALLO S/LAT AL K",
            "ingredients": "INGREDIENTI: LATTE delattosato sale caglio fermenti lattici. Può contenere GLUTINE.",
            "plu": "010091"
        },
        {
            "_id": "139488",
            "name": "NONCE SCAMORZA S/LAT AL KG",
            "ingredients": "INGREDIENTI: LATTE delattosato sale caglio fermenti lattici. Può contenere GLUTINE.",
            "plu": "010090"
        },
        {
            "_id": "139493",
            "name": "SAPOROSA SCAMORZA APP.AL KG",
            "ingredients": "INGREDIENTI: LATTEsalecaglio correttore di acidità: E330. Può contenere GLUTINE.",
            "plu": "010070"
        },
        {
            "_id": "139671",
            "name": "VEROPANE FILONE DI SEMOLA KG",
            "ingredients": "PRODOTTO PRECOTTO CONGELATO INGREDIENTI:semola rimacinata di GRANO duro acqua lievito madre (semola rimacinata di duro acqua) sale lievito. Può contenere SOIA SENAPE Conservare a -18°C.Non ricongelare. Se cotto proveniente da prodotto precotto congelato. Decongelare 2 ore prima e cuocere in forno preriscaldato a 190°C per 12 minuti.",
            "plu": "014850"
        },
        {
            "_id": "139796",
            "name": "ANSUINI SALAME CORALLINA 500GR",
            "ingredients": "INGREDIENTI: Carne di suino (Origine UE) sale destrosio pepe aglio(SOLFITI) conservanti: E250E252antiossidante:E301. Può contenere GLUTINELATTE",
            "plu": "014382"
        },
        {
            "_id": "139904",
            "name": "GRANATA NERE ITRANA EXTRA KG",
            "ingredients": "INGREDIENTI:Olive acqua sale correttore di acidità: E270. Può contenere: SEDANOLUPINI SOLFITI.",
            "plu": "015910"
        },
        {
            "_id": "139905",
            "name": "GRANATA OLIVE PASSOL.GRECIA KG",
            "ingredients": "INGREDIENTI:Olive nere acqua sale correttore di acidità: acido lattico E270. Può contenere: SEDANOLUPINISOLFITI.",
            "plu": "015909"
        },
        {
            "_id": "139906",
            "name": "GRANATA OLIVE VERDI SIC.MAX KG",
            "ingredients": "INGREDIENTI:Olive verdi acqua sale correttore di acidità: E270E330 antiossidante: E300. Può contenere: SEDANOLUPINISOLFITI.",
            "plu": "015911"
        },
        {
            "_id": "139911",
            "name": "FULVI FORMAGGIO CASARO AL KG",
            "ingredients": "INGREDIENTI:LATTE di vacca LATTE di pecora pastorizzato fermenti lattici caglio sale. Crosta non edibile. Può contenere GLUTINE.",
            "plu": "010705"
        },
        {
            "_id": "139973",
            "name": "DALL AVA PR.S.D.PRES.DOP60M KG",
            "ingredients": "INGREDIENTI: Coscia di suino (Origine Italia) sale. Può contenere LATTEGLUTINE.",
            "plu": "015915"
        },
        {
            "_id": "139975",
            "name": "SOAVE RIC.SAL.DURA PECORA KG",
            "ingredients": "INGREDIENTI: Siero di LATTE di pecorasale. Può contenere GLUTINE.",
            "plu": "015914"
        },
        {
            "_id": "140032",
            "name": "SAPOROSA ARTIGIAN.VAS.1K AL KG",
            "ingredients": "INGREDIENTI: LATTEsalecaglio correttore di acidità: E330. Può contenere GLUTINE.",
            "plu": "015917"
        },
        {
            "_id": "140033",
            "name": "SAPOROSA ARTIGIAN.VAS.3K AL KG",
            "ingredients": "INGREDIENTI: LATTEsalecaglio correttore di acidità: E330. Può contenere GLUTINE.",
            "plu": "015916"
        },
        {
            "_id": "140070",
            "name": "FINI SALS.NAP.CURV.DOL.S/V500G",
            "ingredients": "INGREDIENTI: carne di suino (Origine UE)sale saccarosiodestrosiospezieantiossidanti: ascorbato di sodioconservanti:nitrato di potassio nitrito di sodio. Può contenere LATTE GLUTINE.",
            "plu": "015919"
        },
        {
            "_id": "140071",
            "name": "FINI SALS.NAP.CURV.PIC.SV 530G",
            "ingredients": "INGREDIENTI:carne di suino(Orig. UE)salepeperoncino saccarosiopaprikadestrosio spezieantiossidanti: ascorbato di sodioconservanti:nitrato di potassio nitrito di sodio. Può contenere LATTEGLUTINE.",
            "plu": "015921"
        },
        {
            "_id": "140281",
            "name": "DELIZIOSA STRACCIATELLA AL KG",
            "ingredients": "INGREDIENTI: LATTE pastorizzato PANNA UHT 45% correttore di acidità: acido lattico sale caglio. Può contenere GLUTINE.",
            "plu": "015924"
        },
        {
            "_id": "140283",
            "name": "DELIZIOSA GIUNCATA ARTIG.KG",
            "ingredients": "INGREDIENTI: LATTE vaccino pastorizzato sale caglio fermenti lattici. Può contenere GLUTINE.",
            "plu": "015926"
        },
        {
            "_id": "140291",
            "name": "DELIZIOSA BURRATA 100GR",
            "ingredients": "INGREDIENTI: LATTE pastorizzato PANNA UHT 41% correttore di acidità: acido lattico sale caglio Può contenere GLUTINE.",
            "plu": "015932"
        },
        {
            "_id": "140298",
            "name": "DELIZIOSA CACIOC.SIL.DOP 15KG",
            "ingredients": "INGREDIENTI: LATTE vaccino crudo  siero innesto naturale sale caglio. Può contenere GLUTINE.",
            "plu": "010142"
        },
        {
            "_id": "140299",
            "name": "DELIZIOSA MOZZARELLA SP.200GR",
            "ingredients": "INGREDIENTI:LATTEcorrettore di acidità: acido lattico SIERO innesto naturalesale caglio. Può contenere: GLUTINE",
            "plu": "015940"
        },
        {
            "_id": "140300",
            "name": "DELIZIOSA CACIOC.STAG.GROT.KG",
            "ingredients": "INGREDIENTI: LATTE vaccino crudo  siero innesto naturale sale caglio. Può contenere GLUTINE.",
            "plu": "010143"
        },
        {
            "_id": "140302",
            "name": "DELIZIOSA MOZZARELLA S/L.125GR",
            "ingredients": "INGREDIENTI: LATTE delattosato correttore di acidità: acido citricosale caglio. Può contenere GLUTINE.",
            "plu": "015941"
        },
        {
            "_id": "140310",
            "name": "DELIZIOSA BURRATA AL KG",
            "ingredients": "INGREDIENTI:LATTE pastorizzato PANNA UHT 41% correttore di acidità:acido lattico sale caglio. Può contenere GLUTINE.",
            "plu": "015928"
        },
        {
            "_id": "140311",
            "name": "DELIZIOSA PECORINO D ANGIO 2KG",
            "ingredients": "INGREDIENTI: LATTE ovino pastorizzato caglio sale fermenti lattici.Trattamento superficiale della crosta: conservanti: E 235E 200. Crosta non edibile. Può contenere GLUTINE.",
            "plu": "010296"
        },
        {
            "_id": "140313",
            "name": "DELIZIOSA CREMA RIC.PECOR.KG",
            "ingredients": "INGREDIENTI: Siero di LATTE ovino LATTE ovino sale.Può contenere GLUTINE",
            "plu": "015936"
        },
        {
            "_id": "140315",
            "name": "DELIZIOSA CACIOCCHIATO AL KG",
            "ingredients": "INGREDIENTI:LATTE vaccino sale caglio fermenti lattici.Può contenere GLUTINE.",
            "plu": "011132"
        },
        {
            "_id": "140316",
            "name": "DELIZIOSA TRULLOCCH.GROT.4KG",
            "ingredients": "INGREDIENTI: LATTE sale caglio fermenti lattici. Crosta non edibile. Può contenere GLUTINE.",
            "plu": "010298"
        },
        {
            "_id": "140317",
            "name": "DELIZIOSA PEC.D ANGIO GROT.2KG",
            "ingredients": "INGREDIENTI: LATTE ovino pastorizzato caglio sale fermenti lattici.Crosta non edibile. Può contenere GLUTINE.",
            "plu": "010297"
        },
        {
            "_id": "140397",
            "name": "ANSUINI SALAMELLA NORCIA 500GR",
            "ingredients": "INGREDIENTI: carne di suino (Origine Italia) saledestrosiopepeaglio(SOLFITI) conservante:E250:E252antiossidante:E301. Può contenere LATTEGLUTINE.",
            "plu": "015943"
        },
        {
            "_id": "140509",
            "name": "FILETTI LING MOLVA 700/1000",
            "ingredients": "INGREDIENTI: LING (Molva molva)saleacqua Stabilizzante:E450E451 Zona FAO27 Attrezzo di pesca: ami e palangari Contiene PESCE",
            "plu": "016412"
        },
        {
            "_id": "140736",
            "name": "FIORUCCI PROS.C.TIVOLI C/OS14M",
            "ingredients": "INGREDIENTI:Coscia di suino (Origine ITALIA) sale spezie aromi naturali. Può contenere LATTE GLUTINE.",
            "plu": "010005"
        },
        {
            "_id": "140854",
            "name": "SANTINI MORTADELLA BEATRICE KG",
            "ingredients": "INGREDIENTI: carne di suino (origine italia) grasso di suino saccarosio aromi naturali spezievino(SOLFITI) E301 erbe aromatiche E250. Può contenere LATTE GLUTINE.",
            "plu": "010124"
        },
        {
            "_id": "140855",
            "name": "SANTINI MORTADELLA C/PISTAC.KG",
            "ingredients": "INGREDIENTI: carne di suino (origine italia) grasso di suino saccarosio PISTACCHIO (0 6%) aromi naturali spezievino(SOLFITI) E301 erbe aromatiche E250. Può contenere LATTE GLUTINE.",
            "plu": "010123"
        },
        {
            "_id": "140856",
            "name": "SANTINI CULATTA REGINA AL KG",
            "ingredients": "INGREDIENTI: Carne di suino (origine italia) sale destrosio pepe nero vino(SOLFITI) aglio(SOLFITI) E252 spezie. Può contenere LATTEGLUTINE.",
            "plu": "010120"
        },
        {
            "_id": "140857",
            "name": "SANTINI SALAME MILANO AL KG",
            "ingredients": "INGREDIENTI: Carne di suino (origine italia) sale destrosio pepe saccarosio E301 vino (SOLFITI) aglio (SOLFITI) E250 E252 spezie. Può contenere LATTE GLUTINE.",
            "plu": "010114"
        },
        {
            "_id": "140858",
            "name": "SANTINI SALAME VECCH.NAPOLI KG",
            "ingredients": "INGREDIENTI: Carne di suino (origine italia) sale destrosiospeziesaccarosio E301 aromi naturaliE250 E252. Può contenere LATTE GLUTINE.",
            "plu": "010116"
        },
        {
            "_id": "140859",
            "name": "SANTINI FESA TACCHINO ARROS.KG",
            "ingredients": "INGREDIENTI: Fesa di tacchino sale vino (SOLFITI)aromi naturali E301 E250 stabilizzante: E451. Può contenere LATTE GLUTINE.",
            "plu": "010125"
        },
        {
            "_id": "140860",
            "name": "SANTINI SALAME UNGHERESE AL KG",
            "ingredients": "INGREDIENTI: Carne di suino (origine italia) sale vino(SOLFITI)destrosio saccarosio paprika pepe E301 aglio (SOLFITI) E252 E250 spezie. Può contenere LATTE GLUTINE.",
            "plu": "010115"
        },
        {
            "_id": "140861",
            "name": "SANTINI BRESAOLA 1/2 SV AL KG",
            "ingredients": "INGREDIENTI: Coscia di bovino sale E301 aromi vino (SOLFITI)destrosio pepe aglio (SOLFITI) E250 E252. Può contenere LATTE GLUTINE.",
            "plu": "010121"
        },
        {
            "_id": "140862",
            "name": "SANTINI PR.COTTO AUGUSTUS KG",
            "ingredients": "INGREDIENTI: Coscia di suino (origine UE) acqua sale saccarosio E451 E407 marsala aromi naturali E301 E250. Può contenere LATTE GLUTINE.",
            "plu": "010122"
        },
        {
            "_id": "140863",
            "name": "SANTINI SALAME MUGNANO AL KG",
            "ingredients": "INGREDIENTI: Carne di suino (origine ITALIA) sale destrosio spezie saccarosio E301 E250 E252. Può contenere LATTE GLUTINE.",
            "plu": "010117"
        },
        {
            "_id": "140864",
            "name": "SANTINI P.COTTO ARTISTA A.Q.KG",
            "ingredients": "INGREDIENTI: Coscia di suino (origine italia) saccarosio marsala aromi naturali E301 E250. Può contenere LATTE GLUTINE.",
            "plu": "010118"
        },
        {
            "_id": "140865",
            "name": "SANTINI SALAME GENTILE MAXI KG",
            "ingredients": "INGREDIENTI: Carne di suino (origine italia) sale destrosio spezie E301 vino (SOLFITI) aglio (SOLFITI) E250 E252. Può contenere LATTE GLUTINE.",
            "plu": "010119"
        },
        {
            "_id": "140873",
            "name": "CHIOCCIOLA CHIA/CURCUMA 100GR",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI:Semola GRANO duro semi di girasole Acqua SEMI DI SESAMO semi di lino farina di FARRO integrale farina di semola di GRANO tumminia farina di AVENAsemi di chia 08% semi di miglio lievito di birra destrosio sale pasta acida essiccata di FRUMENTO lievito naturale di farina di GRANO tenero tipo 0 in polvere curcuma 02%. Può contenere SOIA LATTE UOVA FRUTTA A GUSCIOSENAPE. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C.Consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "010126"
        },
        {
            "_id": "140874",
            "name": "BAGUETTINO INTEGRALE 150GR",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI:Farina integrale di GRANO tenero acqua sale lievito FRUMENTO. Può contenere SOIALATTENOCESEMI DI SESAMO SENAPE. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C.Consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "010127"
        },
        {
            "_id": "141586",
            "name": "RECCO PROVOL.PICC.1/4SV 24M.KG",
            "ingredients": "INGREDIENTI: LATTE vaccino (Origine Italia) caglio fermenti lattici. Può contenere GLUTINE.",
            "plu": "010129"
        },
        {
            "_id": "141734",
            "name": "BOSCHI PR.CR.PARMA C/O 16M.KG",
            "ingredients": "INGREDIENTI: Carne di suino (Origine Italia) sale. Può contenere LATTE GLUTINE.",
            "plu": "010132"
        },
        {
            "_id": "141735",
            "name": "BOSCHI PROS.CRUDO DISOS.AL KG",
            "ingredients": "INGREDIENTI: Carne di suino (Origine UE) sale. Può contenere LATTE GLUTINE.",
            "plu": "010131"
        },
        {
            "_id": "141871",
            "name": "SANTINI MORTADELLA S/P 100KG",
            "ingredients": "INGREDIENTI : Carne di suino grasso di suino sale saccarosio aromi naturali spezie vino(SOLFITI) antiossidante: ascorbato di sodio (E301) erbe aromaticheconservante: nitrito di sodio (E250). Puo contenere GLUTINELATTEPISTACCHIO. Origine carne: Italia",
            "plu": "010138"
        },
        {
            "_id": "142588",
            "name": "FINI COTTO LUSSO",
            "ingredients": "INGREDIENTI: coscia di suino (82%)sale destrosioamidi modificatiaromi Gelificante:carrageninesaccarosio cloruro di potassio Antiossidante:E301 Correttore di acidità:citrato di sodio Conservante: E250 Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "013059"
        },
        {
            "_id": "143339",
            "name": "INVERNIZZI GIM OTTAVI",
            "ingredients": "INGREDIENTI: LATTE vaccinosale caglio Può contenere GLUTINE",
            "plu": "010923"
        },
        {
            "_id": "143805",
            "name": "ANTICA C.CACIO IL CANESTRATO",
            "ingredients": "INGREDIENTI: LATTE ovino pastorizzatto fermenti lattici selezionaticaglio di vittello sale. Può contenere GLUTINE.",
            "plu": "010147"
        },
        {
            "_id": "143969",
            "name": "KOFLER SPECK AL KG",
            "ingredients": "INGREDIENTI: Carne di suino (origine UE) salespeziedestrosioantiossidante: acrosbato di sodioconservanti: nitrito di sodio nitrito di potassio. Può contenere LATTEGLUTINE.",
            "plu": "010146"
        },
        {
            "_id": "143979",
            "name": "BRESAOLA ZERO PREMIUM 1/2 AL K",
            "ingredients": "INGREDIENTI: Carne di bovino sale aromi naturali conservanti: nitrito di potassio. Può contenere LATTEGLUTINE.",
            "plu": "010148"
        },
        {
            "_id": "145118",
            "name": "M&T PROS.PARMA ADDOBBO 16 MESI",
            "ingredients": "INGREDIENTI: Coscia di suino (Origine:Italia) sale sugna suina (sugnasalepepe). Può contenere LATTEGLUTINE.",
            "plu": "010229"
        },
        {
            "_id": "145119",
            "name": "M&T PROS.PARMA C/OSSO 16 MESI",
            "ingredients": "INGREDIENTI: Coscia di suino (Origine:Italia) sale sugna suina (sugnasalepepe). Può contenere LATTEGLUTINE.",
            "plu": "010230"
        },
        {
            "_id": "145177",
            "name": "PIZZIGHETTONE PROV.MAN.PIC.20K",
            "ingredients": "INGREDIENTI: LATTE vaccinocaglio sale. Conservante:E229 Conservante sulla crosta:E202 E235. Crosta non edibile Può contenere GLUTINE",
            "plu": "010777"
        },
        {
            "_id": "145318",
            "name": "PIZZIGHETTONE PANCETTONE 60KG",
            "ingredients": "INGREDIENTI: LATTE vaccinocaglio sale Conservante:E229 Conservante sulla crosta:E202 E235 Crosta non edibile Può contenere GLUTINE",
            "plu": "010780"
        },
        {
            "_id": "145416",
            "name": "GENGA GRANPANCIOTTO FRAS1/2 KG",
            "ingredients": "INGREDIENTI: Carne di suino (origine Italia) sale pepe destrosio saccarosioaromi antiossidante:E301 conservante: E252E251 Budello non edibile. Può contenere LATTE GLUTINE.",
            "plu": "010234"
        },
        {
            "_id": "145418",
            "name": "GENGA SALAME TRANCIO SV AL KG",
            "ingredients": "INGREDIENTI: Carne di suino (origine Italia) sale pepe destrosio aromi antiossidante: E300 conservante: E252. Budello non edibile. Può contenere LATTEGLUTINE.",
            "plu": "010232"
        },
        {
            "_id": "145419",
            "name": "GENGA SALAME FABRIANO TRANC.KG",
            "ingredients": "INGREDIENTI: Carne di suino (origine Italia) lardo di schiena di suino sale pepe destrosio aromi antiossidante: E300 conservante: E252. Budello non edibile. Può contenere GLUTINELATTE.",
            "plu": "010233"
        },
        {
            "_id": "145571",
            "name": "AGRITECH BARRETTA PROTEICA100G",
            "ingredients": "INGREDIENTI:FARINA (FRUMENTO integrale(425%)FRUMENTO) acqua lenticchie rosse croccanti (170%) SOIA edamame (160%) glutine di FRUMENTO lievitosale lievito madre in polvere (farina di GRANO tenero tipo 0) FRUMENTO maltato; misto erbe (aglio in polvere(SOLFITI) basilico prezzemolo spinaci essiccati). Può contenere LATTE SEMI DI SESAMO SENAPE FRUTTA A GUSCIO.Conservare a -18°C. Non ricongelare. Consumare previa cottura.",
            "plu": "010373"
        },
        {
            "_id": "145572",
            "name": "SIFOR PECORINO FANTASIA AL KG",
            "ingredients": "INGREDIENTI: LATTE di pecora olive farcite 6% (olive peperoni acqua sale E330 E270 SOLFITI) rucola 08% peperoncino 08% capperi 05% (SOLFITI) salecaglio fermenti lattici. Può contenere PISTACCHI NOCI GLUTINE.",
            "plu": "010235"
        },
        {
            "_id": "145573",
            "name": "OCCELLI CASTELMAGNO DOP 1/4 KG",
            "ingredients": "INGREDIENTI: LATTE di vacca sale caglio. Può contenere GLUTINE.",
            "plu": "010237"
        },
        {
            "_id": "145599",
            "name": "SCHETTINO CAPOCOLLO",
            "ingredients": "INGREDIENTI:carne suinosale aromi naturali saccarosiodestrosiospezie Conservante: E250E252. Antiossidante: E300E301 Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "011829"
        },
        {
            "_id": "145790",
            "name": "MICCIO OL.VERDI MAGNUM SIC.KG",
            "ingredients": "INGREDIENTI: Olive acqua sale correttore di acidità: E270 antiossidante: E300. Può contenere LUPINI. Può contenere frammenti di nocciolo.",
            "plu": "010240"
        },
        {
            "_id": "145795",
            "name": "MEDUSA FRITTURA MARINATA KG",
            "ingredients": "INGREDIENTI:ACQUADELLAaceto di vino(SOLFITI) olio di semi di girasole farina di FRUMENTO sale. Può contenere CROSTACEISOIASENAPE.",
            "plu": "010242"
        },
        {
            "_id": "145796",
            "name": "MEDUSA ANGUILLA MARINATA AR KG",
            "ingredients": "INGREDIENTI: ANGUILLAaceto di vino(SOLFITI) acquasale. Può contenere GLUTINE CROSTACEI.",
            "plu": "010241"
        },
        {
            "_id": "145798",
            "name": "MEDUSA ANGUILLA MARINATA MB KG",
            "ingredients": "INGREDIENTI: ANGUILLAaceto di vino(SOLFITI) acquasale. Può contenere GLUTINE CROSTACEI.",
            "plu": "010243"
        },
        {
            "_id": "146095",
            "name": "GRANTERRE P.COT.G.TENERONE KG",
            "ingredients": "INGREDIENTI: Coscia di suino (Origine UE) salezuccheroaromi antiossidante: ascorbato di sodio conservante: nitrito di sodio. Può contenere LATTEGLUTINE.",
            "plu": "010244"
        },
        {
            "_id": "146367",
            "name": "MANTUANELLA CLASSICO 1/8 FORMA",
            "ingredients": "INGREDIENTI: LATTE vaccino parzialmente scremato caglio di vitello sale lisozima da UOVO. Può contenere GLUTINE. nn n nnn nn",
            "plu": "010245"
        },
        {
            "_id": "146428",
            "name": "AIA AEQ PETTO TACCHINO ARR.KG",
            "ingredients": "INGREDIENTI: petto di tacchino acqua albume dUOVO amido di patataesaltatori di sapidità: glutammato monosodicoantiossidanti: ascorbato di sodioconservanti: nitrito di sodio coloranti: caramelloaromi. Può contenere GLUTINELATTE.",
            "plu": "010248"
        },
        {
            "_id": "146497",
            "name": "DELIZIOSA OVOLINE AL KG",
            "ingredients": "INGREDIENTI: LATTE correttore di acidità: acido lattico siero innesto naturale sale caglio. Può contenere GLUTINE.",
            "plu": "010295"
        },
        {
            "_id": "146498",
            "name": "DELIZIOSA NODINO AL KG",
            "ingredients": "INGREDIENTI: LATTE correttore di acidità: acido lattico siero innesto naturale sale caglio. Può contenere GLUTINE.",
            "plu": "010293"
        },
        {
            "_id": "146499",
            "name": "DELIZIOSA OVOLINE AFFUM.AL KG",
            "ingredients": "INGREDIENTI: LATTE correttore di acidità: acido lattico salecaglio. Affumicata naturalmente con trucioli di faggio. Può contenere GLUTINE.",
            "plu": "010294"
        },
        {
            "_id": "146500",
            "name": "DELIZIOSA GIUNCATA RUCOLA KG",
            "ingredients": "INGREDIENTI: LATTE pastorizzato rucola 1% sale caglio fermenti lattici. Può contenere GLUTINE.",
            "plu": "010292"
        },
        {
            "_id": "146501",
            "name": "DELIZIOSA SCAMORZA AFFUM.AL KG",
            "ingredients": "INGREDIENTI:LATTE cagliata di LATTE (LATTE pastorizzato salefermenti lattici caglio) correttore di acidità:acido lattico sale caglio. Può contenere GLUTINE.",
            "plu": "010290"
        },
        {
            "_id": "146609",
            "name": "FUMAGALLI SALAME NAPOLI 900GR",
            "ingredients": "INGREDIENTI: Carne di suino sale aromi vino(SOLFITI)spezie destrosioantios. ascorbato di sodio e acido ascorbico conservanti: nitrato di potassio e nitrito di sodio pepe aglio(SOLFITI). BUDELLO NON EDIBILE.Origine suino: Italia Può contenere GLUTINE LATTE.",
            "plu": "010279"
        },
        {
            "_id": "146720",
            "name": "CAMOSCIO D ORO CR.MAGGIO 18K",
            "ingredients": "INGREDIENTI: LATTE vaccinoPANNAsale gelatina emulsionante (E471) FERMENTI LATTICI caglio Può contenere GLUTINE.",
            "plu": "010924"
        },
        {
            "_id": "147481",
            "name": "GILDO FIORDICAPRA NATURALE KG",
            "ingredients": "INGREDIENTI: LATTE di caprasalecaglio sorbato di potassio. Può contenere GLUTINE.",
            "plu": "010363"
        },
        {
            "_id": "147482",
            "name": "GILDO FIORDICAPRA 4 AROMI KG",
            "ingredients": "INGREDIENTI: LATTE di caprasalecaglio aromi 26%(erba cipollinaoriganopeperoncino pepe) sorbato di potassio. Può contenere GLUTINE.",
            "plu": "010364"
        },
        {
            "_id": "147483",
            "name": "GILDO CAPRINO FRESCO CAPRA KG",
            "ingredients": "INGREDIENTI: LATTE di caprasalecaglio sorbato di potassio. Può contenere GLUTINE.",
            "plu": "010365"
        },
        {
            "_id": "147484",
            "name": "GILDO MONTECAPRA STAGIONATO KG",
            "ingredients": "INGREDIENTI: LATTE di caprasalecaglio Trattato in superficie con: conservanti(E202 E235) coloranti (E150aE160Bb). Può contenere GLUTINE.",
            "plu": "010362"
        },
        {
            "_id": "147485",
            "name": "GILDO CACIOTTA BONTA CAPRA KG",
            "ingredients": "INGREDIENTI: LATTE di caprasalecaglio. Può contenere GLUTINE.",
            "plu": "010366"
        },
        {
            "_id": "147510",
            "name": "M&T PROS.PARMA C/OSSO DOP 24M",
            "ingredients": "INGREDIENTI: Coscia di suinosale. Può contenere GLUTINELATTE.",
            "plu": "010360"
        },
        {
            "_id": "147674",
            "name": "KOMETA PETTO DI TACCHINO AL KG",
            "ingredients": "INGREDIENTI: Petto di tacchino 90%acqua saledestrosiosciroppo di glucosio amido di patate aromi e spezie ascorbato di sodio nitrito di sodio. Può contenere LATTEGLUTINE.",
            "plu": "010369"
        },
        {
            "_id": "147875",
            "name": "PRAZZOLI CAPOCOLLO SV AL KG",
            "ingredients": "INGREDIENTI:Coppa suinasaledestrosiospezie. Conservanti:E 252 E 250 Antiossidanti:E 300 E 301. Origine suino: UE Può contenere LATTEGLUTINE.",
            "plu": "010372"
        },
        {
            "_id": "148090",
            "name": "MORBIDO AL LATTE 100GR",
            "ingredients": "PRODOTTO PRECOTTO SURGELATO INGREDIENTI: Farina di FRUMENTO tipo 0 acqua LATTE scremato in polvere (3%) lievito margarina olio e grassi vegetali (palma girasole)acquaE471 lecitina di girasole acido citricozuccherosalearoma. Può contenere SEMI DI SESAMO SOIA SENAPEARACHIDE. Conservare a -18° C. Non ricongelare. Se cotto ottenuto da prodotto precotto surgelato.",
            "plu": "010374"
        },
        {
            "_id": "148091",
            "name": "CIABATTA ALL ACQUA 270GR",
            "ingredients": "PRODOTTO PRECOTTO SURGELATO INGREDIENTI: Farina di FRUMENTO acqua sale lievito. Può contenere SEMI DI SESAMO SOIA SENAPE FRUTTA A GUSCIO. Conservare a -18° C. Non ricongelare. Se cotto ottenuto da prodotto precotto surgelato.",
            "plu": "010375"
        },
        {
            "_id": "148092",
            "name": "FILONE CRISTALLO 300GR",
            "ingredients": "PRODOTTO PRECOTTO SURGELATO INGREDIENTI: Farina di FRUMENTO tipo 0 acqua massa madre di FRUMENTO olio di oliva sale zucchero lievito. Può contenere SEMI DI SESAMO SOIA SENAPE. Conservare a -18° C. Non ricongelare. Se cotto ottenuto da prodotto precotto surgelato.",
            "plu": "010376"
        },
        {
            "_id": "148196",
            "name": "LE DELIZIE FILETTI DI ALICI KG",
            "ingredients": "INGREDIENTI: ALICI (Engraulis encrasicolus) 80% olio di semi di girasole aceto di vino(SOLFITI) sale prezzemolo E330. Può contenere GLUTINE SOIA UOVA CROSTACEI MOLLUSCHI SEDANO.",
            "plu": "010379"
        },
        {
            "_id": "148198",
            "name": "LE DELIZIE FILETTI SALMONE KG",
            "ingredients": "INGREDIENTI:SALMONE (Salmo salar)(80%)olio di semi di girasole (20%)Aceto di vino(SOLFITI) pepe rosa pepe nero sale E330. Può contenere GLUTINE SOIA UOVO CROSTACEI.",
            "plu": "010380"
        },
        {
            "_id": "148199",
            "name": "LE DELIZIE FIL.SGOMBRO GRIG.KG",
            "ingredients": "INGREDIENTI: SGOMBRO (Scomber scombrus) 80% olio di semi di girasole aceto di vino(SOLFITI) sale prezzemoloE330E211. PUÒ contenere GLUTINE SOIA UOVA CROSTACEI MOLLUSCHI SEDANO.",
            "plu": "010381"
        },
        {
            "_id": "148206",
            "name": "AMATRICE PECORINO MA-TRU KG",
            "ingredients": "INGREDIENTI: LATTE ovino pastorizzato caglio sale fermenti lattici. Trattato in superficie con olio di semi di girasole e aceto di vino(SOLFITI). Può contenere GLUTINE FRUTTA A GUSCIO.",
            "plu": "010377"
        },
        {
            "_id": "148207",
            "name": "AMATRICE PECOR.G/RIS.GROTTA KG",
            "ingredients": "INGREDIENTI:LATTE ovino pastorizzato caglio sale fermenti lattici. Trattato in superficie con olio di semi di di girasole e aceto di vino(SOLFITI). Può contenere GLUTINE FRUTTA A GUSCIO.",
            "plu": "010378"
        },
        {
            "_id": "148227",
            "name": "LE DELIZIE PEPERONI RIP/TON.KG",
            "ingredients": "INGREDIENTI:melanzane (80%) olio di girasole spezie(prezzemolo peperoncino a rondelle capperiaglio (contiene SOLFITI) Può contenere GLUTINE LATTE SOIA UOVO SEDANO.",
            "plu": "010382"
        },
        {
            "_id": "148228",
            "name": "LE DELIZIE CARCIOFI INT.ARR.KG",
            "ingredients": "INGREDIENTI:carciofi (80%) olio di semi di girasole (20%) sale spezie (prezzemolo peperoncino AGLIO (contiene SOLFITI)aceto di vino bianco (contiene SOLFITI) conservante E330. Può contenere GLUTINE SOIA SEDANO LATTE UOVO.",
            "plu": "010383"
        },
        {
            "_id": "148229",
            "name": "LE DELIZIE MELANZANE FIL.KG",
            "ingredients": "INGREDIENTI: melanzane (80%) olio di semi di girasole (20%) peperoncino a pezzi origano aglio (contiene SOLFITI) aceto di vino bianco (contiene SOLFITI)additivi: E270 E300 sale spezie. Può contenere GLUTINESOIA UOVO LATTE.",
            "plu": "010384"
        },
        {
            "_id": "148230",
            "name": "LE DELIZIE POMODORI SECCHI KG",
            "ingredients": "INGREDIENTI:pomodori (75%) olio di semi di girasole (20%) capperi (25%) spezie (2 5%)(prezzemolopeperoncino aglio (contiene SOLFITI)aceto di vino bianco (contiene SOLFITI)additivi E330sale. Può contenere GLUTINE SOIA SEDANO LATTE UOVA.",
            "plu": "010385"
        },
        {
            "_id": "148231",
            "name": "LE DELIZIE MELANZANE ARR.KG",
            "ingredients": "INGREDIENTI:melanzane (80%) olio di girasole (20%) spezie(prezzemolo peperoncino a rondelle capperiaglio (contiene SOLFITI)aceto di vino bianco (contiene SOLFITI) conservante E300. Può contenere GLUTINE LATTE SOIA UOVO SEDANO.",
            "plu": "010386"
        },
        {
            "_id": "148232",
            "name": "LE DELIZIE FUNGHI GRIGLIATI KG",
            "ingredients": "INGREDIENTI: funghi (Plerotus) (80%) olio di girasole (20%) aceto di vino bianco (contiene SOLFITI)additivi E330 sale spezie (prezzemolopeperoncinoaglio (contiene SOLFITI).Può contenere GLUTINESEDANO SOIA LATTEUOVO.",
            "plu": "010387"
        },
        {
            "_id": "148483",
            "name": "BLU BUFALA AL KG",
            "ingredients": "INGREDIENTI: LATTE di bufala intero pastorizzato sale caglio. Può contenere GLUTINE.",
            "plu": "010396"
        },
        {
            "_id": "148484",
            "name": "CASATICA DI BUFALA AL KG",
            "ingredients": "INGREDIENTI: LATTE di bufala intero pastorizzato sale caglio. Può contenere GLUTINE.",
            "plu": "010391"
        },
        {
            "_id": "148485",
            "name": "CAMEMBERT DI BUFALA AL KG",
            "ingredients": "INGREDIENTI: LATTE di bufala pastorizzato sale caglio. Può contenere GLUTINE.",
            "plu": "010394"
        },
        {
            "_id": "148486",
            "name": "ROQUEFORT AOP AL KG",
            "ingredients": "INGREDIENTI: LATTE di pecora crudo sale caglio fermenti lattici Penicillinum Roqueforti. Può contenere GLUTINE.",
            "plu": "010389"
        },
        {
            "_id": "148487",
            "name": "RACLETTE FRANCESE AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccino pastorizzato sale fermenti lattici cloruro di calcio. Crosta non edibile trattata con E235. Può contenere GLUTINE.",
            "plu": "010395"
        },
        {
            "_id": "148488",
            "name": "TOMA PIEMONTESE DOP AL KG",
            "ingredients": "INGREDIENTI: LATTE crudo vaccino sale caglio. Può contenere GLUTINE.",
            "plu": "010392"
        },
        {
            "_id": "148489",
            "name": "ROBIOLA DI ROCCAVER.DOP AL KG",
            "ingredients": "INGREDIENTI: LATTE di capra crudo sale caglio. Può contenere GLUTINE.",
            "plu": "010393"
        },
        {
            "_id": "148490",
            "name": "ERBORIN SANT AMBROEUS AL KG",
            "ingredients": "INGREDIENTI: LATTE di capra pastorizzato sale caglio Penicillinum Roqueforti. Crosta aromatizzata con scorza di arancia essiccata cannella chiodi di garofano cardamomo zenzero pepe rosa. Può contenere GLUTINE.",
            "plu": "010390"
        },
        {
            "_id": "148495",
            "name": "FIGLI FIORI CAPRINO PIST.AL KG",
            "ingredients": "INGREDIENTI: LATTE caprino pastorizzatosale granella di PISTACCHIO in crosta (1%) caglio. Può contenere: GLUTINE altra FRUTTA A GUSCIO.",
            "plu": "010445"
        },
        {
            "_id": "148508",
            "name": "SANTINI FESA TACC.ERBE AL KG",
            "ingredients": "INGREDIENTI: Fesa di tacchino salevino(SOLFITI) E451 aromi naturali erbe aromatiche E301 E250. Può contenere LATTE GLUTINE.",
            "plu": "010428"
        },
        {
            "_id": "148550",
            "name": "MARTINI PROS.CRUD.NAZ.DISOS.KG",
            "ingredients": "INGREDIENTI: Coscia di suino (Origine Italia) sale. Può contenere LATTE GLUTINE.",
            "plu": "010427"
        },
        {
            "_id": "148600",
            "name": "BRIE DE MEAUX AOP KG",
            "ingredients": "INGREDIENTI: LATTE crudo sale fermenti lattici caglio Penicillinum candidum. Può contenere GLUTINE.",
            "plu": "010424"
        },
        {
            "_id": "148601",
            "name": "RASCHERA DOP AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccino intero crudo sale caglio. Può contenere GLUTINE.",
            "plu": "010388"
        },
        {
            "_id": "148602",
            "name": "RAGUSANO DOP AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccino crudo sale caglio di agnello o capretto. Può contenere GLUTINE.",
            "plu": "010423"
        },
        {
            "_id": "148829",
            "name": "LE DELIZIE CARCIOFI SPIC.AL KG",
            "ingredients": "INGREDIENTI: Carciofi a spicchi (80%) semi di girasole sale aceto di vino(SOLFITI) E330. Può contenere GLUTINESOIALATTEUOVO.",
            "plu": "010431"
        },
        {
            "_id": "148832",
            "name": "SANTINI ROAST BEEF INGLESE KG",
            "ingredients": "INGREDIENTI: Coscia di bovino sale pepe rosmarino. Può contenere GLUTINELATTE.",
            "plu": "010432"
        },
        {
            "_id": "148863",
            "name": "LA VALLE PANCETTA UNICA STA.KG",
            "ingredients": "INGREDIENTI:Carne di suino (origine Italia) sale destrosio aromi spezie E250E252 E301. Può contenere LATTEGLUTINE.",
            "plu": "010425"
        },
        {
            "_id": "148864",
            "name": "LA VALLE SALAME VARZI DOP KG",
            "ingredients": "INGREDIENTI: Carne di suino (origine Italia) sale pepe infuso di aglio in vino rosso (contiene SOLFITI) nitrato di potassio nitrito di sodio. Può contenere GLUTINELATTE.",
            "plu": "010426"
        },
        {
            "_id": "148865",
            "name": "BILLO BRESAOLA TIROLESE AL KG",
            "ingredients": "INGREDIENTI: Carne di bovino sale sale spezie aromi di affumicatura aromi E326E331 E301E250. Può contenere LATTEGLUTINE.",
            "plu": "010433"
        },
        {
            "_id": "149010",
            "name": "RASPINI FESA DI MANZO COTTA KG",
            "ingredients": "INGREDIENTI: Carne di bovino sale saccarosio destrosio aromi aroma di affumicatura antiossidante: ascorbato di sodio conservante: nitrito di sodio. Può contenere LATTE GLUTINE.",
            "plu": "010434"
        },
        {
            "_id": "149100",
            "name": "CACIO DEI LATTARI AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccino crudo salecaglio misto (50% capretto 50% vitello). Può contenere GLUTINE.",
            "plu": "010440"
        },
        {
            "_id": "149101",
            "name": "PECORINO BAGNOLESE CLASSICO KG",
            "ingredients": "INGREDIENTI: LATTE ovino caglio sale. Può contenere GLUTINE.",
            "plu": "010439"
        },
        {
            "_id": "149102",
            "name": "PECORINO BAGNOLESE FIENO KG",
            "ingredients": "INGREDIENTI: LATTE ovino caglio sale. Può contenere GLUTINE.",
            "plu": "010437"
        },
        {
            "_id": "149103",
            "name": "PECORINO BAGNOLESE VINACCIA KG",
            "ingredients": "INGREDIENTI: LATTE ovino caglio sale. Può contenere GLUTINE.",
            "plu": "010438"
        },
        {
            "_id": "149114",
            "name": "PINI BRESAOLA P.ANCA IGP 1/2SV",
            "ingredients": "INGREDIENTI:Carne di bovino saledestrosio aromi naturali. Conservanti:nitrito di sodio (E250) nitrato di sodio (E251). Può contenere: GLUTINE LATTE.",
            "plu": "010444"
        },
        {
            "_id": "149132",
            "name": "CIABATTINA MIX CER.25GR AL KG",
            "ingredients": "PRODOTTO PRONTOFORNO CONGELATO INGREDIENTI: Farina di GRANO tenero di tipo 0 semola rimacinata di GRANO duroacqua olio evo sale lievito semi di girasole 22% fiocchi dORZO 22%SEMI DI SESAMO 22% semi di miglio 22% semi di lino bruno 22%. Può contenere SENAPESOIA. Conservare a -18°C. Non ricongelare. Consumare previa cottura. Se cotto ottenuto da prodotto prontoforno congelato.",
            "plu": "010451"
        },
        {
            "_id": "149133",
            "name": "FOCACCIA POMODORINI 250GR",
            "ingredients": "PRODOTTO PRONTOFORNO CONGELATO INGREDIENTI: Semola rimacinata di GRANO duro acqua olio evo sale lievito pomodoro ciliegino 12% olive origano. Può contere SOIA SENAPE SEMI DI SESAMO. Può contenere noccioli o frammenti di essi. Conservare a -18°C. Non ricongelare. Consumare previa cottura. Se cotto ottenuto da prodotto prontoforno congelato.",
            "plu": "010450"
        },
        {
            "_id": "149135",
            "name": "CIABATTA CEREALI 100GR",
            "ingredients": "PRODOTTO PRONTOFORNO CONGELATO INGREDIENTI: Farina di GRANO tenero di tipo 0 farina di SEGALEfarina di FARROacqua farina integrale di GRANO crusca di GRANOfarina di mais granella di SOIA sale farina di ORZO semi di girasole semi di linoSEMI DI SESAMO semi di papavero destrosiozucchero semi di zucca. Può contenere SENAPE. Conservare a -18°C. Non ricongelare. Consumare previa cottura. Se cotto ottenuto da prodotto prontoforno congelato.",
            "plu": "010449"
        },
        {
            "_id": "149140",
            "name": "RENZINI BRACE CULAT.ERBE1/2 KG",
            "ingredients": "INGREDIENTI:coscia di suino (origine: UE) sale aromi saccarosio destrosiospezie ed erbe aromaticheantiossidante(E301)conservante (E250). Può contenere:GLUTINE LATTE FRUTTA A GUSCIOSOLFITI",
            "plu": "010442"
        },
        {
            "_id": "149158",
            "name": "AMATRICE PEC.ROMANO DOP SV KG",
            "ingredients": "INGREDIENTI:LATTE di pecora sale caglio di agnello fermenti latticiTrattato in superficie con agenti di rivestimento: conservanti E235 E202 e colorante:E172. Può contenere: FRUTTA A GUSCIO GLUTINE.",
            "plu": "010436"
        },
        {
            "_id": "149263",
            "name": "GALBANI SPIANATA PICCANTE KG",
            "ingredients": "INGREDIENTI: Carne di puro suino (origine UE) sale destrosio spezie aromi aromatizzanti di affumicatura antiossidante: E301 (ascorbato di sodio) conservanti: E250 (nitrito di sodio) E252 (nitrato di potassio). Può contenere: LATTE UOVA GLUTINE",
            "plu": "010452"
        },
        {
            "_id": "149354",
            "name": "RISOTTO ALLA PESCATORA AL KG",
            "ingredients": "INGREDIENTI:PESCE surg.51%COZZEVONGOLECALAMARI TOTANOSURIMI (polpa di PESCE stab. :Sorbitolo E420zuccheroacquafecola di patateamido di mais amido di tapiocaolio di colzasalealbume dUOVO aroma di GRANCHIOcolor.:E160cE120)CROSTACEI riso parboiled 37%pomodoriniolio evo prep. per brodo (sale olio di palma idrogenato esaltatore di sapidità:glutammato monos.zucchero estratto di lievitoverdure disid. (cipolla 11% SEDANOpomodorocarote)color.: caramello semplice curcuma)aglio(SOLFITI).Può contenere GLUTINE. Consumare entro 48h dalla data di preincarto.",
            "plu": "010468"
        },
        {
            "_id": "149355",
            "name": "LASAGNA NAPOLETANA AL KG",
            "ingredients": "INGREDIENTI:Sugo di pomodoro 56%pomodori pelati olio evovino bianco (contiene SOLFITI) cipolla salePolpettine di carne bovina 13% macinato di carne bovina 55% olio di semi di girasole PANE di FRUMENTO UOVA pastorizzate GRANA PADANO (LATTEsalecaglio lisozima da UOVO) salePROVOLA affumicata 12% (LATTE vaccinocaglio sale)RICOTTA 9 5% (LATTE pastorizzato sale) pasta sfoglia di semola di GRANO duro75% GRANA PADANO (LATTEsalecagliolisozima da UOVO) Può contenere SEDANO. Consumare entro 48h dalla data di preincarto.",
            "plu": "010469"
        },
        {
            "_id": "149356",
            "name": "INSALATA DI RISO AL KG",
            "ingredients": "INGREDIENTI: Riso parboiled prosciutto cotto (coscia di suino sale destrosio aromi antiossidante: ascorbato di sodio conservante: nitrito di sodio) emmenthal (LATTE) wurstel di suino verdure miste in semi di girasole (peperoni carote cetrioli rape finocchi olive funghi coltivati olio di semi di girasole aceto di vino (SOLFITI) sale correttore di acidità: acido citrico antiossidante: acido ascorbico) tonno sottolio (PESCE) olio EVO sale.Può contenere GLUTINE SOIA. Consumare entro 24h dalla data di preincarto.",
            "plu": "010475"
        },
        {
            "_id": "149357",
            "name": "INSALATA DI RISO VENERE AL KG",
            "ingredients": "INGREDIENTI: riso venere 28% fagiolini carote sottaceto GAMBERETTI surgelati (CROSTACEI MOLLUSCHI PESCE) olio evo MANDORLE sale. Può contenere GLUTINESOIA SOLFITI. Consumare entro 24h dalla data di preincarto.",
            "plu": "010474"
        },
        {
            "_id": "149358",
            "name": "INSALATA DI MARE AL KG",
            "ingredients": "INGREDIENTI: SEPPIE surgelate POLPO surgelato GAMBERETTI surgelati julienne di carote olive verdi SEDANO olio evo limone sale. Può contenere GLUTINE PESCE SOLFITI. Consumare entro 48h dalla data di preincarto.",
            "plu": "010477"
        },
        {
            "_id": "149359",
            "name": "PARMIGIANA MELANZANE ROSSA KG",
            "ingredients": "INGREDIENTI: Melanzane 46% pomodori pelati 365% PROVOLA affumicata 12% (LATTE vaccino cagliosale) olio di semi di girasole farina di FRUMENTO GRANA PADANO 25% (LATTEsale cagliolisozima da UOVO) olio evovino rosso(SOLFITI)cipolla sale. Può contenere SEDANO. Consumare entro 48h dalla data di preincarto.",
            "plu": "010473"
        },
        {
            "_id": "149360",
            "name": "POLPETTE AL RAGU AL KG",
            "ingredients": "INGREDIENTI: Pomodori pelati carne bovina PANE raffermoolio di semi di girasole UOVA GRANA PADANO (LATTE vaccino sale caglio lisozima da UOVO) pepe e sale. Può contenere SOLFITISEDANO. Consumare entro 48h dalla data di preincarto.",
            "plu": "010478"
        },
        {
            "_id": "149361",
            "name": "PEPERONI GRATIN.OLIVE/CAPP. KG",
            "ingredients": "INGREDIENTI: peperoni 82% PANGRATTATO (farina di GRANO tenero tipo 0 acqua destrosio 5% lievito sale estratto di malto dORZO) olive nere olio evo olio di semi di girasole capperi sale. Può contenere SEDANO SOIA. Consumare entro 48h dalla data di preincarto.",
            "plu": "010472"
        },
        {
            "_id": "149362",
            "name": "PATATE AL FORNO AL KG",
            "ingredients": "INGREDIENTI: Patate 954 % olio di semi di girasole rosmarino sale. Può contenere SOLFITISEDANO. Consumare entro 48h dalla data di preincarto.",
            "plu": "010471"
        },
        {
            "_id": "149363",
            "name": "ZUCCHINE ALLA SCAPECE AL KG",
            "ingredients": "INGREDIENTI:Zucchine 82%olio di semi di girasole aceto di vino (contiene SOLFITI) 17% sale. Può contenere SEDANOGLUTINE. Consumare entro 48h dalla data di preincarto.",
            "plu": "010470"
        },
        {
            "_id": "149364",
            "name": "MELANZANE A FUNGHETTO AL KG",
            "ingredients": "INGREDIENTI: melanzane 90% olio di semi di girasole pomodorini olio evo aglio(SOLFITI). Può contenere GLUTINE. Consumare entro 48h dalla data di preincarto.",
            "plu": "010476"
        },
        {
            "_id": "149391",
            "name": "FONTAL ALPINELLA 1/4 SV AL KG",
            "ingredients": "INGREDIENTI: LATTE pastorizzato sale fermenti lattici caglio microbico. Trattato in crosta con E235E160b IIE172. Può contenere GLUTINE.",
            "plu": "010453"
        },
        {
            "_id": "149532",
            "name": "PRINCIPE PROSC.PRIMO PRAGA KG",
            "ingredients": "INGREDIENTI:Coscia suina 75% (origine UE)acqua sale fecola di patate destrosio amidi aromi zucchero antiossidanti E301 stabilizzanti E407 Conservanti:E250 Può contenere: GLUTINELATTE",
            "plu": "010885"
        },
        {
            "_id": "149914",
            "name": "GALBANI DOLCELATTE DOP AL KG.",
            "ingredients": "INGREDIENTI: LATTE vaccinosale caglio Può contenere GLUTINE.",
            "plu": "010925"
        },
        {
            "_id": "149923",
            "name": "KING S PROSC.PARMA C/OS 16M KG",
            "ingredients": "INGREDIENTI:coscia di suinosale marino. Può contenere GLUTINELATTE.",
            "plu": "010890"
        },
        {
            "_id": "150013",
            "name": "DE LUCA SALAME MILANO AL KG",
            "ingredients": "INGREDIENTI:Carne di suino sale saccarosio spezie aromi naturali . Antiossidante: E301; conservanti: E250E252.Origine carne suina: UE Può contenere: LATTE GLUTINE.",
            "plu": "010893"
        },
        {
            "_id": "150014",
            "name": "DE LUCA CAPOCOLLO DOLCE AL KG",
            "ingredients": "INGREDIENTI:Carne di suino sale aromi naturali spezie destrosio fruttosioesaltatore di sapidità: E621; antiossidante: E301; conservanti: E250E252.Origine carne suina: UE Può contenere: LATTE GLUTINE.",
            "plu": "010892"
        },
        {
            "_id": "150015",
            "name": "DE LUCA CAPOCOLLO PICC.AL KG",
            "ingredients": "INGREDIENTI:Carne di suino sale aromi naturali spezie destrosio fruttosioesaltatore di sapidità: E621; antiossidante: E301; conservanti: E250E252.Origine carne suina:UE Può contenere: LATTE GLUTINE",
            "plu": "010891"
        },
        {
            "_id": "150016",
            "name": "DE LUCA SPIANATA DOLCE AL KG",
            "ingredients": "INGREDIENTI: Carne di suino sale destrosio saccarosio aromi spezieantiossidante: E301 conservanti: E250E252.Origine carne suina: UE Può contenere: LATTEGLUTINE",
            "plu": "010895"
        },
        {
            "_id": "150017",
            "name": "DE LUCA SPIANATA PICCANTE KG",
            "ingredients": "INGREDIENTI: Carne di suino sale destrosio saccarosio aromispezieantiossidante: E301 conservanti:E250E252.Budello non edibile. Origine carne suina: UE Può contenere: LATTE GLUTINE. Codice Descrizione/Ingredienti Plu bilancia",
            "plu": "010894"
        },
        {
            "_id": "150018",
            "name": "DE LUCA SALAME NAPOLI SV AL KG",
            "ingredients": "INGREDIENTI: Carne di suino sale destrosio saccarosio speziearomi naturali aroma di affumicaturaantiossidante: E301; conservanti: E250E252.Budello non edibile. Origine carne suina:UE Può contenere: LATTE GLUTINE.",
            "plu": "010896"
        },
        {
            "_id": "150027",
            "name": "GALBANI GRANGUSTO FORMA AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccino crudo sale coagulante microbico. Può contenere GLUTINE.",
            "plu": "010898"
        },
        {
            "_id": "150096",
            "name": "GALBANI SAL.NAPOLI 400GR AL KG",
            "ingredients": "INGREDIENTI:Carne di suino(origine UE) sale destrosioaromi spezie conservanti:nitrato di potassionitrito di sodio Budello non commestibile. Può contenere: LATTE GLUTINE.",
            "plu": "010899"
        },
        {
            "_id": "150255",
            "name": "AGRITECH FOCAC.STRACCHINO 200G",
            "ingredients": "PRODOTTO PRONTOFORNO CONGELATO INGREDIENTI: FORMAGGIO STRACCHINO (LATTE intero sale batteri lattici caglio) FARINA (GRANO tenero tipo 0 ORZO maltato FRUMENTO maltato) acqua olio di oliva olio evo sale lievito malto dORZO fiocchi di patate. Può contenere SOIA SENAPE SEMI DI SESAMO. Prodotto congelato Conservare a -18°C. Non ricongelare e consumare previa cottura. Se cotto ottenuto da prodotto prontoforno congelato.",
            "plu": "010897"
        },
        {
            "_id": "150256",
            "name": "AGRITECH BARRETTA CEREALI 120G",
            "ingredients": "PRODOTTO PRONTOFORNO CONGELATO INGREDIENTI: FARINA (GRANO ORZO SEGALE AVENA FRUMENTO) acquasemi di girasolesemi di lino bruno SEMI DI SESAMO semi di zucca sale fiocchi di FRUMENTOsemi di lino giallilievitofiocchi dORZO amido di mais GLUTINE DI FRUMENTO. Può contenere LATTE SOIA SENAPE FRUTTA A GUSCIO. Conservare a -18°C. Non ricongelare e consumare previa cottura. Se cotto ottenuto da prodotto prontoforno congelato.",
            "plu": "010900"
        },
        {
            "_id": "150401",
            "name": "KING S PROSC.PARMA DIS.16M KG",
            "ingredients": "INGREDIENTI: coscia di suino sale marino. Può contenere LATTE GLUTINE.",
            "plu": "010903"
        },
        {
            "_id": "150404",
            "name": "KOMETA PORCHETTA ROSETTA AL KG",
            "ingredients": "INGREDIENTI: Lonza di suino con cotenna e pancetta (origine UE)acquasaledestrosio saccarosioamido di patatacarrageninaaromispeziecitrato di sodio carbonato di sodio ascorbato di sodionitrito di sodio. Può contenere LATTEGLUTINE.",
            "plu": "010901"
        },
        {
            "_id": "150405",
            "name": "RULIANO PR.PARMA DOP C/OS.20M",
            "ingredients": "INGREDIENTI: coscia di suino sale marino. Può contenere LATTE GLUTINE.",
            "plu": "010902"
        },
        {
            "_id": "150450",
            "name": "SAN ROCCO ASIAGO DOP C/NERA KG",
            "ingredients": "INGREDIENTI:LATTE fermenti latticicaglio sale. Può contenere GLUTINE.",
            "plu": "010956"
        },
        {
            "_id": "150615",
            "name": "NEGRONI SALAME MILANO 4KG",
            "ingredients": "INGREDIENTI:carne di suinosale destrosio saccarosio spezieLATTE in polvere piante aromatiche. Conservante: E250E252 Antiossidante: E300 Può contenere: GLUTINE Origine suino: UE",
            "plu": "014650"
        },
        {
            "_id": "150934",
            "name": "CACIOCAVALLO PODOLICO GIOV.KG",
            "ingredients": "INGREDIENTI: LATTE di vacca crudo caglio sale. Può contenere GLUTINE.",
            "plu": "011011"
        },
        {
            "_id": "150935",
            "name": "CASTAGNA FONTINA DOP 1/4 AL KG",
            "ingredients": "INGREDIENTI: LATTE crudo sale caglio. Crosta non edibile. Può contenere GLUTINE.",
            "plu": "011012"
        },
        {
            "_id": "150938",
            "name": "SANTINI SALSIC.MURGIANA SV KG",
            "ingredients": "INGREDIENTI: Carne di suino (Origine Italia) sale vino (contiene SOLFITI)destrosio finocchiettosaccarosio paprika antiossidante: ascorbato di sodio (E301) conservanti: nitrato di potassio (E252) nitrito di sodio (E250). Può contenere LATTE GLUTINE.",
            "plu": "011015"
        },
        {
            "_id": "150942",
            "name": "OCCELLI TOMA MONTE REGALE 1/4",
            "ingredients": "INGREDIENTI:LATTE di vacca 975% concentrato di pomodoro 014% acqua sale (marino) Caglio di vitello Conservante E235.Può contenere GLUTINE.",
            "plu": "011013"
        },
        {
            "_id": "150943",
            "name": "OCCELLI BIANCO LANGA TARTUF1/4",
            "ingredients": "INGREDIENTI:LATTE di vacca 72% LATTE di capra 20% PANNA 4% tartufo destate 3% (Tuber Aestivum Vit.) sale marino estratto per brodo (proteine del LATTE idrolizzate sale) caglio vegetale aroma. Può contenere GLUTINE.",
            "plu": "011014"
        },
        {
            "_id": "150990",
            "name": "ANSUINI PR.NORCIA IGP DIS.KG",
            "ingredients": "INGREDIENTI: coscia di suino sale pepe aglio (contiene SOLFITI). Può contenere LATTE GLUTINE.",
            "plu": "011017"
        },
        {
            "_id": "150991",
            "name": "ANSUINI LONZINO STAG1/2 SV KG",
            "ingredients": "INGREDIENTI: carne di suino sale pepe aglio (contiene SOLFITI) Conservanti: E250 E252 Antiossidante: E301. Può contenere LATTEGLUTINE.",
            "plu": "011016"
        },
        {
            "_id": "151015",
            "name": "ELI PROS.S.DANIELE ADDOBBO 18M",
            "ingredients": "INGREDIENTI: coscia di suino sale. Può contenere LATTE GLUTINE.",
            "plu": "011005"
        },
        {
            "_id": "151079",
            "name": "SARDAFORMAGGI PECOR.NURAGHE KG",
            "ingredients": "INGREDIENTI:LATTE di pecora crudosalecaglio. Conservanti sulla crosta: Olio di oliva e fumo naturale. Crosta non edibile. Può contenere GLUTINE.",
            "plu": "011006"
        },
        {
            "_id": "151083",
            "name": "GALBANI BELPAESE DI PECORA KG",
            "ingredients": "INGREDIENTI: LATTE ovino pastorizzato sale caglio fermenti lattici. Trattato in crosta con conservante E235.Crosta non edibile Può contenere GLUTINE.",
            "plu": "011018"
        },
        {
            "_id": "151115",
            "name": "MARTINI PR.CRUD.NAZ.ADDOBBO KG",
            "ingredients": "INGREDIENTI:Coscia di suino (origine Italia) sale. Può contenere LATTE GLUTINE.",
            "plu": "011020"
        },
        {
            "_id": "151332",
            "name": "PEDRANZINI FIOCCO PROSC.CR.KG",
            "ingredients": "INGREDIENTI: Carne di suino sale pepe aromi naturali destrosio saccarosio Conservanti:Nitrito di Sodio (E250) Nitrato di Sodio (E251). Può contenere LATTEGLUTINE.",
            "plu": "011028"
        },
        {
            "_id": "151333",
            "name": "PEDRANZINI PR.CRUD.STAG.DIS.KG",
            "ingredients": "INGREDIENTI: Carne di suino sale. Può contenere LATTEGLUTINE.",
            "plu": "011027"
        },
        {
            "_id": "151340",
            "name": "TÊTE DE MOINE AOC AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccino crudo sale caglio fermenti. Può contenere LATTEGLUTINE.",
            "plu": "011026"
        },
        {
            "_id": "151600",
            "name": "SEGATA GUANCIALE SV 400G AL K",
            "ingredients": "INGREDIENTI: Carne di suino (origine UE) sale destrosio speziearomi naturali; antiossidante: E 301;conservanti: E 250E 252. Può contenere: LATTEGLUTINE.",
            "plu": "011029"
        },
        {
            "_id": "151603",
            "name": "AURICCHIO PROV.CLAS.CAMPANA50K",
            "ingredients": "INGREDIENTI: LATTE (9798%)sale caglio conservanti:E239 E202 E235(in superficie). Crosta non edibile. Può contenere: GLUTINE.",
            "plu": "011030"
        },
        {
            "_id": "151927",
            "name": "SANTINI MORTAD.C/PISTAC.100KG",
            "ingredients": "INGREDIENTI:Carne di suino grasso di suino sale saccarosioPISTACCHIO(06%) aromi naturali spezie vino (SOLFITI)antiossidante: ascorbato di sodio (E301)erbe aromatiche conservante: nitrito di sodio (E250). Budello non edibile.Origine suino: Italia. Può contenere: LATTEGLUTINE.",
            "plu": "011032"
        },
        {
            "_id": "152019",
            "name": "GALBANI GALBANET.MENO SALE KG",
            "ingredients": "INGREDIENTI: Carne di suino(origine UE)sale cloruro di potassio aromi destrosio spezie piante aromatiche conservanti:nitrato di potassio nitrito di sodio. Budello non commestibile. Può contenere: LATTEGLUTINE.",
            "plu": "011035"
        },
        {
            "_id": "152047",
            "name": "GRANATA PEPERONI ROS.ACETO 4KG",
            "ingredients": "INGREDIENTI: Peperoni aceto di vino(SOLFITI) acqua sale. Può contenere:GLUTINELATTE PESCESEDANOLUPINI",
            "plu": "011034"
        },
        {
            "_id": "152319",
            "name": "IL CARRO GR.PECOR.PUG.1/2SV KG",
            "ingredients": "INGREDIENTI: LATTE ovinosalecaglio fermenti lattici.Crosta non edibile. Può contenere: GLUTINE",
            "plu": "011040"
        },
        {
            "_id": "152320",
            "name": "IL CARRO CAPRA DI PUGLIA AL KG",
            "ingredients": "INGREDIENTI: LATTE di capra sale caglio fermenti lattici.Crosta non edibile. Può contenere: GLUTINE",
            "plu": "011041"
        },
        {
            "_id": "152382",
            "name": "VOGLIAZZI INS.CAPRICCIOSA32KG",
            "ingredients": "INGREDIENTI:MAIONESE 50% oli vegetali (colza soia maissemi di girasole) acquaUOVO pastorizzato amido modificato di mais aceto di vino(SOLFITI) sale zuccheroSENAPE (acqua semi di SENAPE aceto di vino (SOLFITI)sale zucchero miscela di spezie acidificante: E334) succo di limone correttore di acidità: E270; conservante: E200 ortaggi misti sottaceto in proporzione variabile 50%(SEDANO rapa carote peperoni aceto di vino salezucchero correttori dacidità:E330 E300;antiossidante: ANIDRIDE SOLFOROSA). Può contenere:CROSTACEI PESCE LATTE FRUTTA A GUSCIO (NOCI ANACARDI) GLUTINE.",
            "plu": "011043"
        },
        {
            "_id": "152459",
            "name": "PARMIG.REGG.DOP 30MESI SV 300G",
            "ingredients": "INGREDIENTI: LATTE sale caglio. Può contenere GLUTINE.",
            "plu": "011053"
        },
        {
            "_id": "152592",
            "name": "EDAMER AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccino sale fermenti lattici vivi caglio microbico Può contenere: GLUTINE",
            "plu": "011062"
        },
        {
            "_id": "152618",
            "name": "M&T PROS.PARMA DOP C/OSSO 18M",
            "ingredients": "INGREDIENTI: coscia di suino italiano sale sugna.Può contenere GLUTINE LATTE.",
            "plu": "011052"
        },
        {
            "_id": "152774",
            "name": "VEROPANE TARTARUGHE AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: semola rimacinata di GRANO duro farina di GRANO tenero 00 acqua lievito naturale agente di trattamento delle farine: E300. Può contenere UOVA SOIA SENAPE semi di lino e di girasole crusca di GRANO tenero. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto prontoforno congelato.",
            "plu": "016105"
        },
        {
            "_id": "152775",
            "name": "VEROPANE ROSETTE AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: semola rimacinata di GRANO duro farina di GRANO tenero 00 acqua lievito madre sale farina di GRANO maltato lievito naturale olio evo agente di trattamento delle farine E300. Può contenere UOVA semi di lino e di girasole crusca di GRANO teneroSOIASENAPE. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto prontoforno congelato.",
            "plu": "016104"
        },
        {
            "_id": "152776",
            "name": "VEROPANE FILONCINI INTEGR.A KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: farina integrale di GRANO tenero farina di GRANO tenero 00 acqua lievito madre sale farina di GRANO maltato lievito naturale agente di trattamento delle farine E300. Può contenere UOVA semi di lino e di girasole. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto prontoforno congelato.",
            "plu": "016103"
        },
        {
            "_id": "152778",
            "name": "VEROPANE FILONCINI AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: semola rimacinata di GRANO duro farina di GRANO tenero 00 acqua lievito madre sale farina di GRANO maltato lievito naturale agente di trattamento delle farine E300. Può contenere UOVA semi di lino e di girasole crusca di GRANO teneroSOIASENAPE. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto prontoforno congelato.",
            "plu": "016102"
        },
        {
            "_id": "152779",
            "name": "VEROPANE CIABATTINE AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: semola rimacinata di GRANO duro acqua farina di GRANO tenero 00 acqua lievito madre sale farina di GRANO maltato lievito naturale agente di trattamento delle farine E300. Può contenere UOVA semi di lino e di girasole crusca di GRANO teneroSOIASENAPE. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto prontoforno congelato.",
            "plu": "016101"
        },
        {
            "_id": "152795",
            "name": "VALTELLINA CASERA DOP1/4 SV KG",
            "ingredients": "INGREDIENTI:LATTEsalecaglio. Può contenere: GLUTINE",
            "plu": "011054"
        },
        {
            "_id": "152796",
            "name": "BITTO DOP D ANNATA 1/4 SV KG",
            "ingredients": "INGREDIENTI: LATTE crudosalecaglio. Pyò contenere: GLUTINE",
            "plu": "011055"
        },
        {
            "_id": "152882",
            "name": "FULVI CACIOT.DOLCECAS.S.LAT.KG",
            "ingredients": "INGREDIENTI:LATTE di vacca con aggiunta di LATTE di pecora pastorizzati caglio sale fermenti lattici. Crosta non edibile. Può contenere: GLUTINE.",
            "plu": "011058"
        },
        {
            "_id": "152883",
            "name": "FULVI CACIOTTA DOLCECASSIA KG",
            "ingredients": "INGREDIENTI:LATTE di vacca con aggiunta di LATTE di pecora pastorizzati caglio sale fermenti lattici. Crosta non edibile. Può contenere: GLUTINE.",
            "plu": "011059"
        },
        {
            "_id": "152885",
            "name": "FORMAGGIO CACIO RE AL KG",
            "ingredients": "INGREDIENTI:LATTE di pecora pastorizzato fermenti latticicagliosale. Crosta non edibile. Può contenere: GLUTINE.",
            "plu": "011060"
        },
        {
            "_id": "152931",
            "name": "FRANCIA STRACCIATELLA IMP KG",
            "ingredients": "INGREDIENTI: LATTE PANNA sale acido lattico caglio. Può contenere GLUTINE.",
            "plu": "011129"
        },
        {
            "_id": "152932",
            "name": "FRANCIA BURRATA FOGLIA KG",
            "ingredients": "INGREDIENTI: LATTE PANNA sale acido lattico caglio. Può contenere GLUTINE.",
            "plu": "011130"
        },
        {
            "_id": "152987",
            "name": "GATEAU DI PATATE AL KG",
            "ingredients": "INGREDIENTI:Patate 62%fiordilatte (LATTE vaccino cagliosale)prosciutto cotto (Carne di suino 67% acquaamidisalearomi stabilizzante (E407a) zuccherodestrosio antiossidante (E316) conservante (E250))UOVAgrana padano (LATTE salecagliolisozima da UOVO)olio extravergine dolivapangrattato (farina di GRANO tenero tipo 0acqua destrosio 5%lievito saleestratto di malto dorzo)burro (crema di siero di LATTE crema di LATTE fermenti lattici)pepe sale. Può contenere SOIA SEDANO. Consumare entro 48h dalla data di preincarto.",
            "plu": "011064"
        },
        {
            "_id": "152988",
            "name": "SALSICCIA E FRIARIELLI AL KG",
            "ingredients": "INGREDIENTI:Friarielli 55%salsiccia di carne suina 457%olio extravergine doliva peperoncino sale. Può contenere tracce di SOLFITI. Consumare entro 48h dalla data di preincarto.",
            "plu": "011065"
        },
        {
            "_id": "152989",
            "name": "FRIARIELLI AL KG",
            "ingredients": "INGREDIENTI: Friarielli olio extravergine doliva aglio sale peperoncino.Può contenere SOLFITI. Consumare entro 48h dalla data di preincarto.",
            "plu": "011063"
        },
        {
            "_id": "153124",
            "name": "DE LUCA PR.CRUDO FAETO DISS.KG",
            "ingredients": "INGREDIENTI:Carne di suino (origine Italia) sale. Può contenere: LATTEGLUTINE.",
            "plu": "011131"
        },
        {
            "_id": "153544",
            "name": "AURICCHIO PROV.CLAS CAMP.16KG",
            "ingredients": "INGREDIENTI: LATTE sale caglio conservanti: E202E235. Può contenere GLUTINE.",
            "plu": "016210"
        },
        {
            "_id": "154029",
            "name": "BERETTA MORT.IGP C/PIS.250KG",
            "ingredients": "INGREDIENTI: Carne di suino(Origine UE) sale PISTACCHIO (067%)aromi naturali spezie pepe.Antiossidante: ascorbato di sodio. Conservante: nitrito di sodio. Può contenere: GLUTINE",
            "plu": "011135"
        },
        {
            "_id": "154157",
            "name": "CILINDRO AFFUMICATO 5KG",
            "ingredients": "INGREDIENTI:LATTE (LATTE vaccino pastorizzato sale fermenti lattici caglio microbico) sieroinnesto sale caglio. Affumicato con fumi di legno di faggio. Può contenere GLUTINE.",
            "plu": "011136"
        },
        {
            "_id": "154259",
            "name": "PECORINO ROMANO DOP LAZIO KG",
            "ingredients": "INGREDIENTI: LATTE ovino termizzato sale caglio conservante: E235colorante: E172. Crosta non edibile. Può contenere: GLUTINE",
            "plu": "011138"
        },
        {
            "_id": "154260",
            "name": "CACIO PECORINO KG",
            "ingredients": "INGREDIENTI: LATTE ovino pastorizzato salecagliofermenti lattici selezionati. conservante : E235.Crosta non edibile. Può contenere: GLUTINE",
            "plu": "011137"
        },
        {
            "_id": "154310",
            "name": "BECHELLI PROSCIUTTO COTTO CLUB",
            "ingredients": "INGREDIENTI:Prosciutto suino(69%)acqua(2237%) fecola di patate (3%) destrosio aromi naturali sale Gelificante: carragenina Antiossidante: E300 Conservante: E250 Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "013072"
        },
        {
            "_id": "154336",
            "name": "CERULLO CACIOT.DI PECORA KG1.3",
            "ingredients": "INGREDIENTI: LATTE di pecoracaglio sale. Può contenere GLUTINE.",
            "plu": "010685"
        },
        {
            "_id": "154501",
            "name": "LATTERIE VICENT.ASIAGO DOP KG",
            "ingredients": "INGREDIENTI: LATTE sale fermenti lattici caglio. Crosta non edibile. Può contenere GLUTINE.",
            "plu": "011139"
        },
        {
            "_id": "154612",
            "name": "MARTINA FR.CAPOCOLLO INT.SV KG",
            "ingredients": "INGREDIENTI: Carne di suino (Origine Italia) sale destrosio aromi aromi naturali spezie Antiossidante:E301. Conservanti:E250E252 Può contenere LATTEGLUTINE.",
            "plu": "011141"
        },
        {
            "_id": "154877",
            "name": "LE VIE DEL GR.MEZ.LUNA INT.KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: farina di FRUMENTO tipo 00 farina FRUMENTO integrale 50% acqua lievito naturale (contiene FRUMENTO) sale farina di CEREALI maltati. Può contenere SOIA SESAMO NOCI MANDORLE NOCCIOLE LATTE UOVA SENAPE. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto prontoforno congelato.",
            "plu": "016115"
        },
        {
            "_id": "154954",
            "name": "DI LECCE OLIVE ROSATE PICC.KG",
            "ingredients": "INGREDIENTI: olive rosate 91.5% peperone 4.5% olio di semi di girasole origano semi di finocchiocorrettore di acidità: E330.",
            "plu": "011145"
        },
        {
            "_id": "154956",
            "name": "DI LECCE MIX OLIVE CONDITE KG",
            "ingredients": "INGREDIENTI: Olive denocciolate 85% peperone dolce 9.8% olio di semi di girasole origano conservante: E202 correttore di acidità: E270 antiossidante:E300stabilizzante del colore: E579.",
            "plu": "011147"
        },
        {
            "_id": "154957",
            "name": "DI LECCE OLIVE NERE FORNO KG",
            "ingredients": "INGREDIENTI: Olive nere 98% olio di semi di girasole conservante:E202 stabilizzante del colore: E579.",
            "plu": "011144"
        },
        {
            "_id": "154958",
            "name": "DI LECCE OL.VER.DOL.CERIGN.KG",
            "ingredients": "INGREDIENTI: Olive verdi salamoia (acqua sale conservante: E202 antiossidante: E300).",
            "plu": "011146"
        },
        {
            "_id": "154977",
            "name": "LE VIE DEL GR.BAG.MULTIC.AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI:Farina di FRUMENTO tipo OO acqua farina di FARRO lievito naturale sale.Può contenere:SOIASESAMOFRUTTA A GUSCIO LATTEUOVASENAPE. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto prontoforno congelato.",
            "plu": "016106"
        },
        {
            "_id": "155117",
            "name": "SAN MARCO PROSCIUT.TORCELLO KG",
            "ingredients": "INGREDIENTI:Carne suina (Origine UE) sale destrosio saccarosio aromi naturali pezie conservanti: nitrito di sodio (E250). Può contenere LATTEGLUTINE.",
            "plu": "011148"
        },
        {
            "_id": "155118",
            "name": "IL FIORINO CACIO MARZOL.MIS.KG",
            "ingredients": "INGREDIENTI:LATTE bovino e ovino pastorizzato sale di Volterra caglio fermenti lattici. Trattato in superficie con conservante E235. Crosta non edibile. Può contenere GLUTINE.",
            "plu": "011170"
        },
        {
            "_id": "155120",
            "name": "IL FIORINO FIOR PASTORE SV KG",
            "ingredients": "INGREDIENTI:LATTE ovino pastorizzato sale di Volterra caglio fermenti lattici. Trattato in superficie con conservante E235. Crosta non edibile. Può contenere GLUTINE.",
            "plu": "011168"
        },
        {
            "_id": "155121",
            "name": "IL FIORINO CACIO MARZOL.PEC.KG",
            "ingredients": "INGREDIENTI:LATTE ovino pastorizzato sale di Volterra caglio fermenti lattici. Trattato in superficie con conservante E235. Se rosso trattato conc oncentrato di pomodoro. Crosta non edibile. Può contenere GLUTINE.",
            "plu": "011169"
        },
        {
            "_id": "155122",
            "name": "IL FIORINO SEMISTAGIONATO KG",
            "ingredients": "INGREDIENTI:LATTE ovino pastorizzato sale di Volterra caglio fermenti lattici. Crosta non edibile. Può contenere GLUTINE.",
            "plu": "011171"
        },
        {
            "_id": "155123",
            "name": "IL FIORINO PEC.TOSC.DOP AL KG",
            "ingredients": "INGREDIENTI: LATTE ovino pastorizzato sale di Volterra caglio  fermenti lattici autoctoni. Crosta non edibile. Può contenere GLUTINE.",
            "plu": "011172"
        },
        {
            "_id": "155124",
            "name": "IL FIORINO CACIO CATERINA KG",
            "ingredients": "INGREDIENTI:LATTE ovino pastorizzato sale di Volterra caglio fermenti lattici. Crosta non edibile.Può contenere GLUTINE.",
            "plu": "011173"
        },
        {
            "_id": "155125",
            "name": "IL FIORINO PECOR. C/TARTUFO KG",
            "ingredients": "INGREDIENTI:LATTE ovino pastorizzato tartufo bianchetto (tuber borchii vitt.) min. 05 % tartufo estivo min. 05 % tartufo estivo min.05 % aroma sale di Volterra caglio fermenti lattici.Trattato in superficie con E235. Crosta non edibile. Può contenere GLUTINE.",
            "plu": "011175"
        },
        {
            "_id": "155126",
            "name": "IL FIORINO RIS.FONDAT.PORZ.KG",
            "ingredients": "INGREDIENTI:LATTE ovino pastorizzato sale di Volterra caglio fermenti lattici. Crosta non edibile.Può contenere GLUTINE.",
            "plu": "011174"
        },
        {
            "_id": "155177",
            "name": "LE VIE DEL GR.TARTARUGA AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: farina di FRUMENTO tipo 00 acqua lievito di birra farina di CEREALI maltati sale emulsionante: E471. Può contenere SOIA SESAMO NOCI MANDORLE NOCCIOLE LATTESENAPE. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto prontoforno congelato.",
            "plu": "016113"
        },
        {
            "_id": "155277",
            "name": "LE VIE DEL GR.BAGUETTE INT.KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: farina integrale farina di GRANO tenero tipo 00 acqua sale lievito di birra farina di FRUMENTO maltato. Può contenere SOIA SESAMOSENAPE NOCI MANDORLE NOCCIOLE LATTE e UOVA. Prodotto parz. cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto prontoforno congelato.",
            "plu": "016107"
        },
        {
            "_id": "155515",
            "name": "GRANA PADANO 1/8 SCELTO",
            "ingredients": "INGREDIENTI: LATTE sale caglio Conservante: lisozima da UOVA Può contenere GLUTINE.",
            "plu": "010255"
        },
        {
            "_id": "155523",
            "name": "MIRAGLIA OLIVE TONDE VERDI 000",
            "ingredients": "INGREDIENTI: Olive verdi acqua sale correttore di acidità:E330 antiossidante:E300.",
            "plu": "015964"
        },
        {
            "_id": "155618",
            "name": "M&T PROS.PARMA DOP 30M C/OS KG",
            "ingredients": "INGREDIENTI: Coscia suina (origine Italia) sale sugna (suinofarina di risosalepepe). Può contenere LATTEGLUTINE.",
            "plu": "011176"
        },
        {
            "_id": "155629",
            "name": "PETRUCCI FORMAGGIO VERGARO KG",
            "ingredients": "INGREDIENTI: LATTE ovino e vaccino pastorizzato (origine Italia) caglio sale fermenti lattici Trattato in superficie con agenti di rivestimento conservanti: E235. Crosta non edibile. Può contenere: GLUTINEFRUTTA A GUSCIO",
            "plu": "011194"
        },
        {
            "_id": "155630",
            "name": "PETRUCCI FORMAG.SETTECOLLI KG",
            "ingredients": "INGREDIENTI: LATTE vaccino e ovino pastorizzato (origine Italia) caglio sale fermenti lattici Trattato in superficie con agenti di rivestimento conservanti: E235. Crosta non edibile. Può contenere: GLUTINEFRUTTA A GUSCIO",
            "plu": "011195"
        },
        {
            "_id": "155831",
            "name": "FICACCI OL.TAGGIASCHE DEN.2KG",
            "ingredients": "INGREDIENTI: Olive denocciolate taggiasche 90% olio extra vergine di oliva sale E270. Può contenere LATTE GLUTINE SOLFITI LUPINI FRUTTA A GUSCIO SEDANO SEMI DI SESAMO.",
            "plu": "011202"
        },
        {
            "_id": "155856",
            "name": "SCAROLE COTTE AL KG",
            "ingredients": "INGREDIENTI: Scarole olio evo olive nere denocciolatecapperi sotto sale aglio e sale. Può contenere: GLUTINE LATTE UOVA ARACHIDISOIA FRUTTA A GUSCIOSEDANO SENAPE SESAMOANIDRIDE SOLFOROSA LUPINI MOLLUSCHI CROSTACEI PESCE.",
            "plu": "011203"
        },
        {
            "_id": "155857",
            "name": "MELANZANE INDORATE E FRITTE KG",
            "ingredients": "INGREDIENTI:melanzane 90%farina di GRANO UOVA PARMIGIANO grattugiatoolio di semi di girasole pepe sale. Può contenere:ARACHIDISOIA FRUTTA A GUSCIO SEDANOSENAPE SESAMO ANIDRIDE SOLFOROSA LUPINI MOLLUSCHI CROSTACEI PESCE.",
            "plu": "011206"
        },
        {
            "_id": "155858",
            "name": "MELANZANE ARROSTITE AL KG",
            "ingredients": "INGREDIENTI: Melanzane arrostite olio di semi di girasoleprezzemolo peperoncino aglio e sale. Può contenere:GLUTINE LATTE UOVA ARACHIDISOIA FRUTTA A GUSCIOSEDANO SENAPE SESAMOANIDRIDE SOLFOROSA LUPINI MOLLUSCHI CROSTACEI PESCE.",
            "plu": "011204"
        },
        {
            "_id": "155859",
            "name": "ZUCCHINE ARROSTITE AL KG",
            "ingredients": "INGREDIENTI:Zucchine arrostite olio di semi di girasole prezzemolo peperoncino aglio sale. Può contenere: GLUTINE LATTE UOVA ARACHIDISOIA FRUTTA A GUSCIOSEDANO SENAPE SESAMOANIDRIDE SOLFOROSA LUPINI MOLLUSCHI CROSTACEI PESCE.",
            "plu": "011205"
        },
        {
            "_id": "155875",
            "name": "SAN MARCO PROSC.CRUDO DIS.KG",
            "ingredients": "INGREDIENTI: Coscia di suino (Origne UE) sale marino. Può contenere LATTE GLUTINE.",
            "plu": "011208"
        },
        {
            "_id": "155876",
            "name": "IL FIORINO LA MUCCHINA AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccino pastorizzato sale di Volterra caglio fermenti lattici. Trattato in superficie con E235. Crosta non edibile.Può contenere GLUTINE.",
            "plu": "011196"
        },
        {
            "_id": "155878",
            "name": "IL FIORINO DOLCE MAREMMA AL KG",
            "ingredients": "INGREDIENTI: LATTE ovino pastorizzato sale di Volterra caglio fermenti lattici. Trattato in superficie con E235. Crosta non edibile.Può contenere GLUTINE.",
            "plu": "011198"
        },
        {
            "_id": "155880",
            "name": "IL FIORINO LA PECORINA AL KG",
            "ingredients": "INGREDIENTI: LATTE ovino pastorizzato sale di Volterra caglio fermenti lattici. Trattato in superficie con E235. Crosta non edibile.Può contenere GLUTINE.",
            "plu": "011197"
        },
        {
            "_id": "155881",
            "name": "IL FIORINO FIOR NAT.BIO C.VEG.",
            "ingredients": "INGREDIENTI: LATTE ovino pastorizzato sale di Volterra caglio vegetale (cynara carduluncus) fermenti lattici. Crosta non edibile.Può contenere GLUTINE.",
            "plu": "011199"
        },
        {
            "_id": "155882",
            "name": "IL FIORINO PECORINO PEP.NO KG",
            "ingredients": "INGREDIENTI: LATTE ovino pastorizzato sale di Volterra caglio peperoncino 01% fermeti lattici. Trattato in superficie con E235. Crosta non edibile.Può contenere GLUTINE.",
            "plu": "011200"
        },
        {
            "_id": "155883",
            "name": "IL FIORINO PEC.PIST..BRONTE KG",
            "ingredients": "INGREDIENTI: LATTE ovino pastorizzato sale di Volterra caglio PISTACCHIO di Bronte 4% fermeti lattici. Trattato in superficie con E235. Crosta non edibile.Può contenere GLUTINE.",
            "plu": "011201"
        },
        {
            "_id": "156112",
            "name": "DELIZIOSA CACIORICOTTA M.AL KG",
            "ingredients": "INGREDIENTI: LATTE bovino e caprino caglio sale conservante: E200. Può contenere GLUTINE.",
            "plu": "011210"
        },
        {
            "_id": "156113",
            "name": "FIORUCCI PROSC.COTTO AQ AL KG",
            "ingredients": "INGREDIENTI:Carne di suino (origine UE) brodo vegetale (3%) salearomi naturali zuccheroantiossidante (E316) conservante(E250). Può contenere: LATTE GLUTINE.",
            "plu": "011212"
        },
        {
            "_id": "156266",
            "name": "COPPIETTE DI SUINO PICCANTI KG",
            "ingredients": "INGREDIENTI:carne di suino sale pepe nero peperoncinoaglio (contiene SOLFITI) finocchio paprika dolce antiossidanti: E300E301. conservanti: E250E252. Può contenere LATTE GLUTINE Origine suino:UE",
            "plu": "011218"
        },
        {
            "_id": "156267",
            "name": "PORCHETTA ROMANA AUGUSTA IN.KG",
            "ingredients": "INGREDIENTI:carne di suino sale pepe nero peperoncinoaglio (contiene SOLFITI) finocchio rosmarino.Può contenere LATTEGLUTINE. Origine suino: UE",
            "plu": "011217"
        },
        {
            "_id": "156268",
            "name": "TRONCH.PORCHETTA ROMANA AUG.KG",
            "ingredients": "INGREDIENTI:carne di suino sale pepe nero peperoncinoaglio (contiene SOLFITI) finocchio rosmarino.Può contenere LATTEGLUTINE. Origine suino: UE",
            "plu": "011216"
        },
        {
            "_id": "156294",
            "name": "PINI CARPACCIO BRESAOLA 1/2 KG",
            "ingredients": "INGREDIENTI:carne di bovino sale destrosio aromi naturali conservanti: nitrito di sodio (E250)nitrato di sodio (E251). Può contenere LATTE GLUTINE.",
            "plu": "011219"
        },
        {
            "_id": "156436",
            "name": "LE DELIZIE ROLLE DI SURIMI KG",
            "ingredients": "INGREDIENTI: acqua SURIMI 31% polpa di PESCE 28% (MERLUZZO dAlaska MERLUZZO del pacifico)stabilizzanti E420-E450-E451-E452 zucchero amido (contiene FRUMENTO)albume dUOVO sale olio di colza aroma (contiene CROSTACEI)addensanti (carragenina E412) esaltatore di sapidità (E621) maltodestrina fibre di FRUMENTO coloranti(estratto di paprica). Può contenere: SOIA SENAPE MOLLUSCHILATTE SEDANO.",
            "plu": "011213"
        },
        {
            "_id": "156453",
            "name": "GALBANI GALBANONE AFFUMIC.KG",
            "ingredients": "INGREDIENTI: LATTE (origine UE) sale caglio aroma di affumicatura. Può contenere: GLUTINE",
            "plu": "011221"
        },
        {
            "_id": "156727",
            "name": "FUMAGALLI SALAME MUGNANO AL KG",
            "ingredients": "INGREDIENTI:Carne di suino (origine Italia) sale vino (SOLFITI)destrosio spezie aromi antiossidanti: ascorbato di sodio e acido ascorbicoconservanti: nitrato di potassio e nitrito di sodio. Può contenere: GLUTINE LATTE.",
            "plu": "011223"
        },
        {
            "_id": "157289",
            "name": "NUOVA BOSCHI PROSC.PARMA DISS",
            "ingredients": "INGREDIENTI:coscia di suinosale Può contenere GLUTINELATTE.",
            "plu": "012257"
        },
        {
            "_id": "157700",
            "name": "PLAC CACIOC.DEL PRIORE AL KG",
            "ingredients": "INGREDIENTI: LATTE sale caglio Colorante:E120 E131. Trattato in superficie con E202 E235 Può contenere GLUTINE.",
            "plu": "010813"
        },
        {
            "_id": "157702",
            "name": "FILETTI LING VASCHETTA 400/70",
            "ingredients": "INGREDIENTI: LING (Molva molva)saleacquae Stabilizzante:E450E451 Zona FAO27 Attrezzo di pesca:ami e palangari Contiene PESCE",
            "plu": "016311"
        },
        {
            "_id": "157704",
            "name": "RENZINI PR.CRUDO NORCIA PEPE",
            "ingredients": "INGREDIENTI: coscia suinosalepepe(02%) Può contenere GLUTINELATTE. Origine suino: ITALIA",
            "plu": "012680"
        },
        {
            "_id": "157736",
            "name": "OCCELLI AL BAROLO AL KG",
            "ingredients": "INGREDIENTI: LATTE di vacca (provenienza Italia) LATTE di capra (provenienza Italia) vinacce 10% (SOLFITI)vino Barolo DOCG 4% (SOLFITI)sale (marino) caglio animale di vitello Può contenere GLUTINE.",
            "plu": "011111"
        },
        {
            "_id": "157737",
            "name": "OCCELLI MALTO D ORZO E WHISKY",
            "ingredients": "INGREDIENTI: LATTE di vacca 877%malto dORZO 7% whisky 4% sale caglio Può contenere GLUTINESOIASENAPE.",
            "plu": "011110"
        },
        {
            "_id": "157739",
            "name": "OCCELLI IN FOGLIA DI CASTAGNO",
            "ingredients": "INGREDIENTI: LATTE di pecora(origine:ITALIA) LATTE di vacca (origine:ITALIA) sale (marino) caglio (animale di vitello) ricoperto in superficie da foglie di castagno (non edibili). Può contenere GLUTINE",
            "plu": "011109"
        },
        {
            "_id": "157740",
            "name": "OCCELLI TOMA MONTE REGALE",
            "ingredients": "INGREDIENTI:LATTE vaccino crudo sale caglio. Può contenere GLUTINE.",
            "plu": "011108"
        },
        {
            "_id": "157741",
            "name": "OCCELLI VALCASOTTO AL KG",
            "ingredients": "INGREDIENTI:LATTE vaccino crudo sale caglio. Può contenere GLUTINE.",
            "plu": "011107"
        },
        {
            "_id": "159673",
            "name": "PRESIDENT BRIE KG1",
            "ingredients": "INGREDIENTI: LATTE vaccinosaleFERMENTI LATTICI caglio Può contenere GLUTINE.",
            "plu": "010842"
        },
        {
            "_id": "160077",
            "name": "ANTICA MURGIA RICOTTA AL FORNO",
            "ingredients": "INGREDIENTI: siero di LATTE vaccinosale. Può contenere GLUTINE.",
            "plu": "010404"
        },
        {
            "_id": "160499",
            "name": "VERONI MORTADELLA V",
            "ingredients": "INGREDIENTI:spalla di suinoguanciale di suino sale aromi speziePISTACCHI Antiossidante: E300 Conservante: E250 Esaltatore di sapitdità: E621 Può contenere GLUTINE LATTE. Origine suino: UE",
            "plu": "011624"
        },
        {
            "_id": "160614",
            "name": "ANTICA MURGIA RICOTTA LIMONE",
            "ingredients": "INGREDIENTI: RICOTTA min 80% (siero di LATTE e LATTE di bufala e vaccasale)crema di limone (15%)(zuccheroPANNA pastorizzata e LATTE UHT olio essenziale naturale di limone limone e arancio)amido di patata Addensanti:E407 Colorante:curcumina Conservante: E200 Emulsionante:E471 Può contenere GLUTINE.",
            "plu": "010405"
        },
        {
            "_id": "162206",
            "name": "VALGRANA FORMAG.PIEMONTESE 1/8",
            "ingredients": "INGREDIENTI: LATTE di vaccacaglio sale Conservante: lisozima da UOVA Può contenere GLUTINE.",
            "plu": "010256"
        },
        {
            "_id": "163428",
            "name": "FILETTI DI LING MOLVA 1000 UP",
            "ingredients": "INGREDIENTI: LING (Molva molva)saleacqua Stabilizzante:E450E451 Zona FAO27 Attrezzo di pesca: ami e palangari Contiene PESCE",
            "plu": "016413"
        },
        {
            "_id": "165175",
            "name": "MIRAGLIA GIARD.ACETO 5KG",
            "ingredients": "INGREDIENTI: Carote cavoli cetrioli rape peperonicipollineSEDANOaceto di vino(SOLFITI) acqua sale. Può contenere MANDORLELUPINI.",
            "plu": "015965"
        },
        {
            "_id": "169482",
            "name": "AMALATTEA FORM.CAPRA TESTADURA",
            "ingredients": "INGREDIENTI: LATTE sale caglio Può contenere GLUTINE.",
            "plu": "010687"
        },
        {
            "_id": "171577",
            "name": "VEROPANE BASSO DI SEMOLA KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: semola rimacinata di GRANO duro acqua lievito madre lievito naturale sale. Può contenere UOVA semi di lino e di girasole crusca di GRANO teneroSOIASENAPE. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016108"
        },
        {
            "_id": "171677",
            "name": "*VEROPANE FILONE DI SEMOLA KG",
            "ingredients": "INGREDIENTI: semola rimacinata di GRANO duro acqua lievito madre lievito naturale sale. Potrebbe contenere tracce di: UOVA SEMI di LINO e di GIRASOLE CRUSCA di GRANO TENERO. Prodotto parz.cotto surgelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da pane parzialmente cotto. Codice Descrizione/Ingredienti Plu bilancia",
            "plu": "None"
        },
        {
            "_id": "173641",
            "name": "FICACCI OLIVE NERE GAETA 4KG",
            "ingredients": "INGREDIENTI:Olive olio di semi di girasole sale stabilizzante del colore: gluconato ferroso - E579",
            "plu": "016023"
        },
        {
            "_id": "173658",
            "name": "FICACCI OLIVE N.COND.FORNO25K",
            "ingredients": "INGREDIENTI: Olive (97%) olio di semi di girasole (16%) sale stabilizzante del colore: E570.",
            "plu": "015969"
        },
        {
            "_id": "175687",
            "name": "NEGRONI PROSCIUTTO PARMA DIS.",
            "ingredients": "INGREDIENTI:coscia di suinosale Può contenere GLUTINELATTE.",
            "plu": "012259"
        },
        {
            "_id": "177086",
            "name": "FALODE CACIOTTA DELIZIE MATESE",
            "ingredients": "INGREDIENTI: LATTE bovino razza bruna sale caglio di vitello fermenti lattici. Può contenere GLUTINE.",
            "plu": "010981"
        },
        {
            "_id": "177087",
            "name": "FALODE CACIOTTA MISTA AL KG",
            "ingredients": "INGREDIENTI: LATTE bovino razza bruna di pecora laguane di capra saneen sale caglio di vitello fermenti lattici. Può contenere GLUTINE.",
            "plu": "010982"
        },
        {
            "_id": "177088",
            "name": "FALODE PECORINO STAGIONATO KG",
            "ingredients": "INGREDIENTI: LATTE di pecora di razza lacaune FERMENTI LATTICI caglio sale Può contenere GLUTINE.",
            "plu": "010979"
        },
        {
            "_id": "177289",
            "name": "SAN VINC.CACIOSPIANATA PICC.KG",
            "ingredients": "INGREDIENTI:Carne di suino LATTE vaccino caglio saleconc. di peperoncino piccante (peperoncino piccante calabrese 98%sale (2%) )salearomi destrosiosaccarosio spezie (di cui peperoncino piccante calabrese 02% ) aromatizzanti di affumicatura antiossidante:acido ascorbico E300 conservanti:nitrito di sodio - E250 nitrato di potassio - E252.Può contenere ANIDRIDE SOLFOROSA GLUTINE. Origine suino: UE",
            "plu": "012041"
        },
        {
            "_id": "177290",
            "name": "SAN VINC.CACIOCAPICOLLO KG",
            "ingredients": "INGREDIENTI:Carne di suino LATTE vaccino caglio sale destrosio aromi naturali pepe conservanti: nitrito di sodio - E250 nitrato di potassio - E252 Può contenere GLUTINE. Origine suino: UE",
            "plu": "012030"
        },
        {
            "_id": "177292",
            "name": "SAN VINC.NDUJA 400GR",
            "ingredients": "INGREDIENTI: grasso di suino carne di suino (Origine UE) peperoncino piccante (di cui 10% peperoncino piccante calabrese) olio di oliva sale destrosio saccarosio aromatizzante di affumicatura antiossidante: E301 Conservante:E250.Può contenere:LATTE SOLFITIGLUTINE. Conservare tra 2 e 8°C.",
            "plu": "012019"
        },
        {
            "_id": "177327",
            "name": "PALATELLA NAPOLETANA KG (500)",
            "ingredients": "INGREDIENTI: FARINA 00 lievito naturale sale acqua. Può contenere SOIA guscio di NOCI MANDORLE NOCCIOLE SENAPE. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto: ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016060"
        },
        {
            "_id": "177495",
            "name": "MAASDAMMER 1/4 AL KG",
            "ingredients": "INGREDIENTI:LATTE sale FERMENTI LATTICIcaglio microbicotrattato in crosta con conservante E235. Può contenere: GLUTINE.",
            "plu": "011105"
        },
        {
            "_id": "177496",
            "name": "TOSI GORGONZ.DOP A CUCCHIAIO",
            "ingredients": "INGREDIENTI: LATTE di vacca sale caglio Può contenere GLUTINE.",
            "plu": "011104"
        },
        {
            "_id": "177509",
            "name": "MONT.CRUDO STAG.SGAMBATO",
            "ingredients": "INGREDIENTI:Coscia di suino sale conservante: nitrato di potassio - E252 Può contenere GLUTINELATTE Origine suino: UE",
            "plu": "012092"
        },
        {
            "_id": "177515",
            "name": "T.DUCALI SALAME FELINO AL KG",
            "ingredients": "INGREDIENTI:Carne di suino salevino(SOLFITI) pepe nero aglio(SOLFITI)destrosio saccarosio antios: ascorbato di sodio - E301 conservanti:nitrato di potassio - E252 nitrito di sodio - E250 Può contenere GLUTINELATTE. Origine suino: ITALIA",
            "plu": "012053"
        },
        {
            "_id": "177530",
            "name": "SAN ROCCO ASIAGO DOP AL KG",
            "ingredients": "INGREDIENTI:LATTE FERMENTI LATTICI caglio sale Può contenere GLUTINE",
            "plu": "011076"
        },
        {
            "_id": "177548",
            "name": "BERGADER EDILPILZ RETTANGOL.KG",
            "ingredients": "INGREDIENTI:LATTE vaccino pastorizzato sale fermenti lattici caglio microbico colture selezionate di muffa blu Può contenere GLUTINE",
            "plu": "010497"
        },
        {
            "_id": "177592",
            "name": "PARMIGIANO REGGIANO 1/8 36MESI",
            "ingredients": "INGREDIENTI: LATTE sale caglio Può contenere GLUTINE",
            "plu": "010315"
        },
        {
            "_id": "177716",
            "name": "GRUYERE FORMAGGIO AL KG",
            "ingredients": "INGREDIENTI:LATTEcaglio fermenti lattici sale. Può contenere GLUTINE",
            "plu": "010859"
        },
        {
            "_id": "177898",
            "name": "IAQUILAT IL GROTTONE OCCHI.AFF",
            "ingredients": "INGREDIENTI: LATTE vaccino caglio sale lisozima da UOVO FERMENTI LATTICIaroma di affumicatura. Può contenere GLUTINE",
            "plu": "010807"
        },
        {
            "_id": "180083",
            "name": "FINI COTTO LUSSO PRAGA",
            "ingredients": "INGREDIENTI: coscia di suinoAMIDI modificati salearomisaccarosioproteine del LATTE acqua Conservante:E250 Esaltatore di sapidità:E621 Antiossidante:E301 Stabilizzanti:polifosfati Origine suino: UE",
            "plu": "013062"
        },
        {
            "_id": "180109",
            "name": "NEGRONI CULATELLO ZIBELLO DOP",
            "ingredients": "INGREDIENTI:Carne di suino sale pepe aglio(SOLFITI).Può contenere GLUTINELATTE.",
            "plu": "012658"
        },
        {
            "_id": "182741",
            "name": "ROVAGNATI GRAN BISCOTTO DELI",
            "ingredients": "INGREDIENTI:coscia di suinosale aromi Antiossidante:E301 Conservante:E250 Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "013061"
        },
        {
            "_id": "183863",
            "name": "FORMAGGIO RIGATINO 2.7KG",
            "ingredients": "Ingredienti:LATTE pastorizzato sale marino alimentarecaglio FERMENTI LATTICI selezionati Conservanti: E251 Crosta plastificata non edibile trattata con agenti coloranti E160b e conservanti E235E202 Può contenere GLUTINE.",
            "plu": "010688"
        },
        {
            "_id": "184895",
            "name": "FIORUCCI MORTADELLA SUPRE K90",
            "ingredients": "INGREDIENTI: carne di suinotrippino di suino salezuccherospezie aromiPISTACCHIO Antiossidante: E301E300 Conservante: E250 Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "011626"
        },
        {
            "_id": "184911",
            "name": "FIORUCCI SALAM.SPIANATA1/2 S.V",
            "ingredients": "INGREDIENTI: carne di suinosale destrosio aromiLATTE scremato in polvere aglio(SOLFITI) pepe zucchero vino bianco(SOLFITI). Antiossidante:E301 Conservante:E252 E250 Può contenere GLUTINE. Origine suino: UE",
            "plu": "011941"
        },
        {
            "_id": "184937",
            "name": "NEGRONI PROSC PRIMA STELLA",
            "ingredients": "INGREDIENTI: Coscia di suinosalevino(SOLFITI) aromi naturali aromi antiossidante: ascorbato di sodio; conservante: nitrito di sodio Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "011320"
        },
        {
            "_id": "185355",
            "name": "GALBANI MORTADELLA AUGUSTISSIM",
            "ingredients": "INGREDIENTI:carne di suinotrippino di suinosale magro suino di testazucchero spezie acqua carne e grasso di suino separati meccanicamente PISTACCHIaromi naturali. Origine suino: UE Esaltatore di sapidità:E621 Antiossidante:E301 Conservante:E250 Può contenere GLUTINELATTE.",
            "plu": "011627"
        },
        {
            "_id": "186734",
            "name": "FINI MORTAD.BONISSIMA PIS 25KG",
            "ingredients": "INGREDIENTI: carne di suinosaccarosio sale aromi naturalispezie Può contenere PISTACCHIGLUTINELATTE Antiossidante: E300 Conservante: E250 Esaltatore di sapidità: E621 Origine suino: UE",
            "plu": "011629"
        },
        {
            "_id": "187070",
            "name": "BEDOGNI PROSCIUTTO PARMA C/OSS",
            "ingredients": "INGREDIENTI: coscia di suinosale. Può contenere GLUTINELATTE.",
            "plu": "012260"
        },
        {
            "_id": "187849",
            "name": "PROVOLONE DEL MONACO DOP.",
            "ingredients": "INGREDIENTI: LATTE vaccinocaglio sale. Può contenere GLUTINE",
            "plu": "010788"
        },
        {
            "_id": "187872",
            "name": "SARDAFORMAGGI PEC.ROMANO B.1/4",
            "ingredients": "INGREDIENTI: LATTE di pecorasale caglio Può contenere GLUTINE",
            "plu": "010992"
        },
        {
            "_id": "189605",
            "name": "PINNA CACIOT REGNO DI SARDEGNA",
            "ingredients": "INGREDIENTI: LATTE di pecoracagliosale FERMENTI. NO OGM. Può contenere GLUTINE",
            "plu": "010690"
        },
        {
            "_id": "190366",
            "name": "NEGRONI PANCETTA TESA DOLCE 1K",
            "ingredients": "INGREDIENTI: Pancetta di Suino Sale Saccarosio Spezie Aromi naturali Conservanti: Nitrito di Sodio. Può contenere GLUTINELATTE Origine suino: UE",
            "plu": "011571"
        },
        {
            "_id": "190367",
            "name": "AMATRICE PECORINO AL KG",
            "ingredients": "INGREDIENTI:Siero di LATTE vaccino e ovino LATTE ovino e sale. Può contenere GLUTINE",
            "plu": "010533"
        },
        {
            "_id": "190789",
            "name": "GUSTO DECO PROV.PICC.MAND.20KG",
            "ingredients": "INGREDIENTI:LATTEsalecaglioconservante E239 Conservanti in superficie: E202E235 Può contenere GLUTINE",
            "plu": "011406"
        },
        {
            "_id": "190790",
            "name": "GUSTO DECO PROVOLONE 100KG",
            "ingredients": "INGREDIENTI:LATTE salecaglioconservante E239 Conservanti in superficie: E202E235 Può contenere GLUTINE",
            "plu": "011407"
        },
        {
            "_id": "190794",
            "name": "GUSTO DECO PAR.REGGIANO 30MESI",
            "ingredients": "INGREDIENTI:LATTEsalecaglio Può contenere GLUTINE",
            "plu": "011408"
        },
        {
            "_id": "190795",
            "name": "GUSTO DECO GRANA RISERVA20MESI",
            "ingredients": "INGREDIENTI:LATTEsalecaglio Conservante:Lisozima (derivato dellUOVO) Può contenere GLUTINE",
            "plu": "011409"
        },
        {
            "_id": "190919",
            "name": "DEL GIUDICE RICOT.PECORA A KG",
            "ingredients": "INGREDIENTI:Siero di LATTE ovino pastorizzato LATTE ovino pastorizzato sale. Può contenere GLUTINE",
            "plu": "011410"
        },
        {
            "_id": "190943",
            "name": "LEONCINI COPPA NOSTRANA AL KG",
            "ingredients": "INGREDIENTI:Coppa di suino Sale Saccarosio Aromi. Antiossidante: ascorbato di sodio. Conservanti: nitrato di potassio nitrito di sodio Può contenere GLUTINELATTE Origine suino: UE",
            "plu": "011565"
        },
        {
            "_id": "191105",
            "name": "ROMAGNA T.PECORINO CAMOMIL.KG",
            "ingredients": "INGREDIENTI: LATTE di pecora pastorizzato sale caglio e fermenti lattici. Trattato in superficie con fiori di camomilla (2%). Crosta non edibile. Può contenere GLUTINE FRUTTA A GUSCIO.",
            "plu": "011417"
        },
        {
            "_id": "191107",
            "name": "ROMAGNA T.PECORINO FIORDAL.KG",
            "ingredients": "INGREDIENTI:LATTE di pecora pastorizzato sale caglio e fermenti lattici. Ricoperto in superficie con fiori di fiordaliso(2%). Crosta non edibile. Può contenere GLUTINE FRUTTA A GUSCIO.",
            "plu": "011416"
        },
        {
            "_id": "191108",
            "name": "ROMAGNA T.PECORINO PERA KG",
            "ingredients": "INGREDIENTI:LATTE di pecora pastorizzato sale succo di pera liofilizzato 09% (succo di pera liofilizzato aromi naturali) caglio e fermenti lattici. Crosta non edibile. Può contenere GLUTINE FRUTTA A GUSCIO",
            "plu": "011415"
        },
        {
            "_id": "191380",
            "name": "CARMASCIANDO FORM.AGLIANICO KG",
            "ingredients": "INGREDIENTI:LATTE vaccino e ovino sale caglio vino aglianico(SOLFITI). Può contenere GLUTINE.",
            "plu": "011421"
        },
        {
            "_id": "191485",
            "name": "LE DELIZIE INS.C/POLPO NAT.4KG",
            "ingredients": "INGREDIENTI: TOTANO Gigante del Pacifico (80%) POLPO Messicano(20%)saleaceto di vino(SOLFITI) glucosio E575E330E211. Può contenere CROSTACEIGLUTINESOIAUOVASEDANO.",
            "plu": "010637"
        },
        {
            "_id": "191493",
            "name": "MANCIN ANGUILLA MARINATA AL KG",
            "ingredients": "ANGUILLE aceto di vino (SOLFITI) acqua sale. Può contenere: MOLLUSCHI CROSTACEI GLUTINE UOVA SOIA SEDANO SENAPE.",
            "plu": "010632"
        },
        {
            "_id": "191495",
            "name": "MANCIN FRITTURA LATTERINI KG",
            "ingredients": "LATTERINI aceto di vino (SOLFITI) acqua farina di FRUMENTO tipo 0 olio di semi di girasole sale. Può contenere SOIASENAPE.",
            "plu": "010633"
        },
        {
            "_id": "191496",
            "name": "PEZZANA PRATAIOLA MIGNON AL KG",
            "ingredients": "INGREDIENTI:LATTE sale caglio. Può contenere GLUTINE.",
            "plu": "010636"
        },
        {
            "_id": "191503",
            "name": "FEDERICI CIAUSCOLO IGP AL KG",
            "ingredients": "INGREDIENTI:Carne di suino grasso suino sale pepe aglio(SOLFITI)vino (SOLFITI) destrosio saccarosioconservanti (E252) antiossidanti (E300 E301). Può contenere GLUTINELATTE.",
            "plu": "015054"
        },
        {
            "_id": "191504",
            "name": "FEDERICI SALAMELLA STAG.AL KG",
            "ingredients": "INGREDIENTI:Carne di suino italiano fegato di suino italiano (40%) sale destrosio saccarosio aromi spezie conservanti (E250 E252) antiossidanti (E300 E301). Può contenere GLUTINELATTE",
            "plu": "010639"
        },
        {
            "_id": "191505",
            "name": "PECORELLA CACIOT.MISTA AL KG",
            "ingredients": "INGREDIENTI: LATTE di vacca e pecora pastorizzato sale caglio FERMENTI LATTICI conservanti in superficie (E202 E235) coloranti in superficie (E172). Può contenere GLUTINE.",
            "plu": "010634"
        },
        {
            "_id": "191506",
            "name": "PECORELLA CACIOT.OVINA PEP.KG",
            "ingredients": "INGREDIENTI:LATTE di vacca e pecora pastorizzato sale caglio FERMENTI LATTICI peperoncino (2%). Trattato in superficie con acetato di polivinile e conservante (E235). Può contenere GLUTINE",
            "plu": "010635"
        },
        {
            "_id": "191507",
            "name": "C.LOMBARDI PESTO GENOV.AL KG",
            "ingredients": "INGREDIENTI: Semilavorato di Basilico genovese DOP 37% Basilico genovese DOP 64%olio di semi di girasole olio extravergine di olivasale antiossidante (E300) olio extra vergine doliva olio di semi di girasole BURRO GRANA PADANO DOP LATTE sale caglio lisozima da UOVO) NOCI PECORINO ROMANO DOP (LATTE sale caglio) PINOLI aglio (SOLFITI) sale conservante(E200) antiossidante(E300).",
            "plu": "010620"
        },
        {
            "_id": "191508",
            "name": "C.LOMBARDI PESTO GENOV.S/AG.KG",
            "ingredients": "INGREDIENTI: Olio di semi di girasole Basilico Genovese DOP 24% BURRO 100% Italiano olio extra vergine di oliva PECORINO ROMANO DOP (LATTE sale caglio) GRANA PADANO DOP (LATTE sale caglio lisozima da UOVO) PINOLI sale fibre vegetali antiossidante (E300) conservante (E200). Può contenere FRUTTA A GUSCIO.",
            "plu": "010642"
        },
        {
            "_id": "191794",
            "name": "DEL GIUDICE LA RICOTTINA AL KG",
            "ingredients": "INGREDIENTI: LATTE pastorizzato siero di LATTE sale. Può contenere: GLUTINE.",
            "plu": "016267"
        },
        {
            "_id": "195156",
            "name": "AURICCHIO CACIOCAVALLO BIANK3",
            "ingredients": "INGREDIENTI: LATTE vaccinosale caglio Conservante sulla crosta:E235. Può contenere GLUTINE",
            "plu": "010791"
        },
        {
            "_id": "196535",
            "name": "AURICCHIO MAND.STRAVECCHIO 20K",
            "ingredients": "INGREDIENTI: LATTE vaccinosale caglio Conservante sulla crosta:E202235. Può contenere GLUTINE.",
            "plu": "010792"
        },
        {
            "_id": "197186",
            "name": "VERONI MORTADELLA AL KG.",
            "ingredients": "INGREDIENTI: carne suinasalearomi naturali spezie PISTACCHI (06%) Antiossidante: E330 Esaltatore di sapidità: E621 Conservante: E250 Può contenere GLUTINELATTE.",
            "plu": "011630"
        },
        {
            "_id": "199315",
            "name": "OSELLA PRIMO SALE AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccino pastorizzato crema di LATTEsale Può contenere GLUTINE.",
            "plu": "010939"
        },
        {
            "_id": "201228",
            "name": "BERGADER CACIOT.BAVARESE 600G",
            "ingredients": "INGREDIENTI: LATTE vaccino pastorizzato sale caglio FERMENTI LATTICI Può contenere GLUTINE",
            "plu": "010843"
        },
        {
            "_id": "201244",
            "name": "BERGADER ROTONDO KG 2.3",
            "ingredients": "INGREDIENTI: LATTE culture di LATTE sale caglio calcio cloride penicillio roqueforti Può contenere GLUTINE.",
            "plu": "010845"
        },
        {
            "_id": "208520",
            "name": "BERNARD.LARDO CONCA MARMO KG",
            "ingredients": "INGREDIENTI: lardo di suinosale spezie piante aromatiche Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "011956"
        },
        {
            "_id": "211193",
            "name": "NORITA SALM.NORVEGESE INT.2KG",
            "ingredients": "INGREDIENTI: SALMONE (Salmo salar) allevato in Norvegia pescato con draghe sale.",
            "plu": "016291"
        },
        {
            "_id": "211557",
            "name": "ROVAGNATI COTTO SAP.DELICATO",
            "ingredients": "INGREDIENTI: coscia di suinoacqua sale aromidestrosio saccarosio Gelificante: carragenina Esaltatore di sapidità:E621 Antiossidante:E301 Conservante: E250 Può contenere GLUTINELATTE Origine suino: UE",
            "plu": "013066"
        },
        {
            "_id": "213074",
            "name": "CACIOCAVALLO SILANO DOP",
            "ingredients": "INGREDIENTI: LATTE vaccino caglio sale. Può contenere GLUTINE",
            "plu": "010947"
        },
        {
            "_id": "215731",
            "name": "VALTIBERINO PROSC.CRUDO NORCIA",
            "ingredients": "INGREDIENTI:Coscia di suino sale pepe Può contenere GLUTINELATTE.",
            "plu": "012660"
        },
        {
            "_id": "215855",
            "name": "KING S CRUDO SNOCCIOLATO AL KG",
            "ingredients": "INGREDIENTI:coscia di suino sale zucchero pepe. Conservante:nitrito di sodio (E250) Nitrito di potassio (E252) Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "012068"
        },
        {
            "_id": "216812",
            "name": "TOGIMA OL.VERDI SELEZIONE",
            "ingredients": "INGREDIENTI:Olive acqua sale correttore di acidità: acido citrico - E330",
            "plu": "015975"
        },
        {
            "_id": "216820",
            "name": "TOGIMA OLIVE NERE ITRANE",
            "ingredients": "INGREDIENTI:Olive acqua sale correttore di acidità: acido citrico - E330",
            "plu": "015976"
        },
        {
            "_id": "222190",
            "name": "TOGIMA VERDI SCHIACCIATE",
            "ingredients": "INGREDIENTI:Olive acqua sale correttore di acidità: acido citrico - E330",
            "plu": "015978"
        },
        {
            "_id": "222208",
            "name": "TOGIMA OLIVE NERE PASSOLONI",
            "ingredients": "INGREDIENTI:Olive acqua sale correttore di acidità: acido citrico - E330",
            "plu": "015979"
        },
        {
            "_id": "223016",
            "name": "FIORUCCI PROS.COTTO ARROSTO KG",
            "ingredients": "INGREDIENTI: carne di suinoAMIDIzucchero sale aromidestrosio spezie Stabilizzante: E407 Antiossidante:E316 Correttore di acidità:E331 Conservante:E250 Può contenere LATTE. Origine suino: UE",
            "plu": "011967"
        },
        {
            "_id": "229203",
            "name": "LEONCINI PORCHETTA AL FORNO KG",
            "ingredients": "INGREDIENTI: Carne si suino saccarosio stabilizzanti: E452 E451 antiossidante: E301 conservante: E250 aromi. Può contenere GLUTINE LATTE. Origine suino: UE",
            "plu": "016300"
        },
        {
            "_id": "245605",
            "name": "LEONCINI PANCETTA AFFUM.BACON",
            "ingredients": "INGREDIENTI:pancetta di suinosale saccarosioaromi Antiossidante:E301 Esaltatore di sapidita:E621 Conservanti:E250 Puo contenere GLUTINELATTE. Origine suino: UE",
            "plu": "013284"
        },
        {
            "_id": "248393",
            "name": "FINI MORTADELLA EMILIA 15KG",
            "ingredients": "INGREDIENTI:carne di suinotrippini di suino sale proteine del LATTEsaccarosio aromi naturali spezie e piante aromatiche Esaltatore di sapidita: E621 Antiossidante:E301 Conservante:E250 Puo contenere PISTACCHIOGLUTINE Origine suino: UE Budello non edibile",
            "plu": "011641"
        },
        {
            "_id": "251579",
            "name": "VOGLIAZZI TORTA VETRINA 3500GR",
            "ingredients": "INGREDIENTI:Insalata russa:patate olio di semi di girasole carote piselli UOVA pastorizzate aceto di vino (SOLFITI)salezuccheroSENAPE succo di limone addensanti: E412 E415; acidif.: E270; conservante: E200. Gelatina: acqua aceto di VINO gelatina animale sale acidificante: E325;esaltatore di sapidità:E621 conservante: E202. Preparazione alimentare a base di UOVA.MAIONESE(olio di semi di girasole UOVA pastorizzate aceto di VINO SENAPEsucco di limone acidificante: E270 salezucchero conservante: E200) GAMBERI in salamoia Pasta di peperone (acqua peperoniaceto di VINO gelatina animale saleconservante: E202 correttore dacidità: E330) Può contenere:GLUTINE PESCE SOIA LATTE FRUTTA A GUSCIOSEDANO MOLLUSCHI.",
            "plu": "010748"
        },
        {
            "_id": "251595",
            "name": "VOGLIAZZI INS.RUSSA UOVA 22KG",
            "ingredients": "INGREDIENTI: Insalata russa 81%: patate olio di semi di girasolecarote piselli UOVA pastorizzateaceto di vino(SOLFITI)sale zucchero SENAPEsucco di limone addensanti: E412 E415;acidificante E270;conserv. E200 Gelatina 13%: acqua aceto di VINOgelatina animale saleesaltatore di sapidità E621conserv E202 MAIONESE(olio di semi di girasole UOVA pastorizzate aceto di VINO SENAPE succo di limone acidif.E270; sale zucchero conserv. E200.Preparazione alimentare a base di UOVA pasta di peperone (aceto di VINO). Può contenere: GLUTINE CROSTACEI PESCE SOIA LATTE MOLLUSCHI FRUTTA A GUSCIO SEDANO SEMI DI SESAMO.",
            "plu": "010819"
        },
        {
            "_id": "255083",
            "name": "KING S GRANSPECK AL KG",
            "ingredients": "INGREDIENTI:coscia di suinosale pepe zucchero spezie Conservante: E250E252 Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "015566"
        },
        {
            "_id": "255109",
            "name": "KING S PROSCIUTTO VAL LIONA KG",
            "ingredients": "INGREDIENTI:Coscia di suino sale zucchero pepe. Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "012078"
        },
        {
            "_id": "260901",
            "name": "CIOLI PORCHETTA TRANCIO",
            "ingredients": "INGREDIENTI: Carne di suino sale marino pepe nero aglio(SOLFITI)rosmarino. Può contenere GLUTINELATTE.",
            "plu": "010553"
        },
        {
            "_id": "263046",
            "name": "SENFTER PORCHETTA 1/2 SV AL KG",
            "ingredients": "INGREDIENTI:carne di suinospeziesale aromidestrosiopiante aromatiche fecola di patate Stabilizzante: E407E451 Antiossidante: E300 Conservante: E250 Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "012000"
        },
        {
            "_id": "292391",
            "name": "NONNO NANNI PRIMO SALE AL KG",
            "ingredients": "INGREDIENTI: LATTE pastorizzatosale caglio. Può contenere GLUTINE.",
            "plu": "010169"
        },
        {
            "_id": "300121",
            "name": "FRANCIA RIC.BUF.FUSCELLA AL KG",
            "ingredients": "INGREDIENTI: siero di LATTE di bufala pastorizzatoLATTE di bufala pastorizzato acido citrico sale. Può contenere: GLUTINE",
            "plu": "010165"
        },
        {
            "_id": "300460",
            "name": "FRANCIA RIC.PEC.FUSCELLA 1900G",
            "ingredients": "INGREDIENTI: Siero di LATTE ovino LATTE ovino pastorizzato sale. Può contenere GLUTINE.",
            "plu": "015869"
        },
        {
            "_id": "300462",
            "name": "FRANCIA CONFETTI VASCH.DA 3KG",
            "ingredients": "INGREDIENTI: LATTE pastorizzatosale coagulante vegetalefermenti lattici vivi. Può contenere: GLUTINE",
            "plu": "015877"
        },
        {
            "_id": "300463",
            "name": "FRANCIA NODINI SFUSI VASCH.3KG",
            "ingredients": "INGREDIENTI: LATTE pastorizzatosale coagulante vegetalefermenti lattici vivi. Può contenere: GLUTINE",
            "plu": "015878"
        },
        {
            "_id": "300597",
            "name": "FRANCIA CACIO SFUSO 1KG",
            "ingredients": "INGREDIENTI: LATTE pastorizzatosale coagulante microbicofermenti lattivi vivi. Può contenere: GLUTINE",
            "plu": "015867"
        },
        {
            "_id": "300598",
            "name": "FRANCIA CACIOC.AFF.1KG",
            "ingredients": "INGREDIENTI: LATTE pastorizzatosale coagulante microbicofermenti lattici vivi fumo liquido. Può contenere: GLUTINE",
            "plu": "015868"
        },
        {
            "_id": "300704",
            "name": "FRANCIA NOCC.POD.CIUF.POL5X50G",
            "ingredients": "INGREDIENTI: LATTE di bufala pastorizzato sale siero innesto naturale caglio. Può contenere: GLUTINE.",
            "plu": "015873"
        },
        {
            "_id": "300705",
            "name": "FRANCIA BOCC.POD.CIUF.POL2X125",
            "ingredients": "INGREDIENTI: LATTE di bufala pastorizzato sale siero innesto naturale caglio. Può contenere: GLUTINE.",
            "plu": "015875"
        },
        {
            "_id": "300706",
            "name": "FRANCIA OVOL.POD.CIUF.POL.KG",
            "ingredients": "INGREDIENTI: LATTE di bufala pastorizzato sale siero innesto naturale caglio. Può contenere: GLUTINE.",
            "plu": "015876"
        },
        {
            "_id": "301261",
            "name": "SAPOROSA NODINI VASCHETTA 3KG",
            "ingredients": "INGREDIENTI: LATTEsalecaglio correttore di acidità: E330. Può contenere GLUTINE.",
            "plu": "010002"
        },
        {
            "_id": "301371",
            "name": "DELIZIOSA DELICATA MIX S/L.KG",
            "ingredients": "INGREDIENTI:LATTE pastorizzato LATTE ovino pastorizzato 35% sale cagliofermenti lattici. Trattamento superficiale della crosta con E235  E200. Crosta non edibile. Può contenere GLUTINE.",
            "plu": "010140"
        },
        {
            "_id": "301550",
            "name": "PARMAR.P.REG.VAC.ROS.24M 1/8KG",
            "ingredients": "INGREDIENTI: LATTEsalecaglio. Può contenere:GLUTINE.",
            "plu": "010282"
        },
        {
            "_id": "302245",
            "name": "MONTRONE MOZZARELLA C/RIC.KG",
            "ingredients": "INGREDIENTI: LATTE crudo Siero di LATTE sale caglio correttore di acidità: acido citrico. Può contenere GLUTINE.",
            "plu": "010996"
        },
        {
            "_id": "302246",
            "name": "MONTRONE BURRATA AFFUMIC.AL KG",
            "ingredients": "INGREDIENTI:LATTE crudo PANNA sale caglio Correttore di acidità: Acido citrico Può contenere GLUTINE.",
            "plu": "010997"
        },
        {
            "_id": "361600",
            "name": "SAN FRANCESCO PR.CR.C/O AL KG",
            "ingredients": "INGREDIENTI: Carne suina Sale Conservante: Nitrato di potassio (E252). Può contenere: GLUTINE LATTE.",
            "plu": "010144"
        },
        {
            "_id": "367755",
            "name": "CIOLI COPPIETTE SUINO AL KG",
            "ingredients": "INGREDIENTI:Carne di suinosale pepe finocchio peperoncinoantiossidanti:E301 conservante: E250. Può contenere LATTE GLUTINE. Conservare tra 0 e 4°C.",
            "plu": "012004"
        },
        {
            "_id": "368613",
            "name": "VALTIDONE PANCETTA TESA AL KG",
            "ingredients": "INGREDIENTI:pancetta di suinosaledestrosio aromi naturali spezie Antiossidante:E300 Conservanti: E250E252 Puo contenere GLUTINELATTE. Origine suino: UE",
            "plu": "013885"
        },
        {
            "_id": "368639",
            "name": "VALTIDONE PANC.COTTA BACON KG",
            "ingredients": "INGREDIENTI:Pancetta di suino (90%) acqua saledestrosio amido di patata aromi aromi naturaliaromatizzanti di affumicatura gelificante: carragenina - E407correttore di acidità: acetato di sodio - E262antiossidante:ascorbato di sodio- E301 conservante:nitrito di sodio - E250 Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "013873"
        },
        {
            "_id": "373571",
            "name": "VOGLIAZZI INS.RUSSA GAMB.22KG",
            "ingredients": "INGREDIENTI: Insalata russa 61%: patate olio di semi di girasolecarote piselli UOVA pastorizzateaceto di vino(SOLFITI)sale zucchero SENAPEsucco di limone addensanti: E412 E415;acidificante E270;conserv. E200 Gelatina: acqua aceto di VINOgelatina animale saleacidif. E325;esaltatore di sapidità E621 conserv E202. GAMBERI in salamoia 11% MAIONESE(olio di semi di girasole UOVA pastorizzate 12%aceto di VINO SENAPE succo di limone acidif.E270; sale zucchero conserv. E200). Pasta ai peperoni: acqua peperoni gelatina animale aceto di VINO sale conserv. E202 correttori dacidità E330 antiossidante E220.Può contere: GLUTINE PESCE SOIALATTEMOLLUSCHIFRUTTA A GUSCIO SEDANO SEMI DI SESAMO.",
            "plu": "016257"
        },
        {
            "_id": "400780",
            "name": "DEL GIUDICE CACIO VASCH.1500GR",
            "ingredients": "INGREDIENTI: LATTE pastorizzato caglio sale fermenti lattici vivi. Può contenere: GLUTINE",
            "plu": "016340"
        },
        {
            "_id": "400782",
            "name": "DEL GIUDICE MOLIS.BIA.RET.A KG",
            "ingredients": "INGREDIENTI:LATTE pastorizzato caglio sale fermenti lattici vivi. Può contenere: GLUTINE",
            "plu": "016341"
        },
        {
            "_id": "400783",
            "name": "DEL GIUDICE MOLISANO AFF.AL KG",
            "ingredients": "INGREDIENTI:LATTE pastorizzato caglio sale fermenti lattici vivi aroma fumo. Può contenere: GLUTINE",
            "plu": "016342"
        },
        {
            "_id": "419853",
            "name": "BACCALA ISLANDA 1.7-2.7 (12)",
            "ingredients": "INGREDIENTI:MERLUZZO NORDICO (Gadus Morhua)sale Stabilizzanti ed addensanti:E450E451 Zona FAO27 attrezzo di pesca: ami e palangari",
            "plu": "016415"
        },
        {
            "_id": "419861",
            "name": "BACCALA ISLANDA 2.7-4 KG(12A)",
            "ingredients": "INGREDIENTI:MERLUZZO Nordico (Gadus Morhua)sale Stabilizzanti ed addensanti:E450E451 Zona FAO27 attrezzo di pesca: ami e palangari",
            "plu": "016416"
        },
        {
            "_id": "419879",
            "name": "BACCALA ISLANDA 4KG+ (12AA)",
            "ingredients": "INGREDIENTI:MERLUZZO NORDICO (Gadus Morhua)sale Zona FAO27 (Oceano Atlantico) Attrezzo di pesca: ami e palangari",
            "plu": "016417"
        },
        {
            "_id": "419887",
            "name": "BACCALA ISLANDA 1.2-1.7 (12M)",
            "ingredients": "INGREDIENTI:MERLUZZO NOTRDICO (Gadus Morhua)sale Zona FAO27 (Oceano Atlantico) Attrezzo di pesca: ami e palangari",
            "plu": "016418"
        },
        {
            "_id": "426544",
            "name": "FIORUCCI SALAME UNGHERESE 3KG",
            "ingredients": "INGREDIENTI:carne di suinosale destrosio aromi spezieLATTE scremato in polvere zuccheroaromatizzante di affumicatura Antiossidante: E301 Conservante: E250E252 Puo contenere GLUTINE Origine suino: UE",
            "plu": "014849"
        },
        {
            "_id": "439596",
            "name": "ANTICA C.VERO FORM.SCOPAROLO",
            "ingredients": "INGREDIENTI: LATTE ovino pastorizzato fermenti  lattici selezionati caglio (di vitello) sale. Trattato in superficie con olio di lino. Farina di riso sulla crosta Può contenere GLUTINE.",
            "plu": "016140"
        },
        {
            "_id": "441345",
            "name": "PARMIGIANO REGGIANO KG 1",
            "ingredients": "INGREDIENTI: LATTE sale caglio. Può contenere GLUTINE.",
            "plu": "011039"
        },
        {
            "_id": "441428",
            "name": "MONTI T.ASIAGO STAG.DOP AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccino caglio sale Conservante: lisozima da UOVA Trattamento in superficie:olio di lino Può contenere GLUTINE.",
            "plu": "015983"
        },
        {
            "_id": "446229",
            "name": "LE DELIZIE INS.MARE FANT.4000G",
            "ingredients": "INGREDIENTI: TOTANO gigante del Pacifico 72% surimi al sapore di GRANCHIO 22% (polpa di PESCE E420E452acquaalbume d UOVOAMIDOsalezucchero olio di colzaaroma (CROSTACEI) E621E631E635 amido modificatoproteina di SOIAE160cE120) MAZZANCOLLA tropicale 4% MOSCARDINI 2%acqua sale aceto di vino(SOLFITI)glucosio E575E330E211. Può contenere SEDANO. Provenieza: FAO 87-51-41",
            "plu": "015864"
        },
        {
            "_id": "447912",
            "name": "KING S CONF.REG.PARMA META KG",
            "ingredients": "INGREDIENTI: Coscia di suino sale marino. Può contenere LATTE GLUTINE.",
            "plu": "010195"
        },
        {
            "_id": "452649",
            "name": "FIORUCCI GUANCIALE AMATRIC.KG",
            "ingredients": "INGREDIENTI:carne suinasalespeziearomi naturali Conservante:nitrato di potassio E252 nitrito di sodio E250. Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "012024"
        },
        {
            "_id": "488247",
            "name": "MONT.PROSC CRUDO C/O AL KG",
            "ingredients": "INGREDIENTI: carne di suinosale Conservante:nitrato di potassio(E252) Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "012093"
        },
        {
            "_id": "500298",
            "name": "LA REPUB.LARDO DI COLONNATA",
            "ingredients": "INGREDIENTI:lardo suinosalepepeaglio(SOLFITI) rosmarino spezie erbe aromatiche. Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "011983"
        },
        {
            "_id": "501387",
            "name": "PIZZIGHETTONE PROVOLONE KG100",
            "ingredients": "INGREDIENTI: LATTE vaccinocaglio sale Conservante:E229 Conservante sulla crosta:E202 E235. Crosta non edibile Può contenere GLUTINE",
            "plu": "010800"
        },
        {
            "_id": "501791",
            "name": "SWITZERLAND EMMENTALER F.K100",
            "ingredients": "INGREDIENTI: LATTE vaccinoFERMENTI LATTICIsale Può contenere GLUTINE",
            "plu": "010569"
        },
        {
            "_id": "506220",
            "name": "FIORUCCI CARPACCIO MANZO SV KG",
            "ingredients": "INGREDIENTI: carne di bovinosale marino destrosioaromiaromatizzante di affumicatura Antiossidante: E301 Conservante: E251E252 Può contenere GLUTINELATTE",
            "plu": "015360"
        },
        {
            "_id": "507426",
            "name": "AGRITECH CIAB.GR.DURO KG (250)",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO INGREDIENTI:semola rimacinata di GRANO duro 29% farina di GRANO tenero TIPO 0olio doliva 18% acquasalelievito. Può contenere SOIA SEMI DI SESAMOSENAPE FRUTTA A GUSCIO LATTE UOVA. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto prontoforno congelato.",
            "plu": "016059"
        },
        {
            "_id": "513267",
            "name": "GOZZI PR.CRUDO SGAMBATO.S/OSSO",
            "ingredients": "INGREDIENTI:Coscia di suino sale aromi naturali spezie conservante: nitrato di potassio - E252 Origine suino: UE",
            "plu": "012075"
        },
        {
            "_id": "517532",
            "name": "KING S REBELLO AL KG",
            "ingredients": "INGREDIENTI: coscia di suino sale pepe Può contenere GLUTINELATTE Origine suino: UE",
            "plu": "012667"
        },
        {
            "_id": "517680",
            "name": "SALCIS CACIOTTA PEC.VIGNAIOLO",
            "ingredients": "INGREDIENTI:LATTE di pecora pastorizzato sale caglio vino rosso(SOLFITI) Conservante sulla crosta: E203E235 Può contenere GLUTINE.",
            "plu": "011002"
        },
        {
            "_id": "517698",
            "name": "SALCIS CACIOTTA PEC.AL TARTUF",
            "ingredients": "INGREDIENTI: LATTE di pecora pastorizzato tartufo 1% sale caglio.Trattato in superficie con E235 E202. Può contenere GLUTINE.",
            "plu": "011000"
        },
        {
            "_id": "517706",
            "name": "SALCIS CACIOTTA PEC.IN CENERE",
            "ingredients": "INGREDIENTI:LATTE di pecora pastorizzato sale caglio Conservante sulla crosta: E202 E235 Copertura: cenere di legno Può contenere GLUTINE",
            "plu": "011001"
        },
        {
            "_id": "523977",
            "name": "BAYERNLAND PAMIGO RIGATINO KG.",
            "ingredients": "INGREDIENTI: LATTE FERMENTI LATTICIsale caglio. Crosta non edibile Può contenere GLUTINE",
            "plu": "010858"
        },
        {
            "_id": "548206",
            "name": "LE DELIZIE FUNGHI GRIGLIATI KG",
            "ingredients": "INGREDIENTI: funghi (60%) olio di semi di girasole (40%) aceto di VINO bianco (SOLFITI) additivi: E330E270; sale spezie.",
            "plu": "010188"
        },
        {
            "_id": "554006",
            "name": "MONTELLO NONNO NANNI F.P10X200",
            "ingredients": "INGREDIENTI: LATTE pastorizzato crema di LATTE sale caglio FERMENTI LATTICI VIVI Può contenere GLUTINE.",
            "plu": "010968"
        },
        {
            "_id": "554774",
            "name": "ALCISA MORTADELLA 2TORRI ELITE",
            "ingredients": "INGREDIENTI: carne di suinosalearomi saccarosioPISTACCHIO (06%)spezie Antiossidante: E301 Conservante: E252 Esaltatore di sapidità:E621 Può contenere GLUTINE LATTE Origine suino: UE",
            "plu": "011636"
        },
        {
            "_id": "557157",
            "name": "MIRAGLIA OL.DENOC.COND.5KG",
            "ingredients": "INGREDIENTI: Olive verdi denocciolate acqua olio di girasole peperoni aceto di vino (SOLFITI) spezie disidratate sale E300 E330 Può contenere noccioli o tracce di essi.",
            "plu": "016010"
        },
        {
            "_id": "557165",
            "name": "MIRAGLIA OL.CAPRICCIOSE 5KG",
            "ingredients": "INGREDIENTI: Olive denocciolate verdifunghi champignonnameko pleurotus muschio carciofi. Può contenere SEDANO LUPINI FRUTTA A GUSCIO.",
            "plu": "010299"
        },
        {
            "_id": "576132",
            "name": "KING S CONF.S.DANIELE META KG",
            "ingredients": "INGREDIENTI: Coscia di suino sale marino. Può contenere LATTE GLUTINE.",
            "plu": "012675"
        },
        {
            "_id": "584821",
            "name": "CITTERIO SALAME MILANO AL KG",
            "ingredients": "INGREDIENTI: carne di suino sale zucchero pepe nitrato di potassio nitrito di sodio acido ascorbico ascorbato di sodio piante aromatiche. Può contenere GLUTINELATTE Origine suino: ITALIA",
            "plu": "010547"
        },
        {
            "_id": "598227",
            "name": "INVERNIZZI GIMAS TRONCHETTO KG",
            "ingredients": "INGREDIENTI: MASCARPONE 65% (PANNA LATTE correttore di acidità: acido citrico) GORGONZOLA DOP 35% (LATTEsalecaglio). Può contenere GLUTINE",
            "plu": "016250"
        },
        {
            "_id": "599787",
            "name": "GUSTO DECO PROSC PAR.18M C/O.K",
            "ingredients": "INGREDIENTI: coscia di suino sale Può contenere GLUTINELATTE",
            "plu": "012268"
        },
        {
            "_id": "622704",
            "name": "CIOLI SALSICCIA ST.SIENA SV",
            "ingredients": "INGREDIENTI: Carne di suino sale aromi destrosio saccarosio peperoncino antiossidanti:E300E301conservanti:E250E252. Può contenere LATTE GLUTINE. Conservare tra 0 e 4°C.",
            "plu": "012006"
        },
        {
            "_id": "648659",
            "name": "VERONI PROSCI.CORALLO ADDOBBO",
            "ingredients": "INGREDIENTI: carne di suino sale Conservante:nitrato di potassio (E252) nitrato di sodio (E250) Può contenere GLUTINELATTE Origine suino: UE",
            "plu": "012076"
        },
        {
            "_id": "649988",
            "name": "GOLOSITA SALS.NOST.DOLCE 350GR",
            "ingredients": "INGREDIENTI: Carne di suino (origine: UE) sale spezie destrosio aroma naturale aroma antiossidante: E301 conservanti: E250E252. Può contenere LATTE SOLFITIGLUTINE. Conservare tra 0 e 4°C.",
            "plu": "014084"
        },
        {
            "_id": "649996",
            "name": "GOLOSITA SALS.NOST.PICCA.350GR",
            "ingredients": "INGREDIENTI: Carne di suino (origine: UE) sale spezie destrosio aroma naturale peperoncino 006% aromaantiossidante: E301 conservanti: E250E252. Può contenere LATTE SOLFITIGLUTINE. Conservare tra 0 e 4°C.",
            "plu": "014085"
        },
        {
            "_id": "650002",
            "name": "GOLOSITA SALS.DIAVOL.SV 200GR",
            "ingredients": "INGREDIENTI:Carne di suino (Origine: UE) sale budello naturale spezie aromi naturali spezie aromi destrosio antiossidante:E301conservanti:E250E252. Può contenere LATTE GLUTINE Conservare tra 2 e 4°C",
            "plu": "014086"
        },
        {
            "_id": "650010",
            "name": "GOLOSITA SALS.ANGIOL.SV 200GR",
            "ingredients": "INGREDIENTI: Carne di suino (Origine:UE) sale budello naturale aromi naturali spezie destrosio antiossidante: E301 Conservanti: E250E252 Può contenere LATTEGLUTINE. Conservare tra 2 e 4°C",
            "plu": "014087"
        },
        {
            "_id": "650028",
            "name": "GOLOSITA SALAME NAPOLI 12KG",
            "ingredients": "INGREDIENTI:carne di suino saledestrosio saccarosioaromi naturali proteine del LATTE Antiossidante:E300 Conservante: E252E250 Può contenere GLUTINE Origine suino: UE",
            "plu": "014379"
        },
        {
            "_id": "650044",
            "name": "GOLOSITA PANC.ARR.S/COT.S/VUOT",
            "ingredients": "INGREDIENTI:carne di suino salearomidestrosio Antiossidante: E301 Conservante: E250 E252 Può contenere GLUTINELATTE Origine suino: ITALIA",
            "plu": "013482"
        },
        {
            "_id": "650051",
            "name": "GOLOSITA FILONE 1/2 SV 35KG",
            "ingredients": "INGREDIENTI:carne di suinosale aromidestrosio Antiossidante:E301 Conservanti: E250 E252 Puo contenere GLUTINELATTE Origine suino: UE",
            "plu": "015249"
        },
        {
            "_id": "650184",
            "name": "TOGIMA OLIVE VERDI 000 FUSTO",
            "ingredients": "INGREDIENTI:Olive acqua sale correttore di acidità: acido citrico - E330",
            "plu": "015990"
        },
        {
            "_id": "651786",
            "name": "PINNA CACIOT.BRIGANTE PEPER.KG",
            "ingredients": "INGREDIENTI: LATTE di pecora pastorizzato sale caglio (<1%)peperoncino rosso (max 1%) Conservante sulla crosta:E235 NO OGM. Può contenere GLUTINE.",
            "plu": "010710"
        },
        {
            "_id": "651794",
            "name": "PINNA CACIOT.BRIGANTE PEPE KG",
            "ingredients": "INGREDIENTI: LATTE di pecora pastorizzato caglio (<1%)salepepe in grani (max 1%) Conservante sulla crosta:E235 NO OGM.Può contenere GLUTINE.",
            "plu": "010711"
        },
        {
            "_id": "659755",
            "name": "MONT.PROSC CRUDO DISOSS.AL KG",
            "ingredients": "INGREDIENTI: carne di suino sale Conservante:nitrato di potassio(E252) Può contenere GLUTINE LATTE Origine suino: UE",
            "plu": "012082"
        },
        {
            "_id": "660829",
            "name": "SARDAFORMAG.RIC.MONTEL.SAL.KG",
            "ingredients": "INGREDIENTI: siero di LATTE di pecora pastorizzato sale Può contenere GLUTINE",
            "plu": "011089"
        },
        {
            "_id": "660852",
            "name": "DECO PROSC CRUDO STAG.DISOSS.",
            "ingredients": "INGREDIENTI: carne di suino sale Può contenere GLUTINE LATTE Origine suino: UE",
            "plu": "012071"
        },
        {
            "_id": "661868",
            "name": "SENFTER SPECK BACON 1/2 AL KG",
            "ingredients": "INGREDIENTI: carne suina (94%) acquasale spezie aromidestrosioaffumicatura naturale Stabilizzante:carragerina Antiossidante: E300 Conservante: E250 Può contenere GLUTINE LATTE Origine suino: UE",
            "plu": "015556"
        },
        {
            "_id": "661876",
            "name": "SENFTER LYONER FARCITO 1/2",
            "ingredients": "INGREDIENTI: carne e grasso di suinoolive peperoni aromidestrosio aroma di affumicaturasale spezie Stabilizzante: E407E451 Antiossidante:E300 Conservante: E250 Affumicatura naturale Può contenere GLUTINE LATTE Origine suino: UE",
            "plu": "011998"
        },
        {
            "_id": "664268",
            "name": "GUSTO DECO BRES.P.ANCA IGP SV",
            "ingredients": "INGREDIENTI:carne bovinasale destrosio aromi naturali Conservanti: E250 E251 Può contenere GLUTINE LATTE",
            "plu": "015359"
        },
        {
            "_id": "664276",
            "name": "GUSTO DECO ASIAGO PRES.DOP KG",
            "ingredients": "INGREDIENTI: LATTE vaccino sale caglio Conservante sulla crosta: E235 Può contenere GLUTINE",
            "plu": "010964"
        },
        {
            "_id": "665026",
            "name": "GUSTO DECO PROSC COT.NAZ.AQ KG",
            "ingredients": "INGREDIENTI:carne di suino sale aromi Conservante:nitrito di sodio E250. Antiossidante:ascorbato di sodio E301 Può contenere GLUTINELATTE Origine suino: ITALIA.",
            "plu": "013094"
        },
        {
            "_id": "665034",
            "name": "DECO PROSC COTTO SCELTO AL KG",
            "ingredients": "INGREDIENTI: coscia di suinoacqua sale aromidestrosioproteine del LATTE. Esaltatore di sapidità:E621. Conservante:E250 Antiossidante:E300E301 Può contenere GLUTINE Origine suino: UE",
            "plu": "013093"
        },
        {
            "_id": "665042",
            "name": "AGRITECH BAGUETTE 280GR",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO INGREDIENTI: farina di GRANO tenero Tipo 0 acqua sale lievito Può contenere SOIALATTESENAPE (compreso LATTOSIO)SESAMO e loro derivati. Prodotto parz.cotto congelato da non ricongelare Conservare a T-18°C.Consumare preferibilmente entro la data riportata in etichetta.Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può ssere ricongelato. Prodotto congelato allorigine da consumarsi solo previa cottura. Se cotto:ottenuto da prodotto prontoforno congelato.",
            "plu": "016055"
        },
        {
            "_id": "665588",
            "name": "PIAVE MEZZANO FORMAG.DOP AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccino sale caglio Conservante: Lisozima da UOVA. Crosta non edibile Può contenere GLUTINE",
            "plu": "010966"
        },
        {
            "_id": "666024",
            "name": "NEGRONI PROSCIUT.S DANIELE DIS",
            "ingredients": "INGREDIENTI:Coscia di suino italiano sale Può contenere GLUTINE LATTE",
            "plu": "012465"
        },
        {
            "_id": "666586",
            "name": "DECO SPECK SV AL KG",
            "ingredients": "INGREDIENTI: coscia suinosale spezie piante aromatiche Antiossidante: ascorbato di sodio (E301) Conservante: nitrito di sodio (E250) Affumicatura naturale Può contenere GLUTINE LATTE Origine suino: UE",
            "plu": "015561"
        },
        {
            "_id": "666875",
            "name": "GRAN MORAVIA FORME",
            "ingredients": "INGREDIENTI: LATTEsale caglio Conservante: E1105lisozima da UOVA Può contenere GLUTINE",
            "plu": "010971"
        },
        {
            "_id": "667212",
            "name": "GOLFERA IL GOLFETTA AL KG",
            "ingredients": "INGREDIENTI:carne di suino sale marino iodato LATTE scremato in polveredestrosio saccarosio spezie aromi Antiossidante: E301E300 Conservante: E252E250 Può contenere GLUTINE LATTE Origine suino: ITALIA",
            "plu": "012084"
        },
        {
            "_id": "668178",
            "name": "AMALATTEA FOR.CAPRA LAPRIMA 1K",
            "ingredients": "INGREDIENTI: LATTE di capra pastorizzato sale caglio caprino fermenti. Trattato in superficie con conservante E235. Crosta non edibile. Può contenere GLUTINE.",
            "plu": "010713"
        },
        {
            "_id": "668400",
            "name": "GRANATA OLIVE VERDI GIGAN.5KG",
            "ingredients": "INGREDIENTI:Olive verdi acqua sale correttore di acidità: E270E330 antiossidante: E300. Può contenere: SEDANOLUPINISOLFITI.",
            "plu": "015996"
        },
        {
            "_id": "668418",
            "name": "GRANATA OLIVE VERDI OOO 5KG",
            "ingredients": "INGREDIENTI: Olive verdi acqua sale correttore di acidità: E270E330 antiossidante: E300. Può contenere: SEDANOLUPINISOLFITI.",
            "plu": "015995"
        },
        {
            "_id": "668426",
            "name": "GRANATA OLIVE VERDI TAGL.5KG",
            "ingredients": "INGREDIENTI:Olive verdi acqua sale correttore di acidità: E270E330 antiossidante: E300. Può contenere: SEDANOLUPINISOLFITI.",
            "plu": "015999"
        },
        {
            "_id": "668434",
            "name": "GRANATA OLIVE NERE RIVIERA 5KG",
            "ingredients": "INGREDIENTI:Olive acqua sale correttore di acidità: E270. Può contenere: SEDANOLUPINI SOLFITI.",
            "plu": "015997"
        },
        {
            "_id": "668442",
            "name": "GRANATA OLIV.NERE PASS.EGIT.5K",
            "ingredients": "INGREDIENTI:Olive acqua sale correttore di acidità: E270. Può contenere: SEDANOLUPINI SOLFITI.",
            "plu": "015998"
        },
        {
            "_id": "668574",
            "name": "GUSTO DECO MORTAD.IGP C/PIS.KG",
            "ingredients": "INGREDIENTI: carne di suinotrippinosale saccarosioPISTACCHI (06%)speziearomi Antiossidante:E301 Conservante:E250 Può contenere GLUTINE LATTE",
            "plu": "011639"
        },
        {
            "_id": "671941",
            "name": "DECO PASTA FILATA 5 KG AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccinosalecaglio FERMENTI LATTICI Può contenere GLUTINE",
            "plu": "010803"
        },
        {
            "_id": "683136",
            "name": "SCHETTINO CICCIOLI 1/4 SV KG",
            "ingredients": "INGREDIENTI:suino sale aromi. Conservante: nitrito di sodio (E250) Può contenere GLUTINE LATTE Origine suino: UE",
            "plu": "013272"
        },
        {
            "_id": "696005",
            "name": "GOLOSITA SALS.CASERECCIA PICC",
            "ingredients": "INGREDIENTI:Carne di suino sale spezie destrosio peperoncino (0.6%)aroma naturale aromi. Antiossidante: E301. Conservanti: E250 E252 Origine suino: UE",
            "plu": "014100"
        },
        {
            "_id": "696302",
            "name": "GOLOSITA CAPOCOLLO STAG.AL KG",
            "ingredients": "INGREDIENTI: carne di suinosalearomidestrosio Antiossidante: E301 Conservante: E250 E252 Può contenere GLUTINE LATTE Origine suino: UE",
            "plu": "011841"
        },
        {
            "_id": "696344",
            "name": "GOLOSITA SALS.CASERECCIA DOLCE",
            "ingredients": "INGREDIENTI:Carne di suino  sale spezie destrosio aroma naturale aroma. Antiossidante: E301. Conservanti: E250 E252. Origine suino: UE",
            "plu": "014104"
        },
        {
            "_id": "699165",
            "name": "MIRAGLIA OL.NERE DENOC.SAL.5KG",
            "ingredients": "Olive nere acqua sale. Antiossidanti: E300 E330. Può contenere: MANDORLELUPINISEDANO. Può contenere noccioli o tracce di essi.",
            "plu": "011418"
        },
        {
            "_id": "734848",
            "name": "GOLOSITA SALAME VENTRICINA 1/2",
            "ingredients": "INGREDIENTI: Carne di suino (Origine UE) sale peperoncino 12% spezie destrosio aroma naturale antiossidante:E301 conservanti: E250E252. Budello non edibile. Può contenere SOLFITI LATTE GLUTINE. Conservare tra 0 e +4°C.",
            "plu": "011564"
        },
        {
            "_id": "740738",
            "name": "GALBANI SALAME UNGHERESE AL KG",
            "ingredients": "INGREDIENTI:Carne di suinosaledestrosioaromi aromatizzanti di affumicatura spezie. Conservanti:nitrato di potassio (E252) nitrito di sodio (E250) Può contenere GLUTINE  LATTE Origine suino: UE",
            "plu": "014855"
        },
        {
            "_id": "743121",
            "name": "VALTIDONE PANCETTA ARROTOLATA",
            "ingredients": "INGREDIENTI: Pancetta di suino sale destrosio aromispezie.Antiossidante: E301. Conservanti: E250 E252. Può contenere LATTEGLUTINE. Conservare max a 12°C Origine suino: UE",
            "plu": "013469"
        },
        {
            "_id": "745051",
            "name": "GALBANI CERTOSA AL KG",
            "ingredients": "INGREDIENTI:LATTEsaleenzimi coagulanti del LATTE FERMENTI LATTICI VIVI Può contenere GLUTINE",
            "plu": "011446"
        },
        {
            "_id": "745565",
            "name": "CARSODO SALAME SPAGNOLO PEPE",
            "ingredients": "INGREDIENTI:carne e grasso di suinosalearomi proteine del LATTE e della SOIAfarina di riso zucchero speziedestrina patata LATTOSIO Origine suino: UE Acidificante:E331 Esaltatore di sapidità: E621 Conservanti: E252E250E262 Coloranti: E120E160c Stabilizzanti:E450E451 Antiossidanti:E301 Rivestimento superficiale:spezie Trattamento di superficie: E235 Può contenere GLUTINE",
            "plu": "012051"
        },
        {
            "_id": "745567",
            "name": "CARSODO SALAME SPAGNOLO ERBE",
            "ingredients": "INGREDIENTI:carne e grasso di suinosaleLATTOSIO proteine del LATTE e della SOIAfarina di riso zuccherospeziedestrina patataaromi.Origine suino:UE Acidificante:E331 Stabilizzanti:E450E451 Antiossidanti:E301 Esaltatore di sapidità:E621 Conservanti:E250 E252E262 Coloranti:E120E160c Rivestimento superficiale:spezie Trattamento di superficie:E235 Può contenere GLUTINE",
            "plu": "012050"
        },
        {
            "_id": "746145",
            "name": "AGRITECH CIAB.GR.TENERO 100GR",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO INGREDIENTI:farina di GRANO tenero TIPO 0 olio doliva 07%acquasalelievito. Può contenere SOIALATTESESAMOSENAPE FRUTTA A GUSCIO(NOCI) e loro derivati. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato",
            "plu": "016064"
        },
        {
            "_id": "746146",
            "name": "LANTERNA CIAB.PATATA AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: farina di GRANO Tenero Tipo 0 acqua farina di patate 7%LATTE in polvere farina di FRUMENTOzuccherosale lievito spezie (curcuma). Può contenere SOIA SESAMOSENAPE. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato",
            "plu": "016065"
        },
        {
            "_id": "746147",
            "name": "AGRITECH BAGUETTE CEREALI 250G",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO INGREDIENTI: farina di GRANO Tenero Tipo 0 acqua mix di semi 7%(lino brunoSESAMOgirasole lino giallograno saracenomiglio)semola di GRANO duro sale lievito di SEGALEfarine di:(SEGALE grano saracenomaisORZOAVENAORZO maltato) Emulsionante:E477 Agenti di trattamento: E300 Può contenere derivati del LATTE FRUTTA A GUSCIO SOIASENAPE. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato",
            "plu": "016063"
        },
        {
            "_id": "746203",
            "name": "FORTE PANE FILONE SFUSO DA 3KG",
            "ingredients": "INGREDIENTI:Semola rimacinata di GRANO duro acqua lievito madre 10% (Semola rimacinata di GRANO duro acqua) sale lievito. Può contenere SOIA SENAPE.",
            "plu": "016067"
        },
        {
            "_id": "746204",
            "name": "FORTE PANE FILONE SFUSO DA 5KG",
            "ingredients": "INGREDIENTI:Semola rimacinata di GRANO duro 65% acqua lievito madre 10% (Semola rimacinata di GRANO duro acqua) sale lievito. Può contenere SOIA SENAPE.",
            "plu": "016068"
        },
        {
            "_id": "746513",
            "name": "NEGRONI COPPA DI ZIBELLO kg",
            "ingredients": "INGREDIENTI:Carne suina sale saccarosio vino(SOLFITI)antioss:ascorbato di sodio-E301; spezie e piante aromatiche conservanti: nitrato di potassio - E252 nitrito di sodio-E250 Può contenere GLUTINE Origine suino: UE",
            "plu": "011845"
        },
        {
            "_id": "746673",
            "name": "VIANI PROSC.TOSCANO C/O DOP.KG",
            "ingredients": "INGREDIENTI: Coscia di suino (Origine Italia) sale pepe aromi naturali. Può contenere GLUTINELATTE.",
            "plu": "012672"
        },
        {
            "_id": "746735",
            "name": "BLD OCCHIATO BAV.BERGH.C.A28K",
            "ingredients": "INGREDIENTI: LATTE vaccino sale fermenti lattici caglio microbico. Può contenere GLUTINE",
            "plu": "010581"
        },
        {
            "_id": "748560",
            "name": "FIORUCCI SALAME DI PROSCIUT.KG",
            "ingredients": "INGREDIENTI:carne di suinosale spezie LATTE scremato in polverearomi naturali destrosio zucchero Antiossidante: E301 Conservanti: E251E250 Può contenere GLUTINE Origine suino: UE",
            "plu": "012001"
        },
        {
            "_id": "748570",
            "name": "BUSTI MARZOLINO TOSCANO AL KG",
            "ingredients": "INGREDIENTI: LATTE ovino pastorizzatosale FERMENTI LATTICI caglio Trattamento in superficie:pomodoro concentrato E235E202 Può contenere GLUTINE",
            "plu": "011091"
        },
        {
            "_id": "748571",
            "name": "BUSTI PECORINO PIENZA AL KG.",
            "ingredients": "INGREDIENTI: LATTE ovino pastorizzatosale FERMENTI LATTICIcaglio Trattamento in superfice:pomodoro concentrato E235E202 Può contenere GLUTINE",
            "plu": "011092"
        },
        {
            "_id": "748572",
            "name": "BUSTI PECORINO TOSCANO DOP KG",
            "ingredients": "INGREDIENTI: LATTE ovino pastorizzato FERMENTI AUTOCTONIsalecaglio Trattamento in superfice:olio di oliva E202E235 Può contenere GLUTINE",
            "plu": "011093"
        },
        {
            "_id": "749539",
            "name": "F.CREM.PROV.PICC.SV 1/2CIL.5K",
            "ingredients": "INGREDIENTI:LATTE vaccino sale caglio conservanti in crosta:Natamicina - E235 Sorbato di potassio - E202 cera microcristallina - E905 Può contenere GLUTINE.",
            "plu": "010217"
        },
        {
            "_id": "749824",
            "name": "NONNO NANNI FRESCO RUCOLA A KG",
            "ingredients": "INGREDIENTI: LATTE pastorizzato sale caglio rucola fresca 1% conservante: sorbato di potassio. Può contenere GLUTINE.",
            "plu": "011525"
        },
        {
            "_id": "750693",
            "name": "MIRAGLIA ALICI SALATE II BARRA",
            "ingredients": "INGREDIENTI:ACCIUGHE (Engraulis encrasicolus) sale salamoia",
            "plu": "016350"
        },
        {
            "_id": "751044",
            "name": "PARMIG.R.RAZZA BRUNA OLTRE 24M",
            "ingredients": "INGREDIENTI: LATTE vaccinosale caglio. Puo contenere GLUTINE.",
            "plu": "010311"
        },
        {
            "_id": "751381",
            "name": "GUSTO DECO PROSC S.DANIELE C/O",
            "ingredients": "INGREDIENTI: coscia di suino sale Può contenere GLUTINELATTE",
            "plu": "012468"
        },
        {
            "_id": "751388",
            "name": "HAPPY H.LUPINI SAL.GR.00 SG.6K",
            "ingredients": "INGREDIENTI: LUPINI sale antiossidante: E330",
            "plu": "016202"
        },
        {
            "_id": "752506",
            "name": "FORTE PANE ALTAMURA DOP 500GR",
            "ingredients": "INGREDIENTI:Semola rimacinata di GRANO duro acqua lievito madre 10% (Semola rimacinata di GRANO duro acqua) sale lievito. Può contenere SOIA SENAPE.",
            "plu": "016086"
        },
        {
            "_id": "752644",
            "name": "FORTE PANE ALTAMURA DOP 1000GR",
            "ingredients": "INGREDIENTI:Semola rimacinata di GRANO duro acqua lievito madre 10% (Semola rimacinata di GRANO duro acqua) sale lievito. Può contenere SOIA SENAPE.",
            "plu": "016085"
        },
        {
            "_id": "752645",
            "name": "FORTE PANE BASSO SFUSO 500GR",
            "ingredients": "INGREDIENTI:Semola rimacinata di GRANO duro acqua lievito madre 10% (Semola rimacinata di GRANO duro acqua) sale lievito. Può contenere SOIA SENAPE.",
            "plu": "016088"
        },
        {
            "_id": "752871",
            "name": "IAQUILAT IL GROTTONE OCCHIATO",
            "ingredients": "INGREDIENTI: LATTE vaccino caglio sale lisozima da UOVA FERMENTI LATTICI Può contenere GLUTINE",
            "plu": "010809"
        },
        {
            "_id": "752909",
            "name": "BUSTI PECORINO RONCIONE AL KG.",
            "ingredients": "INGREDIENTI:LATTE ovino crudo sale di Volterra caglio e fermenti lattici. Trattato in superficie con conservanti E235 E202 paglia. Può contenere GLUTINE.",
            "plu": "015777"
        },
        {
            "_id": "753667",
            "name": "MIRAGLIA PASSOLONI CONDITI KG",
            "ingredients": "INGREDIENTI: Olive nere capperi olio di girasole origano peperoncino AGLIO. Può contenere: MANDORLE LUPINI Provenienza: ITALIA.",
            "plu": "016007"
        },
        {
            "_id": "753668",
            "name": "MIRAGLIA PICCANTONI CAMPANI KG",
            "ingredients": "INGREDIENTI:Olive denocciolate peperoncini rossi olio di semi extravergine di oliva aceto di VINO  acqua sale spezie disidratate (AGLIO origano peperoncino) antiossidanti: acido ascorbico - E300 acido cirico - E330. Può contenere tracce di MANDORLE LUPINI SEDANO.",
            "plu": "016006"
        },
        {
            "_id": "755034",
            "name": "PIZZIGHETTONE PROVOLONE 5KG",
            "ingredients": "Ingredienti: LATTE sale caglio conservante E239 - crosta non commestibile - trattato in superficie con conservanti (E202 E203 E235) Può contenere GLUTINE",
            "plu": "010221"
        },
        {
            "_id": "755818",
            "name": "MONT.STUCCATI CON PEPERONCINO",
            "ingredients": "INGREDIENTI: Coscia di suino (Origine: UE) sale conservante: E252. Può contenere LATTE. Conservare a temperatura inferiore a 10°C.",
            "plu": "012085"
        },
        {
            "_id": "755877",
            "name": "RENZINI PR.CRUDO LA SELLA M.D.",
            "ingredients": "INGREDIENTI:coscia di suino (origine: UE) sale Aceto Balsamico di Modena IGP 1% (aceto di vino mosto duva ) destrosio saccarosio spezie in variabile (02%) aromi naturali conservante(E250 E252)antiossidante (E301). Può contenere SOLFITI LATTE GLUTINE.",
            "plu": "012087"
        },
        {
            "_id": "756185",
            "name": "ARINGHE DORATE AFFUMICATE KG",
            "ingredients": "INGREDIENTI: ARINGHE (Clupea Harengus FAO 27) sale fumo naturale di faggio Conservare tra 0° e +4°C. Da consumare previa cottura.",
            "plu": "015804"
        },
        {
            "_id": "756200",
            "name": "AMALATTEA RICOTTA DI CAPRA KG",
            "ingredients": "INGREDIENTI: Siero di LATTE di capra LATTE di capra crema di LATTE di capra sale. Può contenere GLUTINE.",
            "plu": "010419"
        },
        {
            "_id": "756225",
            "name": "GRANATA PAPACCELLE ACETO 4 KG",
            "ingredients": "INGREDIENTI: Peperoniaceto di vino(SOLFITI) acqua sale. Può contenere:LATTE GLUTINE PESCE SEDANO LUPINI.",
            "plu": "011038"
        },
        {
            "_id": "756226",
            "name": "GRANATA PEPERONI PIC.ACETO 4KG",
            "ingredients": "INGREDIENTI: Cerasiniaceto di vino(SOLFITI) acqua sale. Può contenere:LATTE GLUTINE PESCE SEDANO LUPINI.",
            "plu": "011037"
        },
        {
            "_id": "756768",
            "name": "SAN VINC.CAPOCOLLO PICCANTE KG",
            "ingredients": "INGREDIENTI: Carne di suino sale spezie (tra cui peperoncino di Calabria 2%) destrosio saccarosio aromi aromatizzanti di affumicatura antiossidante: acido ascorbico - E300 conservanti: nitrato di potassio - E252 nitrito di sodio - E250. Può contenere GLUTINE LATTE ed ANIDRIDE SOLFOROSA Origine suino: UE",
            "plu": "011838"
        },
        {
            "_id": "756769",
            "name": "SAN VINC.SPIANATA PICCANTE KG",
            "ingredients": "INGREDIENTI:Carne di suino concentrato di peperoncino piccante ( peperoncino piccante calabrese (98%)sale (2%) ) sale aromi destrosio saccarosio spezie ( di cui peperoncino piccante calabrese (02%) ) aromatizzanti di affumicatura antiossidante: acido ascorbico - E300conservanti: conservanti: nitrito di sodio - E250 nitrato di potassio - E252. Può contenere GLUTINELATTE e ANIDRIDE SOLFOROSA Origine suino: UE",
            "plu": "011837"
        },
        {
            "_id": "756881",
            "name": "GRANA PAD.ORO DEL TEMPO FORME",
            "ingredients": "INGREDIENTI: LATTE sale caglio conservante: lisozima da UOVA. Può contenere GLUTINE.",
            "plu": "010357"
        },
        {
            "_id": "757515",
            "name": "AMADORI GRANROSTY FESA TACC.",
            "ingredients": "INGREDIENTI:Fesa di tacchino 75%acquaamido di patata aromi naturali sale destrosio antiossidante: ascorbato di sodio conservante: nitrito di sodio. Può contenere LATTE GLUTINE.",
            "plu": "010151"
        },
        {
            "_id": "757614",
            "name": "PINNA CACIOT.BRIGANTE S/LATT.",
            "ingredients": "INGREDIENTI: LATTE di pecorasale caglio Può contenere GLUTINE",
            "plu": "011447"
        },
        {
            "_id": "757681",
            "name": "PANE AL FORMAGGIO AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: FARINA(FRUMENTO)FORMAGGIO acqualievitosaleGLUTINE DI FRUMENTO acido ascorbico. Può contenere SESAMOSOIANOCI PECAN UOVA PISTACCHI. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C. Consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016084"
        },
        {
            "_id": "757858",
            "name": "RECCO PROVOL.PICCANTE 24M.30KG",
            "ingredients": "INGREDIENTI:LATTEsalecaglio. Può contenere GLUTINE",
            "plu": "010155"
        },
        {
            "_id": "758113",
            "name": "RENZINI TACCHINO IN PORCHET.KG",
            "ingredients": "INGREDIENTI:carne di tacchino 97%sale spezie 07% (rosmarinoaglio(SOLFITI)pepe finocchio in proporzione variabile) Può contenere GLUTINELATTE",
            "plu": "015050"
        },
        {
            "_id": "759417",
            "name": "GRANATA OLIVE VERDI DEN.ISC.3K",
            "ingredients": "INGREDIENTI:Olive verdi etnee denocciolate (90%) peperoni a filetto rossi olio di semi di girasole aglio(SOLFITI)finocchietto peperoncino origano prezzemolo basilico aromi naturali correttore di acidità E270antiossidante E300. Può contenere: SEDANO LUPINISOLFITI.",
            "plu": "015944"
        },
        {
            "_id": "759420",
            "name": "GRANATA OL.NERE DEN.COND.NAP3K",
            "ingredients": "INGREDIENTI:Olive nere denocciolate (85%) peperoni piccanti a rondelle olio di semi di girasole aglio(SOLFITI) finocchiettopeperoncinoaromi naturali correttore di acidità: E270. Può contenere: SEDANOLUPINI.",
            "plu": "015945"
        },
        {
            "_id": "759800",
            "name": "ZORBAS CREMA YOG.GRECO AL KG",
            "ingredients": "INGREDIENTI: LATTE pastorizzato crema di LATTE fermenti lattici vivi. ORIGINE DEL LATTE: GRECIA Conservare tra 2 e 6 gradi C",
            "plu": "016338"
        },
        {
            "_id": "759804",
            "name": "GUSTO DECO PR.NORCIA IGP18M KG",
            "ingredients": "INGREDIENTI: coscia suinosalepepe(02%) Può contenere GLUTINELATTE",
            "plu": "012664"
        },
        {
            "_id": "760086",
            "name": "PANE DI SAN SEBASTIANO AL KG",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: farina di FRUMENTO tipo 00 acqua lievito naturale (GLUTINE) sale lievito di birra. Può contenere SOIA SESAMO SENAPE NOCI MANDORLE NOCCIOLE LATTE UOVA. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016092"
        },
        {
            "_id": "760785",
            "name": "PRESIDENT EMMENTAL FORMA 35K",
            "ingredients": "INGREDIENTI: LATTE vaccinosaleFERMENTI LATTICI caglio Può contenere GLUTINE",
            "plu": "010868"
        },
        {
            "_id": "761242",
            "name": "BUSTI PECORINO METELLO AL KG",
            "ingredients": "INGREDIENTI:LATTE ovino pastorizzato salecaglioFERMENTI LATTICI Trattato in superficie con conservanti E235 E202 e bucce di castagne. Può contenere GLUTINE",
            "plu": "011151"
        },
        {
            "_id": "763218",
            "name": "VOGLIAZZI INSALATA RUSSA 32KG",
            "ingredients": "INGREDIENTI: Ortaggi cotti in proporzione variabile 63% (patate carote piselli acqua aceto (SOLFITI) sale correttore di acidità: E270). MAIONESE 37% oli vegetali (colza soia mais semi di girasole) acqua UOVO pastorizzato aceto di vino (SOLFITI) sale SENAPE succo di limone zucchero acidificante: E270 addensanti: E415  E1442 conservante: E200  Può contenere CROSTACEI PESCE FRUTTA A GUSCIO (NOCI ANACARDI) SEDANO.",
            "plu": "010638"
        },
        {
            "_id": "770127",
            "name": "RENZINI PORCHETTA UMBRA AL KG",
            "ingredients": "INGREDIENTI: carne di suino sale spezie (aglio(SOLFITI)pepe finocchiorosmarino) Può contenere LATTEGLUTINE",
            "plu": "015558"
        },
        {
            "_id": "772030",
            "name": "BERETTA PROS.CARPEGNA C/O.DOP",
            "ingredients": "INGREDIENTI: Coscia di suino sale. Può contenere LATTEGLUTINE.",
            "plu": "016298"
        },
        {
            "_id": "773751",
            "name": "TRECCIONE BUFALA CAMP.DOP.1KG",
            "ingredients": "INGREDIENTI: LATTE bufalino fresco pastorizzato siero innesto naturale sale caglio. Può contenere: GLUTINE",
            "plu": "010285"
        },
        {
            "_id": "773752",
            "name": "TRECCIONE BUFALA CAMP.DOP.2KG",
            "ingredients": "INGREDIENTI: LATTE bufalino fresco pastorizzato siero innesto naturale sale caglio. Può contenere: GLUTINE",
            "plu": "010286"
        },
        {
            "_id": "775995",
            "name": "DELIZIOSA CACIOC.SIL.DOP 15KG",
            "ingredients": "INGREDIENTI: LATTE caglio sale. Può contenere GLUTINE.",
            "plu": "011044"
        },
        {
            "_id": "775996",
            "name": "DELIZIOSA STRACCIATELLA AL KG",
            "ingredients": "INGREDIENTI:LATTE pastorizzato PANNA UHT 45% correttore di acidità: acido lattico sale caglio. Può contenere GLUTINE.",
            "plu": "011045"
        },
        {
            "_id": "775997",
            "name": "DELIZIOSA GIUNCATA AL KG",
            "ingredients": "INGREDIENTI:LATTE pastorizzato sale caglio fermenti lattici. Può contenere GLUTINE.",
            "plu": "011046"
        },
        {
            "_id": "775998",
            "name": "DELIZIOSA BURRATA 100GR",
            "ingredients": "INGREDIENTI:LATTE pastorizzato PANNA UHT 41% correttore di acidità:acido lattico sale caglio. Può contenere GLUTINE.",
            "plu": "011047"
        },
        {
            "_id": "776000",
            "name": "DELIZIOSA CACIOC.STAG.GROT.KG",
            "ingredients": "INGREDIENTI:LATTE SIERO innesto naturale sale caglio. Crosta non edibile. Può contenere: GLUTINE",
            "plu": "011048"
        },
        {
            "_id": "776001",
            "name": "DELIZIOSA PECORINO D ANGIO 2KG",
            "ingredients": "INGREDIENTI: LATTE ovino pastorizzatocaglio sale fermenti lattici. Trattamento superficiale della crosta: conservanti: E 235E 200. Crosta non edibile. Può contenere: GLUTINE",
            "plu": "011049"
        },
        {
            "_id": "776002",
            "name": "DELIZIOSA PEC.D ANGIO GROT.2KG",
            "ingredients": "INGREDIENTI: LATTE ovino pastorizzatocaglio sale fermenti lattici. Crosta non edibile. Può contenere: GLUTINE",
            "plu": "011050"
        },
        {
            "_id": "776003",
            "name": "DELIZIOSA TRULLOCCH.GROT.4KG",
            "ingredients": "INGREDIENTI: LATTE sale caglio fermenti lattici. Crosta non edibile. Può contenere GLUTINE.",
            "plu": "011051"
        },
        {
            "_id": "840009",
            "name": "NEGRONI PANCETTA ZIBELLO AL KG",
            "ingredients": "INGREDIENTI:Pancetta suina sale saccarosio spezie e piante aromatiche conservanti: nitrato di potassio - E252 nitrito di sodio - E250 Può contenere LATTEGLUTINE Origine suino: UE",
            "plu": "013870"
        },
        {
            "_id": "844233",
            "name": "TOGIMA PEPERONI IN ACETO KG.5",
            "ingredients": "INGREDIENTI:peperoni aceto (SOLFITI) acqua sale acido citricoANIDRIDE SOLFOROSA",
            "plu": "016004"
        },
        {
            "_id": "845354",
            "name": "TOGIMA PEPERONI FORTI ACETO",
            "ingredients": "INGREDIENTI:Peperoni ACETO bianco(SOLFITI) acqua sale correttore di acidità: E330 conservante: E220",
            "plu": "016005"
        },
        {
            "_id": "846709",
            "name": "GUSTO DECO SALAME NAPO.800G CA",
            "ingredients": "INGREDIENTI:carne suinosale spezie LATTE in polvere scrematosaccarosio Conservante: E250E252 Antiossidante: E300 Può contenere GLUTINE Origine suino: UE",
            "plu": "014388"
        },
        {
            "_id": "852418",
            "name": "ENTREMONT BRIE 60% AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccino saleFERMENTI LATTICI caglio Può contenere GLUTINE",
            "plu": "010869"
        },
        {
            "_id": "854703",
            "name": "FIORUCCI ARROSTO DI POLLO KG",
            "ingredients": "INGREDIENTI:Carne di pollo(85%)acquasale destrosiozuccheroaromi. Origine suino: UE Stabilizzanti:E450E451E407 Antiossidanti:E316E300 Correttore di acidità:E331 Conservante: E250 Può contenere LATTEGLUTINE",
            "plu": "012002"
        },
        {
            "_id": "856484",
            "name": "LANTERNA BASTONCINO OLIVE 100G",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: farina di GRANO tenero Tipo 0 acquaolive verdi 26%strutto3%lievito di birra salefarina di FRUMENTO maltato. Può contenere LATTE(compreso LATTOSIO)SENAPE SESAMOSOIAFRUTTA A GUSCIO(NOCI) e loro derivati. Può contenere noccioli di oliva o parti di essi. Prodotto parz.cotto congelato da non ricongelare Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto Prontoforno Congelato.",
            "plu": "016058"
        },
        {
            "_id": "868570",
            "name": "MANTUANELLA FORMA AL KG",
            "ingredients": "INGREDIENTI: LATTE vaccino parzialmente scremato; caglio di vitello; sale;Lisozima da UOVA. Può contenere GLUTINE.",
            "plu": "010983"
        },
        {
            "_id": "871293",
            "name": "DEL GIUDICE SCAM/TREC.PASS.KG",
            "ingredients": "INGREDIENTI:LATTE pastorizzato caglio sale fermenti lattici vivi. Può contenere: GLUTINE",
            "plu": "010113"
        },
        {
            "_id": "871301",
            "name": "ORSETTO DI SUINO AL KG",
            "ingredients": "INGREDIENTI:Carne di suino(78%)acquasale iodato caramellospezieestratti di spezie Colorante: carminio di cocciniglia Stabilizzante: difosfato Antiossidante:E300 Conservante: E250 Può contenere LATTEGLUTINE Origine suino: UE",
            "plu": "013078"
        },
        {
            "_id": "874925",
            "name": "T.DUCALI STROLGHINO SALAME KG",
            "ingredients": "INGREDIENTI:carne suinosale aromi naturali destrosiospezie saccarosio Conservante: E250E252 Antiossidante: E301 Può contenere LATTEGLUTINE Origine suino: ITALIA",
            "plu": "012029"
        },
        {
            "_id": "883520",
            "name": "SARDAFOR.RIC.MONTEL.SALATA KG",
            "ingredients": "INGREDIENTI: siero di LATTE di pecora pastorizzato sale Può contenere GLUTINE",
            "plu": "010461"
        },
        {
            "_id": "886093",
            "name": "VIANI LOMBO ARROSTO AL KG",
            "ingredients": "INGREDIENTI: carne suina (78%)acquasale aromi naturaliproteine del LATTEdestrosio saccarosiomaltodestrine Esaltatore di sapidità:E621 Antiossidante:E301 Conservante:E250 Addensante:carregenina Può contenere GLUTINE Origine suino: UE",
            "plu": "012027"
        },
        {
            "_id": "886101",
            "name": "VIANI COPPA DI TESTA JUTA KG",
            "ingredients": "INGREDIENTI:Carne di suino Magro Suino di Testa sale limone aglio (contiene SOLFITI) vino bianco(SOLFITI)aromi naturali Spezie. Antiossidante: Ascorbato di Sodio. Conservante: Nitrito di Sodio. Può contenere LATTEGLUTINE. Origine suino: Italia",
            "plu": "012026"
        },
        {
            "_id": "886119",
            "name": "VIANI PETTO TACC.FORNO AL KG",
            "ingredients": "INGREDIENTI:carne di tacchino(85%)acquasale amidi modificatiaromidestrosio saccarosio Stabilizzante:E451E407 Antiossidante:E301 Conservante:E250 Può contenere LATTEGLUTINE",
            "plu": "012028"
        },
        {
            "_id": "887984",
            "name": "AMATRICE PECORINO GORZANO KG",
            "ingredients": "INGREDIENTI: LATTE ovino italiano pastorizzato caglio saleFERMENTI LATTICI Trattato in superficie con olio di semi di girasole e aceto duva bianco (SOLFITI). Può contenere GLUTINEFRUTTA A GUSCIO.",
            "plu": "010734"
        },
        {
            "_id": "888453",
            "name": "IGOR GONGORZOLA&MASCARPONE KG",
            "ingredients": "INGREDIENTI:MASCARPONE 60% (crema di LATTE correttore di acidità: acido citrico) GORGONZOLA 40% ( LATTE sale caglio) OGM FREE Può contenere GLUTINE.",
            "plu": "010492"
        },
        {
            "_id": "888461",
            "name": "IGOR GORGONZOLA DOLCE 1/8 KG.",
            "ingredients": "INGREDIENTI: LATTE vaccino (9826%)FERMENTI LATTICI caglio muffe (penicillium)sale marino Può contenere GLUTINE.",
            "plu": "010491"
        },
        {
            "_id": "892307",
            "name": "SARDA RIC.MONTELLA STAG.PIC.KG",
            "ingredients": "INGREDIENTI: siero di LATTE di pecora pastorizzatosale peperoncino Può contenere GLUTINE.",
            "plu": "010417"
        },
        {
            "_id": "898031",
            "name": "DECO STRACCHINO CREMOSO AL KG",
            "ingredients": "INGREDIENTI: LATTE pastorizzato crema di LATTE fermenti lattici sale caglio. Può contenere GLUTINE.",
            "plu": "011167"
        },
        {
            "_id": "899013",
            "name": "FICACCI OLIVE SECCHE MAR.5KG",
            "ingredients": "INGREDIENTI:Olive nere (99%) sale olio extravergine doliva",
            "plu": "015987"
        },
        {
            "_id": "961272",
            "name": "GIRAU CACIOTTA FLOR DI CAPRA K",
            "ingredients": "INGREDIENTI: LATTE di capra caglio sale Puo contenere GLUTINE.",
            "plu": "010716"
        },
        {
            "_id": "961273",
            "name": "GIRAU CACIOTTA CAPRALLEGRA KG",
            "ingredients": "INGREDIENTI: LATTE di capra caglio sale.",
            "plu": "None"
        },
        {
            "_id": "962337",
            "name": "MICCIO OLIVE COND.BOSCAIOLA KG",
            "ingredients": "INGREDIENTI:Olive(45%) Funghi di muscio 30% (volvarea volvacea) olio di semi di girasole peperoni in agrodolce 7% prezzemolo aceto(SOLFITI) aglio(SOLFITI) peperoncino origano finocchietto. Può contenere noccioli o trace di essi.",
            "plu": "016008"
        },
        {
            "_id": "963047",
            "name": "SALCIS PECORINO PAGL&FIENO KG",
            "ingredients": "INGREDIENTI:LATTE di pecora pastorizzato sale caglio Conservante sulla crosta: E203 E235 Copertura: olio di olivafieno Può contenere GLUTINE.",
            "plu": "010739"
        },
        {
            "_id": "963282",
            "name": "IAVARONE CICCIOLI 1/4 AL KG",
            "ingredients": "INGREDIENTI:suino sale Conservante: nitrito di sodio (E250) Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "013620"
        },
        {
            "_id": "963283",
            "name": "IAVARONE CICCIOLI FROLLI AL KG",
            "ingredients": "INGREDIENTI:suino salespeziearomi Esaltatore si sapidità:E621 Conservante: E250 Può contenere GLUTINELATTE. Origine suino: UE",
            "plu": "015761"
        },
        {
            "_id": "963304",
            "name": "AMATRICE PECORINO MONTANARO KG",
            "ingredients": "INGREDIENTI: LATTE ovino italiano pastorizzato caglio sale e FERMENTI LATTICI Trattato in superficie con olio di lino Può contenere GLUTINEFRUTTA A GUSCIO.",
            "plu": "015765"
        },
        {
            "_id": "963709",
            "name": "AURICCHIO PECORINO MOLITERNO",
            "ingredients": "INGREDIENTI:LATTE di pecora pastorizzato sale caglio Può contenere GLUTINE.",
            "plu": "010740"
        },
        {
            "_id": "963766",
            "name": "ALCISA PROSC.SAN DANIELE C/O",
            "ingredients": "INGREDIENTI: coscia di suino  sale Può contenere GLUTINELATTE.",
            "plu": "012474"
        },
        {
            "_id": "964144",
            "name": "MONT.PROSC CRUDO PEPE N.C/O.KG",
            "ingredients": "INGREDIENTI: Coscia di suino (Origine: UE) sale conservante: E252. Può contenere LATTE GLUTINE. Conservare a temperatura inferiore a 10°C.",
            "plu": "012096"
        },
        {
            "_id": "964145",
            "name": "ALCISA PROSCIUTTO PARMA DIS.KG",
            "ingredients": "INGREDIENTI: coscia di suino  sale Può contenere GLUTINELATTE.",
            "plu": "012291"
        },
        {
            "_id": "964149",
            "name": "TRENTINGRANA FORMA AL KG",
            "ingredients": "INGREDIENTI: LATTE crudo vaccino , sale, caglio di vitello PuÃ² contenere GLUTINE.",
            "plu": "010352"
        }
    ],
    "CONGELATO": [
        {
            "_id": "100024",
            "name": "VENTAGLI GRATINATI AL KG",
            "ingredients": "INGREDIENTI:COZZE cilene sgusciate(Mytilus chilensisallevate in Cile)VONGOLE del Pacifico sgusciate(Paphia undulatapescate in Oceano Pacifico FAO 71con draghe) MAZZANCOLLE tropicali sgusciate (Penaeus vannamei allevate in Thailandia e/o allevate in Vietnam e/o Ecuadorcontiene E452) e/o MAZZANCOLLE atlantiche (Penaeus notialis pescate in Oceano Atlantico centro orientale FAO 34con reti da traino)IMPASTO semilav. per panatura farina di FRUMENTO (GLUTINE) lievito di birrasalepaprikaolio di semi di girasolepassata di pomodoroprezzemolosucco di limonepreparato per gratinatura sale rosmarinoAGLIOprezzemolocipollaoriganosalviatimoalloro maltodestrineestratto di lievitoaromi (contiene PESCE e CROSTACEI) fibre vegetali alimentari FRUMENTO (GLUTINE)agrumipatatesale aglio(SOLFITI)Su \u00c2\u00bd guscio di CAPPASANTA Pecten maximus). Conservare a T-18°C una volta scongelato non ricongelare il prodotto e consumare previa cottura.Può contenere SEDANOPESCE MOLLUSCHISOIASENAPE.",
            "plu": "051017"
        },
        {
            "_id": "100025",
            "name": "COZZE GRATINATE AL KG",
            "ingredients": "INGREDIENTI: COZZE cilene su \u00c2\u00bd guscio precotte (Mytilus chilensis allevate in Cile); Impasto: semilavorato per panatura farina di FRUMENTO (GLUTINE) lievito di birra sale paprika) olio di semi di girasole passata di pomodoro prezzemolo succo di limone preparato per gratinatura sale rosmarino AGLIO prezzemolo cipolla origano salvia timo alloro maltodestrine estratto di lievito aromi naturali (contiene PESCE e CROSTACEI) fibre vegetali alimentari FRUMENTO (GLUTINE) patate agrumisale AGLIO(SOLFITI) Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere CROSTACEI PESCE SOIA SENAPE e MOLLUSCHISEDANO.",
            "plu": "051018"
        },
        {
            "_id": "100466",
            "name": "POLPI RIC.MAROC.T5 12-15 KG",
            "ingredients": "INGREDIENTI:POLPO intero congelato (Octopus Vulgaris) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050891"
        },
        {
            "_id": "100480",
            "name": "FIORI PANGASIO CONG.A K",
            "ingredients": "INGREDIENTI: PANGASIO (Pangasius hypophthalmus) salestabilizzanti:E450 E451 correttori di acidità:E330 E332 Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050762"
        },
        {
            "_id": "100784",
            "name": "SEPPIOLINE CONG.AL KG.",
            "ingredients": "INGREDIENTI: SEPPIA (Sepia aculeata) sale correttori di acidità E331 E330. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050856"
        },
        {
            "_id": "101913",
            "name": "FIORI ZUCCA PASTELLATO AL KG",
            "ingredients": "INGREDIENTI: Pastella (farina di GRANO tenero tipo 00 acqua sale lievito naturale) fiori di zucca olio di semi di girasole. Conservare a T-18°C. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere: CROSTACEI UOVA ARACHIDI SOIA FRUTTA A GUSCIO SEDANO LATTE PESCE SESAMO SENAPE MOLLUSCHI e ANIDRIDE SOLFOROSA.",
            "plu": "051294"
        },
        {
            "_id": "101914",
            "name": "BASTONCINI TOTANO CONG.AL KG",
            "ingredients": "INGREDIENTI: TOTANO gigante del Pacifico (Todarodes sagittatus) farina di FRUMENTOolio di girasoleamido di patateacquasalespezie lievito.Può contenere:LATTEUOVASOIA SEDANOSENAPEMOLLUSCHIPESCE. Conservare a T-18°C.Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "051020"
        },
        {
            "_id": "101915",
            "name": "BASTONCINI DI SALMONE AL KG",
            "ingredients": "INGREDIENTI:SALMONE rosa (Oncorhynchus gorbusha) farina di FRUMENTO olio di semi di colza acqua amido di patata sale lievito spezie. Può contenere CROSTACEI UOVA SOIA LATTE SEDANO SENAPE MOLLUSCHI CROSTACEI.Conservare a T-18°C. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura",
            "plu": "051019"
        },
        {
            "_id": "101916",
            "name": "BASTONCINI DI VERDURE AL KG",
            "ingredients": "INGREDIENTI: Ortaggi in proporzione variabile (broccoli mais carote patate SEDANO) farina di GRANO tenero oli vegetali (girasole colza) FORMAGGIO EDAM acqua amido di patate patate in fiocchi UOVA intere zucchero sale fibre da GRANO lievito spezie (contiene SEDANO) BURRO proteine vegetali malto destrine. Può contenere: CROSTACEI PESCE SOIA SENAPE e MOLLUSCHI. Prodotto surgelato allorigine da consumarsi solo previa cottura. Conservare a -18°C. Non ricongelare.",
            "plu": "051293"
        },
        {
            "_id": "103694",
            "name": "ASTICE CRUDO 500/650 AL KG",
            "ingredients": "INGREDIENTI: ASTICE americano (Homarus americanus) pescato nellOceano Atlantico Nord Occidentale (Fao 21) con nasse e trappole sale acqua. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050346"
        },
        {
            "_id": "103813",
            "name": "GAMBERI ARGENT.CONG.L1C/TER.K",
            "ingredients": "INGREDIENTI: GAMBERI argentini (Pleoticus muelleri) antiossidante: E223(metabiSOLFITI di sodio) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050331"
        },
        {
            "_id": "104272",
            "name": "RISPO FIORI ZUCCA RIC.PROV.KG",
            "ingredients": "INGREDIENTI: RICOTTA vaccina (siero di LATTE vacci no LATTE vaccino PANNA di LATTE sale) fiori di zucca PROVOLA affumicata (LATTE sale caglio fermenti LATTICI aroma fumo) acqua preparato per PASTELLA (amido di mais farina di FRUMENTO farina di riso agenti lievitanti: E450i E500ii E341i sale esaltatore di sapidità: E621 addensante:gomma di Xanthan pepe nero spezie) GRANA PADANO DOP (LATTE sale caglio conservante: lisozima - proteina dellUOVO) PANE grattugiato (farina di FRUMENTO lievito di birra sale)",
            "plu": "051298"
        },
        {
            "_id": "111025",
            "name": "FIORE MERLUZZO NORD.CONG.ALKG",
            "ingredients": "INGREDIENTI: filetto di MERLUZZO nordico (Gadus morhua)salestabilizzanti:E451E450 E331correttori di acidità E330 e acqua. Conservare a temperatura non superiore a -18°C una volta scongelato non ricongelare e consumare entro 24h previa cottura.",
            "plu": "050766"
        },
        {
            "_id": "111797",
            "name": "COTOLETTA POLLO CONG.AL KG",
            "ingredients": "INGREDIENTI:filetto di pollo54%farina di FRUMENTO acquaolio vegetale di colza AMIDO(FRUMENTOpiselli patatetapioca)salestabilizzante E450regolatore di acidità E575antiossidanti E300E316lievito estrattodi spezie.Può contenere LATTESOIASENAPE. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050024"
        },
        {
            "_id": "111798",
            "name": "NUGGETS POLLO CONG.AL KG",
            "ingredients": "INGREDIENTI:filetto di pollo50%farina di FRUMENTO olio vegetale di colzaacquaAMIDO stabilizzante E450destrosiolievitoaromi e spezie. Può contenere SOIASENAPE. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050023"
        },
        {
            "_id": "111799",
            "name": "CORDON BLEU CONGELATO AL KG",
            "ingredients": "INGREDIENTI:filetti di pollo48%farina di FRUMENTO acquaprosciutto di tacchino8% (tacchinoacquafeco la di patatesaleconservante E250destrosiostabi- lizzanti E450 E451addensanti E407E407aE412E415 estratto di lievitoaromimaltodestrineantiossidan teE316proteine di pollo)FORMAGGIO 65%(FORMAGGIO amidoacquastabilizzanti E452E339 sale)olio vege taledi colzaAMIDO(FRUMENTOmaispisellipatateta pioca)sale stabilizzante E450lievitoaromi(SEDANO) maltodestrinespezievegetali in polvere. Può contenere SOIA SENAPE. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050022"
        },
        {
            "_id": "113057",
            "name": "DELIFRANCE CROISSANT MINI 55GR",
            "ingredients": "INGREDIENTI:Farina di GRANO teneroBURRO 24%acquazucchero lievitoGLUTINE di GRANO teneroUOVAsale agente di trattamento della farina: E300enzima. Può contenere SOIA SEMI DI SESAMO FRUTTA A GUSCIO SENAPE Prodotto dolciario da forno al burro - Da cuocere - Surgelato. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "051188"
        },
        {
            "_id": "114164",
            "name": "TRANCI FILETTI BACCALA AL KG",
            "ingredients": "INGREDIENTI: MERLUZZO(Gadus morhua) acqua e sale. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050768"
        },
        {
            "_id": "114277",
            "name": "RISPO RUSTICI MIGNON CONG.KG",
            "ingredients": "SFOGLIA:FARINAmargarina veg.oli e grassi di girasole e palmaacquaE330E331iii sale emulsionante (E471) aromi acqua sale strutto prep.alim.proteine del LATTE LATTE scre. in polv.E500E471LATTOSIO maltodestrinecurcuma. RIPIENO:RICOTTA vaccina wurstel carne di suino* carne di tacchino* acquagrasso di suino amido di patatasale iodatoaromidestrosioaroma naturale e di affumicaturasaleE407farina di semi di carrube;E301;E250; *separata meccanicamente) prosciutto cottocarne di suinoacquaamido di patatasaledestrosiosaccarosioaromi e spezie gelatina alimentareE621E450E451 E407E301 E316E250preparazione di SALMONE. Cons. a -18°C. Non ricongelare.Può contenere SENAPESOIA.",
            "plu": "051340"
        },
        {
            "_id": "115277",
            "name": "PIZZETTA POMODORO CONG. AL KG",
            "ingredients": "INGREDIENTI:farina di GRANO tenero tipo ?00? acqua strutto zucchero lievito di birra(Saccharomyces Cerevisiae)sale.Farcitura: passata di pomodoro (pomodoro fresco acido citrico) sale zucchero MOZZARELLA (LATTE sale caglio). Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato all?origine da consumarsi solo previa cottura.Può contenere SOIASENAPE.",
            "plu": "051334"
        },
        {
            "_id": "115377",
            "name": "PIZZETTA MONTANARA CONG. AL KG",
            "ingredients": "INGREDIENTI:FARINA 00 acquasalelievitopomodoroFORMAGGIO grattugiato basilicoaglio (SOLFITI) olio di semi di girasole. Conservare a -18°C. Prodotto congelato allorigine. Non ricongelare.Può contenere SOIA SENAPE.",
            "plu": "051333"
        },
        {
            "_id": "115667",
            "name": "TRANCI MERLUZ.NORD.CONG.AL KG",
            "ingredients": "INGREDIENTI: MERLUZZO nordico (Gadus morhua) acquasale(sale agenti antiagglomerante E536) correttore di acidità E500stabilizzante E331 antiossidante E330 e umidificante E965 E966. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050020"
        },
        {
            "_id": "117775",
            "name": "MERLUZZO LEGGERI CROCCAN.AL KG",
            "ingredients": "INGREDIENTI:MERLUZZO(Merluccius capensis/paradoxus) farina di GRANO duroacquaolio di semi di girasoleAMIDOsalelievito naturale GLUTINE di FRUMENTO. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato.Prodotto surgelato allorigine da consumarsi solo previa cottura.Può contenere SOIASENAPE.Può contenere lische.",
            "plu": "051026"
        },
        {
            "_id": "117778",
            "name": "FRITTATINE MIGNON PF CONG.KG",
            "ingredients": "INGREDIENTI: acqua amido modificato di mais (E1422) proteine del LATTE BURRO in polvere pasta di GRANO duro carne di suino passata di pomodoro (pomodoro fresco correttore di acidità (E330) piselli (piselli cotti a vapore acqua zucchero e sale) misto per soffritto (carote SEDANO cipolle in quantità variabili) pancetta carne di suini sale destrosio aromi naturali spezie antiossidante (E316) conservanti (E250 E252) olio sale PECORINO ROMANO (LATTE  caglio  sale FORMAGGIO di pecora) GRANA PADANO (LATTE sale caglio conservante lisozima da UOVO)  farina di GRANO tenero tipo 00 PANE grattuggiato (farina di GRANO tenero tipo 0 acqua lievito sale spezie) aroma naturale (oleoresina di curcuma e polisorbato E433) olio di girasole. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Puo contenere SOIA SENAPE.",
            "plu": "051332"
        },
        {
            "_id": "120297",
            "name": "FILETTI MERLUZZO ALASKA AL KG",
            "ingredients": "INGREDIENTI: MERLUZZO dAlaska(Theragra chalcogramma) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050493"
        },
        {
            "_id": "121217",
            "name": "FIORI SALMONE C/PELLE CONG.KG",
            "ingredients": "INGREDIENTI:SALMONE rosa(Oncorhynchus Gorbuscha) acqua. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050494"
        },
        {
            "_id": "121653",
            "name": "MARTINUCCI PASTIC.CR/AMAR.60GR",
            "ingredients": "INGREDIENTI:Crema 31% (zucchero tuorlo dUOVO sciroppo di glucosioLATTE scremato in polvere amido di riso sieroproteine del LATTE margarina (grassi vegetali di palma e oli vegetali di girasole emulsionante E471) sale aromi) amido modificato di mais addensanti (E401E410) aromi emulsionante (lecitina di SOIA) sale) farina di FRUMENTO passata di amarena 125% (sciroppo di glucosio-fruttosioamarene 175% purea e succo di visciole 175% saccarosio addensanti (amido modificato di tapioca pectina)succo di limone concentratocoloranti (E150d E163) acidificante (E330) aromi conservante (E202)) zucchero strutto farina di semola di GRANO duro rimacinataUOVA margarina (grassi vegetali di palma e oli vegetali di girasoleemulsionante (E471) sale aromi) tuorlo dUOVO sciroppo di glucosio-fruttosio aromiagenti lievitanti (E503 E450i E500iiamido di mais) sale colorante (E160a). Può contenere ARACHIDI FRUTTA A GUSCIO SENAPE.",
            "plu": "051201"
        },
        {
            "_id": "121654",
            "name": "MARTINUCCI PASTIC.CREMA 60 GR",
            "ingredients": "INGREDIENTI:Crema 41% (zucchero tuorlo dUOVO sciroppo di glucosio LATTE scremato in polvere amido di riso sieroproteine del LATTE margarina (grassi vegetali di palma e oli vegetali di girasole emulsionante (E471) sale aromi) amido modificato di mais addensanti (E401E410) aromi emulsionante (lecitina di SOIA)sale) farina di FRUMENTO zucchero strutto farina di semola di GRANO duro rimacinata UOVA margarina (grassi vegetali di palma e oli vegetali di girasole emulsionante (E471) sale aromi) tuorlo dUOVO sciroppo di glucosio-fruttosio aromi agenti lievitanti (E503 E450i E500ii amido di mais) sale colorante (E160a). Contiene: GLUTINE UOVA SOIA LATTE. Può contenere ARACHIDI FRUTTA A GUSCIOSENAPE.",
            "plu": "051200"
        },
        {
            "_id": "121655",
            "name": "MARTINUCCI PASTIC.CR/CIOC.60GR",
            "ingredients": "INGREDIENTI:Crema al cioccolato 32%(zuccherotuorlo dUOVOsciroppo di glucosioLATTE screm. in polverecacao magro in polveresieroproteine del LATTEamido di riso margarina(grassi vegetali di palma e oli vegetali di girasoleemulsionante(E471)sale aromi)amido modif. di maiscioccolato fondente 05%(pasta di cacaozucchero burro di cacao emulsionante(lecitina di SOIA)aroma)addensanti E401E410aromi emulsionante(lecitina di SOIA)sale)farina di FRUMENTO zucchero struttocrema 8 5%(zuccherotuorlo dUOVOsciroppo di glucosioLATTE scremato in polvereamido di riso sieroproteine del LATTEmargarina (grassi veg. di palma e oli vegetali di girasole emulsionante(E471) sale aromi)amido modificato di maisaddensanti(E401E410)aromi emulsionante(lecitina di SOIA)sale)farina di semola di GRANO duro rimacinataUOVA margarina(grassi vegetali di palma e oli veg. di palma e oli veg. di girasole emulsionante (E471)sale aromi)tuorlo dUOVO sciroppo di glucosio-fruttosio aromi agenti lievitanti (E503E450i E500iiamido di mais)salecolorante(E160a). Può contenere ARACHIDI FRUTTA A GUSCIOSENAPE.",
            "plu": "051202"
        },
        {
            "_id": "121656",
            "name": "MARTINUCCI PASTIC.CREMA 110GR",
            "ingredients": "INGREDIENTI:Crema 36% (zuccherotuorlo dUOVOLATTE scremato in polvere sieroproteine del LATTEsciroppo di glucosiomargarina (grassi vegetali di palma e oli vegetali di girasoleemulsionante (E471) sale aromi) amido modificato di mais amido di riso addensanti (E401 E410) fibre vegetali sale emulsionante (lecitina di SOIA) aromi) farina di FRUMENTO zuccherostrutto UOVA margarina (grassi vegetali di palma e oli vegetali di girasole emulsionante (E471) sale aromi) tuorlo dUOVO sciroppo di glucosio e fruttosio agenti lievitanti (E503E450i E500ii amido di mais) aromi colorante (E160a) sale. Può contenere ARACHIDI FRUTTA A GUSCIOSENAPE.",
            "plu": "051198"
        },
        {
            "_id": "121658",
            "name": "MARTINUCCI PASTIC.CR/AMA.110GR",
            "ingredients": "INGREDIENTI: Crema 355% (zuccherotuorlo dUOVO sciroppo di glucosio margarina (grassi vegetali di palma e oli vegetali di girasole emulsionante (E471) sale aromi)LATTE scremato in polvere amido modificato di maissieroproteine del LATTEamido di riso addensanti (E401E410) fibre vegetali emulsionante (lecitina di SOIA)sale aromi) farina di FRUMENTO zuccherostrutto UOVA margarina (grassi vegetali di palma e oli vegetali di girasole emulsionante (E471) sale aromi) passata di amarena 4% (sciroppo di glucosio-fruttosio amarene 75% purea e succo di visciole 175% saccarosio addensanti (amido modificato di tapioca E1440pectina) succo di limone concentrato coloranti (E150dE163)acidificante (E330)aromi conservanti (E202)) tuorlo dUOVO sciroppo di glucosio-fruttosio agenti lievitanti(E503E450iE500ii amido di mais)aromi colorante (E160a) sale. Contiene: GLUTINE UOVA SOIA LATTE. Può contenere ARACHIDI FRUTTA A GUSCIO SOIA.",
            "plu": "051199"
        },
        {
            "_id": "122020",
            "name": "ARANCINO PROSC/MOZZ.220GR",
            "ingredients": "AcquarisoPAN GRATTATO(FARINA 0lievito di birrasale)MOZZARELLA 16% (LATTEfermenti latticicaglio acido citricoE202)FARINA 00 BECHAMELLE (LATTE FARINA OO sale) prosciutto cotto 8% (coscia suina 75% sale destrosio saccarosio amido di patata aromi e spezie Stabilizzanti: E450 - E451 esaltatore di sapidità E621 addensante: E407 antiossidante E316 conservante E250) olio di semi di girasole margarina (oli e grassi vegetali in parte idrogenati (80%) acqua emulsionanti (E471 E475 E322 lecitina di SOIA) sale (02%)  conservante (E202) correttore di acidità (E330) aromi coloranti (E160B E100) sale preparato per brodo (sale da cucina grassi ed oli vegetali esaltatori di sapidità: glutammato monosodico guanilato disodico inosinato disodico ESTRATTO DI LIEVITO cipolla in polvere SEMI DI SESAMO in polvere) curcuma. IL PRODOTTO E FRITTO IN OLIO DI GIRASOLE.Conservare a - 18°C. Non ricongelare. Può contenere SENAPEARACHIDI.",
            "plu": "051356"
        },
        {
            "_id": "122021",
            "name": "ARANCINO ALLA CARNE 220GR",
            "ingredients": "Acqua riso rag\u00c3\u00b9 25% circa polpa di pomodoro macinato di bovino adulto (carne bovina 94% acqua sale aromi naturali)piselliolio di semi di girasole zucchero cipolla sale polpa di pomodoro MOZZARELLA (LATTE fermenti lattici caglio sale) correttore di acidità: acido citrico conservante:E202 PAN GRATTATO (FARINA TIPO O  LIEVITO di birra sale) FARINA tipo OO olio di semi di girasole margarina (oli e grassi vegetali in parte idrogenati (80%) acqua emulsionanti (E471 E475 E322 lecitina di SOIA) sale (02%) conservante (E202) correttore di acidità (E330) aromi coloranti (E160B E100) sale preparato per brodo (sale da cucina grassi ed oli vegetali esaltatori di sapidità: glutammato monosodico guanilato disodico inosinato disodico ESTRATTO DI LIEVITO cipolla in polvere SEMI DI SESAMO in polvere cipolla) in quantità variabile curcuma. IL PRODOTTO E FRITTO IN OLIO DI SEMI DI GIRASOLE.Conservare a -18°C. Non ricongelare.Può contenere SOIASENAPEARACHIDE.",
            "plu": "051358"
        },
        {
            "_id": "122085",
            "name": "*FILETTI DI HALIBUT 900GR",
            "ingredients": "INGREDIENTI: HALIBUT della Groenlandia Regolatore di acidità:E330 Antiossidanti:E300E302 Può contenere: MOLLUSCHI CROSTACEI",
            "plu": "050496"
        },
        {
            "_id": "122680",
            "name": "COTOLETTA AGLI SPINACI CONG.KG",
            "ingredients": "INGREDIENTI:Carne di pollo * 50% PANGRATTATO (farina di FRUMENTO LIEVITO di birra sale curcuma paprika) carne di pollo (petto) olio vegetale (di palma e girasole) spinaci 5% preparazione al FORMAGGIO(acquagrassi vegetali non idrogenati di palma e girasole proteine del LATTE FORMAGGIO amido di patatasaleemulsionanti:E331 E452.Correttore di acidità: E330)preparato a base di proteine del LATTE e LATTE scrematoacqua aromi fiocco di patata sale farina di risofarina di FRUMENTO amido di FRUMENTOfibre vegetali. *Meccanicamente separata.Può contenere SOIASENAPE. Conservare a -18°C. Non ricongelare.",
            "plu": "050025"
        },
        {
            "_id": "122808",
            "name": "SORRENTINA PROSCIUTTO/MOZ.180G",
            "ingredients": "INGREDIENTI: Farina di FRUMENTOacquaprosciutto cotto (13%) (carne di suino acqua sale amido di patata destrosio zucchero aromi gelificante: E407 correttore di acidità: E262 antiossidante: E301 conservanti: E250) passata di pomodoro (125%) MOZZARELLA (10%) (LATTE sale caglio)olio extra vergine di oliva sale origano LIEVITO. Può contenere:SOIASENAPE.",
            "plu": "051360"
        },
        {
            "_id": "122809",
            "name": "SORRENTINA POMODORO/MOZ.180GR",
            "ingredients": "INGREDIENTI: farina di FRUMENTO acqua MOZZARELLA (20%) (LATTE sale caglio) passata di pomodoro (15%) olio extravergine doliva sale origano LIEVITO. può contenere SOIA SENAPE.",
            "plu": "051359"
        },
        {
            "_id": "122810",
            "name": "SORRENTINA SPINACI/MOZ.180GR",
            "ingredients": "INGREDIENTI: farina di FRUMENTO acqua RICOTTA (115%) (siero di LATTE CREMA DI LATTE vaccino regolatore di acidità: acidi citrico) MOZZARELLA (105%) (LATTE sale caglio) spinaci (105%) PARMIGIANO REGGIANO (2%) (LATTEsale caglio) olio extravergine di oliva sale lievito pepe nero aglio (SOLFITI). può contenere SOIASENAPE.",
            "plu": "051361"
        },
        {
            "_id": "122832",
            "name": "CALZONE MOZZARELLA/POMOD.140GR",
            "ingredients": "INGREDIENTI: farina di FRUMENTO acqua MOZZARELLA (18%) (LATTE sale caglio) passata di pomodoro (135%) sale olio evo olio di semi di girasole lievito farina di riso basilicoorigano. Può contenere SOIASENAPE.",
            "plu": "051367"
        },
        {
            "_id": "123253",
            "name": "DELIFRANCE MACARONS MIX 15GR",
            "ingredients": "INGREDIENTI:cioccolato al LATTE (zuccheroburro di cacaoLATTE intero in polverepasta di cacaoE322 (SOIA)aroma naurale di vaniglia)zucchero a velo crema di LATTEMANDORLE in polverechiare dUOVO zucchero invertitoBURROcaff\u00c3\u00a8 solubile 07% zucchero estratto di caff\u00c3\u00a8 04%E415cioccolato fondentecacao magro in polvere alcalinizzato purea di limoni acqua tuorli dUOVOamidoglucosioE160bE101aromi lampone a pezzettiE440 E120purea di aranciaaroma allaranciaestratto di vanigliasemi di vaniglia disid. Può contenere PESCESEMI DI SESAMOGLUTINEaltra FRUTTA A GUSCIOSENAPEARACHIDI. Conservare a -18°C. Non ricongelare. Consumare previa cottura.",
            "plu": "051204"
        },
        {
            "_id": "123257",
            "name": "POLPI SENEGAL T5 12/15 AL KG",
            "ingredients": "INGREDIENTI: POLPO Senegal(Octopus vulgaris) origine Senegal (zona Fao 27) con lenze a mano Conservare a T -18°c. Non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050910"
        },
        {
            "_id": "123258",
            "name": "POLPI SENEGAL T6 800/12 AL KG",
            "ingredients": "INGREDIENTI: POLPO Senegal(OCTOPUS VULGARIS) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050911"
        },
        {
            "_id": "123672",
            "name": "PIZZA MARGHERITA 150GR",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO CONSERVATO INGREDIENTI: Farina di GRANO tenero tipo 0 acqua LIEVITO naturale (farina di GRANO tenero tipo 0  acqua LIEVITO di birra) passata di pomodoro 12% MOZZARELLA 10% (LATTE vaccino pastorizzato caglio sale fermenti LATTICI)margarina vegetale grassi (cocco) ed oli vegetali (girasole) non idrogenati 80% acquaextravergine doliva sale LIEVITO di birra origanodestrosio LIEVITO naturale essiccato di GRANO tenerofarina di GRANO tenero maltato. Può contenere: UOVA SOIA FRUTTA A GUSCIO SEMI DI SESAMOSENAPE. Conservare il prodotto a -18°C entro il termine minimo di conservazione. Una volta scongelato non ricongelare il prodotto e consumarlo entro 24 ore dallo scongelamento. Se cotto: ottenuto da prodotto prontoforno congelato.",
            "plu": "051414"
        },
        {
            "_id": "124733",
            "name": "ORATA INTERA 400/600 CONG.KG",
            "ingredients": "INGREDIENTI: ORATA intera (Sparus aurata) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050640"
        },
        {
            "_id": "124734",
            "name": "BRANZINO INTERO400/600 CONG.K",
            "ingredients": "INGRESIENTI: SPIGOLA (dicentrarchus labrax) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050641"
        },
        {
            "_id": "124889",
            "name": "RISPO ARANCINI MIGNON PF CONG",
            "ingredients": "INGREDIENTI: riso 48% acqua polpa di pomodoro 10% MOZZARELLA vaccina 84% (LATTE sale cagliocorrettore di acidità: E330 conservante: E202) semilavorato per impanatura (farina di FRUMENTO lievito di birrasalepaprica)farina di FRUMENTOvino(SOLFITI) PROVOLONE(LATTEcaglio sale)Grana Padano D.O.P.(LATTEsalecaglio conservante:lisozima da UOVA)FORMAGGIO pecorino (LATTE di pecora caglio sale) olio di semi di girasole concentrato di pomodoro SEDANOcarotacipolla in proporzione variabile preparato per besciamella (amido di mais modificato LATTE scremato in polvere LATTOSIO proteine del LATTE BURRO in polvere grassi vegetali di cocco in polvere sale) carne bovina piselli salepreparato per brodo (sale iodato fecola di patate estratto di lievito zucchero grasso vegetale di palma cipolla disidratata aromi spezie)bietola rossa in polverebasilico cipolla pepeaglio(SOLFITI)noce moscata alloro.Può contenere PESCE SENAPESOIA.Conservare a -18°C. Non ricongelare. Consumare previa cottura.",
            "plu": "051384"
        },
        {
            "_id": "124897",
            "name": "RISPO RUSTICI MIGNON WURS.CONG",
            "ingredients": "INGREDIENTI:sfoglia: farina di GRANO tenero tipo ?00?  margarina vegetale (oli e grassi vegetali di girasole e palma acqua correttori di acidità: E330 E331iii sale emulsionante: E471 aromi) acqua sale strutto. Doratura superficiale (proteine del LATTE LATTE scremato in polvere correttore di acidità: E500 LATTOSIO maltodestrine) curcuma. Ripieno: wurstel 95% (carne di suino* 32% carne di tacchino* 31% acqua grasso di suino 12% amido di patata sale iodato aromi destrosio aroma naturale aroma di affumicatura sale addensanti: E407 farina di semi di carrube; antiossidante: E301; conservante: E250; *separata meccanicamente) pepe. Può contenere SEDANO UOVO PESCE SENAPE ANIDRIDE SOLFOROSA e SOLFITI SOIA CROSTACEI. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura. .",
            "plu": "051380"
        },
        {
            "_id": "125478",
            "name": "FILET.CERNIA 150/200 CONG",
            "ingredients": "INGREDIENTI: CERNIA indopacifica (Epinephelus diacanthus pescato in Oceano Indiano occidentale. Zona FAO 51 con reti da traino)stabilizzante E451. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050497"
        },
        {
            "_id": "127588",
            "name": "OREO MUFFIN 80GR",
            "ingredients": "INGREDIENTI: Zucchero; farina di FRUMENTO; Olio di colza; Acqua; Pezzi di biscotto al cacao Oreo\u00c2\u00ae(5%) (Olio di colza; Cacao magro in polvere(03%); farina di FRUMENTO; Zucchero; Sciroppo di glucosio fruttosio; amido di FRUMENTO; Agenti lievitanti: Carbonati di potassio Carbonati dammonio Carbonati di sodio; Grasso di palma; Sale; Emulsionanti: LECITINE DI SOIA; Aroma); Grassi vegetali: Palma Cocco; Cacao magro in polvere(3%); amido di FRUMENTO; Agenti lievitanti: Difosfati Carbonati di sodio Fosfati di calcio Gluconodeltalattone; LATTOSIO; proteine del LATTE; Amido di mais modificato; Farina di riso pregelatinizzata; siero di LATTE dolce in polvere; UOVA in polvere; LATTE intero in polvere; Emulsionanti: Stearoil-2-lattilato di sodio Lecitine(SOIA); Correttori di acidità: Idrossido di potassio Idrossido di sodio; Sale; Aroma naturale di vaniglia. Può contenere: FRUTTA A GUSCIO SENAPE SESAMOARACHIDE. Conservare a -18°C.",
            "plu": "051207"
        },
        {
            "_id": "127589",
            "name": "OREO DONUT 73GR",
            "ingredients": "INGREDIENTI: Far. di FRUMENTO; Grassi vegetali: Palma Cocco (in proporzione variabile); Zucchero; Pezzi di biscotto al cacao Oreo\u00c2\u00ae (Far. di FRUMENTO Zucchero Olio di colza Cacao magro in polv. Sciroppo di glucosio fruttosio amido di FRUMENTO Agenti lievitanti: Carbonati di potassio Carbonati dammonio Carbonati di sodio; Olio di palma Sale Emulsionanti: lecitine SOIA; Aroma); Acqua; Oli vegetali: Palma Colza; LATTOSIO; Lievito; Farina di SEGALE Cacao magro in polv. (14%); Emulsionanti: Mono- e digliceridi degli acidi grassi Lecitine Stearoil-2-lattilato di sodio; LATTE intero in polv.; Destrosio; Sciroppo di glucosio; Sale; Agenti lievitanti: Difosfati Carbonati di sodio; Addensanti: Gomma di xantano; Aroma (LATTE); Agenti di trattamento della farina: Acido ascorbico. Può contenere FRUTTA A GUSCIO UOVA. SOIASENAPE.Conservare a -18°C.",
            "plu": "051209"
        },
        {
            "_id": "127590",
            "name": "MILKA DONUT 65GR",
            "ingredients": "INGREDIENTI: Far. di FRUMENTO; Cioccolato al latte Milka\u00c2\u00ae Alpenmilch (Zucchero Burro di cacao LATTE screm. in polv. Cacao in massa siero di LATTE dolce in polv. BURRO chiarificato pasta di NOCCIOLE Emulsionanti: lecitine di SOIA Poliricinoleato di poliglicerolo; Aroma. Cacao: minimo 30%); Grasso di palma; Zucchero; Acqua; Oli vegetali: Colza Girasole; LATTE scremato in polv.; Lievito; Emulsionanti: Mono- e digliceridi degli acidi grassi Lecitine Stearoil-2-lattilato di sodio lecitina di SOIA; Far. di SEGALE; Destrosio; BURRO anidro; Cacao in massa; Sciroppo di glucosio; Sale; Cacao magro in polvere; Agenti lievitanti: Carbonati di sodio Difosfati; Addensanti: Gomma di xantano; Aroma. Può contenere UOVA altra FRUTTA A GUSCIOSENAPEARACHIDE. Conservare a -18°C.",
            "plu": "051210"
        },
        {
            "_id": "127591",
            "name": "MILKA MUFFIN 110GR",
            "ingredients": "INGREDIENTI: Far. di FRUMENTO; Olio di colza; Zucchero; Acqua; farc. cacao e LATTE (64%) (Zucchero; Oli di Girasole Colza LATTE screm. in polv.; BURRO ; Cacao in massa; Cacao magro in polv.; Emuls.: lecitine di SOIA); Glassa ciocc. Milka\u00c2\u00ae (55%) (Zucchero; Burro di cacao; LATTE screm. in polv.; Cacao in massa; siero LATTE in polv.; grassi LATTE; pasta di NOCCIOLE; Emuls.: lecitine di SOIA Poliricinoleato di poliglicerolo; Aroma); Gocce di ciocc. fond. Milka\u00c2\u00ae (51%) (Zucchero; LATTE int. in polv.; Cacao in massa; Burro di cacao; siero LATTE in polv.; Emuls.: lecitine di SOIA; Vanillina); amido di FRUMENTO; Gocce di ciocc. fond. Milka\u00c2\u00ae (18%) (Zucchero; Burro di cacao; LATTE screm. in polv.; Cacao in massa; siero di LATTE in polv.; grassi LATTE; pasta di NOCCIOLE; Emuls.: lecitine di SOIA; Aroma); Ag. lievitanti: Difosfati Carbonati di sodio Gluconodeltalattone; Amido di mais modificato; prot. LATTE; Far. di riso pregel.; siero LATTE in polv.; UOVA in polv. da allevamento allaperto; Corr. di acidità: Fosfati di calcio; Emuls.: Stearoil-2-lattilato di sodio; Aroma nat. di vaniglia; Sale. Può cont. FRUTTA A GUSCIOSENAPE.",
            "plu": "051208"
        },
        {
            "_id": "127659",
            "name": "BRANZINO INT.800/1000 IG CONG",
            "ingredients": "INGREDIENTI: BRANZINO intero eviscerato e squamato (Dicentrarchus Labrax) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050643"
        },
        {
            "_id": "127814",
            "name": "COOKIE TRIPLO CIOCCOLATO 72GR",
            "ingredients": "INGREDIENTI: Far. di FRUMENTO ( Far. di FRUMENTO; Carbonato di calcio; Ferro; Niacina; Tiamina); Zucchero; Cioccolato bianco belga (16%) (Zucchero; Burro di cacao; LATTE intero in polv.; Emulsionanti:lecitine di SOIA; Aroma); Cioccolato fondente belga (16%) (Zucchero; Cacao in massa; Burro di cacao; Emulsionanti: lecitine di SOIA; Aroma); BURRO; Grasso di palma; Sciroppo di zucchero invertito; Cacao magro in polvere (39%); Olio di colza; siero di LATTE in polv.; UOVA in polv.; Agenti lievitanti: Carbonati di sodio Difosfati; Sale; Aroma (LATTE); Melassa. Può contenere FRUTTA A GUSCIO ANIDRIDE SOLFOROSASENAPE. Conservare a -18°C.",
            "plu": "051216"
        },
        {
            "_id": "127815",
            "name": "COOKIE CIOCCOLATO LATTE 72GR",
            "ingredients": "INGREDIENTI: Cioccolato belga al latte (31%) (Zucchero; LATTE intero in polv.; Burro di cacao; Cacao in massa; Emulsionanti: lecitine di SOIA (E 322); Aroma naturale di vaniglia); Far. di FRUMENTO (Far. di FRUMENTO; Carbonato di calcio; Ferro; Niacina; Tiamina); Zucchero; Grasso vegetale: Palma; BURRO salato; Olio vegetale: Colza; siero di LATTE in polv.; Sciroppo di zucchero invertito; Umidificanti: Glicerolo (E 422); UOVA in polv.; Agenti lievitanti: Carbonati di sodio (E 500) Difosfati (E 450); Sale; Aroma (LATTE). Può contenere FRUTTA A GUSCIO ANIDRIDE SOLFOROSASOIASENAPE. Conservare a -18°C.",
            "plu": "051217"
        },
        {
            "_id": "127819",
            "name": "DONUT CHEESECAKE LAMPONE 69GR",
            "ingredients": "INGREDIENTI: Far. di FRUMENTO; Zucchero; Grassi veg.: Palma Cocco; QUARK magro (8%); Acqua; Sciroppo di glucosio fruttosio; Purea di lampone (2%); Oli vegetali: Colza Girasole; Lievito; Emulsionanti: Mono- e digliceridi degli acidi grassi Lecitine Stearoil-2-lattilato di sodio;yogurt in polv. da LATTE screm. (13%); Far. di SEGALE; Destrosio; Concentrato di purea di lamponi (06%); Sciroppo di glucosio; Sale; Agenti lievitanti: Carbonati di sodio Difosfati; Addensanti: Pectine Gomma di xantano; concentrato di barbabietola; aromi naturali;Concentrato di bacche di sambuco; Aroma naturale di vaniglia (LATTE)Agenti di trattamento della farina: Acido ascorbico; Agenti di rivestimento: Cera dapi bianca e gialla; Coloranti: Carbone vegetale Antociani. Può contenere UOVA SOIA FRUTTA A GUSCIOSENAPE. Conservare a -18°C.",
            "plu": "051215"
        },
        {
            "_id": "127820",
            "name": "DONUT CIOCCOLATO 73GR",
            "ingredients": "INGREDIENTI: Far. di FRUMENTO; Grassi veg.: Palma Cocco; Ciocc. belga al latte (11%) (Zucchero; Burro di cacao; LATTE intero in polv.; Cacao in massa; siero di LATTE dolce in polv.; Emulsionanti: lecitine di SOIA Poliricinoleato di poliglicerolo; Aroma naturale di vaniglia); Zucchero; Acqua; Oli vegetali: Colza Girasole Palma; Ciocc. fondente belga (34%) (Cacao in massa; Zucchero; Burro di cacao; BURRO anidro; Emulsionanti: lecitine di SOIA; Aroma nat. di vaniglia); Ciocc. bianco (34%) (Zucchero; Burro di cacao; LATTE intero in polv.; LATTE screm. in polv.; LATTOSIO; siero di LATTE dolce in polv.; Emulsionanti:lecitine di SOIA; Aroma nat. di vaniglia); Cacao magro in polv. (3%); Lievito; Emulsionanti: Mono- e digliceridi degli acidi grassi Lecitine Stearoil-2-lattilato di sodio lecitine di SOIA; Far. di SEGALE; LATTE intero in polv.; Destrosio; Sciroppo di glucosio; Sale; Agenti lievitanti: Difosfati Carbonati di sodio; LATTE screm. in polv.; Cacao in massa; Addensanti: Gomma di xantano; Agenti di trattamento della farina: Acido ascorbico; Aroma. Può contenere UOVA altra FRUTTA A GUSCIOSENAPE.Conservare a -18°C.",
            "plu": "051214"
        },
        {
            "_id": "127828",
            "name": "CORNETTI PROSCIUTTO/FORM.31GR",
            "ingredients": "INGREDIENTI: Far. di FRUMENTO; Margarina (Grasso di palma; Acqua; Olio di girasole; Emuls. : Mono- e digliceridi degli ac. grassi; Sale; Aroma; Corr. di acidità: Ac. citrico; Color. : E 160a); Prep. prosc.(16%) (Suino; Acqua; Amido di patata; Sale; prot. di SOIA; Prot. carne maiale; Stabil.: Trifosf. Difosf. Carragenina; Destrosio; LATTICELLO; Antioss.: E301 E331 E316; E252; E621; Color.: Carminio); Acqua; prod. base LATTE (9 8%)(FORMAGGIO; Acqua; prot. del LATTE; Amido di patata; Grassi Palma e Girasole; Amido modif.; Amido acetilato; Sali di fusione: Polifosf. Difosf. Fosf. di calcio Fosf. di sodio; Sale; Aroma; Corr. di acidità: Ac. citrico; Conser.: E202; Stabil.: Gomma di xantano); Zucchero; Lievito; Destrosio; Sale; GLUTINE di FRUMENTO; Emuls.: Esteri mono- e diacetiltartarici di mono- e digliceridi degli ac. grassi Mono- e digliceridi degli ac. grassi; Ag. antiaggl.: Carbonato di calcio; Amido modif.; far. di SOIA; Fruttosio; Addens. : Gomma di xantano Idrossi-propil-cellulosa; Aroma; Ag. di tratt.della far.: Ac. ascorbico; Amido di mais; Enzimi. Può contenere FRUTTA A GUSCIO UOVASENAPE.Conservare a -18°C.",
            "plu": "051387"
        },
        {
            "_id": "130331",
            "name": "GARBO MOZZAR.CAR.PROS.CONG.KG",
            "ingredients": "Prodotto prontoforno - Congelato INGREDIENTI: Pancarr\u00c3\u00a8 25% (farina di GRANO tenero tipo 0; Acqua; Lievito; Strutto; Sale; Destrosio; Farina di FRUMENTO maltato; Conservante: Acido Sorbico). Mozzarella 16% (LATTE sale caglio fermenti lattici correttore di acidità E330). Prosciutto cotto 16%: carne di suino (625%) acqua fecola di patate sale destrosio aromi naturali gelificante: carragenina antiossidante: ascorbato di sodio conservante: nitrito di sodio. LATTE in polvere. Farina di FRUMENTO LATTE in polvere olio di semi di girasole Sale Lievito. Colorante alimentare: estratto di paprica E160c zucchero caramellizzato. Conservare a temperatura non superiore a -18°C una volta scongelato non ricongelare e consumare entro 24h previa cottura. Può contenere SOIASENAPE.",
            "plu": "051389"
        },
        {
            "_id": "130332",
            "name": "GARBO MOZZAR.CARROZZA CONG.KG",
            "ingredients": "Prodotto prontoforno - Congelato INGREDIENTI: Mozzarella 33% (LATTE sale caglio fermenti lattici). Pancarr\u00c3\u00a8: (farina di grano acqua lievito strutto sale destrosio farina di frumento maltato; conservante: acido sorbico); Farina di FRUMENTO LATTE in polvere olio di semi di girasole Sale Lievito. Colorante alimentare: estratto di paprica E160c zucchero caramellizzato. Conservare a temperatura non superiore a -18°C una volta scongelato non ricongelare e consumare entro 24h previa cottura. Può contenere SOIASENAPE.",
            "plu": "051388"
        },
        {
            "_id": "130333",
            "name": "GARBO PARMIGIANINE CONG. KG",
            "ingredients": "INGREDIENTI: Melanzane grigliate 33%mozzarella (LATTE sale caglio FERMENTI LATTICI) pomodoro 11% (polpa di pomodoro correttore di acidità acido citrico)parmigiano reggiano DOP 2% (LATTEsalecaglio) Besciamella (Amido modificato LATTE scremato in polvere LATTOSIOgrasso vegetale siero di LATTE in polvere sale gelificante: alginato di sodio E401 esaltatore di sapidità: glutammato monosodico E621 sciroppo di glucosioagente di resistenza: solfato di calcio E516).Formaggio - LATTE (formaggi saleE339ii) sale aglio(SOLFITI)prezzemolopepe. Brodo vegetale: sale fecola di patate verdure disidratate (cipolla carota SEDANO prezzemolo) estratto per brodo di proteine vegetali (SOIA mais) olio di girasole spezie aromi naturali. Ingredienti panatura: farina di FRUMENTO acqua sale lievitocolorante alimentare: paprica E160c zucchero caramellizzatoprezzemolo. Olio di semi di girasole. Può contenere UOVA PESCE CROSTACEI MOLLUSCHI FRUTTA A GUSCIO ARACHIDI LUPINISENAPE SESAMOANIDRIDE SOLFOROSA.Conservare a -18°C Non ricongelare.",
            "plu": "051390"
        },
        {
            "_id": "130338",
            "name": "DELIFRANCE MADELAINE CIOCC.80",
            "ingredients": "Prodotto dolciario da forno - Congelato INGREDIENTI:zucchero farina di GRANO tenero farcitura 188% (zucchero grassi vegetali non idrogenati cocco palmisto palmacolza) pasta di NOCCIOLE 105% cioccolato in polvere 95% (cacao in polvere zucchero) LATTE scremato in polvere emulsionante (E322) estratto naturale di vaniglia) BURRO UOVA NOCCIOLE 55% chiare duovo sfere di cereali al cacao 25% (farina di GRANO tenero farina di riso farina di AVENA farina dORZO maltatazucchero cacao farina di mais sale) amido modificato siero di LATTE in polvere  agente lievitante (E450 E500) emulsionante (E477E471E481) sale glutine di GRANO tenero aroma addensante (E412 E415). Può contenere SOIA FRUTTA A GUSCIOSENAPEARACHIDI.",
            "plu": "051238"
        },
        {
            "_id": "130950",
            "name": "FRITTELLE PATATE/ROSM.CONG KG",
            "ingredients": "INGREDIENTI: Patate Farina di GRANO 00 acqua lievito di birra sale rosmarino olio di girasole. Può contenere SEDANO PESCE UOVA CROSTACEI ANIDRIDE SOLFOROSASOIASENAPE. Conservare a temperatura non superiore a -18°C una volta scongelato non ricongelare e consumare entro 24h previa cottura.",
            "plu": "051394"
        },
        {
            "_id": "131061",
            "name": "DONUT MIX 73GR",
            "ingredients": "Prodotto dolciario da forno - congelato INGREDIENTI: Vedi Libro Unico Ingredienti Può contenere SOIASENAPEARACHIDI. Contiene: GLUTINEUOVALATTEFRUTTA A GUSCIO.",
            "plu": "051241"
        },
        {
            "_id": "131280",
            "name": "FRITTO MISTO NAPOLI PF CONG.KG",
            "ingredients": "Prodotto da forno - Congelato ARANCINO MIGNON : vedi codice 268474 CROCCHE MIGNON AL SALAME : vedi codice 124892 FRITTATINA MIGNON : vedi codice 750312 TRAMEZZINO: pancarr\u00c3\u00a9(farina di GRANO tenero tipo ?00?oli vegetali 4% lievito di birra sale farina di SOIAfarina di CEREALI maltati) sottilette ( FORMAGGIO fuso in fette)spalla cotta(carne di spalla di suinoacquasaleamidoproteine di SOIAisolantedestrosio LATTOSIO zuccheroLATTE scrematoaromiesaltatore di sapidità:E621 stabilizzante:E450 antiossidante:E301Farina di GRANOacquasale Olio di semi di girasole. Triangolo di polenta:Vedi codice 75760 Può contenere CROSTACEI PESCE UOVASOIASENAPE. Conservare a -18 C. Non ricongelare.",
            "plu": "051395"
        },
        {
            "_id": "131393",
            "name": "CALAMARI SPORCHI 2P CONG.AL KG",
            "ingredients": "INGREDIENTI: CALAMARI interi (Loligo vulgaris pescato nellOceano Atlantico zona FAO 34 con reti da traino) Può contenere PESCE e CROSTACEI. Conservare a -18°C.Una volta scongelato il prodotto non deve essere ricongelato. Conservare in frigorifero e consumato entro 24 ore previa cottura.",
            "plu": "050917"
        },
        {
            "_id": "131397",
            "name": "INTEGRUS WAFFEL 70GR",
            "ingredients": "ING: Farina di GRANO Zucchero(23%)Margarina (grasso di palma grasso di cocco olio di colza acqua emulsionanti(mono e digliceridi degli acidi grassi)) Acqua BURRO (6%) UOVA zucchero invertito Lievito Farina di SOIA Sale Emulsionanti(lecitina di SOIA) Aroma: vaniglia. Può contenere SENAPEARACHIDE. Conservare a temperatura non superiore a -18°C Non ricongelare.",
            "plu": "051242"
        },
        {
            "_id": "131626",
            "name": "CROCCHE EXTRA MOZ.PF.CONG.KG",
            "ingredients": "INGREDIENTI:acqua fiocchi di patate (patate disidratate al 99% emulsionanti: E471 stabilizzante : E450ispezieantiossidante: E304 E228 aroma naturale E330) spalla (carne suina al 42% acqua fecola di patate sale destrosio aromi naturali gelificante: carragenina stabilizzanti: polifosfati esaltatore di sapidità: glutammato monosodico  antiossidante: ascorbato di sodio conservante: nitrito di sodio) PECORINO ROMANO (LATTE cagliosale FORMAGGIO di pecora ) LATTE  sale margarina ((olii e grassi vegetali (grassi palma)  grassi parz. idrog.(palma e cocco in prop. var.) oli ( SOIA e girasole e colza in prop. var.) acqua emulsionanti (E471E 322 lectina di SOIA) conservante (E 202)) MOZZARELLA (LATTE pastorizzatofermenti lattici caglio microbico sale) FARINA\u00e2\u20ac\u015300PANE grattugiato(farina di GRANO tenero0 acqua lievito salespezie) pepe prezzemolo aroma naturale ( oleoresina di curcuma e polisorbatoE433) olio di girasole. Può contenere:SEDANOPESCECROSTACEIUOVA ANIDRIDE SOLFOROSASENAPE.",
            "plu": "051398"
        },
        {
            "_id": "131627",
            "name": "CROCCHE EXTRA NAP.PF.CONG.KG",
            "ingredients": "INGREDIENTI: acqua fiocchi di patate (patate disid. 99%emuls.: E471 stabiliz.:E450i spezieantios.: E304E228 aroma naturalecorr. di acidità: E330)salame tipo Napoli (carne suinosale destrosio spezie aromi antios.:E301conser.:E250E252) spalla (carne suina 42% acquafecola di patate sale destrosio aromi naturali gelif.: carrageninastabiliz.: polifosfatiesaltatore di sapidità: glutammato monosodico  antios. : ascorbato di sodio conservante: nitrito di sodio)PECORINO (LATTE  caglio  sale FORMAGGIO di pecora )  LATTE  sale margarina ((olii e grassi vegetali (grassi palma) grassi parz. idrog.(palma e cocco in prop. var.) oli ( SOIA  girasole e colza in prop. var.) oli parzialmente idrogenati (palma e cocco in prop. var.oli ( SOIA e girasole e colza in prop. var.acqua emulsionanti (E471E 322 lectina di soia)  conser.(E 202)) Mozzarella (LATTE pastorizzatofermenti lattici caglio microbico sale) FARINA00 PANE grattugiato (FARINA 0 acqua lievito salespezie) pepe prezzemolo E433olio di girasole. Può contenere:SEDANOPESCEUOVACROSTACEISOLFITISENAPE.",
            "plu": "051397"
        },
        {
            "_id": "131628",
            "name": "CROCCHE MIGNON NAP.PF.CONG.K",
            "ingredients": "INGREDIENTI: acqua fiocchi di patate (patate disid. 99%emuls.: E471 stabiliz.:E450i spezieantios.: E304E228 aroma naturalecorr. di acidità: E330)salame tipo Napoli (carne suinosale destrosio spezie aromi antios.:E301conser.:E250E252) spalla (carne suina 42% acquafecola di patate sale destrosio aromi naturali gelif.: carrageninastabiliz.: polifosfatiesaltatore di sapidità: glutammato monosodico  antios. : ascorbato di sodio conservante: nitrito di sodio)PECORINO ROMANO(LATTE  caglio sale FORMAGGIO di pecora )  LATTE  sale margarina ((olii e grassi vegetali (grassi palma)grassi parz. idrog.(palma e cocco in prop. var.) oli ( SOIA  girasole e colza in prop. var.) oli parzialmente idrogenati (palma e cocco in prop. var.oli ( SOIA e girasole e colza in prop. var.acqua emulsionanti (E471E 322 lectina di soia)  conser. (E 202))MOZZARELLA (LATTE pastorizzatofermenti lattici caglio microbico sale) FARINA00 PANE grattugiato (FARINA 0 acqua lievito salespezie) pepe prezzemolo E433olio di girasole. Può contenere:SEDANOPESCEUOVACROSTACEISOLFITISENAPE.",
            "plu": "051396"
        },
        {
            "_id": "131629",
            "name": "FRITTATINE GRANDI PF CONG.KG",
            "ingredients": "INGREDIENTI: acquaamido modificato di mais (E1422) proteine del LATTE BURRO in polvere pasta di GRANO duro  carne ( macinato di suino)passata di pomodoro (pomodoro fresco correttore di acidità: acido citrico) piselli (piselli cotti a vapore acqua zucchero e sale) misto per soffritto (carote SEDANO cipolle in quantità variabili) pancetta ( carne di suini sale destrosio aromi naturali spezie antiossidante: E316 conservanti : E250-E252) olio sale PECORINO ( LATTEcaglio  sale FORMAGGIO di pecora)GRANA PADANO ( LATTE sale caglio conservante:lisozima da UOVA)  FARINA 00  PANE grattuggiato (farina di GRANO tenero 0acqua lievito sale spezie)aroma naturale (oleoresina di curcuma e polisorbato E433) olio di girasole. Può contenere PESCE CROSTACEI ANIDRIDE SOLFOROSASENAPE. Conservare a -18°C. Prodotto surgelato allorigine.Non ricongelare.",
            "plu": "051400"
        },
        {
            "_id": "131630",
            "name": "FRITTATINE MEDIE PF CONG.KG",
            "ingredients": "INGREDIENTI: acquaamido modificato di mais (E1422) proteine del LATTE BURRO in polvere pasta di GRANO duro  carne ( macinato di suino)passata di pomodoro (pomodoro fresco correttore di acidità: acido citrico) piselli (piselli cotti a vapore acqua zucchero e sale) misto per soffritto (carote SEDANO cipolle in quantità variabili) pancetta ( carne di suini sale destrosio aromi naturali spezie antiossidante: E316 conservanti : E250-E252) olio sale PECORINO romano ( LATTEcaglio  sale FORMAGGIO di pecora) GRANA PADANO ( LATTE sale caglio conservante:lisozima da UOVA)  FARINA 00  PANE grattuggiato (farina di GRANO tenero 0acqua lievito sale spezie)aroma naturale (oleoresina di curcuma e polisorbato E433) olio di girasole. Può contenere PESCE CROSTACEI ANIDRIDE SOLFOROSASENAPE. Conservare a -18°C. Prodotto surgelato allorigine. Non ricongelare.",
            "plu": "051401"
        },
        {
            "_id": "131631",
            "name": "FRITTATINE EXTRA PAST.PF CONG.",
            "ingredients": "INGREDIENTI: acquaamido modificato di mais (E1422) proteine del LATTE BURRO in polvere pasta di GRANO duro  carne ( macinato di suino)passata di pomodoro (pomodoro fresco correttore di acidità: acido citrico) piselli (piselli cotti a vapore acqua zucchero e sale) misto per soffritto (carote SEDANO cipolle in quantità variabili) pancetta ( carne di suini sale destrosio aromi naturali spezie antiossidante: E316 conservanti : E250-E252) olio sale PECORINO romano ( LATTEcaglio  sale FORMAGGIO di pecora) GRANA PADANO ( LATTE sale caglio conservante:lisozima da UOVA)  FARINA 00  PANE grattuggiato (farina di GRANO tenero 0acqua lievito sale spezie)aroma naturale (oleoresina di curcuma e polisorbato E433) olio di girasole. Può contenere PESCE CROSTACEI ANIDRIDE SOLFOROSASENAPESOIA. Conservare a -18°C. Prodotto surgelato allorigine. Non ricongelare.",
            "plu": "051399"
        },
        {
            "_id": "131801",
            "name": "ARROSTICINI TOTANO/SALMONE KG",
            "ingredients": "INGREDIENTI:TOTANO GIGANTE DEL PACIFICO 70% (Dosidicus Gigas pescato in Oceano Pacifico Sudest zona Fao 87 con ami e palangari) Panatura (30%):semilavorato per panatura (farina di FRUMENTOlievito di birrasalepaprika)olio di semi di girasole prezzemolo succo di limone preparato per gratinatura (sale rosmarino AGLIO prezzemolo cipolla origano salvia timo alloro maltodestrine estratto di lievito aromi naturali (contiene PESCE e CROSTACEI)) aglio(SOLFITI)sale.Può contenere SEDANOSENAPESOIA. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "051029"
        },
        {
            "_id": "131802",
            "name": "FRITTURA MISTA CONG.AL KG",
            "ingredients": "INGREDIENTI: Anelli e fettuccine di TOTANO gigante (Dosidicus gigas- pescato in Oceano Pacifico zona FAO 87 con ami e palangari) Ciuffi di CALAMARO (Calamaro Indopacifico Uroteuthis duvacelii pescati in Oceano Indiano zona Fao 51 con reti da traino e/o CALAMARO del Pacifico Uroteuthis chinensis pescato in Oceano Pacifico Nordovest zona FAO 61 con reti da traino) GAMBERI (gambero indopacifico - Parapenaeopsis stilifera - pescati in Oceano Indiano Zona FAO 51 con reti da trainoantiossidante E223 (metabiSOLFITO di sodio)e/o MAZZANCOLLE tropicali (Litopenaeus Vannamei allevate in Vietnamcontiene metabiSOLFITO di sodio e/o GAMBERI Argentini sgusciati - Pleoticus muelleri pescati nellOceano Atlantico sud-ovest zona FAO 41 con reti da traino contiene metabiSOLFITO di sodio)farina di GRANO tenero tipo 00. Può contenere PESCE SEDANO SOIASENAPE. Conservare a -18°C.Una volta scongelato il prodotto non deve essere ricongelato. Conservare in frigorifero e consumato entro 24 ore previa cottura.",
            "plu": "051030"
        },
        {
            "_id": "131914",
            "name": "CANNELLONI RICOTTA/SPINACI KG",
            "ingredients": "INGREDIENTI:Pasta:farina di GRANO tenero 00 UOVA pastorizzate 55% acqua semola di GRANO duro. Ripieno:ricotta 42% (siero di LATTE ovino LATTE ovino sale) spinaci 13% sale pepe noce moscata. Può contenere SOIASENAPE. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050231"
        },
        {
            "_id": "131915",
            "name": "CANNELLONI CARNE AL KG",
            "ingredients": "INGREDIENTI:Pasta:farina di GRANO tenero 00UOVA pastorizzate acqua semola di GRANO duro sale. Ripieno:preparato di carne bovina e suina 15% (carne bovina 44% carne suina 44% cotenna suinacarota cipolla sale aromi spezie) besciamella (amido di mais LATTE intero in polvere siero di LATTE in polvereamido di riso farina di GRANO tenero tipo 00salegrasso vegetale di palma non idrogenato spezie) mortadella 13% (carne suinagrasso suinocotennafarina di risosalearomispezie conservante: E250PISTACCHIO prosciutto crudo 7% (carne suina sale spezie conservante: E250)pangrattato (farina di GRANO tenero 0 acqua lievito sale)salepepe noce moscata. Può contenere SOIA SENAPE. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050230"
        },
        {
            "_id": "134376",
            "name": "BURGER DI BRANZINO 80GR",
            "ingredients": "INGREDIENTI: BRANZINO 45% (Dicentrarchus labrax) (Allevato in Turchia) MERLUZZO NORDICO (Gadus morhua) (Pescato in Alantico nord orientale (FAO 27/IV) Albume dUOVOPatateOlio di semi di girasole Fiocco di patate (Patate disidratateemulsionanti mono digliceridi acidi grassi)Farina di mais succo di limone fibra di pisello saleerbe aromatiche spezieamido di patata e tapioca correttore di acidità: bicarbonato di sodio. Può contenere MOLLUSCHI e CROSTACEI. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050232"
        },
        {
            "_id": "134662",
            "name": "ANT.CAS.PIZZELLE ASTICE AL KG",
            "ingredients": "INGREDIENTI:Farina di GRANO teneroSURIMI 15% (polpa di PESCE 42%amido di FRUMENTOolio vegetalesale zucchero proteine della SOIA aroma di GRANCHIOalbume dUOVOE621 E160C E120)pomodoriolio di palmaASTICE 75% olive verdi cipolla sale capperi lievito di birra prezzemoloSEDANO carote paprika piccante origano.Può contenere MOLLUSCHI FRUTTA A GUSCIOSOIASENAPE.Conservare a T -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "051406"
        },
        {
            "_id": "134713",
            "name": "TOTANO ATLANTICO IQF 200/300KG",
            "ingredients": "INGREDIENTI: TOTANO ATLANTICO (Illex argentinus) pescato nellOceano Atlantico sud occidentale zona FAO 41 con reti da traino. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050922"
        },
        {
            "_id": "135130",
            "name": "FIORI SALMONE CILE C/PELLE KG",
            "ingredients": "INGREDIENTI: SALMONE (Salmo salar). Allevato in Cile (ZONA FAO N°87). Conservare a -18°C. Non ricongelare. Consumare previa cottura. Può contenere spine.",
            "plu": "050773"
        },
        {
            "_id": "135133",
            "name": "FILETTI TROTA SALM.150/200 KG",
            "ingredients": "INGREDIENTI: Filetti di TROTA SALMONATA (onchorynchus mykyss) con pelle. Allevato in Turchia. Conservare a -18°C. Non ricongelare. Consumare previa cottura. Può contenere spine.",
            "plu": "050499"
        },
        {
            "_id": "136483",
            "name": "BURGER DI SALMONE 100GR",
            "ingredients": "INGREDIENTI: SALMONE (Salmo salar) sale correttore di acidità: citrato di sodio. Conservare a -18°C. Non ricongelare. Consumare previa cottura. Può contenere spine.",
            "plu": "050234"
        },
        {
            "_id": "136647",
            "name": "RANA PESCATRICE 200/400 AL KG",
            "ingredients": "INGREDIENTI: RANA PESCATRICE del Pacifico (Lophius litulon) (zona FAO 61 pescata con rete da traino) stabilizzanti: E330E331.Prodotto congelato. Conservare a -18°C. Consumare previa cottura. Non ricongelare.",
            "plu": "050500"
        },
        {
            "_id": "136656",
            "name": "CALAMARI SPORCHI 3P CONG.AL KG",
            "ingredients": "INGREDIENTI: CALAMARO (Loligo vulgaris) Conservare a -18°C. Non ricongelare. Consumare previa cottura.",
            "plu": "050925"
        },
        {
            "_id": "136700",
            "name": "CROCCOFILETTI CONG. AL KG",
            "ingredients": "INGREDIENTI: Filetti di MERLUZZO dAlaska (Theragra chalcogramma) pescato in Oceano Pacifico Nordest zona FAO 67 con reti da traino (60%) farina di GRANO tenero olio di girasole sale. Può contenere UOVASOIASENAPE. Prodotto congelato.Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "051407"
        },
        {
            "_id": "137210",
            "name": "MERLUZZO NORDICO PORZ.CONG.KG",
            "ingredients": "INGREDIENTI: MERLUZZO nordico (Gadus Morhua) pescato nellOceano Atlantico Nord Occidentale FAO 27 con reti da traino acqua sale. Prodotto congelato. Conservare a -18°C. Non ricongelare e consumare previa cottura. Può contenere spine.",
            "plu": "050774"
        },
        {
            "_id": "138292",
            "name": "SCAMPI POLITI 21/25 CONG.KG",
            "ingredients": "INGREDIENTI: SCAMPI (Nephrops norvegicus) pescati con reti da traino nel Mar del Nord (FAO27) sodio metabiSOLFITO. Prodotto congelato. Conservare a -18°C. Consumare previa cottura.",
            "plu": "050339"
        },
        {
            "_id": "138365",
            "name": "CALAMARO PULITO U/5 AL KG",
            "ingredients": "INGREDIENTI:CALAMARO del pacifico (Uroteuthis Chinensis) Pescato in oceano Pacifico Nord Occidentale (zona FAO 61) con reti da trainocorrettore di acidità: E330E331 sale. Prodotto congelato. conservare a -18°C. Non ricongelare.",
            "plu": "050929"
        },
        {
            "_id": "138366",
            "name": "CALAMARO SPORCO 14/18 AL KG",
            "ingredients": "INGREDIENTI:CALAMARO del pacifico (Uroteuthis Chinensis)Pescato in Oceano Pacifico Nord Occidentale (zona FAO 61) con reti da trainocorrettore di acidità: E330E331 sale. Prodotto congelato. Conservare a-18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050927"
        },
        {
            "_id": "138367",
            "name": "CALAMARO SPORCO 18/22 AL KG",
            "ingredients": "INGREDIENTI:CALAMARO del pacifico (Uroteuthis Chinensis) Pescato in oceano Pacifico Nord Occidentale (zona FAO 61) con reti da trainocorrettore di acidità: E330E331 sale. Prodotto congelato. conservare a -18°C. Non ricongelare.",
            "plu": "050928"
        },
        {
            "_id": "138443",
            "name": "PACGEL CORN.PISTACCHIO CONG.90",
            "ingredients": "INGREDIENTI:Farina di FRUMENTOmargarina vegetale Crema di PISTACCHIO (LATTE magro reidratatozucchero sciroppo di glucosioamido di maisPISTACCHI 47% tuorlo dUOVO aromasale)zuccherolievito di birra UOVAGLUTINEsaleproteine del LATTELATTE scremato in polvereclorofilla.Può contenere SEMI DI SESAMO PESCESOIANOCCIOLESENAPE. Prodotto congelato. Conservare a -18°C. Non ricongelare. Consumare previa cottura.",
            "plu": "051249"
        },
        {
            "_id": "138706",
            "name": "FIORI SALMONE C/PEL.NOR150/250",
            "ingredients": "INGREDIENTI: SALMONE (Salmo Salar) acqua. Allevato in Norvegia. Prodotto congelato. Conservare a -18° C. Non ricongelare. Può contenere lische.",
            "plu": "050502"
        },
        {
            "_id": "138728",
            "name": "MUFFIN ALLA NUTELLA 90GR",
            "ingredients": "PRODOTTO DECONGELATO-Consumare entro 3 giorni. INGREDIENTI:zuccherocrema alle NOCCIOLE e cacao (contiene SOIA)farina di FRUMENTOUOVAoli veg. YOGURTlievitoacquatuorlo dUOVOsiero di LATTE amido modif.E450E500glutine di FRUMENTOE471 aromigomma di xantano e di guarsale. Può contenere ARACHIDISEMI DI SESAMO e altra FRUTTA A GUSCIO SOIASENAPE.",
            "plu": "051250"
        },
        {
            "_id": "139802",
            "name": "PANINO NAPOLETANO 150GR",
            "ingredients": "PRODOTTO PRECOTTO PRONTOFORNO CONGELATO INGREDIENTI:Farina di GRANO 0 acqua mix di salumi e formaggi 25% prosciutto cotto 15% (carne di suino 88% acqua sale aromi naturali destrosio saccarosio conservanti: E250 antiossidante E301) provolone piccante stagionato 10 % (LATTE sale caglio) salame 4% (carne di suino 91% sale destrosio spezie aromi naturali conservante: E250 antiossidante: E301) mortadella 1% (carne suina 940% grasso suino sale zuccheri amido spezie aromi naturali conservante: E250 antiossidante E300) olio extravergine di oliva 6% strutto LATTE in polvere sale zucchero lievito di birra emulsionante: E472 pepe. Può contenere UOVA SOIA SEMI DI SESAMO SOLFITI PISTACCHIOSENAPE. Conservare a -18°C.Non ricongelare.",
            "plu": "051409"
        },
        {
            "_id": "140870",
            "name": "POLPO T8 TUNISIA CONG. AL KG",
            "ingredients": "INGREDIENTI: POLPO (Octopus Vulgaris)pescato nel Mar Mediterraneo - zona FAO 37.2-con nasse e trappole. Può contenere PESCE e CROSTACEI. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050930"
        },
        {
            "_id": "140871",
            "name": "TENTACOLI DI POLPO CONG. AL KG",
            "ingredients": "INGREDIENTI: POLPO (Octopus Vulgaris)pescato nel Mar Mediterraneo - zona FAO 34 - con nasse e trappole. Può contenere PESCE e CROSTACEI. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050931"
        },
        {
            "_id": "141287",
            "name": "COTOLETTA SALMONE CONG.AL KG",
            "ingredients": "INGREDIENTI: SALMONE (Salmo salar)Farina di FRUMENTOolio di colzaacquafibre di FRUMENTO salelievitofarina di maisamido di FRUMENTO aglio in polvere (SOLFITI) sciroppo di glucosiopaprica in polvere cipolla in polverecurcuma in polvere aromi naturali.Può contenere SOIASENAPE. Conservare a -18°C. Non riconglare. Consumare previa cottura entro 24 ore.",
            "plu": "051031"
        },
        {
            "_id": "141579",
            "name": "FILET.ORATA.CONG.160/220GR",
            "ingredients": "INGREDIENTI: ORATA (Sparus aurata) allevata in Turchia zona FAO 37.3acqua E301E331. Conservare a -18°C. Consumare previa cottura entro 24h. Non ricongelare.",
            "plu": "050504"
        },
        {
            "_id": "141580",
            "name": "FILET.SPIGOLA CONG.160/220GR",
            "ingredients": "INGREDIENTI: BRANZINO (Dicentrarchus labrax) allevato in Turchia - zona FAO 373 acqua E331E301. Conservare a -18°C. Consumare previa cottura. Non ricongelare.",
            "plu": "050503"
        },
        {
            "_id": "141698",
            "name": "CALAMARO PUL.MAR. 2P CONG. KG",
            "ingredients": "INGREDIENTI:CALAMARO (Loligo vulgaris) del Marocco zona FAO 37pescati con reti da traino. Conservare a -18°C. Non ricongelare. Una volta scongelato consumare previa cottura entro 24 ore.",
            "plu": "050933"
        },
        {
            "_id": "141699",
            "name": "CALAMARO PUL.MAR. P CONG. KG",
            "ingredients": "INGREDIENTI:CALAMARO (Loligo vulgaris) del Marocco zona FAO 37pescati con reti da traino. Conservare a -18°C. Non ricongelare. Una volta scongelato consumare previa cottura entro 24 ore.",
            "plu": "050932"
        },
        {
            "_id": "141729",
            "name": "CUORE MERLUZ.NORD.500+CONG.KG",
            "ingredients": "INGREDIENTI: MERLUZZO NORDICO (Gadus Macrocephalus) pescato nel Pacifico Nord Est FAO 67 con ami e palangari sale. Conservare a -18°C. Non ricongelare. Una volta scongelato consumare previa cottura entro 24 ore.",
            "plu": "050775"
        },
        {
            "_id": "141730",
            "name": "ICELANDIC TRAN.MERL.180/250 KG",
            "ingredients": "INGREDIENTI: BACCALA (Gadus Morhua) pescato nel Nord Est Atlantico - zona FAO 27 con ami e reti da traino acqua e sale. Conservare a -18°C. Non ricongelare. Una volta scongelato consumare previa cottura entro 24 ore.",
            "plu": "050776"
        },
        {
            "_id": "141731",
            "name": "ICELANDIC PORZ.MERL.NORD.AL KG",
            "ingredients": "INGREDIENTI: BACCALA (Gadus Morhua) pescato nel Nord Est Atlantico - zona FAO 27 con ami e reti da traino acqua e sale. Conservare a -18°C. Non ricongelare. Una volta scongelato consumare previa cottura entro 24 ore.",
            "plu": "050777"
        },
        {
            "_id": "142008",
            "name": "SANGIORGIO CIAMB.ZUCCHER.100GR",
            "ingredients": "INGREDIENTI:Farina di FRUMENTOzucchero(14%) acqua margarina olio di girasole alto oleico grasso vegetale (burro di karit\u00c3\u00a9) acqua olio di girasole emulsionante mono e digliceridi degli acidi grassi concentrato aroma naturale olio vegetale (girasole) ALBUME pastorizzato UOVA pastorizzate lievito LATTE intero in polvere sale aromi lecitina (girasole) sciroppo di glucosio emulsionanti: E471 E472e (colza) agente di trattamento della farina: E300 agente antiagglomerante: carbonato di calcio alfa amilasi (FRUMENTO). Può contenere FRUTTA A GUSCIOSOIASENAPEARACHIDE. Conservare a T -18 gradi C.Una volta scongelato non ricongelare il prodotto.",
            "plu": "051497"
        },
        {
            "_id": "142011",
            "name": "SANGIORGIO CORN.VUOTO 80GR",
            "ingredients": "INGREDIENTI:Farina di FRUMENTOmargarina oli e grassi vegetali(palma e girasole);acqua; sale;emulsionante:mono-e digliceridi degli acidi grassi(palma);correttore di acidità: acido citrico; aromiacquazucchero UOVA pastorizzatestruttolievitosciroppo di glucosio disidratato(mais)emulsionanti:E472e E471agente di trattamento della farina: acido ascorbicoestratto di malto in polvere ORZO)alfa amilasilecitina di SOIAproteine del LATTELATTOSIOsaleolio di semi di girasole aromi.Può contenere SOIASENAPE ARACHIDI. Conservare a -18°C. Non ricongelare.",
            "plu": "051500"
        },
        {
            "_id": "142626",
            "name": "POLPI SENEGAL T3 2/3 AL KG",
            "ingredients": "INGREDIENTI: POLPO eviscerato (Octopus Vulgaris) pescato nellOceano Atlantico Orientale-Centrale zona FAO 34 con ami e palangari. Può contenere CROSTACEI E PESCE. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050934"
        },
        {
            "_id": "143461",
            "name": "STRIPS DI POLLO CONG.AL KG",
            "ingredients": "ING: Petto di pollo (63%) Olio di girasole PANE (farina di GRANO tenero tipo 0 lievito di birra sale) Preparato per salsa impanante (farina di FRUMENTO amido di FRUMENTO sale) Acqua Fiocchi di patate Sale Aromi naturali Spezie Aceto di alcool(SOLFITI) Peperoncini rossi piccanti Sodio caseinato (LATTE)SEMI DI SESAMO. Addensante: E407 Antiossidanti: E300 E301. Può contenere SOIASENAPE. Da consumare previa cottura.Conservare a -18°C. Non ricongelare. SESAMO.",
            "plu": "050028"
        },
        {
            "_id": "143822",
            "name": "CHICKEN STICK CONGELATI AL KG",
            "ingredients": "INGREDIENTI: Petto di pollo 75% olio di girasole prep. per salsa (farina e amido di FRUMENTOsale) salearomi naturalipomodoroolio di colzaspezie brodo granulato destrosiozuccheropaprikaprezzemolo sodio CASEINATOE300E301E407.Può contenere UOVA SOLFITISEMI DI SESAMOSENAPESOIA. Conservare a -18°C.Non ricongelare.",
            "plu": "050055"
        },
        {
            "_id": "143823",
            "name": "BURGER POLLO/CEREALI 200GR",
            "ingredients": "INGREDIENTI:Carne di pollo PANGRATTATO farina di FRUMENTOsaleolio di girasole curcumapaprikaacquafarina di SOIA aromi naturaliE300E301.Può contenere LATTESENAPE UOVASOLFITISEMI DI SESAMO. Conservare a -18°C. Non ricongelare.",
            "plu": "050077"
        },
        {
            "_id": "143824",
            "name": "CHICKEN RINGS BACON CONG.AL KG",
            "ingredients": "INGREDIENTI:Macinato di petto di polloolio di girasolePANEbacon (carne suina sale E250 ascorbato di sodioestratti di spezie aroma fumo) preparato per salsa (farina e amido di FRUMENTOsale acquafiochhi di patatesalearomi naturaliaroma fumodestrosio saccarosioolio di semi di colza olio di olivaaroma di affumicaturazucchero caramellatosodio CASEINATOE300E301.Può contenere SOLFITISEMI DI SESAMOSOIASENAPE. Conservare a -18°C. Non ricongelare.",
            "plu": "050029"
        },
        {
            "_id": "143871",
            "name": "FIORI SALMONE S/PEL.NOR150/250",
            "ingredients": "INGREDIENTI: Filetto di SALMONE (Salmo salar) allevato in Norvegia. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050778"
        },
        {
            "_id": "143986",
            "name": "HOTDOG VIENNESE 105GR",
            "ingredients": "INGREDIENTI: Wurstel (carne suinapancettaacqua saledestrosiospeziecitrato di sodio difosfati ascorbato di sodioestratto di rosmarinonitrito di sodio fumo) farina di FRUMENTOBURROacqua SEMI DI SESAMOlievitosalezuccheroUOVO destrosiomono e digliceridi degli acidi grassi acido ascorbicoamilasixilanasi. Può contenere FRUTTA A GUSCIOSOIALUPINISEDANOSENAPE.",
            "plu": "051391"
        },
        {
            "_id": "144333",
            "name": "GAMBERI SGU.80/120 CONG.AL KG",
            "ingredients": "INGREDIENTI: GAMBERI indopacifici (Metapenaeus affinis o Parapenaeopsis stylifera o Solenocera crassicornis) pescati con reti da traino in Oceano indiano occidentale FAO51 acqua sale correttore di acidità: E330 antiossidante: metabiSOLFITO di sodio. Può contenere PESCE MOLLUSCHI. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050939"
        },
        {
            "_id": "144446",
            "name": "CARCIOFI CONG. AL KG",
            "ingredients": "INGREDIENTI: Carciofi. Può contenere GLUTINESOIASEDANOSOLFITI. Conservare a -18°C. Non ricongelare. Consumare previa cottura.",
            "plu": "051461"
        },
        {
            "_id": "144502",
            "name": "SANGIORGIO MUFFIN CLAS 70GR",
            "ingredients": "INGREDIENTI:ZuccheroFarina di GRANO TENEROacqua UOVAolio di girasoleBURROamido di GRANOolio di palmaamido modif.siero di LATTEdifosfato disodicocarbonato acido di sodioE481E472e E472bE475salearomiE415sciroppo di glucosio LATTE.Può contenere ARACHIDISEMI DI SESAMOSOIA SOLFITIFRUTTA A GUSCIOSENAPE. Conservare a -18°C.Non ricongelare.",
            "plu": "051415"
        },
        {
            "_id": "144503",
            "name": "SANGIORGIO MUFFIN ALBICOC.85GR",
            "ingredients": "INGREDIENTI:ZuccheroFarina di GRANO TENEROacqua farcitura albicocca 16% (purea albicocchesciroppo di glucosio-fruttosiosaccarosiopectinaE330aromi) UOVAolio di girasoleBURROamido di GRANOolio di palmaamido modif.siero di LATTEdifosfato disodicocarbonato acido di sodioE481E472e E472bE475salearomiE415sciroppo di glucosio LATTE.Può contenere ARACHIDISEMI DI SESAMOSOIA SOLFITIFRUTTA A GUSCIO SENAPE. Conservare a -18°C.Non ricongelare.",
            "plu": "051416"
        },
        {
            "_id": "144504",
            "name": "SANGIORGIO MUFFIN CACAO 85GR",
            "ingredients": "INGREDIENTI:Zuccherocrema al cioccolato 17% (cioccolato fond.50%grasso vegetale zuccheroamido E442amido)farina di GRANO teneroUOVAolio di girasoleBURROamido di GRANOacquacioccolato fondente a pezzi 4% (zuccheropasta e burro di cacao E322aroma vaniglia)cacao in polvere 35%olio di palmaamido modif.siero di LATTEdifosfato disodicocarbonato acido di sodioE481E472e E472bE475salearomiE415sciroppo di glucosio LATTE.Può contenere ARACHIDISEMI DI SESAMOSOIA SOLFITIFRUTTA A GUSCIO SENAPE. Conservare a -18°C.Non ricongelare.",
            "plu": "051417"
        },
        {
            "_id": "144505",
            "name": "SANGIORGIO MUFFIN CER/MIR 85G",
            "ingredients": "INGREDIENTI:Zuccherofarina di GRANO tenero farcitura di mirtilli 14%(purea di mirtilli sciroppo di glucosio-fruttosiosaccarosio pectinaCARAMELLO SOLFITO CAUSTICOsucco di limonearoma)acquaUOVAolio di semi di girasolesemilavorato ai CEREALI (FRUMENTO SEMI DI SESAMOSEGALEAVENAORZO)BURRO amido di GRANO teneroolio di palmaamido modificato fiocchi di AVENAsiero di LATTE difosfato disodicocarbonato acido di sodio E481E472e E472bE475salearomiE415 sciroppo di glucosioLATTEcacao. Può contenere ARACHIDISEMI DI SESAMO SOIAFRUTTA A GUSCIOSENAPE. Conservare a -18°C.Non ricongelare.",
            "plu": "051419"
        },
        {
            "_id": "144506",
            "name": "SANGIORGIO MUFFIN PISTACC.85GR",
            "ingredients": "INGREDIENTI:Zuccheroripieno al PISTACCHIO 15% (Sciroppo di glucosiodestrosioacqua pasta di PISTACCHIOLATTEE1442E1450E460iE466E170 E141aromaacido citricosale E202)acquaUOVA Farina di GRANO teneroamido di GRANOolio di semi girasoleBURROolio di palma amido modif. siero di LATTEsemi di linofarina di mais crusca di pisello difosfato disodicocarbonato cido di sodioE481E472ebeta carotene E472bE475sale aromiE415sciroppo di glucosio LATTE.Può contenere ARACHIDISEMI DI SESAMOSOIA SOLFITIaltra FRUTTA A GUSCIOSENAPE. Conservare a -18°C.Non ricongelare.",
            "plu": "051418"
        },
        {
            "_id": "145163",
            "name": "POLPO RICCIO MAROCCO F5 AL KG",
            "ingredients": "INGREDIENTI: POLPO (Octopus vulgaris) pescato in Marocco zona FAO 34/37 con ami e palangari. Può contenere PESCECROSTACEI. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050940"
        },
        {
            "_id": "145164",
            "name": "POLPO RICCIO MAROCCO F6 AL KG",
            "ingredients": "INGREDIENTI: POLPO (Octopus vulgaris) pescato in Marocco zona FAO 34/37 con ami e palangari. Può contenere PESCECROSTACEI. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050941"
        },
        {
            "_id": "145165",
            "name": "POLPO RICCIO MAROCCO F7 AL KG",
            "ingredients": "INGREDIENTI: POLPO (Octopus vulgaris) pescato in Marocco zona FAO 34/37 con ami e palangari. Può contenere PESCECROSTACEI. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050942"
        },
        {
            "_id": "145436",
            "name": "CALAMARO SPORCO 22/29 AL KG",
            "ingredients": "INGREDIENTI: CALAMARO (Uroteuthis chinensis) origine Cina zona FAO pescato con reti da traino. Conservare a -18°C. Consumare previa cottura e non ricongelare.",
            "plu": "050943"
        },
        {
            "_id": "145464",
            "name": "FILETTO MERLUZ.160/200 CONG.KG",
            "ingredients": "INGREDIENTI: BACCALA (Merluccius capensis/paradoxus) pescato in Namibia zona FAO 47 con ami e palangari. Prodotto congelato. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050505"
        },
        {
            "_id": "145829",
            "name": "POLPO RICCIO TUNISIA T6 CONG.",
            "ingredients": "INGREDIENTI: POLPO (Octopus vulgaris)pescato in Tunisia zona FAO 37.1.3-37.2.2 con reti da traino. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050944"
        },
        {
            "_id": "145830",
            "name": "POLPO RICCIO TUNISIA T7 CONG.",
            "ingredients": "INGREDIENTI: POLPO (Octopus vulgaris)pescato in Tunisia zona FAO 37.1.3-37.2.2 con reti da traino. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050945"
        },
        {
            "_id": "145831",
            "name": "POLPO RICCIO TUNISIA T8 CONG.",
            "ingredients": "INGREDIENTI: POLPO (Octopus vulgaris)pescato in Tunisia zona FAO 37.1.3-37.2.2 con reti da traino. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050946"
        },
        {
            "_id": "146356",
            "name": "DONUT LOVE 54GR",
            "ingredients": "INGREDIENTI:farina (FRUMENTO; riso ); oli e grassi oli e grassi vegetali (palma; cocco; colza; girasole);acqua;zucchero;lievito;cacao magro in polv.; destrosio;siero di LATTEn polvere (07%);emulsionanti in polv.dolce;farina di SOIA;sale;GLUTINE di FRUMENTO; E450; E500; LATTE scremato in polv.;E471; E481 conc. di mela;conc. di ciliegia;E162; curcumina concentrato di barbabietola rossa;estratto di vaniglia. Può contenere SENAPE FRUTTA A GUSCIO UOVA. Prodotto congelato.Conservare a -18°C. Non Ricongelare.",
            "plu": "051422"
        },
        {
            "_id": "146432",
            "name": "SORRENTO PAN PIZZA 120GR",
            "ingredients": "PRODOTTO DA FORNO PRECOTTO CONGELATO INGREDIENTI: farina di FRUMENTO acqua sale olio di semi di girasole farina di FRUMENTO maltata zucchero lievito. Può contenere LATTE SOIASENAPE. Conservare a -18°C. Non ricongelare. Consumare previa cottura. Se cotto ottenuto da prodotto prontoforno surgelato.",
            "plu": "051423"
        },
        {
            "_id": "146439",
            "name": "POLPO MAROCCO TR7 AL KG",
            "ingredients": "INGREDIENTI: POLPO (Octopus vulgaris) pescato in Oceano Atlantico centro orientale zona FAO 34con nasse e trappole acqua. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050948"
        },
        {
            "_id": "147069",
            "name": "CROCCOLONI PASTELLATI CONG.KG",
            "ingredients": "INGREDIENTI:MERLUZZO di Alaska 51% farina di FRUMENTOPANATURA(farina di FRUMENTO lievitozucchero destrosio saleGLUTINE di FRUMENTO paprika)acqua olio di colza amido di FRUMENTO sale MOSTARDA olio di semi di girasole curcuma. Può contenere SOIA. Conservare a -18°C. Da consumare previa cottura. Non ricongelare il prodotto una volta scongelato",
            "plu": "051424"
        },
        {
            "_id": "147614",
            "name": "COTOLETTA VALDOSTANA 150GR",
            "ingredients": "INGREDIENTI:LATTEcaglio microbicosalefermenti latticicarne suinaamido di patata destrosio saccarosio aromi e spezie E621E316E450E451 E205 farina di FRUMENTO amido di maisolio di palma raffinatosciroppo di glucosioE500 PANE grattugiato (farina di FRUMENTOacquasale). Può contenere FRUTTA A GUSCIO SOIA ANIDRIDE SOLFOROSAPESCE MOLLUSCHISENAPE Conservare a -18°C. Non ricongelare. Consumare previa cottura.",
            "plu": "051427"
        },
        {
            "_id": "147615",
            "name": "CANESTRELLA BESCIAM/SALM.150GR",
            "ingredients": "INGREDIENTI:Guscio di CAPPASANTA Atlantica BESCIAMELLA 777% (acquaLATTEsiero di LATTE grasso raffinato amido modificato sale noce moscata E551 SALMONE 65% (SALMONE 81% vino (SOLFITI) Olio di oliva sale fumo naturale) MOZZARELLA (LATTEfermenti LATTICI cagliosale)addensanti (amido modificato destrosio)PANE grattugiato (farina di FRUMENTO acqua lievito sale).Può contenere UOVA FRUTTA A GUSCIO SOIASENAPE. Conservare a -18°C. Non ricongelare. Consumare a previa cottura.",
            "plu": "051426"
        },
        {
            "_id": "147616",
            "name": "SCAGLIUOZZO DI POLENTA AL KG",
            "ingredients": "INGREDIENTI:Acqua Farina di mais ciccioli (suino sale conservanti: E250)Olio di olivaGRANA PADANO (LATTE sale caglio conservante:lisozima da UOVO) Prezzemolo PECORINO romano (LATTE di pecora sale caglio) sale aglio(SOLFITI) Può contenere FRUMENTO PESCE MOLLUSCHIUOVA FRUTTA A GUSCIOSOIASENAPE. Prodotto fritto in olio di semi di girasole. Conservare a -18°C. Non ricongelare. Consumare previa cottura.",
            "plu": "051425"
        },
        {
            "_id": "147760",
            "name": "PACGEL STRUDEL CIOK 90GR",
            "ingredients": "INGREDIENTI: Farina di FRUMENTO; margarina vegetale grassi vegetali (palma cocco) acqua oli vegetali (girasole) correttore di acidità (E331 E330) emulsionanti (E471) sale conservante (E202) aromi coloranti (E160a); crema NOCCIOLA 18% zucchero oli vegetali (palma e colza) cacao magro in polvere NOCCIOLE (55%) LATTE intero in polvere (2%) amido emulsionante (E322) aromi; acqua; strutto; zucchero; sale; lievito disattivato; zucchero invertito; destrosio LATTOSIO amido di FRUMENTO. Il prodotto può contenere:SENAPE SEMI DI SESAMO ARACHIDI FRUTTA A GUSCIO PESCE SOLFITI SOIA. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "051191"
        },
        {
            "_id": "148509",
            "name": "PANINO NAPOLETANO 50GR",
            "ingredients": "PRODOTTO PRONTOFORNO CONGELATO INGREDIENTI: Farina di GRANO tenero tipo 0 acqua mix di salumi e formaggi 25% (prosciutto cotto PROVOLONE PECORINO salame mortadella) olio extravergine di oliva strutto LATTE in polvere sale zucchero lievito di birra miglioratore di lievitazione E472 pepe. Può contenere SOIA SENAPE SEMI DI SESAMO FRUTTA A GUSCIO. Conservare a -18°C. Non ricongelare. Se cotto ottenuto da prodotto precotto congelato.",
            "plu": "051428"
        },
        {
            "_id": "148870",
            "name": "BRIOCHE A TUPPO 100GR",
            "ingredients": "INGREDIENTI: Farina di GRANO tenero tipo 00 LATTEBURROUOVAzuccherolievito di birra agente lievitante (contiene FRUMENTO)sale aroma naturale di arancioaromi. Può contenere SOIASENAPEARACHIDIFRUTTA A GUSCIO SEMI DI SESAMO. Prodotto congelato. Conservare a -18°C. Non ricongelare.",
            "plu": "051151"
        },
        {
            "_id": "149108",
            "name": "SAN GIORGIO SFOGL.RICCIA 130G",
            "ingredients": "PRODOTTO PRONTOFORNO CONGELATO INGREDIENTI: Farina di FRUMENTO acqua RICOTTA strutto zucchero semola di GRANO duro Crema (contiene LATTEUOVA)UOVA pastorizzate cubetti di arancia canditi (contiene GLUTINE) saleamido modificato di maisamido di riso LATTE intero in polvere LATTE magro in polvere E407betacarotenearomivanillina. Può contenere SOIASENAPEFRUTTA A GUSCIO. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "051237"
        },
        {
            "_id": "149109",
            "name": "SAN GIORGIO SFOGL.FROLLA 130G",
            "ingredients": "PRODOTTO PRONTOFORNO CONGELATO INGREDIENTI: Farina di FRUMENTO acqua margarina RICOTTA strutto zucchero semola di GRANO duro Crema (contiene LATTEUOVA)UOVA pastorizzate cubetti di arancia canditi (contiene GLUTINE) salebicarbonato dammonioLATTE intero in polvere LATTE magro in polvereE407betacarotenearomi vanillina.Può contenere SOIA SENAPEFRUTTA A GUSCIO. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "051236"
        },
        {
            "_id": "149111",
            "name": "RUST\u00c3\u0152 ARROSTICINI OVINO KG",
            "ingredients": "INGREDIENTI: Carne di ovino adulto. Prodotto congelato. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050197"
        },
        {
            "_id": "149112",
            "name": "RUST\u00c3\u0152 ARROST.CAMPAGNOLI KG",
            "ingredients": "INGREDIENTI: Carne di ovino e bovino adulto. Prodotto congelato. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050181"
        },
        {
            "_id": "149113",
            "name": "DONUT ZEBRATO CIOCCOLATO 75GR",
            "ingredients": "INGREDIENTI: Farina di FRUMENTO grassi e oli vegetaliacqua cacao magro in polvere lievito cacao in massa lecitine mono e digliceridi degli acidi grassiE481E476 farina di SEGALEdestrosioLATTE scremato in polveresciroppo di glucosiosaledifosfati carbonati di sodioE415acido ascorbicoaromi naturali aroma di vaniglia. Può contenere UOVASOIAFRUTTA A GUSCIO. Prodotto congelato. Conservare a -18°C.Non ricongelare.",
            "plu": "051152"
        },
        {
            "_id": "149256",
            "name": "CORNETTO ISCHIA CREMA/AMAR.98G",
            "ingredients": "PRODOTTO PRONTOFORNO CONGELATO INGREDIENTI: Farina di FRUMENTO margarina crema 15% (acqua zucchero E1442 tuorlo dUOVO zuccherato LATTE intero in polvere LATTE scremato in polvere olio di cocco E202 aromi naturali) acqua lievito madre fresco 11 5% (farina di FRUMENTO acqua) confettura di amarene 5% zucchero 35% lievito UOVA BURRO BURRO concentrato aromi naturali sale sciroppo di glucosioamido di FRUMENTO maltodestrine E471 E472 miele in polvere E170 fibre vegetaliE300 E440 enzimi E330. Può contenere: SOIA FRUTTA A GUSCIO SENAPE SEMI DI SESAMO. Conservare a -18°C. Non ricongelare. Consumare previa cottura.Se cotto ottenuto da prodotto Prontoforno congelato.",
            "plu": "051155"
        },
        {
            "_id": "149347",
            "name": "POLPI SENEGAL T4 15/2 AL KG",
            "ingredients": "INGREDIENTI: POLPO (Octopus vulgaris) pescato nellOceano Atlantico Orientale Centrale (Fao 34) con ami e palangari. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050853"
        },
        {
            "_id": "149677",
            "name": "ICELANDIC LOMO JUMBO CONG.",
            "ingredients": "INGREDIENTI: MERLUZZO (Gadus morhua)acquasale. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050492"
        },
        {
            "_id": "150077",
            "name": "FRITTELLE DI MARE CONG.AL KG",
            "ingredients": "INGREDIENTI: FARINA di GRANO tipo 00 acqua sale lievito di birra(prod.naturale costituito da Saccoromyces Cervisiae) lattuga di mare(fiocchi di alga disidratata macinata)olio di girasole. Può contenere frammenti di conchiglie e tracce di prodotti a base di SEDANO ANIDRIDE SOLFOROSA PESCE CROSTACEI LATTE  SOIASENAPE. Conservare a temperatura non superiore a -18°C una volta scongelato non ricongelare e consumare entro 24h previa cottura.",
            "plu": "051341"
        },
        {
            "_id": "150260",
            "name": "FILETTI LIMANDA 90/130 AL KG",
            "ingredients": "INGREDIENTI: Filetti di LIMANDA congelati (Limanda Aspera) pescati in Oceano Pacifico Nord Orientale zona FAO 67con reti a strascico.Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050507"
        },
        {
            "_id": "150268",
            "name": "SCAMPI INTERI 20/30 CONG.KG",
            "ingredients": "INGREDIENTI: SCAMPO congelato (Nephrops norvegicus) pescato nel Mar dIrlanda zona FAO 27.7 con reti da traino E22. Conservare a -18° C. Non ricongelare e consumare previa cottura.",
            "plu": "050343"
        },
        {
            "_id": "150269",
            "name": "SCAMPI INTERI 30/40 CONG.KG",
            "ingredients": "INGREDIENTI: SCAMPO congelato (Nephrops norvegicus) pescato nel Mar dIrlanda zona FAO 27.7 con reti da traino E22. Conservare a -18° C. Non ricongelare e consumare previa cottura.",
            "plu": "050344"
        },
        {
            "_id": "150346",
            "name": "TOTANO TENTACOLI AL KG",
            "ingredients": "INGREDIENTI: TOTANO gigante del pacifico (Dosidicus Gigas) congelato pescato nellOceano Pacifico Sud orientale zona FAO 87 con ami e palangari. Può contenere PESCE CROSTACEI. Prodotto congelato. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050950"
        },
        {
            "_id": "150947",
            "name": "FIORI DI ZUCCA ALLA RICOTTA KG",
            "ingredients": "INGREDIENTI:Acqua RICOTTA 19% (siero di LATTE crema di LATTE  sale) fiori di zucca 18% farina di FRUMENTO olio di semi di girasole MOZZARELLA 5% (LATTE sale caglio microbico) PROVOLA affumicata 4% (LATTE  sale caglio) preparato per BESCIAMELLA (amido modificato  grassi vegetali in polvere: cocco LATTE magro in polvere LATTOSIO BURRO in polvere saleproteine del LATTE) Grana Padano DOP 1% (LATTE sale caglio lisozima da UOVO)sale lievitobasilico pepe nero. Può contenere: SOLFITI PESCE MOLLUSCHI CROSTACEI SEDANO SENAPE SOIA LUPINO ARACHIDI FRUTTA A GUSCIO SEMI DI SESAMO.",
            "plu": "051421"
        },
        {
            "_id": "151072",
            "name": "PANINO HAMBURG.BARBABIETOLA95G",
            "ingredients": "INGREDIENTI: Farina di FRUMENTO purea di barbabietola rossa 282% acqua SEMI DI SESAMO agente di cottura (Farina di FRUMENTO Farina di FRUMENTO maltatoenzimiagente di trattamento della farina:E300)lievito GLUTINE di FRUMENTO sale. Può contenere UOVA LATTESOLFITISEDANO SENAPEFRUTTA A GUSCIOSOIALUPINO. Prodotto congelato. Conservare a -18°C. Non ricongelare. Scongelare per 20 minuti e consumare entro 24 ore.",
            "plu": "051432"
        },
        {
            "_id": "151073",
            "name": "PANINO HAMBURG.SPINACI 95GR",
            "ingredients": "INGREDIENTI: Farina di FRUMENTO purea di spinaci 282% acqua SEMI DI SESAMO agente di cottura (Farina di FRUMENTO Farina di FRUMENTO maltatoenzimiagente di trattamento della farina:E300)lievito GLUTINE di FRUMENTO sale. Può contenere UOVALATTESOLFITI SEDANO SENAPEFRUTTA A GUSCIOSOIALUPINO. Prodotto congelato. Conservare a -18°C. Non ricongelare. Scongelare per 20 minuti e consumare entro 24 ore.",
            "plu": "051431"
        },
        {
            "_id": "151074",
            "name": "PANINO HAMBURG.PEPERONI 95GR",
            "ingredients": "INGREDIENTI: Farina di FRUMENTO purea di peperone rosso 282%acqua SEMI DI SESAMO agente di cottura (Farina di FRUMENTO Farina di FRUMENTO maltatoenzimiagente di trattamento della farina:E300)lievito GLUTINE di FRUMENTO sale. Può contenere UOVA LATTESOLFITISEDANO SENAPEFRUTTA A GUSCIOSOIALUPINO. Prodotto congelato. Conservare a -18°C. Non ricongelare. Scongelare per 20 minuti e consumare entro 24 ore.",
            "plu": "051430"
        },
        {
            "_id": "151627",
            "name": "GAMBERI ARGENTINI JUMBO SGU KG",
            "ingredients": "INGREDIENTI: GAMBERI argentini (Pleoticus muelleri) pescato nellOceano Atlantico sud occidentale (Zona FAO 41) con reti da traino antiossidante: E223 (metabiSOLFITO di sodio) correttori di acidità: E330-E331. Può contenere PESCE MOLLUSCHI.Prodotto congelato. Conservare a -18°C. Non ricongelare. Consumare previa cottura.",
            "plu": "050345"
        },
        {
            "_id": "151628",
            "name": "RAVIOLI DI CARNE SURG.AL KG",
            "ingredients": "INGREDIENTI:farina di GRANO tenero 00UOVA pastorizzate 75% acqua semola di GRANO duro sale. salepreparato di carne bovina e suina 15% BESCIAMELLAmortadella (contiene PISTACCHIO) prosciutto crudo 7% PANGRATTATOpepe noce moscata. Prodotto congelato. Conservare a -18°C. Non ricongelare. Consumare previa cottura",
            "plu": "050235"
        },
        {
            "_id": "152605",
            "name": "CALAMARI SPORCHI MAROCCO M KG",
            "ingredients": "INGREDIENTI: CALAMARO(Loligo Vulgariss). pescato nellOceano Atlantico(Fao 34) con nasse e trappole. Può contenere: CROSTACEIPESCE. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050951"
        },
        {
            "_id": "153293",
            "name": "S.GIORGIO ZEPPOLA FORN RIP.30G",
            "ingredients": "INGREDIENTI:Crema 73% acqua; zucchero;sciroppo di glucosio; LATTE intero in polvere; amido modificato (mais); tuorlo d UOVO fresco; BURROPANNAgrassi vegetali (cocco);E202 E471salearomibetacarotene UOVA pastorizzate acqua farina di FRUMENTO olio di semi di girasoleciliegia amarenata 6% ciliegie* (46%); sciroppo di glucosio-fruttosio (da FRUMENTO); saccarosio; succo concentrato di amarena; E330;E163; aromi ANIDRIDE SOLFOROSA acqua farina di FRUMENTOolio di semi di girasole zucchero sali aromi.Può contenere SOIASENAPE SEDANOUOVAPESCEFRUTTA A GUSCIO.Conservare a -18°C. Scongelare il prodotto a temperatura ambiente per 120 minuti circa e servire. Può contenere noccioli.",
            "plu": "051158"
        },
        {
            "_id": "153294",
            "name": "S.GIORGIO ZEPPOLA FOR RIP.120G",
            "ingredients": "INGREDIENTI:Crema 66% acqua; zucchero;sciroppo di glucosio; LATTE intero in polvere; amido modificato (mais); tuorlo d UOVO fresco; BURROPANNAgrassi vegetali (cocco);E202 E471salearomibetacarotene UOVA pastorizzate acqua farina di FRUMENTO olio di semi di girasoleciliegia amarenata 33% ciliegie* (46%); sciroppo di glucosio-fruttosio (da FRUMENTO); saccarosio; succo concentrato di amarena; E330;E163; aromi ANIDRIDE SOLFOROSA zucchero sali aromi.Può contenere SOIASENAPE SEDANOUOVAPESCEFRUTTA A GUSCIO. Conservare a -18°C. Scongelare il prodotto a temperatura ambiente per 120 minuti circa e servire. Può contenere noccioli.",
            "plu": "051157"
        },
        {
            "_id": "154879",
            "name": "SEPPIA PUL.CONG.10% 500/1000KG",
            "ingredients": "INGREDIENTI: SEPPIA (Sepia pharaonis) pescata nelOceano Indiano Occidentale (zona Fao 51) e Orientale (zona Fao 57) con reti da imbrocco e analoghe ami e palangari. Antiossidanti: E330E331. Può contenere PESCE CROSTACEI. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050954"
        },
        {
            "_id": "156177",
            "name": "PACGEL POLACCA CR/AMA.CONG.100",
            "ingredients": "INGREDIENTI: Farina di GRANO tenero tipo 0; margarina vegetale oli e grassi vegetali non idrogenati 80% (palma SOIA girasole colza mais in prop. variabile)acquaE471 E322E475sale(06%)E330E202 aromi E160; crema 18 % acqua LATTE intero pastorizzato sciroppo di glucosio zucchero amido modificato grassi vegetali (palma) proteine del LATTE addensante (E466) aromi sale colorante (E160); farcitura allamarena (8%) Sciroppo di glucosio-fruttosio; Amarena (30%); saccarosio; amarene candite 15% (ciliegie rotte; sciroppo di glucosio-fruttosio; saccarosio; gelificante (E440i); correttore di acidità (E330); colorante (E163); acqua; zucchero; lievito di birra; UOVA; GLUTINE; emulsionanti (E471 E472e) destrosio; farina di FRUMENTO MALTATO acido L-ascorbico enzimi; E472; proteine del LATTE; LATTE scremato in polvere; sale; aromi.Può contenere:SEMI DI SESAMO ARACHIDI FRUTTA A GUSCIO PESCESOLFITISOIASENAPE. Conservare a T-18°C una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "051185"
        },
        {
            "_id": "164525",
            "name": "TRANCI_VERDESCA S/PELLE AL KG",
            "ingredients": "INGREDIENTI: VERDESCA (Prionace glauca) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050755"
        },
        {
            "_id": "164954",
            "name": "TRIGLIE C/BORDO 300/500 AL KG",
            "ingredients": "INGREDIENTI: TRIGLIA (Mullus Barbatus) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050619"
        },
        {
            "_id": "170000",
            "name": "RISPO ARANCINI MEDI PF CONG.K",
            "ingredients": "INGREDIENTI:risoacquapolpa di pommozzarella(LAT- TEsalefermenti LATTICIcaglio)sem. per impanatura (f.di FRUMENTOlievito di birrapapricacoloranti: zucc.caramannato)f.di FRUMENTOcarne bovina pisellivino(SOLFITI)provolone(LATTEcaglioag.antia- glomerante semola di GRANO duro)Grana Padano DOP (LATTEcaglioconservante:lisozima-prot.dellUOVO) pecorino(LATTE di pecoracaglio)o.di semi girasole conc.di pomodoroSEDANOcarota cipollapr.per bescia- mella(amido mod.di maisLATTE scremLATTOSIOprot. del LATTEBURRO grassi veg.di cocco)prep.per brodo (sale iodatofec.di patateestr.lievitograsso veg. di palmacipollaaromispezie)bietola rossao.evo basilicopepeaglio(SOLFITI)noce moscataalloro. Può contenere:SOIAPESCEANIDRIDE SOLFOROSASENAPE. Conservare a -18°C. Non ricongelare.",
            "plu": "051343"
        },
        {
            "_id": "170002",
            "name": "RISPO BACCALA PAST.CONG.AL KG",
            "ingredients": "INGREDIENTI:BACCALA(GADUS MORHUAzona FAO27) pastella(acquaamido di maisf.di FRUMENTO f.di risoag.lievit:E450iE500iiE341isaleE621gomma di Xanthanpepespezie)pangratt(f. di FRUMENTO liev.di birra)o.di semi di girasole. Può contenere:SEDANOANIDRIDE SOLFOROSA e SOLFITI UOVOLATTE e prod.a base di LATTESENAPESOIA. Può contenere spine.",
            "plu": "051344"
        },
        {
            "_id": "170004",
            "name": "RISPO FRITT.SPAGH.PAST.PF CONG",
            "ingredients": "INGREDIENTI:pasta di semola di GRANO duroBESCIAMELLA (amido di maisLATTE scremato LATTOSIOpr.del LATTEBURROgrassi veg.di coccosale)PASTELLA (amido di maisf.di FRUMENTOf.di risoE450iE500ii E341iE621gomma di Xanthanspeziepepe nero cayenne) rag\u00c3\u00b9 bianco carne bovina(vino(SOLFITI)SEDANOcipolla o.evobrodo:fec.di patateest.di lievitogras.veg.di palmaaromispeziealloronoce moscata)prosc.cotto MOZZARELLA(LATTE salecagliofer.LATTICI)PROVOLONE (LATTEcagliosemola di GRANO duro)GRANA PADANO DOP (LATTEcagliolisozima-proteine dellUOVO)piselli PECORINO(LATTE di pecoracaglio)o.di semi di giras prezzemoloE503ii. Può contenere: PESCE SENAPE SOLFITISOIA. Conservare a -18°C. prodotto surgelato allorigine. Non ricongelare.",
            "plu": "051346"
        },
        {
            "_id": "174577",
            "name": "PANZEROTTINI FRIAR/PROV.CON.KG",
            "ingredients": "INGREDIENTI:Farina di GRANO tenero ?tipo?00? acqua MOZZARELLA (LATTE sale FERMENTI LATTICI caglio antiagglomerante semola di GRANO duro) PROVOLA (LATTE sale caglio FERMENTI LATTICI) friarielli (broccoli olio di semi di girasole sale peperoncino E330 E200) strutto lievito di birra sale fiocchi di patate patate emulsionanti (E471) coadiuvanti tecnologici semilavorato attivatore per pane e prodotti lievitati (farina di GRANO tenero tipo ?0? emulsionanti (E471 E322) destrosio farina di FRUMENTO maltato alfa amilasi E300 FORMAGGIO da grattugia (LATTE FERMENTI LATTICI sale caglio) emulsionanti (E471) zucchero olio di semi di girasole pepe peperoncino. IL prodotto può contenere : SENAPE SEMI DI SESAMO ARACHIDI FRUTTA A GUSCIO PESCE SOLFITI UOVA SOIA LATTE. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "051347"
        },
        {
            "_id": "177141",
            "name": "PACGEL SFOGL.FROLLA SOUFF.100G",
            "ingredients": "INGREDIENTI: Farina di GRANO tenero tipo 00; farcitura {semola di GRANO duro; pasta concentrata al cacao oli vegetali zucchero cacao magro BURRO di cacao NOCCIOLE (12%) LATTE scremato in polvere emulsionante (LECITINA DI SOIA) aromi; ricotta (siero di LATTE vaccino sale); crema NOCCIOLA 10% zucchero oli vegetali (palma e colza) cacao magro in polvere NOCCIOLE (55%) LATTE intero in polvere (2%) amido emulsionante (E322 aromi; zucchero; UOVA; LATTE scremato in polvere}; zucchero; margarina vegetale grassi vegetali (palma) acqua olio vegetale (girasole) LATTE scremato in polvere emulsionante (E471) sale correttore di acidità (E330) conservante (E202) aromi colorante (E160a); acqua; strutto; LATTE scremato in polvere; zucchero invertito; agente lievitante (ammonio bicarbonato); aromi; colorante (E100).Copertura:UOVO.Può contenere:SENAPE SEMI DI SESAMOARACHIDIFRUTTA A GUSCIOPESCESOLFITISOIA. Conservare a T-18°C una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "051192"
        },
        {
            "_id": "177168",
            "name": "PANZEROTTINI RICOT/SAL.CONG.KG",
            "ingredients": "INGREDIENTI:Farina di GRANO tenero tipo?00?acquaMOZZARELLA(LATTE sale FERMENTI LATTICIcagliosemola di GRANO duro)RICOTTA(siero di LATTE vaccinoLATTE vaccinoPANNA saleE330)preparato per BESCIAMELLA (amido di mais modificato LATTE magro in polvere LATTOSIOproteine del LATTE BURRO in polvere grassi di cocco in polvere sale) strutto prosciutto cotto(carne di suinosaledestrosiosaccarosio fruttosioaromiaromi naturali spezieE300E301E250)salame(carne suinasaledestrosiosaccarosioaromiaromi naturali spezieE300E301 E249E250E252)lievito di birrasalefiocchi di patate (patateE471) coadiuvanti tecnologici semilavorato attivatore per PANE e prodotti lievitati (farina di GRANO tenero tipo 0E471lecitina di SOIA destrosiofarina di FRUMENTO maltatoalfa amilasiE300);E471; FORMAGGIO da grattugia (LATTEFERMENTI LATTICIsalecaglio);zucchero pepe.Può contenere:SESAMOARACHIDIFRUTTA A GUSCIOPESCESENAPE SOLFITIUOVASOIALATTE. Conservare a T-18°C una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "051321"
        },
        {
            "_id": "177169",
            "name": "PANZEROTTINI MOZZ/POM.CONG.KG",
            "ingredients": "INGREDIENTI: Farina di GRANO tenero tipo?00?; acqua; MOZZARELLA (LATTE sale FERMENTI LATTICI caglio microbico antiagglomerante: semola di GRANO duro); polpa di pomodoro; strutto; lievito di birra; sale; fiocchi di patate patate emulsionanti (E471); coadiuvanti tecnologici semilavorato attivatore per PANE e prodotti lievitati (farina di GRANO tenero tipo ?0?; emulsionanti (E471) lecitina di SOIA  destrosio farina di FRUMENTO maltato alfa amilasi E300; E471; FORMAGGIO da grattugia (LATTE FERMENTI LATTICI sale caglio); amido di mais pregelatinizzato; olio; zucchero; pepe. Il prodotto può contenere : SEMI DI SESAMO ARACHIDIFRUTTA A GUSCIO PESCE SOLFITI UOVA SOIA LATTESENAPE.Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. ura. Prodotto fritto in olio di semi di girasole.",
            "plu": "051310"
        },
        {
            "_id": "177172",
            "name": "PANZEROTTINI MOZZ/PROS.CONG.KG",
            "ingredients": "INGREDIENTI:Farina di GRANO tenero tipo?00? acqua mozzarella LATTE sale FERMENTI LATTICI caglio microbico antiagglomerante (semola di GRANO duro) prosciutto cotto carne di suino sale destrosio saccarosio fruttosio aromi aromi naturali e spezie antiossidante (E300 E301) conservante (E250) strutto lievito di birra sale fiocchi di patate patate emulsionanti (E471) coadiuvanti tecnologici semilavorato attivatore per pane e prodotti lievitati (farina di GRANO tenero tipo ?0? emulsionanti (E471 lecitina di SOIA)  destrosio farina di FRUMENTO maltato alfa amilasi E300 E471 amido di mais pregelatinizzato zucchero. Il prodotto può contenere : SEMI DI SESAMO ARACHIDI FRUTTA A GUSCIO SENAPE PESCE SOLFITI UOVA SOIA LATTE. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "051311"
        },
        {
            "_id": "177310",
            "name": "FAGIOLINI FINISSIMI CONG. KG",
            "ingredients": "INGREDIENTI: fagiolini Conservare a -18°C. Non ricongelare.",
            "plu": "051456"
        },
        {
            "_id": "177312",
            "name": "SPINACI A FOGLIA CUB.CONG. KG",
            "ingredients": "INGREDIENTI:spinaci",
            "plu": "051459"
        },
        {
            "_id": "177313",
            "name": "MINESTRONE VERDURE CONG. KG",
            "ingredients": "INGREDIENTI:patatecarotezucchinespinaci cavolfioreverzaSEDANOfagiolinipiselli fagioli borlotticipollaprezzemolobasilico (verdure in porzione variabile). Conservare a -18°C. Non ricongelare.",
            "plu": "051457"
        },
        {
            "_id": "177314",
            "name": "PATATE SPICCHI PREF.CONC. KG",
            "ingredients": "INGREDIENTI:patate olio di palma Conservare a -18°C. Non ricongelare.",
            "plu": "051458"
        },
        {
            "_id": "177315",
            "name": "PISELLI FINISSIMI CONG.KG",
            "ingredients": "INGREDIENTI:piselli",
            "plu": "051460"
        },
        {
            "_id": "177324",
            "name": "BASTONCINI SPINACI CONG. KG",
            "ingredients": "INGREDIENTI: Spinaci (31%) farina di FRUMENTO patate olio di semi di girasole acqua FORMAGGIO Edam (LATTE) amido di patate UOVA fiocchi di patate sale lievito spezie. Può contenere MOLLUSCHI CROSTACEI SEDANO SENAPE SOIA PESCE. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "051024"
        },
        {
            "_id": "177326",
            "name": "POLPETTINE DI PESCE CONG.AL K",
            "ingredients": "INGREDIENTI:MERLUZZO(Theragra ChalcogrammaGadus morhuaMerluccius productusPollachins virensmelanogrammus aeglefinusPANATURA(farina di FRUMENTOacqualievitosalespezie destrosio)olio di colzaacqua patatefarina di FRUMENTOsaleerbe aromaticheamido di patatespezie SENAPE (acquasemi diSENAPEaceto di brandy(SOLFITI)salespezie riso macinatoamido di risoUOVO intero in polvere zucche) lievito in polvere acidificante (difosfati)agente lievitante (carbonato di sodio)amido di FRUMENTOPANNA in polvere. Il prodotto può contenere:SOIA.Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "051023"
        },
        {
            "_id": "177476",
            "name": "MAZZANCOLL.TR.CON.40/50 A KG",
            "ingredients": "INGREDIENTI: MAZZANCOLLE TROPICALI(Litopenaeus vannamei) sale conservante E223(metabiSOLFITO di sodio). ALLERGENI: CROSTACEI e SOLFITI. Conservare a temperatura non superiore a -18°C una volta scongelato non ricongelare e consumare entro 24h previa cottura.",
            "plu": "050336"
        },
        {
            "_id": "177478",
            "name": "DELIFRANCE PAIN CHOCO 70GR",
            "ingredients": "INGREDIENTI: farina di GRANO tenero BURRO acqua cioccolato (zucchero massa di cacao BURRO di cacao emulsionante: lecitina di SOIA) zucchero lievito GLUTINE di GRANO tenero UOVA sale emulsionante (E472e) agente per il trattamento della farina (E300) enzima. Conservare a T-18°C. Consumare preferibilmente entro la Data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine. Da consumarsi solo previa cottura. Può contenere FRUTTA A GUSCIO SEMI DI SESAMO SOIA SENAPEARACHIDI.",
            "plu": "051325"
        },
        {
            "_id": "177682",
            "name": "PACGEL CORN.CIOK CONG.90GR",
            "ingredients": "INGREDIENTI: Farina di FRUMENTO; margarina vegetale Grassi vegetali (palma) acqua oli vegetali (girasole) emulsionante (E472c) sale regolatori di acidità (E330 E331) aroma colorante (E160); acqua; crema NOCCIOLA 18% zucchero oli vegetali (palma e colza) cacao magro in polvere NOCCIOLE (55%) LATTE intero in polvere (2%) amido emulsionante (E322) aromi); zucchero; lievito di birra; UOVA; LECITINA DI SOIA; emulsionanti (E472e E471) destrosio farina di FRUMENTO MALTATO acido L- ascorbico enzimi; sale; proteine del LATTE; LATTE scremato in polvere sale glucosio aroma. Può contenere:SESAMOARACHIDIFRUTTA A GUSCIOPESCESENAPE SOLFITI. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "051180"
        },
        {
            "_id": "177684",
            "name": "PACGEL CORN.CREMOLONE CONG.100",
            "ingredients": "INGREDIENTI:farina di FRUMENTOmargarina vegetale(oli e grassi vegeta- li palma/SOIA/girasole/colza in parte frazionatiacquaemulsionanti: E471E322 lecitina di girasoleE475salecorrettore di acidità E330 conservante E202aromibetacarotene)acqua crema(acquaLATTE intero pastorizzatosciroppo di glucosiozuccheroamido modificato grassi vegetale:palmaproteine del LATTEaddensante E466aromisalebetacaro- tene) zuccherolievito di birraUOVAGLUTINEstruttoemulsionanti:este ri mono e diacetiltartarici degli acidi grassi E472eE471destrosio farina di FRUMENTO MALTATO acido L-ascorbicoenzimisaleproteine del LATTELATTE scremato in polverearomazucchero invertitodestrosio coloranteestratto vegetale. Il prodotto potrebbe contenere tracce di:SEMI DI SESAMOFRUTTA a GUSCIO (NOCCIOLE)PESCESOIASENAPEARACHIDE. Conservare a T-18°C una volta scongelato non ricongelare il prodotto consumare previa cottura.",
            "plu": "051184"
        },
        {
            "_id": "177685",
            "name": "PACGEL CORN.ERON CONG.100GR",
            "ingredients": "INGREDIENTI: farina di FRUMENTO; margarina vegetale Grassi vegetali (palma) acqua oli vegetali (girasole) emulsionante (E472c) sale regolatori di acidità (E330 E331) aroma colorante (E160); acqua; crema NOCCIOLA 20% zucchero oli vegetali (palma e colza) cacao magro in polvere NOCCIOLE (55%) LATTE intero in polvere (2%) amido emulsionante (E322) aromi; zucchero; lievito di birra; UOVA; GLUTINE; LECITINA DI SOIA; cacao; strutto; emulsionanti (E472e E471) destrosio farina di FRUMENTO MALTATO acido L-ascorbico enzimi; sale; proteine del LATTE; LATTE scremato in polvere; aroma; zucchero invertito; destrosio; colorante: carbone vegetale. Il prodotto può contenere:SEMI DI SESAMOARACHIDIFRUTTA A GUSCIOPESCESENAPE SOLFITI. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "051176"
        },
        {
            "_id": "177686",
            "name": "PACGEL CORN.FRUTTOLONE CONG100",
            "ingredients": "INGREDIENTI: Farina di FRUMENTO; margarina vegetale Grassi vegetali (palma) acqua oli vegetali (girasole) emulsionante (E472c) sale regolatori di acidità (E330 E331) aroma colorante (E160); acqua; confettura ai frutti di bosco 18% purea di frutti di bosco (fragole ribes more) sciroppo di glucosio-fruttosio saccarosio gelificante (pectina) correttore di acidità (E300) conservante (E202) aromi; zucchero; lievito di birra; UOVA; strutto; LECITINA DI SOIA emulsionanti (E472e E471); Farina di FRUMENTO maltato; acido L-ascorbico enzimi; sale; proteine del LATTE; LATTE scremato in polvere; aroma; zucchero invertito; destrosio; coloranti (carminio eNOCIanina estratto di carota nera). può contenere:SOIASENAPE SEMI DI SESAMO ARACHIDI FRUTTA A GUSCIO PESCE SOLFITI. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "051183"
        },
        {
            "_id": "177815",
            "name": "DELIFRANCE CROIS.FAR/QUIN.80GR",
            "ingredients": "INGREDIENTI: Farina di GRANO tenero acqua margarina vegetale oli vegetali di palma e di colza non idrogenati acqua sale emulsionante (E471) regolatore di acidità (E330) farina di farro zucchero farina integrale di GRANO tenero lievito quinoa GLUTINE di GRANO tenero sale malto d?ORZO tostato emulsionante (E472e) stabilizzante (E412) agente di trattamento della farina (E300) enzima. Contiene GLUTINE. Non contiene ingredienti che derivano da animali. Prodotto idoneo ai Vegani secondo il Regolamento UE 1169/2011. Può contenere :LATTE UOVA ARACHIDISOIA FRUTTA A GUSCIO e di semi di SESAMO. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "051168"
        },
        {
            "_id": "190708",
            "name": "FORNO D.R.TRECCINA NOCI 100GR",
            "ingredients": "INGREDIENTI:Farina di FRUMENTOolio vegetale(palmacolza)acqua zuccheroUOVANOCI DI PECANlievitozucchero candito sciroppo di zucchero invertitosalegrasso vegetale(colza)amido di maisfibra di agrumisiero di LATTE in polveresciroppo di glucosiosciroppo dacero(01%)stabillizzante (E953) emulsionanti(E471E472e) addensante(E406)correttore di acidità(E330)antiossidante(E300)colorante(E150a) vitamina A aroma naturalearoma naturale di aceroenzimi. Sciroppo dacero aromatizzato:sciroppo di glucosiofruttosiozucchero sciroppo di glucosiosciroppo di zucchero canditoacquaaromi d acero. Contiene:GLUTINELATTEUOVAFRUTTA A GUSCIO(NOCI PECAN). Può contenere SOIASENAPE. Prodotto dolciario prelievitato surgelato da non ricongelare. Cons. a T-18°C consumare previa cottura. Se cotto:Ottenuto da prodotto prelievitato.",
            "plu": "051197"
        },
        {
            "_id": "191245",
            "name": "TRANCE SPADA TONDE CONG.AL KG",
            "ingredients": "INGREDIENTI: PESCE SPADA (Xiphias gladius) Conservare a T -18 gradi °C. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050769"
        },
        {
            "_id": "191439",
            "name": "FIORI MERLUZZO ALASKA CONG.KG",
            "ingredients": "INGREDIENTI: MERLUZZO dAlaska (Theragra chalcogramma) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050770"
        },
        {
            "_id": "191529",
            "name": "TRIGLIE CONGELATE AL KG",
            "ingredients": "INGREDIENTI: TRIGLIA atlantica congelata (Pseudopeneus prayensis) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050638"
        },
        {
            "_id": "192220",
            "name": "C.L.AQUILA RICOTTA VACCINA KG",
            "ingredients": "INGREDIENTI:Siero di LATTE vaccino LATTE vaccino sale. Può contenere GLUTINE.",
            "plu": "050700"
        },
        {
            "_id": "195669",
            "name": "PANAPESCA INSALATA MARE 800GR",
            "ingredients": "INGREDIENTI:TOTANO australe del pacifico S/O VONGOLE sgusciate del pacifico C/N COZZE cilene sgusciate MAZZANCOLLA tropicale. Correttori di acidità: E330-E331 Stabilizzante: E452 Antiossidante: E223 (metabiSOLFITO di sodio)",
            "plu": "057022"
        },
        {
            "_id": "195701",
            "name": "RANA_PESCATRICE 300/500 S/PEL",
            "ingredients": "INGREDIENTI: RANA PESCATRICE orientale (Lophius Litulon). Correttori di acidità: E330 E331. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050620"
        },
        {
            "_id": "206391",
            "name": "*CALAMARI SP.S/A BL S AL KG",
            "ingredients": "Conservare a temperatura non superiore a -18°C una volta scongelato non ricongelare e consumare entro 24h previa cottura.",
            "plu": "050897"
        },
        {
            "_id": "208033",
            "name": "POLPO RICC.MAROC.T3 IQF 2/3KG",
            "ingredients": "INGREDIENTI: POLPO riccio (Octopus vulgaris) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050879"
        },
        {
            "_id": "266999",
            "name": "CALAMARI PULITI U10 CONG.AL KG",
            "ingredients": "INGREDIENTI: CALAMARO indiano (Loligo duvauceli) acquasale. Correttore di acidità E331. ALLERGENI: MOLLUSCHI. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050070"
        },
        {
            "_id": "267013",
            "name": "MOZZARELLINE_PANATE CONG.AL KG",
            "ingredients": "INGREDIENTI: mozzarella (LATTE sale caglio FERMENTI LATTICI) semilavorato per impanatura (FARINA di FRUMENTO lievito sale paprica) acqua farina di FRUMENTO farina di mais sale olio di semi di girasole amido di mais UOVO intero in polvere fibra alimentare (FRUMENTO) agenti lievitanti (difosfato disodicocarbonato acido di sodio). Può contenere SOIASEDANOSENAPE.Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "050136"
        },
        {
            "_id": "267021",
            "name": "OLIVE ASCOLANA AL KG",
            "ingredients": "INGREDIENTI: olive verdi denocciolate olive acqua sale correttore di acidità (E330 E270) antiossidante (E300) semilavorato per PANATURA (farina di FRUMENTO lievito di birra sale paprika) carne di bovino carne di suino mortadella carne di suino grasso di suino cotenna farina di riso sale spezie aromi conservante (E250) farina di FRUMENTO acqua GRANA PADANO (LATTE sale caglio lisozima da UOVO) sale carote SEDANO cipolla noce moscata pepe nero agenti lievitanti (E450 E500). Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato all?origine da consumarsi solo previa cottura. Può contenere SOIA UOVA CROSTACEI PESCE MOLLUSCHI SENAPE.",
            "plu": "050137"
        },
        {
            "_id": "267039",
            "name": "CROCCOLONI POMODORO CONG.AL KG",
            "ingredients": "INGREDIENTI:MERLUZZO dAlaska Theragra Chalcogramma pangrattato(farina di FRUMENTOacqua lievitosale spezie)olio di colzafarina di FRUMENTOLATTE interopomodoroMOZZARELLA acquaamido di riso concentrato di pomodoroLATTE scremato in polvere salealbume dUOVO in polvereriso macinatosiero di LATTE dolce in polvereSENAPE(acquasemi di SENAPEaceto di brandy(SOLFITI)salespezie)UOVO intero in polverezuccherospezie tuorlo dUOVO in polvere formaggio a pasta dura(LATTEUOVO)pomodoro e paprica in polvereerbe aromatiche. Può contenere SOIA.",
            "plu": "050092"
        },
        {
            "_id": "267047",
            "name": "CROCCOLONI_MERLUZ/SPIN.CONG.KG",
            "ingredients": "INGREDIENTI:Filetti di MERLUZZO dAlaska (50%) crema agli spinaci (20%) (spinaci 11% acqua PANNA MOZZARELLA amido di riso olio di semi di girasoleollaamido di risosale LATTE scremato in sale noce moscata) pangrattato (farina di FRUMENTO destrosio sale lievito spezie contiene olio di semi di girasole e/o colza)farina FRUMENTOolio di semi di girasoleacquaamido di patate sale.Può contenere UOVASOIASEDANOSENAPE CROSTACEI. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050093"
        },
        {
            "_id": "267054",
            "name": "CRUNCHY FISH CONG.AL KG",
            "ingredients": "INGREDIENTI:MERLUZZO dAlaska(Theragra Chalcogramma) patateolio di colza farina di FRUMENTO(GLUTINE) acqua riso macinato sale amido di risoSENAPE (acqua semi di SENAPE aceto di brandy(SOLFITI) sale spezie)PANgrattato(farina di FRUMENTO acqua sale).Può contenere SOIA.",
            "plu": "050094"
        },
        {
            "_id": "267088",
            "name": "FILET.MERLUZ.CONG.",
            "ingredients": "INGREDIENTI: MERLUZZO sudafricano (Merluccius capensis/paradoxus) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura. AVVERTENZE:possono essere presenti spine di piccole dimensioni.",
            "plu": "050454"
        },
        {
            "_id": "267096",
            "name": "FILET.PERSICO 300/500 CONG. KG",
            "ingredients": "INGREDIENTI: FILETTO di PERSICO africano congelato (Lates niloticus) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050104"
        },
        {
            "_id": "267104",
            "name": "GAMBERI ARGENT.L2CONG/BOR.KG",
            "ingredients": "INGREDIENTI: GAMBERO ARGENTINO (Pleoticus muelleri) antiossidante: METABISOLFITO DI SODIO. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050306"
        },
        {
            "_id": "267120",
            "name": "MERLUZZO DEC.200/600 CONG.AL K",
            "ingredients": "INGREDIENTI: MERLUZZO SUDAFRICANO (Merluccius capensis/paradoxus) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050132"
        },
        {
            "_id": "267138",
            "name": "MERLUZZO DEC.500/900 CONG.AL k",
            "ingredients": "INGREDIENTI: MERLUZZO (Merluccius capensis) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050131"
        },
        {
            "_id": "267161",
            "name": "RISPO ARAN.MEDIO SUGO 2500GR",
            "ingredients": "INGREDIENTI: riso acqua polpa di pomodoro MOZZARELLA (LATTE sale caglio correttore di acidità: E330 conservante: E202) semilavorato per IMPANATURA (farina di FRUMENTO lievito di birra sale paprica) farina di FRUMENTOcarne bovinapiselli vino(SOLFITI)PROVOLONE(LATTE caglio sale) GRANA PADANO DOP (LATTE sale caglio conservante: lisozima - proteina dell?UOVO) FORMAGGIO PECORINO (LATTE di pecora caglio sale) olio di semi di girasole concentrato di pomodoro SEDANO carota cipolla in proporzione variabile preparato per BESCIAMELLA (amido di mais modificato LATTE scremato in polvere LATTOSIO proteine del LATTE BURRO in polvere grassi vegetali di cocco in polvere sale) sale preparato per brodo (sale iodato fecola di patate estratto di lievito zucchero grasso vegetale di palma cipolla disidratata aromi spezie) bietola rossa in polvere basilico curcuma cipolla pepeaglio(SOLFITI)noce moscataalloro. Può?contenere PESCESOIASENAPE.Conservare a T-18°Cnon ricongelare il prodotto e consumare previa cottura.",
            "plu": "050057"
        },
        {
            "_id": "267187",
            "name": "RISPO CROCCHE MIGNON 2500GR",
            "ingredients": "INGREDIENTI: acqua fiocchi di patate (patate disidratate emulsionante: E471 antiossidanti: E304 E300; correttore di acidità: E330) semilavorato per IMPANATURA (farina di FRUMENTO lievito di birra sale) PASTELLA (acqua farina di FRUMENTO sale) MOZZARELLA vaccina (LATTE sale caglio correttore di acidità: E330 conservante: E202) prosciutto cotto (carne di suino acqua amido di patata sale destrosio saccarosio aromi e spezie gelatina alimentare esaltatore di sapidità: E621 stabilizzanti: E450 E451 addensante E407 antiossidanti: E301 E316 conservante: E250) FORMAGGIO PECORINO (LATTE di pecora caglio sale) GRANA PADANO DOP. (LATTE sale caglio conservante: lisozima - proteina dell UOVO) sale olio di semi di girasole prezzemolo pepe. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere: PESCESOIASENAPE SEDANO.",
            "plu": "051265"
        },
        {
            "_id": "267195",
            "name": "RISPO FRIT.NA SPAGH.GRAN.2500G",
            "ingredients": "INGREDIENTI:acquapasta di semola di GRANO duroPANGRATTATO(farina di GRANO tenero 0 lievito di birrasale)spalla cotta(carne suinaacqua amidiemulsione di acqua e carne suinasaledestrosioproteine plasmatichearomi e spezieE621 E301 E316E450 E451 E407 E250) preparato per besciamella(amido di mais modificatoLATTE scremato in polvere LATTOSIOproteine del LATTEBURRO in polveregrassi vegetali in polvere sale)MOZZARELLA vaccina(LATTEsalecaglioE330E202E460i i)PROVOLONE(LATTE sale caglio)farina di GRANO tenero 00carne bovinaGRANA PADANO (LATTEsalecagliolisozima-proteina dellUOVO) polpa di pomodoroFORMAGGIO pecorino Romano(LATTE di pecoracaglio sale) salepiselliinoolio di semi di girasoleconcentrato di pomodo- roprezzemoloSEDANO carotacipollaepeolio di olivapreparato per brodo(sale iodato fecola di patate estratto di lievitozucchero grasso vegetalecipolla disidratataaromispezie)margarina vegetale (grassi e oli vegetali parzialmenteidrogenatiLATTE scremE471E475 E202E330 beta-carotenevitamine A e D)basilicoaromicurcuma. Può contenere PESCESOIASENAPE SOLFITI.Non ricongelare.",
            "plu": "051259"
        },
        {
            "_id": "267211",
            "name": "RISPO FRITTO MISTO NAP.2500GR",
            "ingredients": "INGREDIENTI: acqua fiocchi di patate patate (SOLFITI)E471E450i spezieE223E304 aroma naturaleE330semilavorato per impanatura (farina di GRANO tenero 0lievito di birra sale paprica curcuma prezzemolo basilico) farina di GRANO tenero 00 (GLUTINE) pasta di semola di GRANO duro (semola di GRANO duro acqua) prosciutto cotto (carne suina acqua destrosiosaccarosio proteine del LATTE idratate sale amidi aromi e spezieE621 E407 E450 E301 E316 E250riso ricotta vaccina (siero di LATTE vaccino LATTE vaccino PANNA sale) mozzarella vaccina (LATTE sale FERMENTI LATTICI caglioE330 E202 polpa di pomodoro preparato per besciamella (amido modificato di mais LATTE scremato in polvere LATTOSIO proteine del LATTE BURRO in polvere grassi vegetali in polvere-cocco- sale) semola di GRANO duro (GLUTINE) carne bovina salame tipo Napoli (carne suina sale destrosio saccarosio spezie ?aromi ?aromatizzanti di affumicatura E301E250 E252) semilavorato alimentare acqua formaggio Cheddar/Edam Può contenere SOIASENAPE.",
            "plu": "051252"
        },
        {
            "_id": "267229",
            "name": "SEPPIA PULITA 5/7 AL KG",
            "ingredients": "INGREDIENTI: SEPPIA (Sepia Pharaonis) acquasaleantiossidanti E330 E331. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050163"
        },
        {
            "_id": "267278",
            "name": "TRANCE SPADA CONG.KG",
            "ingredients": "INGREDIENTI: PESCE SPADA (Xiphias gladius) Prodotto congelato. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050752"
        },
        {
            "_id": "268474",
            "name": "RISPO ARAN.MIGNON SUGO 2500GR",
            "ingredients": "INGREDIENTI: riso acqua polpa di pomodoro mozzarella vaccina (LATTE sale caglio correttore di acidità: E330 conservante: E202) semilavorato per impanatura (farina di FRUMENTO lievito di birra sale paprica) farina di FRUMENTO VINO PROVOLONE (LATTE caglio sale) GRANA PADANO (LATTE sale caglio conservante: lisozima - proteina dell?UOVO) PECORINO (LATTE di pecora caglio sale) olio di semi di girasole concentrato di pomodoro SEDANO carota cipolla in proporzione variabile preparato per BESCIAMELLA (amido di mais modificato LATTE scremato in polvere LATTOSIO proteine del LATTE BURRO in polvere grassi vegetali di cocco in polvere sale) carne bovina piselli sale preparato per brodo (sale iodato fecola di patate estratto di lievito zucchero grasso vegetale di palma cipolla disidratata aromi spezie) bietola rossa in polvere basilico cipolla pepeaglio(SOLFITI)noce moscata alloro.Conservare a T-18°C una volta scongelato non ricongelare il prodotto e consumare previa cottura.Può contenere PESCESOIASENAPE.",
            "plu": "050058"
        },
        {
            "_id": "269118",
            "name": "COZZE SGUSCIATE CONG.KG",
            "ingredients": "INGREDIENTI: COZZE IQF(Mytilus Galloprovincialis) cotte sgusciate congelate. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050089"
        },
        {
            "_id": "269142",
            "name": "FILET.PLATES.IMP.PREF.100/130",
            "ingredients": "INGREDIENTI: PLATESSA acqua farina di FRUMENTO olio di colza sale lievito. Può contenere SOIASENAPE. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050190"
        },
        {
            "_id": "269159",
            "name": "ANELLI E CIUFFI S/PELLE AL KG",
            "ingredients": "INGREDIENTI: CALAMARO indiano (Loligo duvauceli) acqua (20%) sale correttore di acidità (E330) antiossidante (E331). FAO51 reti da trainoglassatura 20%",
            "plu": "050852"
        },
        {
            "_id": "279240",
            "name": "TRANCE SPADA EXTRA CONG.AL KG",
            "ingredients": "INGREDIENTI: PESCE SPADA (Xiphias gladius) decapitato eviscerato congelato a bordo. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050170"
        },
        {
            "_id": "294066",
            "name": "RISPO CALZONCELLO PIZZ.2500GR",
            "ingredients": "INGREDIENTI:far.di GRANO 00acquaMOZZARELLA vaccina(LATTEsalecaglio fermenti LATTICI) polpa di pomodorosemola di GRANO duroprosciutto cotto(carne suinaacquadestrosio saccarosiosaleproteine del LATTE idratateamidiaromi e spezieE621E407E450E301E316 E250)strutto vino (SOLFITI)salefiocchi di patate(patateE471 E450ispezieE223E304 aroma naturaleE330)glucosioPECORINO (LATTE di pecoracagliosale)preparato per BESCIAMELLA(amido di mais modificatoLATTE scremato in polvereLATTOSIOproteine del LATTE BURRO in polveregrassi vegetali di cocco in polveresale)concentrato di pomodoro LATTE in polvereE450iE500iiSEDANOcarotacipollalievi- to di birraGLUTINE di FRUMENTOsciroppo di glucosioE472eE471E300 estratto di MALTO in polverefar.di FRUMENTO maltatoenzimiolio evo zuccheroaroma nat.pizza(origanoolivespeziepiante aromatiche)prepa rato per brodo(sale iodatofecola di patateestr.di lievitograsso veg di palmacipolla disidratataaromispezie)basilicopepeorigano. Può contenere UOVA PESCESOIASENAPE.",
            "plu": "051256"
        },
        {
            "_id": "294074",
            "name": "RISPO PIZZETTE MIGNON AL KG",
            "ingredients": "INGREDIENTI: farina di GRANO tenero 00? semola di GRANO duro acqua polpa di pomodoro MOZZARELLAvaccina LATTE sale caglio correttore di acidità (E330) conservante (E202) strutto olio di semi di girasole sale lievito di birra miglioratore di lievitazione GLUTINE di FRUMENTO sciroppo di glucosio emulsionanti (E472e E471); agente di trattamento della farina (acido ascorbico) estratto di MALTO in polvere farina di FRUMENTO maltato enzimi GRANA PADANO DOP (LATTE sale caglio conservante: lisozima-proteina dell? UOVO) FORMAGGIO PECORINO (LATTE di pecora caglio sale) concentrato di pomodoro zucchero amido di mais modificato agenti lievitanti (E450 E500); farina di GRANO tenero tipo 0? bietola rossa in polvere pepe origano. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere:SEDANOPESCESOLFITISENAPESOIA.",
            "plu": "050187"
        },
        {
            "_id": "294199",
            "name": "RISPO CALZONCELLO RIC.2500GR",
            "ingredients": "INGREDIENTI:far.di GRANO tenero 00acquaricotta vaccina(siero di LATTE vaccinoPANNA) semola di GRANO durostruttoprosciutto cotto(car ne suinaacquadestrosiosaccarosio saleproteine del LATTE idratate amidiaromispezieE621E407E450E301E316E250) mozzarella vaccina (LATTEsalecaglioFERMENTI LATTICI)vino(SOLFITI)salame (carne suina saledestrosioE301E315E392E250E252aromispeziearoma di affumicaturasalefiocchi di patate(patateE471E450ispezieE223 E304aroma naturaleE330)glucosiopreparato per besciamella (amido di mais modificatoLATTE scremato in polvereLATTOSIOproteine del LATTEBURRO in polveregrassi vegetali di cocco in polveresale) Grana Padano DOP(LATTE salecagliolisozima-proteina dellUOVO)formag gio pecorino(LATTE di pecoracagliosale) LATTE in polverE450iE500ii lievito di birraGLUTINE di FRUMENTOsciroppo di glucosio E472eE471 E300estr.di MALTO in polvfarina di FRUMENTO maltatoenzimizucchero pepe. Può contenere SEDANOPESCESOIASENAPE.",
            "plu": "051260"
        },
        {
            "_id": "294207",
            "name": "RISPO POLPETTINA MELANZ.2500GR",
            "ingredients": "INGREDIENTI: melanzane grigliate semilavorato per IMPANATURA (farina di FRUMENTO lievito di birra sale curcuma paprica) acqua farina di GRANO tenero ?00? PROVOLA affumicata (LATTE sale caglio FERMENTI LATTICI aroma fumo) PROVOLONE (LATTE sale caglio) GRANA PADANO DOP. (LATTE sale caglio conservante: lisozima -proteina dellUOVO) olive nere olive acqua sale stabilizzante (E579) preparato per BESCIAMELLA (amido di mais modificato LATTE scremato in polvere LATTOSIO proteine del LATTE BURRO in polvere grassi vegetali di cocco in polvere sale) sale pepe. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può? contenere: PESCE SEDANO SOIASENAPE SOLFITI. Può? contenere noccioli di olive e frammenti.",
            "plu": "051263"
        },
        {
            "_id": "294223",
            "name": "RISPO ARAN.SIC.EXT.SUGO 2500GR",
            "ingredients": "INGREDIENTI: riso acqua polpa di pomodoro MOZZARELLA (LATTE sale caglio correttore di acidità: E330 conservante: E202) semilavorato per IMPANATURA (farina di FRUMENTO lievito di birra sale paprica) carne bovina pisellivino(SOLFITI)PROVOLONE(LATTE caglio sale) grana padano(LATTE sale caglioconservante:lisozima - proteina dell?UOVO) FORMAGGIO PECORINO (LATTE di pecora caglio sale) olio di semi di girasole concentrato di pomodoro SEDANO carota cipolla in proporzione variabile preparato per BESCIAMELLA (amido di mais modificato LATTE scremato in polvere LATTOSIO proteine del LATTE BURRO in polvere grassi vegetali di cocco in polvere sale) olio di oliva sale preparato per brodo (sale iodato fecola di patate estratto di lievito zucchero grasso vegetale di palma cipolla disidratata aromi spezie) bietola rossa in polvere basilico curcuma cipolla pepeaglio(SOLFITI)noce moscataalloro.Conservare T-18°Cuna volta scongelato non ricongalare il prodotto e consumare previa cottura. Può?contenere:PESCESOIASENAPE.",
            "plu": "051264"
        },
        {
            "_id": "294249",
            "name": "RISPO RUSTICI MIGN.7GUS.2500GR",
            "ingredients": "Sfoglia:farina di GRANO tenero 00margarina vegetale oli e grassi vegetali (girasole e palma)acquaE330E331iiisaleE471aromiacqua salestruttopreparato alimentare(proteine del LATTELATTE scremato in polvereE500E471LATTOSIOmaltodestrine curcumapepe. Contiene:GLUTINELATTEPESCEUOVASEDANOSOLFITIANIDRIDE SOLFOROSA. Ripieno:RICOTTA vaccina(siero di LATTE vaccinoPANNA vaccinaLATTE vaccinosale) w\u00c3\u00bcrstel(carne di suinocarne di tacchino separata meccanicamentecarne di pollo separata meccanicamenteacquagrasso suinosalefibre vegetaliamidomaltodestrinespeziearomi E450E452 E301E250)prosciutto cotto (carne di suino acqua amido di patata sale destrosiosaccarosioaromi e spezie gelatina alimentareE621 E450E451E407E301E316 E250)preparazione di SALMONE SALMONE affumicatomargarina vegetale(grassi vegetali di palmaacqua grassi vegetali idrogenati di cocco olio di colza LATTE scremato sale E471E322E202E330aromi betacarotenevitamine:A e D)vino (SOLFITI)cipollaprezzemolo sale e pepeTONNO in olio di girasole. Può contenere SOIASENAPEARACHIDI.",
            "plu": "050184"
        },
        {
            "_id": "294280",
            "name": "RISPO DELIZIA 4 FORMAGGI KG.",
            "ingredients": "INGREDIENTI:acqua fiocchi di patate (patate emulsionante: E471 stabilizzante: E450i spezie conservante: E223 ?METABISOLFITO DI SODIO- antiossidante: E304 aroma naturale correttore di acidità: E330) semilavorato per impanatura (farina di FRUMENTO lievito di birra sale curcuma e paprica) preparazione alimentare a base di formaggi acqua cheddar/edam (LATTE vaccino sale colture starter caglio microbico) grassi vegetali non idrogenati di palma e girasole proteine del LATTE emulsionate: E331 sale amido di patate correttore di acidità E330 aromi) farina di FRUMENTO sale formaggio pecorino (LATTE di pecora caglio sale) Grana Padano DOP (LATTE sale caglio conservante: lisozima-proteina dUOVO) prezzemolo pepe. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può? contenere : SEDANO PESCE E PRODOTTI A BASE DI PESCE.",
            "plu": "051261"
        },
        {
            "_id": "296780",
            "name": "GAMBERI ROSSI IV CONG AL KG",
            "ingredients": "INGREDIENTI:GAMBERO ROSSO (Aristaeomorpha foliacea) pescato nel Mar Mediterraneo centrale (Zona FAO 37.2.2) con reti da trainoBiSOLFITO di sodio E222 Antiossidanti: Acido ascorbico E300Citrato di sodio E331; Stabilizzante: Difosfati E450. Prodotto congelato. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050325"
        },
        {
            "_id": "327114",
            "name": "VELE MARE CONG. AL KG.",
            "ingredients": "INGREDIENTI: filetto di MERLUZZO ATLANTICO (Merluccius capensis - FAO47 -Reti da traino) farina di GRANO tenero olio di girasole sale fibra vegetale di FRUMENTO. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Il prodotto può contenere UOVASOIASENAPE.",
            "plu": "050172"
        },
        {
            "_id": "330464",
            "name": "FILET.PLATESSA N3 S/PELLE CONG",
            "ingredients": "INGREDIENTI: PLATESSA (Pleuronectes platessa)acqua E331. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "050452"
        },
        {
            "_id": "341008",
            "name": "GAMBERI SGU.100/200CONG.AL KG",
            "ingredients": "INGREDIENTI:GAMBERO (Metapenaeus affinis) indopacifico antiossidante: E223 (metabiSOLFITO di sodio) correttori di acidità: E330E331 colorante E160c. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050127"
        },
        {
            "_id": "358283",
            "name": "GAMBERI SGU.20/40 CONG.AL KG",
            "ingredients": "INGREDIENTI:GAMBERI (Metapenaeus affinis) indopacifici antiossidante: E223 (metabiSOLFITO di sodio) correttori di acidità: E330E331 colorante E160c. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050328"
        },
        {
            "_id": "362087",
            "name": "FUNNY FISH CONG.AL KG.",
            "ingredients": "INGREDIENTI:MERLUZZO Theragra Chalcogramma Gadus morhuaMerluccius productus pangrattato(farina di FRUMENTOacqualievito salespezie)olio di colzaacqua farina di FRUMENTOamido di FRUMENTOsaleriso macinatolievito in polvere(difosfaticarbonato di sodioamido di FRUMENTO)speziezucchero. Può contenere SOIASENAPE.",
            "plu": "050186"
        },
        {
            "_id": "373092",
            "name": "FILENI CORDON BLEU AL KG.",
            "ingredients": "INGREDIENTI: Filetto di pollo farina di FRUMENTO acqua carne di pollo separata meccanicamente oli vegetali (girasole ARACHIDE) preparazione alimentare con FORMAGGIO acqua olio di girasole proteine del LATTE amido di patate FORMAGGIO (LATTE sale fermenti lattici caglio microbico) prosciutto cotto di pollo e tacchino carne di pollo carne di tacchino acqua sale amidi destrosio aromi naturali addensante (E407) antiossidante (E301) conservante (E250) emulsionanti (E331 E452); sale correttore di acidità (E330) sale succo di limone lievito di birra aroma naturale amido di FRUMENTO paprica curcuma pepe cipolla in polvere antiossidante (estratto di rosmarino). Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere UOVASOIASENAPE.",
            "plu": "050084"
        },
        {
            "_id": "373100",
            "name": "FILENI CROCCHET.POLLO CONG. K",
            "ingredients": "INGREDIENTI: carne di pollo farina di FRUMENTO acqua oli vegetali (girasoleARACHIDE) carne di pollo separata meccanicamente sale lievito di birra amido di FRUMENTO aromi naturali succo di limone pepe paprica curcuma estratto di rosmarino. Può contenere LATTE UOVASOIASENAPE.Carne ricomposta.",
            "plu": "050090"
        },
        {
            "_id": "373266",
            "name": "FILENI PETTO POLLO FETTE A KG",
            "ingredients": "INGREDIENTI: petto di pollo tagliato a fette. Pigmentazione bianca. Conservare a -18°C. Prodotto surgelato allorigine. Non ricongelare.",
            "plu": "050180"
        },
        {
            "_id": "380790",
            "name": "FILENI COTOLETTE SPINACI KG.",
            "ingredients": "INGREDIENTI: Carne di pollo farina di FRUMENTO carne di tacchino spinaci carne di pollo separata meccanicamente acqua oli vegetali (contiene ARACHIDE) succo di limone lievito di birra amido di FRUMENTO sale aromi naturali pepe paprica curcuma cipolla in polvere antiossidante (estratto di rosmarino). Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Carne ricomposta. Può contenere: LATTE UOVASOIASENAPE",
            "plu": "050086"
        },
        {
            "_id": "380808",
            "name": "FILENI MAGIC ZOO AL KG",
            "ingredients": "INGREDIENTI: Filetto di pollo farina di FRUMENTO acqua oli vegetali (girasole ARACHIDE) sale lievito di birra succo di limone amido di FRUMENTO aromi naturali paprica curcuma antiossidante (estratto di rosmarino). Filetto di pollo farina di FRUMENTO acqua oli vegetali (girasole ARACHIDE) carne di pollo separata meccanicamente sale lievito di birra aroma naturale succo di limone amido di FRUMENTO paprica curcuma antiossidante (estratto di rosmarino). Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere LATTE UOVASOIASENAPE.Carne ricomposta.",
            "plu": "050001"
        },
        {
            "_id": "380816",
            "name": "FILENI CHICKEN STICK AL KG",
            "ingredients": "INGREDIENTI: Petto di pollo acqua farina di FRUMENTO oli vegetali (girasole ARACHIDE) farina di mais sale amido modificato di mais amido di FRUMENTO maltodestrine grassi vegetali raffinati (olio di girasole sciroppo di glucosio proteine del LATTE) aromi naturali LATTOSIO LATTE scremato in polvere succo di limone proteine del LATTE pepe prezzemolo disidratato lievito di birra cipolla in polvereaglio in polvere(SOLFITI) lievito di birra destrosio antiossidante (estratto di rosmarino).Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato all?origine da consumarsi solo previa cottura. Può contenere UOVASENAPESOIA. CARNE RICOMPOSTA.",
            "plu": "050080"
        },
        {
            "_id": "380824",
            "name": "FILENI PORTAFOG.PR/FOR.CONG.K",
            "ingredients": "INGREDIENTI: MOZZARELLA (LATTE caglio sale fermenti LATTICI) prosciutto cotto carne suina acqua sale destrosio fruttosio aromi antiossidante (E301) conservante (E250) farina di FRUMENTO acqua oli vegetali (girasole ARACHIDE) amido di FRUMENTO sale lievito di birra spezie estratto di spezie. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato all?origine da consumarsi solo previa cottura. Può contenere: UOVASOIASENAPE.",
            "plu": "051257"
        },
        {
            "_id": "380832",
            "name": "FILENI ALETTE POLLO AL KG.",
            "ingredients": "INGREDIENTI: Ali di pollo passata di pomodoro pomodoro sale correttore di acidità (E330) farina di FRUMENTO aromi aromi di affumicatura sale patate disidratate in fiocchi olio di oliva estratto di lievito spezie in proporzione variabile (peperoncino pepe cayenne) zucchero aglio in polvere(SOLFITI)estratto di malto pomodoro in polvere siero di LATTE in polvere lievito di birra emulsionante (E471) esaltatore di sapidità (E621)destrosio stabilizzante (E450v) antiossidante (estratto di rosmarino) colorante (estratto di paprica). Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato all?origine da consumarsi solo previa cottura. Può contenere UOVASENAPESOIA.",
            "plu": "050056"
        },
        {
            "_id": "380865",
            "name": "FILENI POLLO BUSTO ACCOSC.A K",
            "ingredients": "INGREDIENTI: Pollo eviscerato senza frattaglie.Pigmentazione bianca.",
            "plu": "050183"
        },
        {
            "_id": "385633",
            "name": "FILENI FUSI DI POLLO A KG",
            "ingredients": "INGREDIENTI:tibia e fibula unitamente alla muscolatura che li ricrope. Le due sezionature vanno effettuate in coorispondenza delle articolazioni Pigmentazione bianca. Conservare a T-18°C. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere UOVASOIASENAPE. CARNE RICOMPOSTA.",
            "plu": "050174"
        },
        {
            "_id": "437418",
            "name": "FILENI COTOLETTE POLLO AL KG.",
            "ingredients": "INGREDIENTI: Filetto di pollo farina di FRUMENTO acqua olio vegetale (girasole ARACHIDE) carne di pollo separata meccanicamente succo di limone sale aromi naturali spezie amido di FRUMENTO cipolla in polvere lievito di birra antiossidante (estratto di rosmarino). Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato all?origine da consumarsi solo previa cottura. Può contenere: LATTE UOVASOIA SENAPE. CARNE RICOMPOSTA.",
            "plu": "050087"
        },
        {
            "_id": "437442",
            "name": "FILENI MILANESE POLLO AL KG.",
            "ingredients": "INGREDIENTI: Filetto di pollo 50% panatura 20% (farina di FRUMENTOolio di semi di girasole salelievito di birracurcuma e paprika in polvere) acqua oli vegetali (girasole ARACHIDE)farina di mais GLUTINE di FRUMENTOproteine del LATTEsalesale iodatoaromi naturalidestrosioamido di FRUMENTO. Antiossidante: estratto di rosmarino. Può contenere Può contenere UOVASOIASENAPE.Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050004"
        },
        {
            "_id": "437467",
            "name": "FILENI CROKET.SPINACI CONG.KG",
            "ingredients": "INGREDIENTI: Carne di pollo farina di FRUMENTO carne di tacchino spinaci carne di pollo separata meccanicamente olio vegetale (girasole ARACHIDE) succo di limone lievito di birra amido di FRUMENTO sale aromi naturali spezie antiossidante (estratto di rosmarino). Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato all?origine da consumarsi solo previa cottura. Può contenere LATTE UOVA SOIA SENAPE. CARNE RICOMPOSTA.",
            "plu": "050091"
        },
        {
            "_id": "447813",
            "name": "COZZE 1/2 GUSCIO CONG. KG",
            "ingredients": "INGREDIENTI:COZZE verdi mezzo guscio pulite (Perna canaliculus). ALLERGENI:MOLLUSCHI Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050873"
        },
        {
            "_id": "449066",
            "name": "GAMBERI ARGENT.L1CONG/BORDO KG",
            "ingredients": "INGREDIENTI: GAMBERI argentini (Pleoticus muelleri) Può contenere SOLFITI Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050110"
        },
        {
            "_id": "450924",
            "name": "CALAMARI SPORCHI 300/UP BLOCCO",
            "ingredients": "INGREDIENTI: CALAMARI del Pacifico interi (Loligo chinensis) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050072"
        },
        {
            "_id": "480350",
            "name": "SEPPIA SPORCA AL KG",
            "ingredients": "INGREDIENTI: SEPPIA (Sepia officinalis) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050862"
        },
        {
            "_id": "485029",
            "name": "FETTE DEL NOSTROMO AL KG.",
            "ingredients": "INGREDIENTI:MERLUZZO dAlaska 65% (pescato in Oceano Pacifico - zona FAO 61 e 67 catturato con reti da traino) FRUMENTO olio di girasole amido (FRUMENTOmais) sale emulsionante; E464 spezie (curcuma) estratto di paprika.Conservare a -18°C. Non ricongelare e consumare previa cottura. Può contenere SOIASENAPE.",
            "plu": "051408"
        },
        {
            "_id": "491555",
            "name": "FILET.PANGASIO CONG.120/170 K",
            "ingredients": "INGREDIENTI: PANGASIO (Pangasius hypophthalmus)80% acqua sale stabilizzanti E451E452. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050461"
        },
        {
            "_id": "491571",
            "name": "FIORI MERLUZZO EXTRA CONG.AKG",
            "ingredients": "INGREDIENTI: MERLUZZO sudafricano (Merluccius capensis/paradoxus). Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050107"
        },
        {
            "_id": "493049",
            "name": "POLPI RIC.MAROC.T6 800/12KG",
            "ingredients": "INGREDIENTI: POLPO (Octopus vulgaris) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050866"
        },
        {
            "_id": "514968",
            "name": "BASTONCINI MERL.ALASKA CONG.KG",
            "ingredients": "INGREDIENTI: MERLUZZO dAlaska 65%(Theragra Chalcogramma - FAO61-67)panatura (farina di FRUMENTO acqua spezie sale lievito) olio di colza farina di FRUMENTO amido di patata acqua sale. Può contenere SOIASENAPE. Può contenere lische. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "051002"
        },
        {
            "_id": "514976",
            "name": "GAMBERI_PASTELLATI CONG.AL KG",
            "ingredients": "INGREDIENTI: Pastellatura acqua farina di FRUMENTO farina di mais olio di girasole sale agenti lievitanti (E-450i E-500ii) esaltatore di sapidità (E621) coloranti (E-101ii E-160aii) coda di GAMBERO (Penaeus spp sale). Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere FRUTTA A GUSCIO LATTE UOVO PESCESOIASEDANOSOLFITIMOLLUSCHISENAPE.",
            "plu": "051003"
        },
        {
            "_id": "514984",
            "name": "COTOLETTE MARE NORDA CONG.A K",
            "ingredients": "INGREDIENTI:MERLUZZO(Theragra Chalcogramma-Merluccius productus-Gadus morhua) PANGRATTATO(acquaspeziesale farina di FRUMENTOlievito)olio di colzafarina di FRUMENTOcetriolini sottaceto(cetriolisaleACETO di brandyzucchero)FORMAGGIO EDAM acquasaleSENAPE (acquasemi di SENAPEaceto di brandy(SOLFITI)salespezie) spezie zuccheroamido di patateamido di risoriso macinatolievito in polvere(difosfati carbonato di sodioamido di FRUMENTOerbe aromaticheUOVO intero in polvere.Può contenere SOIA. Prodotto congelato allorigine da non ricongelare. Conservare a -18°C.",
            "plu": "051004"
        },
        {
            "_id": "514992",
            "name": "GAMBERETTI&ZUCCHINE CONG. A KG",
            "ingredients": "INGREDIENTI: GAMBERETTI indiani (Parapenaeopsis stylifera - Reti da Traino Pescati in Oceano Indiano FAO 51-57) e Zucchine (Cucurbita pepo -IT) ottenute da coltivazioni mediante tecniche di lotta integrata antiossidante E331 sale acqua. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Glassatura Gamberetti 20% + 5%. Allergeni: CROSTACEI. Può contenere: MOLLUSCHI PESCE SOLFITI.",
            "plu": "050201"
        },
        {
            "_id": "515007",
            "name": "ANELLI TOTANO CONG.AL KG",
            "ingredients": "INGREDIENTI:TOTANO ATLANTICO (Illex argentinus) sale stabilizzanti:E452 acidificanti:E330 acqua. Può contenere CROSTACEI e PESCE. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050851"
        },
        {
            "_id": "515049",
            "name": "POLPI EG.IQF T10 AL KG.",
            "ingredients": "INGREDIENTI: POLPO (Octopus vulgaris) pescato in Mar Mediterraneo Zona FAO 37.2 con reti da traino. Può contenere PESCI e CROSTACEI. Conservare a -18°C. Non ricongelare. Consumare previa cottura.",
            "plu": "050603"
        },
        {
            "_id": "515064",
            "name": "*CALAMARI SP.S/A CONG.BL L KG",
            "ingredients": "INGREDIENTI: CALAMARI (Loligo reynaudii). Conserva re a T-18°C. Una volta scongelato il prodotto deve consumato en tro le 24 ore e non non può essere ricongelato.Prodotto surgelato all origine da consumarsi solo previa cottura.",
            "plu": "050606"
        },
        {
            "_id": "515080",
            "name": "GALLINELLE 300/500 CONG.AL KG",
            "ingredients": "INGREDIENTI:GALLINELLA(Chelidonichthys lucerna).Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050607"
        },
        {
            "_id": "515098",
            "name": "GALLINELLE 150/400 KG",
            "ingredients": "INGREDIENTI: GALLINELLA (Trigla lucerna) pescata con reti da traino nellAtlantico orientale zona FAO 27. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050608"
        },
        {
            "_id": "519074",
            "name": "SOGLIOLA DOVER CONG.5L AL KG",
            "ingredients": "INGREDIENTI: SOGLIOLA (Solea vulgaris) acqua. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050610"
        },
        {
            "_id": "526509",
            "name": "COCKTAIL DI MARE IQF CONG.A KG",
            "ingredients": "INGREDIENTI: TOTANO GIGANTE (Dosidicus gigas) (FAO87)pescato con reti da trainoCOZZE sgusciate (Mytilus chilensis)(Allevate in Cile) GAMBERI indopacifici (Parapenaopsis stylifera) (FAO51) pescati con reti da trainoANELLI e CIUFFI di CALAMARO(Paphia textile)(FAO71) pescati con reti da traino VONGOLE sgusciate (Uroteuthis edulis) (FAO71) pescate con reti da traino. Prodotto congelato allorigine da non ricongelare. Conservare a -18°C.",
            "plu": "050601"
        },
        {
            "_id": "526517",
            "name": "ARROSTICINI TOTANO CONG.AL KG.",
            "ingredients": "INGREDIENTI: TOTANO GIGANTE DEL PACIFICO (Dosidicus Gigas - pescato in Oceano Pacifico Sudest zona Fao 87 con ami e palangari) PANATURA farina di FRUMENTO (GLUTINE) lievito di birra sale paprika olio di semi di girasole prezzemolo succo di limone preparato per gratinatura sale rosmarino aglio(SOLFITI) prezzemolo cipolla origanosalvia timo alloro maltodestrine estratto di lievito aromi naturali (contiene PESCE e CROSTACEI) AGLIO sale. Può contenere SEDANO. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere SOIASENAPE.",
            "plu": "051006"
        },
        {
            "_id": "526525",
            "name": "FILETTO NORDMEER CONG.AL KG",
            "ingredients": "INGREDIENTI:MERLUZZO dAlaska(Theragra Chalcogramma) o.di colzaPANGRATTATO(farina di FRUMENTO acquariso macinatoamido di riso lievito in polvere(difosfaticarbonato di sodio amido di FRUMENTO)SENAPE(acquasemi di SENAPEaceto brandy(SOLFITI)sale spezie)zuccheroamido di FRUMENTO salesiero di LATTE dolce in polvere.Può contenere SOIA. Prodotto congelato allorigine da non ricongelare. Conservare a -18°C.",
            "plu": "051007"
        },
        {
            "_id": "527473",
            "name": "GAMBERETTI & FUNGHI CONG.ALKG",
            "ingredients": "INGREDIENTI:Funghi champignon (Agaricus bisporus)Famigliola Gialla (Pholiota nameko) Orecchioni (Pleurotus ostreatus)Shitake (Lentinus edulis e gruppo)in proporzioni variabili e GAMBERI indiani (Parapenaeopsis Stylifera)(pescati in Oceano Indiano FAO 51-57 con con reti da traino)scottatisaleacquaconservanti(SOLFITI). Può contenere:PESCEMOLLUSCHI. Impurezze minerali e di origine vegetale le eventuali imperfezioni che il prodotto può presentare derivano dalla sua naturalitànon essendo i funghi sottoposti ad alcun processo chimico n\u00c3\u00a8 fisico diverso dal congelamento. Prodotto congelato allorigine da non ricongelare. Conservare a -18°C.",
            "plu": "050203"
        },
        {
            "_id": "535401",
            "name": "POLPI PICCOLI CONG.AL KG.",
            "ingredients": "INGRDIENTI: POLPO(Octopus Vulgaris) sale correttori di acidità:E330 E331. ALLERGENI: MOLLUSCHI. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050612"
        },
        {
            "_id": "542365",
            "name": "TRANCE SALMONE CONG.AL KG",
            "ingredients": "INGREDIENTI: SALMONE argentato decapitato eviscerato (Oncorhynchus kisutch). Prodotto congelato. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050754"
        },
        {
            "_id": "542555",
            "name": "FILENI PETTO POLLO A KG",
            "ingredients": "INGREDIENTI: petto di pollo senza pelle. Pigmentazione bianca. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "050178"
        },
        {
            "_id": "544890",
            "name": "MERLUZZO DEC.80/200 CONG.AL KG",
            "ingredients": "INGREDIENTI: MERLUZZO sudafricano (Merluccius capensis/paradoxus). Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura. Prodotto congelato.",
            "plu": "050614"
        },
        {
            "_id": "545384",
            "name": "*CALAMARI SP.S/A CONG.BL XL KG",
            "ingredients": "INGREDIENTI: CALAMARO sudafricano (Loligo reynaudi ). Conservare a T-18°C. Consumare preferibilmente entro la data ri portata in etichetta. Una volta scongelato il prodotto deve e ssere consumato entro le 24 ore e non può essere ricongelato. Prod otto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "050859"
        },
        {
            "_id": "545400",
            "name": "*CALAMARI SP.S/A CONG.BL M KG",
            "ingredients": "INGREDIENTI: CALAMARO sudafricano (Loligo reynaudi ). Conservare a T-18°C. Consumare preferibilmente entro la data ri portata in etichetta. Una volta scongelato il prodotto deve e ssere consumato entro le 24 ore e non può essere ricongelato. Prod otto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "050860"
        },
        {
            "_id": "546002",
            "name": "GAMBERI ARGENT.L3CONG/BORDOKG",
            "ingredients": "INGREDIENTI: GAMBERI argentini (Pleoticus muelleri) antiossidante E223. Può contenere SOLFITI. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050309"
        },
        {
            "_id": "549519",
            "name": "FILET.ORATA.CONG.120/160GR",
            "ingredients": "INGREDIENTI: ORATA (Sparus aurata) acqua sale. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "050462"
        },
        {
            "_id": "549527",
            "name": "FILET.SPIGOLA CONG.120/160GR",
            "ingredients": "INGREDIENTI: BRANZINO (Dicentrarchus labrax) acqua sale. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "050463"
        },
        {
            "_id": "563361",
            "name": "FILENI FESA TACCHINO FET.A KG",
            "ingredients": "INGREDIENTI:Fesa di tacchino a fette surgelata",
            "plu": "050171"
        },
        {
            "_id": "598250",
            "name": "FILENI COSCE DI POLLO A KG",
            "ingredients": "DESCRIZIONE: femore tibia e fibula unitamente alla muscolatura che lo ricopre ottenuto dal sezionamento di animali alimentati con mangimi non OGM. Le due sezionature sono effettuate in corrispondenza delle articolazioni. Pigmentazione: bianca e gialla Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "050164"
        },
        {
            "_id": "603142",
            "name": "POLPI RIC.MAROC.T7 500/800 KG",
            "ingredients": "INGREDIENTI:POLPI(Octopus vulgaris).Conservare a T -18°c.Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050864"
        },
        {
            "_id": "618207",
            "name": "GAMBERI SGU.40/60 CONG.KG",
            "ingredients": "INGREDIENTI:GAMBERI (Metapenaeus affinis) indopacifici antiossidante: E223 (metabiSOLFITO di sodio) correttori di acidità: E330E331 colorante E160c. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050335"
        },
        {
            "_id": "618330",
            "name": "POLPI RICCI T8 300/500 AL KG",
            "ingredients": "INGREDIENTI: POLPO (Octopus vulgaris) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050867"
        },
        {
            "_id": "632174",
            "name": "CALAMARO 80UP A KG",
            "ingredients": "INGREDIENTI:CALAMARO del Pacifico(Loligo japonicus) acqua. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "050068"
        },
        {
            "_id": "632182",
            "name": "CALAMARI PULITI U5 CONG.AL KG",
            "ingredients": "INGREDIENTI: CALAMARO (Loligo duvauceli) acquasale. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "050069"
        },
        {
            "_id": "632240",
            "name": "CODE GAMBERI CONG.21/25 AL KG",
            "ingredients": "INGREDIENTI:code di GAMBERI (Metapenaeus affinis) indopacifici pescati con reti da traino nellOceano Indiano Orientale - zona FAO 57 correttori di acidità: E330E331. Può contenere SOLFITI. Conservare a -18°C. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050082"
        },
        {
            "_id": "632257",
            "name": "CODE GAMBERI 51/60 CONG.AL KG",
            "ingredients": "INGREDIENTI:code di GAMBERI (Metapenaeus affinis) indopacifici pescati con reti da traino nellOceano Indiano Orientale - zona FAO 57 correttori di acidità: E330E331. Può contenere SOLFITI. Conservare a -18°C. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050301"
        },
        {
            "_id": "632323",
            "name": "FETTUCCINE TOTANO_PANA.CONG.KG",
            "ingredients": "INGREDIENTI:fettuccine di TOTANO GIGANTE del Paci- fico sale farina di FRUMENTO(GLUTINE) olio di semi di girasole acqua amido di mais destrina lievito spezie(paprika e curcuma). Stabilizzante: E450-E451. Antiossidante: E300. Può contenere SOIA SENAPE. Prodotto congelato allorigine da non ricongelare. Conservare a -18°C.",
            "plu": "050096"
        },
        {
            "_id": "632489",
            "name": "MAZZANCOL.SGUS.51/60 750GR",
            "ingredients": "INGREDIENTI: MAZZANCOLLE tropicali (Penaeus Vannamei) antiossidante: E223 (metabiSOLFITO di sodio) Correttori di acidità: E330 E331 Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050126"
        },
        {
            "_id": "632679",
            "name": "SEPPIA PUL.CONG.250/500 AL KG",
            "ingredients": "INGREDIENTI: SEPPIA (Sepia pharaonis) acquasaleantiossidanti E330 E331. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050160"
        },
        {
            "_id": "632687",
            "name": "SEPPIA PUL.CONG.500/1000 A KG.",
            "ingredients": "INGREDIENTI: SEPPIA (Sepia pharaonis) acquasaleantiossidanti E330 E331. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050162"
        },
        {
            "_id": "632711",
            "name": "TOTANI SPORCHI CONGELATI A KG",
            "ingredients": "INGREDIENTI: TOTANO atlantico (Illex argentinus) Correttori di acidità E330 E331. Può contenere CROSTACEI e PESCE Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050168"
        },
        {
            "_id": "634667",
            "name": "CUORI MERLUZ.CONG.AL KG",
            "ingredients": "INGREDIENTI: MERLUZZO sudafricano (Merluccius capensis). Può contenere MOLLUSCHI e CROSTACEI. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050176"
        },
        {
            "_id": "653576",
            "name": "ANT.CAS.BOCCONCINI GRANCHIO KG",
            "ingredients": "INGREDIENTI:Farina di GRANO 52%acquaSURIMI DI GRANCHIO 20% polpa di PESCE amido di FRUMENTO olio vegetale sale zucchero proteine della SOIA aroma di GRANCHIO ALBUME DUOVO estratto di GRANCHIO esaltatore di sapidità (E621)estratto naturale di paprika(E160cE120) olio di palma RSPO 8%sale 22%prezzemolo 1%lievito di birra 09% paprika dolce 035%. Il prodotto può contenere: SOIA LATTE FRUTTA A GUSCIOSENAPE SEDANO MOLLUSCHI PESCE CROSTACEI SOLFITI. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "050067"
        },
        {
            "_id": "653584",
            "name": "ANT.CAS.BOCCONCINI SPECK CONG.",
            "ingredients": "INGREDIENTI:Farina di GRANO tenero acqua speck carne di suino sale destrosio spezie conservante (E252) olio di palma sale lievito di birra. Il prodotto può contenere SOIA LATTE FRUTTA A GUSCIO SEDANO UOVA MOLLUSCHI PESCESENAPE CROSTACEI SOLFITI. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "050066"
        },
        {
            "_id": "653592",
            "name": "ANT.CAS.PIZZETTE ALGHE CONG KG",
            "ingredients": "INGREDIENTI:Farina di GRANO tenero acqua olio di palma sale alghe (ulva lactuca) lievito di birra. Il prodotto può contenere: SOIA LATTE FRUTTA A GUSCIO SEDANO UOVA SENAPE MOLLUSCHI PESCE CROSTACEI SOLFITI. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "050064"
        },
        {
            "_id": "659920",
            "name": "PORZIONI MERLUZZO AL LIM.AL KG",
            "ingredients": "INGREDIENTI: MERLUZZO nordico ricomposto (Gadus morhua) pescato in Oceano Atlantico Nord- orientale zona FAO 27 con reti da traino farina di GRANO tenero olio di girasole sale fibra vegetale di FRUMENTO coriandolo acqua aroma naturale al limone. Può contenere UOVA SENAPESOIA. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "051009"
        },
        {
            "_id": "668186",
            "name": "RISPO SPECK/PROVOLA CARR.2KG",
            "ingredients": "INGREDIENTI: PAN carr\u00c3\u00a8 farina di GRANO tenero tipo 0 farina di GRANO tenero tipo00 acqua grasso vegetale (palma girasole) destrosio sale lievito di birra conservante (E282) emulsionante (E471) farina di SOIA semilavorato per impanatura farina di FRUMENTO sale lievito di birra paprica polvere colorante (zucchero caramellizzato) curcuma polvereaglio(SOLFITI)pepe neroprezzemolo acqua provola affumicata (LATTE vaccino cagliosalefermenti LATTICIE330 aroma fumoE202 speck (carne suina sale zuccheri destrosio aromi spezieE301E250 E252) preparato per BESCIAMELLA (amido modificato di mais LATTE scremato in polvere LATTOSIO proteine del LATTE BURRO in polvere grassi vegetali in polvere-da cocco- sale) farina di FRUMENTO PECORINO (LATTE di pecora sale caglio) sale pepe noce moscata olio di semi di girasole.Conservare a T-18°C una volta scongelato non ricongelare il prodotto e consumare previa cottura.Può contenere: PESCESEDANO UOVA SOIASENAPECROSTACEI.",
            "plu": "051267"
        },
        {
            "_id": "687004",
            "name": "FILENI PEPITOS A KG",
            "ingredients": "INGREDIENTI: Filetti di pollo farina di FRUMENTO acqua olio vegetale (girasole ARACHIDE) farina di mais peperoncino sale aromi destrosio saccarosio fruttosio spezie lievito peperone pomodoro in polvere cipolla in polvere aglio in polvere(SOLFITI)aceto di vino rosso (SOLFITI) GLUTINE di FRUMENTO maltodestrine di FRUMENTO estratto di lievito antiossidante (estratto di rosmarino) correttore di acidità (E500 E261) colorante (estratto di paprika). Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere LATTE UOVASOIASENAPE. CARNE RICOMPOSTA.",
            "plu": "050005"
        },
        {
            "_id": "730317",
            "name": "RISPO ARANCINI MEDI PREFR.ALK",
            "ingredients": "INGREDIENTI:risoacquapolpa di pomodoromozzarella vaccina (LATTE saleFERMENTI LATTICI caglio)semilavorato per impanatura (farina di FRUMENTOsalelievito di birrapaprica polverezucchero caramelliz. annatto)farina di FRUMENTOcarne bovinapiselliVINO bianco PROVOLONE (LATTEcagliosalesemola provoloneduro)GRANA PADANO DOP (LATTE salecaglio lisozima da UOVO)PECORINO (LATTE di pecoracaglio sale) olio di semi di girasole concentrato di pomodoroSEDANO carotacipolla in prop. var.prep. per BESCIAMELLA (amido modificato maisLATTE scremato in polvereLATTOSIOproteine del LATTEBURRO in polvere grassi vegetali di cocco in polveresale)salepreparato per brodo (sale iodato fecola di patateestratto di lievitozucchero grasso vegetale di palmacipolla disidratata aromispezie)bietola rossa in polv.basilicoolio extravergine dolivacipollapepe aglio(SOLFITI) noce moscata.Può contenere: PESCE. ANIDRIDE SOLFOROSASOIACROSTACEI. Conservare a T-18°C una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "051281"
        },
        {
            "_id": "731554",
            "name": "RISPO MOZZ.NA PANATA 1KG",
            "ingredients": "INGREDIENTI: MOZZARELLA vaccina (LATTE fermenti LATTICI caglio sale) semilavorato per IMPANATURA farina di FRUMENTO sale lievito di birra coloranti alimentari (annatto curcumina zucchero caramellizzato) paprica in polvere agente lievitante: E503-ii PASTELLA (acqua farina di FRUMENTO sale) olio di semi di girasole. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere SEDANO ANIDRIDE SOLFOROSA E SOLFITI PESCE E PRODOTTI A BASE DI PESCE UOVO SOIA  CROSTACEISENAPE.",
            "plu": "051282"
        },
        {
            "_id": "741945",
            "name": "RISPO FILETTI BACC.PAST.1KG",
            "ingredients": "INGREDIENTI:BACCALA(Gadus Morhuazona FAO 27)PASTELLA(acquaamido di maisfarina di FRUMENTOfarina di risoE450iE500iiE341isaleE621 gomma di Xanthanpepe nerospezie) PANGRATTATO(farina di FRUMENTOlie- vito di birrasale)olio di semi di girasole. Può contenere SEDANOANIDRIDE SOLFOROSA e SOLFITIUOVALATTE e prodotti a base di LATTESOIA CROSTACEISENAPE. Può contenere spine. prodotto pre-fritto in olio di semi di girasole.",
            "plu": "051278"
        },
        {
            "_id": "743263",
            "name": "PACGEL CHIOC.PANNALAT.CONG.100",
            "ingredients": "INGREDIENTI:Farina di FRUMENTO; margarina vegetale: Oli e grassi vegetali (palmaSOIA girasole in proporzioni variabili); acqua sale (05%) emulsionante: E471 acido citrico conservante: E202aromi colorante: caroteni misti. crema PANNALATTE 20 % (Acqua Zucchero LATTE magro in polvereSciroppo di glucosio Amido modificato BURRO concentratoPANNA concentrataAddensante:E466 Conservante: potassio sorbato Emulsionante: E471 Sale Aromi);acqua; strutto; zucchero; sale; lievito disattivato; zucchero invertito; destrosio LATTOSIO amido di FRUMENTO. Può contenere FRUTTA A GUSCIO UOVA PESCE SEMI DI SESAMO SENAPE. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "051175"
        },
        {
            "_id": "743265",
            "name": "PACGEL CORN.NERONE CONG.100G",
            "ingredients": "INGREDIENTI: farina di FRUMENTO; margarina vegetale: Grassi vegetali (palma) acqua oli vegetali (girasole) emulsionante (E472c) sale regolatori di acidità (E330 E331) aroma colorante (E160); acqua; crema NOCCIOLA 20% zucchero oli vegetali (palma e colza) cacao magro in polvere NOCCIOLE (55%) LATTE intero in polvere (2%) amido emulsionante (E322) aromi; zucchero; lievito di birra; UOVA; GLUTINE; LECITINA DI SOIA; cacao; strutto; emulsionanti (E472e E471) destrosio farina di FRUMENTO MALTATO acido L- ascorbico enzimi; sale; proteine del LATTE; LATTE scremato in polvere; aroma; zucchero invertito; destrosio; colorante: carbone vegetale. può contenere: SOIA SEMI DI SESAMO ARACHIDI FRUTTA A GUSCIO PESCESENAPE SOLFITI. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "051169"
        },
        {
            "_id": "743266",
            "name": "PACGEL CONCHI GIAND.CONG.100GR",
            "ingredients": "INGREDIENTI: Farina di FRUMENTO; margarina vegetale: grassi vegetali (palma cocco) acqua oli di girasole correttore di acidità (E331E330) emulsionante (E471) sale conservante (E202) aromi colorante (E160a); crema NOCCIOLA 20% Zucchero oli vegetali (palma colza) cacao magro in polvere NOCCIOLE (55%) LATTE intero in polvere (2%) amido emulsionante (lecitina di girasole) aromi; acqua; strutto; zucchero; sale; lievito disattivato; zucchero invertito; destrosio LATTOSIO amido di FRUMENTO. Il prodotto può contenere: SEMI DI SESAMO ARACHIDI FRUTTA A GUSCIOSOIA SENAPE PESCE SOLFITI. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "051174"
        },
        {
            "_id": "743270",
            "name": "PACGEL CORN.CREMA/AMAR.CONG.90",
            "ingredients": "INGREDIENTI:Farina di GRANO tenero tipo 0; margarina vegeale Grassi vegetali (palma) acqua oli vegetali (girasole) emulsionante (E472c) sale da cucina regolatori di acidità (E330E331) aroma colorante (E160); crema 18% (acqua LATTE intero pastorizzato sciroppo di glucosio zucchero amido modificato grassi vegetali (palma) proteine del LATTE addensante (E466) aromi sale colorante (E160); farcitura allamarena (8%) Sciroppo di glucosio-fruttosio; Amarena (30%); saccarosio; amarene candite 15% (ciliegie rotte 100%; sciroppo di glucosio-fruttosio; saccarosio; gelificante (E440i); correttore di acidità (E330); colorante (E163); acqua; zucchero; lievito di birra; UOVA; LECITINA DI SOIA; emulsionanti (E471 E472e) destrosio; farina di FRUMENTO MALTATO acido L- ascorbico enzimi; E472; proteine del LATTE; LATTE scremato in polvere; sale; aromi.Può contenere: SESAMOARACHIDIFRUTTA A GUSCIOPESCESOLFITISENAPESOIA. Conservare a T-18°C una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "051182"
        },
        {
            "_id": "743271",
            "name": "PACGEL CORN.ALBICOCCA CONG.90G",
            "ingredients": "INGREDIENTI:farina di GRANO tenero tipo 0; margarina vegetale Grassi vegetali (palma) acqua oli vegetali (girasole) emulsionante (E472c) sale regolatori di acidità (E330 E331) aroma colorante (E160); passata albicocche 15 % (sciroppo di glucosio-fruttosio; purea di albicocche; zucchero; gelificante (pectina); correttore di acidità (E330); conservante (E202); aromi; estratto di paprica; ANIDRIDE SOLFOROSA (come residuo) <50 mg/Kg ); acqua; zucchero; lievito di birra; UOVA; LECITINA DI SOIA; emulsionanti (E471 E472) destrosio farina di FRUMENTO maltato acido L- ascorbico enzimi; proteine del LATTE; LATTE scremato in polvere; sale; glucosio; aromi. Il prodotto può contenere : SENAPE SESAMO ARACHIDIFRUTTA A GUSCIO PESCE SOLFITI SOIA.Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "051181"
        },
        {
            "_id": "743277",
            "name": "PACGEL CORN.INTEGR.CONG.80GR",
            "ingredients": "INGREDIENTI:Farina di GRANO tenero tipo 0 farina integrale; margarina vegeale Oli e grassi vegetali (palma SOIA girasole colza) in parte frazionati (80%); Acqua; Emulsionanti (E471 E322 E475) sale correttore di acidità (E330) conservante (E202); Aromi colorante (E160); acqua; zucchero; lievito di birra; UOVA; GLUTINE; strutto emulsionanti (E471 E472e) destrosio; farina di FRUMENTO MALTATO acido L- ascorbico enzimi; E472; proteine del LATTE; LATTE scremato in polvere; sale; aromi. Può contenere: SENAPE SESAMO ARACHIDI FRUTTA A GUSCIO PESCE SOLFITI SOIA.Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "051187"
        },
        {
            "_id": "743283",
            "name": "PACGEL CORN.CREMA SUP.CONG.90G",
            "ingredients": "INGREDIENTI: Farina di GRANO tenero tipo 0; margarina vegetale Grassi vegetali (palma) acqua oli vegetali (girasole) emulsionante (E472c) sale regolatori di acidità (acido citrico citrato trisodico) aroma colorante (beta-carotene); crema 18 % (acqua LATTE intero pastorizzato sciroppo di glucosio zucchero amido modificato grassi vegetali (palma) proteine del LATTE addensante (E466) aromi sale colorante (betacarotene); acqua; zucchero; lievito di birra; UOVA; LECITINA DI SOIA; emulsionanti (E471 E472e) destrosio; farina di FRUMENTO MALTATO acido L- ascorbico enzimi; E472; proteine del LATTE; LATTE scremato in polvere; sale; glucosio aromi.Può contenere: SEMI DI SESAMO SENAPE ARACHIDI FRUTTA A GUSCIO PESCE SOLFITI SOIA. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato all?origine da consumarsi solo previa cottura.",
            "plu": "051178"
        },
        {
            "_id": "743284",
            "name": "SFOGLIATA FROLLA 130GR",
            "ingredients": "INGREDIENTI: Farina di GRANO tenero tipo 00?; farcitura (54%) semola di GRANO duro; ricotta 22% (siero di LATTE vaccino); zucchero; UOVA; canditi arancio (cubetti di arancio sciroppo di glucosio-fruttosio zucchero; correttore di acidità E330 conservante E220); proteine del LATTE LATTE scremato in polvere; aromi; acqua strutto margarina vegetale grassi vegetali (palma) acqua olio vegetale (girasole) LATTE scremato in polvere emulsionante (E471) sale correttore di acidità(E330) conservante (E202) aromi colorante (E160a) acqua; strutto; proteine del LATTE LATTE scremato in polvere; zucchero invertito; agente lievitante: ammonio bicarbonato; aromi; colorante (E100). Copertura: MISTO E TUORLO DUOVO pastorizzato. Il prodotto può contenere: SEMI DI SESAMO SENAPE ARACHIDI FRUTTA A GUSCIO PESCE SOLFITI SOIA. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato.",
            "plu": "051173"
        },
        {
            "_id": "743295",
            "name": "PACGEL MIX APERITIVO AL KG",
            "ingredients": "Sfoglia:Farina di FRUMENTO;margarina vegetale:grassi veg.:palmacocco acquaoli veg. :girasolecorrettore di acidità:citrati di sodioE331 acido citrico:E330 emulsionanti:mono e digliceridi degli acidi grassi E471saleconservanteE202aromi coloranti:caroteniE160a;acqua;strutto zucchero;sale;lievito disattivato;zucchero inv. ;destrosioLATTOSIO amido di FRUMENTO;maltodestrine;Ripieni:Wurstel40%:Wurstelcarne di pollo36%separ.meccan.carne di tacchino345% separ.meccan.acqua fecolasaledestrosio aromi.Addensanti:carragenina;farina di semi di carruba.Antiossidanti:ascorbato di sodio. conservanti:nitrito di sodio nitrato di potassio.ripieni:Prosciutto40%:ricotta(siero di LATTE vaccinosale);prosciutto cotto25%(carne suina72%)acquaamidosale proteine del LATTEdestrosioaromiantiossidante:ascorbato di sodio conservanti:nitrato di potassio nitrito di sodio);acqua;pecorino roma- no dop.(LATTE ovinosalecaglio)besciamella(amido modificatoLATTE magro in polvereLATTOSIOproteine del LATTEburro in polveregrassi veg. in polvere cocco)proteine del LATTELATTE scremato in polvere; sale;pepe.Potrebbe contenere:NOCCIOLESEMI DI SESAMOUOVASENAPESOIA.",
            "plu": "051353"
        },
        {
            "_id": "743307",
            "name": "SFOGLIATA RICCIA 130GR",
            "ingredients": "INGREDIENTI:Farina di GRANO tenero tipo ?0?; farcitura 54% {semola di GRANO duro; ricotta (siero di LATTE vaccino); zucchero; UOVA; canditi arancio cubetti di arancio sciroppo di glucosio-fruttosio zucchero; correttore di acidità (E330) conservante (METABISOLFITO DI SODIO); UOVA; proteine del LATTE LATTE scremato in polvere; aromi}; acqua; strutto; zucchero; sale; zucchero invertito. IL PRODOTTO POTRBBE CONTENERE : SEMI DI SESAMO ARACHIDI FRUTTA A GUSCIO PESCESENAPE SOLFITI SOIA. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "051172"
        },
        {
            "_id": "744198",
            "name": "PACGEL TRECCIA CIOK 80GR",
            "ingredients": "INGREDIENTI: Farina di FRUMENTO; margarina vegetale grassi vegetali (palma cocco) acqua oli vegetali (girasole) correttore di acidità (E331 E330) emulsionanti (E471) sale conservante (E202) aromi coloranti (E160a); crema NOCCIOLA 20% Zucchero oli vegetali (palma e colza) cacao magro in polvere NOCCIOLE (55%) LATTE intero in polvere amido emulsionante (E322) aromi; acqua; strutto; zucchero; sale; lievito disattivato; zucchero invertito; destrosio LATTOSIO amido di FRUMENTO. Può contenere SENAPE SESAMO ARACHIDI FRUTTA A GUSCIO PESCE SOLFITI UOVA SOIA . Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "051120"
        },
        {
            "_id": "744200",
            "name": "PACGEL CORN.VUOTO CONG.80GR",
            "ingredients": "INGREDIENTI: Farina di GRANO tenero tipo 0; margarina vegetale Grassi vegetali (palma) acqua oli vegetali (girasole) emulsionante (E472c) sale regolatori di acidità (E330 E331) aroma colorante (E160); acqua; zucchero; lievito di birra; UOVA; LECITINA DI SOIA emulsionanti (E471 E472e) destrosio; farina di FRUMENTO MALTATO acido L- ascorbico enzimi; E472; proteine del LATTE; LATTE scremato in polvere; sale; aromi. può contenere: SEMI DI SESAMOSENAPE ARACHIDI FRUTTA A GUSCIO PESCE SOLFITI. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.ve essere",
            "plu": "051179"
        },
        {
            "_id": "744209",
            "name": "PACGEL CORN.CER/FR.BOS.CONG.80",
            "ingredients": "INGREDIENTI:Farina di FRUMENTO; margarina vegetale Grassi vegetali (palma) acqua oli vegetali (girasole) emulsionante (E472c) sale regolatori di acidità (acido citrico citrato trisodico) aroma colorante (beta-carotene) mix cereali crusca di FRUMENTO termotrattato farina di GRANO tenero 00 pasta acida di fermentazione (farina di FRUMENTO acqua lievito in polvere) SEMI DI SESAMO olio di oliva farina di SEGALE farina di AVENA farina di riso farina di ORZO E300 confettura frutti di bosco 12 % purea di frutti di bosco; sciroppo di glucosio-fruttosio;zucchero; gelificante: pectina acido citrico;E163 E122 E220); acqua; strutto zucchero;lievito di birra; UOVA; GLUTINE; 471 E472e destrosio;farina di FRUMENTO MALTATO acido L- ascorbico enzimi; E472; cacao;proteine del LATTE; LATTE scremato in polvere; sale; aromi. Può contenere: SEMI DI SESAMO ARACHIDI FRUTTA A GUSCIO PESCE SOLFITISOIASENAPE. Conservare a T-18°C una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "051177"
        },
        {
            "_id": "746151",
            "name": "PACGEL STRUDEL FR.BOSCO 90GR",
            "ingredients": "INGREDIENTI: Farina di FRUMENTO; margarina vegetale grassi vegetali (palma cocco) acqua oli vegetali (girasole) correttore di acidità (E331 E330 emulsionanti (E471) sale conservante (E202) aromi coloranti (E160a); confettura ai frutti di bosco purea di frutti di bosco (fragole ribes more) sciroppo di glucosio-fruttosio saccarosio gelificante (pectina) correttore di acidità (E300) conservante (E202) aromi; acqua; strutto; zucchero; sale; lievito disattivato; zucchero invertito; destrosio LATTOSIO amido di FRUMENTO. Può contenere:SENAPE SESAMO ARACHIDI FRUTTA A GUSCIO PESCE SOLFITI UOVA SOIA . Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "051171"
        },
        {
            "_id": "746287",
            "name": "DELIFRANCE DELILOOP CAC/NOC70G",
            "ingredients": "INGREDIENTI: farina di GRANO tenero acqua olio di palma non idrogenato margarina veg grassi veg non idrogenatidi (palma e colza) acqua succo di limone zucchero lievito emulsionanti (E471 E472 E481) GLUTINE di GRANO tenero sale agenti lievitanti (E450 E500) destrosio stabilizzante (E412) LATTOSIO LATTE scremato in polvere agente di trattamento delle farine (E300) farcitura zucchero grassi non idrogenati (girasole SOIA palma) NOCCIOLE cacao magro in polvere LATTOSIO LATTE scremato in polvere emulsionante (E322)aroma glassatura (zucchero grassi veg.non idrogenati (palma cocco SOIA) cacao sgrassato in polvere LATTE intero in polvere emulsionante (E322) aroma. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere SENAPEARACHIDI.",
            "plu": "051153"
        },
        {
            "_id": "746288",
            "name": "DELIFRANCE DELILOOP ARCOB.57GR",
            "ingredients": "INGREDIENTI: farina di GRANO Tenero acqua olio di palma non idrogenato glassatura zucchero oli e grassi veg. non idrogenati (palmistopalmacocco) E322 E171aroma margarina oli e grassi veg.non idrogenati (palmacolza) acqua succo di limone zucchero decoro zucchero farina di riso burro di cacao addensante (E413) coloranti (E101 E120 E141 E160a E163)aroma lievito emulsionanti (E471 E472e E481) GLUTINE di GRANO tenero sale agenti lievitanti (E450-E500) destrosio stabilizzante (E412) LATTOSIO siero di LATTE in polvere E160a. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere FRUTTA A GUSCIO SOIASENAPE ARACHIDI.",
            "plu": "051154"
        },
        {
            "_id": "746291",
            "name": "DELIFRANCE SOGNO CIOCC.90GR",
            "ingredients": "INGREDIENTI: farcitura cioccolato-NOCCIOLE (zucchero olio di colza non idrogenato NOCCIOLE LATTE scremato in polvere cioccolato (cacao in polv pasta di cacao zucchero) E322 (SOIA) E330 aroma naturale di vaniglia) UOVA olio di colza non idrogenato farina di GRANO tenero acqua cacao in polvere cioccolato zucchero pasta di cacao burro di cacao E322(SOIA) aroma naturale di vanigliaamido modificato SIERO di LATTE in polvere E477 E471 E481 E450 E500 sale GLUTINE di GRANO tenero aroma. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere SEMI DI SESAMOSENAPEARACHIDI.",
            "plu": "051156"
        },
        {
            "_id": "746551",
            "name": "DELIFRANCE CROISSANT VUOTO 75G",
            "ingredients": "INGREDIENTI: farina di GRANO tenero BURRO acqua zucchero lievito GLUTINE di GRANO tenero UOVA sale emulsionante (E472) caroteni di origine naturale agente di trattamento di farina (E300) enzima. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere SOIA FRUTTA A GUSCIOSENAPEARACHIDI.",
            "plu": "051162"
        },
        {
            "_id": "746679",
            "name": "CAPPELLETTI AL PROSCIUTTO A KG",
            "ingredients": "INGREDIENTI: pasta: farina di GRANO tenero 00 UOVA semola di GRANO duro. Ripieno: prosciutto stagionato(carne di suino) besciamella(LATTEfarina di GRANO tenero) sale aromi naturali. Può contenere SOIASENAPE.",
            "plu": "050213"
        },
        {
            "_id": "746681",
            "name": "RAVIOLI AGLI SCAMPI AL KG",
            "ingredients": "INGREDIENTI: sfoglia:farina di GRANO tenero 00 semola di GRANO duroUOVA fresche e pastorizzate. Ripieno:RICOTTA(siero di LATTE ovino)concentrato di SCAMPISCAMPI(Nephrops norvegicus)olio di girasole e EVOcipollasaleaglio(SOLFITI)fiocchi di patatecarota SEDANOprezzemolozucchinepomodoro fibre vegestr.di lievitoproteine del LATTEaromi acidificanteacido citrico. Può contenere SOIASENAPE.",
            "plu": "050211"
        },
        {
            "_id": "746682",
            "name": "RAVIOLI AL SALMONE AL KG",
            "ingredients": "INGREDIENTI: sfoglia:farina di GRANO tenero 00 semola di GRANO duroUOVA fresche e pastorizzate. Ripieno:ricotta(siero di LATTE ovino)crema al SALMONE SALMONE(Salmo salar)o.di girasole e EVOcipollasaleaglio(SOLFITI) fiocchi di patatecarota SEDANO prezzemolozucchinepomodorofibre veg estr.di lievitoproteine del LATTEaromiacidifi- canteacido citrico. Può contenere SOIASENAPE.",
            "plu": "050210"
        },
        {
            "_id": "746684",
            "name": "RAVIOLI POLPA DI GRANCHIO A KG",
            "ingredients": "INGREDIENTI: sfoglia:farina di GRANO tenero 00 semola di GRANO duro UOVA fresche e pastorizzate. Ripieno: ricotta(siero di LATTE ovino) crema di GRANCHIOo.di girasole e EVOcipolla aglio(SOLFITI) fiocchi di patate carotasaleSEDANOprezzemolozucchine pomodorofibre vegestr.di lievitoproteine del LATTEaromiacidificanteacido citrico. Può contenere SOIASENAPE.",
            "plu": "050208"
        },
        {
            "_id": "746686",
            "name": "TORTELLI RICOTTA/SPINACI AL KG",
            "ingredients": "INGREDIENTI: sfoglia:farina di GRANO tenero 00 semola di GRANO duro UOVA fresche e pastorizzate. Ripieno: ricotta(siero di LATTE ovino) spinaci sale pepe noce moscata. Può contenere SOIASENAPE.",
            "plu": "050214"
        },
        {
            "_id": "746687",
            "name": "TORTELLI DI MARE AL KG",
            "ingredients": "INGREDIENTI: farina di GRANO tenero 00 semola di GRANO duro UOVA fresche pastorizzate. Ripieno: BESCIAMELLA RICOTTA (siero di LATTE ovino) TENTACOLI di TOTANO precotti GAMBERI lessati PAN GRATTATO prezzemolo aglio(SOLFITI) salearomi naturali. Può contenere SOIASENAPE.",
            "plu": "050215"
        },
        {
            "_id": "747224",
            "name": "DELIFRANCE CROIS.CIOC/NOCC.90G",
            "ingredients": "INGREDIENTI: Farina di GRANO tenero acqua farcitura zucchero NOCCIOLE cioccolato in polvere (zucchero cacao in polvere) grasso vegetale non idrogenato LATTE scremato in polvere emulsionante (lecitina di SOIA) BURRO zucchero lievitodecorazione cioccolato (cacao zucchero BURRO di cacao E476 aroma di vaniglia) GLUTINE di GRANO UOVA sale emulsionante (E472e) E300 colorante (E160) enzima. Eventuale presenza di FRUTTA con GUSCIO. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato all?origine da consumarsi solo previa cottura. Può contenere SOIASENAPEARACHIDI.",
            "plu": "051160"
        },
        {
            "_id": "747225",
            "name": "DELIFRANCE CROIS.ALBIC0CCA 90G",
            "ingredients": "INGREDIENTI: Farina di GRANO acqua farcitura purea di albicocca varietà Bella dImola - origine italiana sciroppo di glucosio-fruttosio zucchero gelificante (E440i) correttore di acidità (E330) aromi BURRO zucchero lievito GLUTINE di GRANO UOVA sale emulsionante (E472e) agente trattamento della farina (E300) colorante (E160aii) enzima. Zucchero di decoro: zucchero grasso vegetale non idrogenato. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Eventuale presenza di ARACHIDI SOIA NOCI di Pecan NOCCIOLE MANDORLESENAPE.",
            "plu": "051161"
        },
        {
            "_id": "747226",
            "name": "DELIFRANCE CROISSANT CREMA 90G",
            "ingredients": "INGREDIENTI: Farina di GRANO tenero acqua BURRO farcitura crema gusto vaniglia 16% (acqua sciroppo di glucosio zucchero amido modificato grasso di palma non idrogenato LATTE scremato in polvere stabilizzante (E460 E466)aromi naturaliemulsionante (E471) conservante (E202)sale)zucchero decoro 3% (zucchero amidi glucosio agente di rivestimento (E903)) lievito glutine di GRANO tenero sale UOVA colorante (caroteni di origine naturale) agente di trattamento della farina (E300) enzima. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere SOIA FRUTTA A GUSCIOSENAPE.",
            "plu": "051163"
        },
        {
            "_id": "747313",
            "name": "GAMBERI ROSA MEDITER.CONG.KG",
            "ingredients": "INGREDIENTI: GAMBERO rosa intero congelato (Parapenaeus Iongirostris) acqua. Antiossidanti: metabiSOLFITO di sodio(E223) Acidificante E330 Antiossidanti E300 E331 Sequestranti E385. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050334"
        },
        {
            "_id": "747330",
            "name": "SCAMPI POL.11/15 CONG.KG",
            "ingredients": "INGREDIENTI: SCAMPI (Nephrops norvegicus) acquaSOLFITO E223. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050330"
        },
        {
            "_id": "748182",
            "name": "POLPI PICCOLI INDIA CONG.ALKG",
            "ingredients": "INGREDIENTI: POLPO indopacifico (Octopus membranaceus) pescato nellOceano Indiano occidentale (zona Fao 51) con reti da traino. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050892"
        },
        {
            "_id": "748183",
            "name": "AMULYA POLPI PIC.CONG.INDIA K",
            "ingredients": "INGREDIENTI: POLPO (Octopus) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050893"
        },
        {
            "_id": "748986",
            "name": "ICELANDIC LOMO GRAN CHEF ALKG",
            "ingredients": "INGREDIENTI:MERLUZZO(gadus morhua)acquasale. Conservare a temperatura non superiore a -18°C una volta scongelato non ricongelare e consumare entro 24h previa cottura.",
            "plu": "050763"
        },
        {
            "_id": "750313",
            "name": "CALAMARI PUL.CONG.11/20",
            "ingredients": "INGREDIENTI: CALAMARO indiano (Loligo Duvauceli) acqua sale correttore di acidità: E331. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050894"
        },
        {
            "_id": "750352",
            "name": "TRANCE SPADA CONG.EXT.TONDE K",
            "ingredients": "INGREDIENTI: PESCE SPADA (Xiphias gladius). Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura. Prodotto congelato.",
            "plu": "050764"
        },
        {
            "_id": "750581",
            "name": "PACGEL SFOGL.FROLLA LEMON 100",
            "ingredients": "INGREDIENTI: Farina di GRANO tenero tipo 00; farcitura 54% {semola di GRANO duro; ricotta (siero di LATTE vaccino); crema limone LATTE intero reidratato Zucchero Sciroppo di glucosio Amido modificato Tuorlo d?UOVO fresco olio di Cocco LATTE scremato in polvere Addensante (E466) Conservante (E202) Aromi naturali; Sale; zucchero; UOVA; macinato di limone limoni di origine italiana sciroppo di glucosio-fruttosio zucchero; correttore di acidità (E330) conservante ANIDRIDE SOLFOROSA); LATTE scremato in polvere; aromi}; acqua strutto margarina vegetale grassi vegetali (palma) acqua olio vegetale (girasole) LATTE scremato in polvere E471 saleE330E202 aromiE160aproteine del LATTE LATTE scremato in polvere; zucchero invertito; agente lievitante: ammonio bicarbonato; aromi;E100. Copertura: MISTO E TUORLO DUOVO pastor. Può contenere:SENAPESESAMOARACHIDIFRUTTA A GUSCIOPESCE SOLFITI SOIA.Conservare a T-18°C.Una volta scongelato non ricongelare il e consumare previa cottura.",
            "plu": "051195"
        },
        {
            "_id": "751803",
            "name": "FILETTO PLATESSA N2S/PE",
            "ingredients": "INGREDIENTI: PLATESSA (Pleuronectes platessa) acqua E331. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050485"
        },
        {
            "_id": "751970",
            "name": "FIORI ZUCCA PASTEL.ALICI AL KG",
            "ingredients": "INGREDIENTI:Pastella(farina di GRANOacquasale e lievito nat.)fiori di zucca mozzarella(LATTE pastor.salecaglioFERMENTI LATTICIE330) ALICI(PESCE- Engraulis encrasicolus zona di pesca mar mediterraneo FAO 37.137.237.3)olio di semi di girasole;Prosciutto cottocarne suinoacquaamididestrosiosalesaccarosioaromiamido di patata E450E451E407E301E316E262E250olio di semi di girasole;Riso pomodoro(polpa di pomodoroE330)mozzarella(LATTEsalecaglioE330) carne(bovino adulto E300);Formaggio-LATTE(formaggisaleE339)cipolla carotaVINOolio di semi di girasole;Preparato per brodo (salefecola di patateverdure disidratate(cipollacarota SEDANOprezzemolo) estratto per brodo di proteine veg.(SOIA mais)olio di girasole speziearomi nat.)salepepe;Ingredienti panatura:farina di FRUMENTO olio di semi di girasolelievitosalepaprika in polv.curcuma in polv Conservare a T-18°Cuna volta scong. non ricongelare il prod. e consumare previa cottura. Può contenere CROSTACEIUOVAARACHIDISESAMO SENAPELUPINIFRUTTA A GUSCIOMOLLUSCHI PESCE e ANIDRIDE SOLFOROSA.",
            "plu": "051307"
        },
        {
            "_id": "751971",
            "name": "GARBO SUPPLI RISO RAGU CONG.KG",
            "ingredients": "INGREDIENTI: Riso pomodoro polpa di pomodoro correttore di acidità (E330) mozzarella LATTE sale caglio correttore di acidità (E330) carne bovino adulto antiossidante (E300). Formaggio - LATTE (formaggi sale emulsionante E339) cipolla carota VINO olio di semi di girasole. Preparato per brodo Sale fecola di patate verdure disidratate (cipolla carota SEDANO prezzemolo) estratto per brodo di proteine vegetali (SOIA mais) olio di girasole spezie aromi naturali sale pepe. Ingredienti panatura: farina di FRUMENTO olio di semi di girasole lievito sale paprika in polvere curcuma in polvere. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere CROSTACEIUOVAARACHIDIFRUTTA A GUSCIOSESAMOSENAPE LUPINIMOLLUSCHIPESCE e ANIDRIDE SOLFOROSA.",
            "plu": "051308"
        },
        {
            "_id": "752444",
            "name": "SEPPIA PULITA 500/1000(25K)",
            "ingredients": "INGREDIENTI: SEPPIA (Sepia aculeata) acquasaleantiossidanti E330 E331. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050920"
        },
        {
            "_id": "752519",
            "name": "ARAGOSTA 860/1100CON.KG",
            "ingredients": "INGREDIENTI: ARAGOSTA (Palinurus homarus) Può contenere SOLFITI. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050333"
        },
        {
            "_id": "753736",
            "name": "CROCCHE MEDIO MOZZ.PF.CONG.KG",
            "ingredients": "INGREDIENTI: Acqua fiocchi di patate (patate disidratateE471 E450i spezieE304E223 aroma naturaleE330) spalla cotta (carne di spalla di suino acqua sale amido proteine di SOIA isolante destrosio LATTOSIO zucchero LATTE scremato aromiE621E450 E301E407E250) PECORINO ROMANO (LATTE intero di pecora terminizzato caglio di agnello in pasta FERMENTI LATTICI sale) LATTE margarina (oli e grassi vegetali in parte idrogenati acqua emulsionanti (E471 E322) conservante (E202) correttore di acidità (E330) aromi coloranti (E160b E100) MOZZARELLA (LATTE caglio sale) sale pepe prezzemolo farina di GRANO tenero tipo 00 PANE grattugiato (farina di GRANO tenero tipo 0 acqua lievitosale) olio di semi di girasole. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato all?origine da consumarsi solo previa cottura. Può contenere:CROSTACEI PESCE SENAPE MOLLUSCHI ARACHIDI.",
            "plu": "051315"
        },
        {
            "_id": "753737",
            "name": "CROCCHE GRANDE MOZ.PF.CONG.KG",
            "ingredients": "INGREDIENTI: Acqua fiocchi di patate (patate disidratateE471E450i spezieE304E223 aroma naturaleE330)spalla cotta (carne di spalla di suinoacquasaleamidoproteine di SOIA isolantedestrosio LATTOSIO zuccheroLATTE scrematoaromiE621E450E301E407 E250) pecorino romano (LATTE intero di pecora terminizzato caglio di agnello in pasta FERMENTI LATTICI sale) LATTE margarina (oli e grassi vegetali in parte e idrogenati acqua emulsionanti (E471 E322)) conservante (E202) correttore di acidità (E330) aromi coloranti (E160b E100) mozzarella (LATTE caglio sale) sale pepe prezzemolo farina di GRANO tenero 00 pane grattugiato (farina di GRANO tenero tipo 0 acqua lievitosale) olio di semi di girasole. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere: CROSTACEI PESCE SOIA SENAPE MOLLUSCHIARACHIDI.",
            "plu": "051316"
        },
        {
            "_id": "753738",
            "name": "CROCCHE MEDIO NAP.PF.CONG.KG",
            "ingredients": "INGREDIENTI:acquafiocchi di patate(patate disidradateE471E450i spezieE304E228aroma naturaleE330)salame tipo napoli carne suino saledestrosiospeziearomiE301E250 E252spalla(carne suinaacqua fecola di patatesaledestrosioaromi natur.carragenina polifosfati E621E301E250)PECORINO ROMANO(LATTEcagliosaleFORMAGGIO di pecora)LATTE salemargarinaoli e grassi vegetali(palma)grassi parzialmente idrogenati(palma e cocco in porzioni variabili)oli (SOIAgirasole e colza in porzioni variabili)oli parzialmente idrogenati (palma e cocco in porzioni variabili)oli(SOIAgirasole e colza in porzioni variabili)acquaE471E322E202)MOZZARELLA(LATTE pastorizzatoFERMENTI LATTICIcaglio sale)farina di GRANO tenero tipo 00PANE grattuggiato (farina di GRANO tenero 0acqua lievito salespezie)pepeprezzemoloaroma naturale (oleoresina di curcuma e polisorbato)olio di girasole.Può contenere CROSTACEIPESCEARACHIDI SENAPE e MOLLUSCHI. Conservare a T-18°C una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "051318"
        },
        {
            "_id": "753739",
            "name": "CROCCHE GRANDE NAP.PF.CONG.KG",
            "ingredients": "INGREDIENTI: Acqua fiocchi di patate (patate disidratate E471450i spezie E304 E223 aroma naturaleE330) salame (carne suina aromi) spalla cotta di suino acqua sale amido proteine di SOIA isolantedestrosio LATTOSIO zucchero LATTE scrematoaromi E621E450E301 E407E250) PECORINO ROMANO (LATTE intero di pecora termizzato caglio di agnello in pasta FERMENTI LATTICI sale) LATTE margarina (oli e grassi vegetali in parte e idrogenati acqua emulsionanti (E471 E322) conservante (E202) correttore di acidità (E330) aromi coloranti (E160b E100) MOZZARELLA (LATTE caglio sale) sale pepe prezzemolo  farina di GRANO tenero tipo 00 PANE grattugiato (farina di GRANO tenero tipo 0 acqua lievito sale) olio di semi di girasole.Conservare a T-18°C una volta scongelato non ricongelare il prodotto e consumare previa cottura. Può contenere: CROSTACEI PESCE SENAPE MOLLUSCHIARACHIDI.",
            "plu": "051317"
        },
        {
            "_id": "753740",
            "name": "FRITTELLA ZUCCH/GAMB.CONG.A KG",
            "ingredients": "INGREDIENTI: farina di GRANO tenero tipo 00acqua zucchine gamberi (Peneaus Semisulcatus pescato nella regione FAO 51/57) lievito di birra (Saccharomyces Cervisiae) saleolio di girasole. Conservare a T-18°C.Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato.Prodotto surgelato allorigineda consumarsi solo previa cottura.Può contenere SOIA SENAPE.",
            "plu": "051319"
        },
        {
            "_id": "753741",
            "name": "NIDO ORTOLANO PAST.CONG.AL KG",
            "ingredients": "INGREDIENTI: farina di GRANO tenero tipo 00 acqua zucchine carote sale lievito di birra (prodotto di origine naturale costituito esclusivamente da Saccharomyces Cere-visiae) olio di girasole.Può contenere SOIASENAPE. Conservare a temperatura non superiore a -18°C una volta scongelato non ricongelare e consumare entro 24h previa cottura.",
            "plu": "051320"
        },
        {
            "_id": "754875",
            "name": "DELIFRANCE CROIS.INT.FR.BOS100",
            "ingredients": "INGREDIENTI: Farina di GRANO tenero acqua BURRO farcitura zucchero frutti di bosco (purea di mirtilli purea di fragole purea di more purea di lamponi) sciroppo di glucosio-fruttosio gelificante:E440 fibra veg E330 aroma naturale) farina integrale di GRANO tenero lievito zucchero semi (lino scuro girasole papavero) GLUTINE di GRANO TENERO UOVA sale E472e E300enzima. Può contenere SENAPE SOIA FRUTTA A GUSCIO. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "051167"
        },
        {
            "_id": "755804",
            "name": "FIL.MERL.NORD.500/1000",
            "ingredients": "INGREDIENTI: MERLUZZO nordico (Gadus morhua) acqua sale. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "050488"
        },
        {
            "_id": "755805",
            "name": "FIL.MERL.NORD.1000UP",
            "ingredients": "INGREDIENTI:MERLUZZO nordico (Gadus morhua)sale stabilizzanti: E451-E450-E331 correttori di acidità: E330. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050489"
        },
        {
            "_id": "756088",
            "name": "SCAMPI 1-6 CONGEL.Al KG",
            "ingredients": "INGREDIENTI:SCAMPO(Nephrops norvegicus)acquaantiossidante metabiSOLFITO di sodio. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050337"
        },
        {
            "_id": "756547",
            "name": "FILET.SPIGOLA CONG.80/120 GR",
            "ingredients": "INGREDIENTI:BRANZINO(Dicentrarchus labrax)acqua. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050490"
        },
        {
            "_id": "756548",
            "name": "FILET.ORATA.CONG.80/120GR",
            "ingredients": "INGREDIENTI:ORATA(Sparus aurata)acqua. Conservare a T -18°c. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050491"
        },
        {
            "_id": "756760",
            "name": "TRIANGOLI POLENTA PF AL KG",
            "ingredients": "INGREDIENTI: acqua farina di mais  ciccioli (suino sale conservante E250)  margarina oli e grassi vegetali (grassi palma)  grassi parzialmente idrogenati (palma e cocco in porzioni variabili) oli (SOIA e girasole e colza in porzioni variabili)  oli parzialmente idrogenati (palma e cocco in porzioni variabili) acqua emulsionanti E471 E322(SOIA)  conservante (E 202) PECORINO ROMANO (LATTE  caglio  sale) sale prezzemolo olio di girasole. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere: SEDANOSOLFITIPESCEMOLLUSCHISENAPEARACHIDI.",
            "plu": "051342"
        },
        {
            "_id": "756932",
            "name": "BASTONCINI DI MERLUZZO AL KG",
            "ingredients": "INGREDIENTI: MERLUZZO (Merluccius paradoxus) pescato nellOceano Atlantico Sud-est (FAO 47) con reti da trainofarina di GRANO olio di semi di girasoleacqua PANATURA 45% amido di mais sale colorante: E160bII proteina di SOIA idrolizzata aromatizzazione (contiene LATTE).Può contenere SENAPE. Può contenere spine.Conservare a -18°C. Non ricongelare. Consumare previa cottura.",
            "plu": "051010"
        },
        {
            "_id": "757051",
            "name": "PACGEL GALAXY BLACK/WHITE 90GR",
            "ingredients": "INGREDIENTI: Farina di FRUMENTO; margarina vegetale: Oli e grassi vegetali (palma SOIA girasole colza) in parte frazionati (80%); Acqua; Emulsionanti (E471 E322 E475) sale correttore di acidità (E330) conservante (E202); Aromi colorante (E160); acqua; crema al cioccolato bianco 15% oli e grasso vegetale (girasole e palma) LATTOSIO amido modificato cioccolato bianco (zucchero burro di cacao LATTE INTERO in polvere); crema NOCCIOLA 12% zucchero oli vegetali (palma e colza) cacao magro in polvere NOCCIOLE (5 5%) LATTE intero in polvere (2%) amido emulsionante (E322 aromi); zucchero; lievito di birra; UOVA; GLUTINE; cacao; strutto; emulsionanti (E472e E471) destrosio farina di FRUMENTO MALTATO acido L- ascorbico enzimi; sale; proteine del LATTE; LATTE scremato in polvere; aroma; zucchero invertito; destrosio; colorante: carbone vegetale.Può contenere:SENAPE SESAMOARACHIDIFRUTTA A GUSCIOPESCE SOLFITISOIA.Conservare a T-18°C una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "051193"
        },
        {
            "_id": "759075",
            "name": "CALAMARO SPORCO 3/6 IQF AL KG",
            "ingredients": "INGREDIENTI: CALAMARO indiano intero (Loligo duvauceli). Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "050901"
        },
        {
            "_id": "759239",
            "name": "*CALAMARO SP.U/3 BL.3X4KG.GL10",
            "ingredients": "INGREDIENTI: CALAMARO indiano intero (Loligo duva uceli). Conservare a T-18°C. Consumare preferibilmente entro la data ri portata in etichetta. Una volta scongelato il prodotto deve e ssere consumato entro le 24 ore e non può essere ricongelato. Prod otto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "050903"
        },
        {
            "_id": "759240",
            "name": "SEPPIA PULITA U1 AL KG",
            "ingredients": "INGREDIENTI: SEPPIA indopacifica pulita (Sepia Pharaonis). Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050902"
        },
        {
            "_id": "759242",
            "name": "*CALAMARO SP.6/10 BL6X2KG.GL10",
            "ingredients": "INGREDIENTI: CALAMARO indiano intero (Loligo duva uceli). Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve e ssere consumato entro le 24 ore e non può essere ricongelato. Prod otto surgelato all?origine da consumarsi solo previa cottura.",
            "plu": "050904"
        },
        {
            "_id": "759780",
            "name": "FRITTURA PARANZA ADRIATICO KG",
            "ingredients": "INGREDIENTI:MERLUZZI(Merluccius Merluccius)MAZZONI(Gobius Niger Jozo)TRIGLIE(Mullus Barbatus)BUSBANE(Gadus Minutus Capelanus) MERLANO o MOLO(Merlangius Merlangus) ZANCHETTE(Amoglossus Laterna) ALICI(engraulis Encrasicolus).Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050906"
        },
        {
            "_id": "759964",
            "name": "CALAMARO ANELLI CIUFFO AL KG",
            "ingredients": "INGREDIENTI: CALAMARO (Loligo Duvauceli) acqua sale antiossidanti: E330 E331. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050907"
        },
        {
            "_id": "761521",
            "name": "SEPPIA PUL.CONG.8/12 KG",
            "ingredients": "INGREDINTI:SEPPIA indopacifica(Sepia Pharaonis) acquasalecorrettore di acidità E330 antiossidante E331. ALLERGENI:MOLLUSCHI Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050908"
        },
        {
            "_id": "761522",
            "name": "SEPPIA PULITA CONG.13/20 AL KG",
            "ingredients": "INGREDINTI:SEPPIA indopacifica(Sepia Pharaonis) acquasalecorrettore di acidità E330 antiossidante E331. ALLERGENI:MOLLUSCHI Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050909"
        },
        {
            "_id": "763490",
            "name": "SEPPIETTE ALMENDRITA AL KG.",
            "ingredients": "INGREDIENTI: SEPPIETTA congelata (Sepia elegans). Conservare a temperatura non superiore a -18°C una volta scongelato non ricongelare e consumare entro 24h previa cottura.",
            "plu": "050883"
        },
        {
            "_id": "770619",
            "name": "POLPO RIC.MAROC.T4 15/2 A KG",
            "ingredients": "INGREDIENTI: POLPO (Octosus vulgaris) pescato nellOceano Atlantico Centro Orientale zona FAO 34 con nasse acqua. Conservare a -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "050916"
        },
        {
            "_id": "847848",
            "name": "ARAGOSTA CRUDA 300/400 IQF",
            "ingredients": "INGREDIENTI: ARAGOSTA (Palinurus mauritanicus) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050314"
        },
        {
            "_id": "847855",
            "name": "PANNOCCHIA(CICALA DI MARE)A KG",
            "ingredients": "INGREDIENTI: PANNOCCHIE (Squilla Mantis) ALLERGENI: CROSTACEI Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050315"
        },
        {
            "_id": "849372",
            "name": "FIORI SALMONE CONG.AL KG",
            "ingredients": "INGREDIENTI: SALMONE rosa (Oncorhynchus Gorbuscha)acqua. Conservare a T-18°C. Una volta scongelato il prodotto non ricongelare e consumare solo previa cottura.",
            "plu": "050756"
        },
        {
            "_id": "853523",
            "name": "FIORI ZUCCA PASTEL.PROSC.AL KG",
            "ingredients": "INGREDIENTI:Pastella(farina di GRANO acquasale e lievito nat.) fiore di zucca mozzarellaLATTEsalecaglioE330)prosciutto cotto (carne di suinoacquaamididestrosio salesaccarosioaromiamido di patataE450E451E407E301E316E262E250)olio di semi di girasole Risopomodoro(polpa di pomodoroE330)mozzarella(LATTEsalecaglio E330) carne(bovino adultoE300)formaggio - LATTE(formaggisaleE339) cipollacarotaVINOolio di semi di girasolepreparato per brodoSale fecola di patateverdure disidr.(cipolla carotaSEDANOprezzemolo) brodo di proteine vegetali(SOIAmais)olio di girasolespezie aromi natur.salepepe.Panatura:farina di FRUMENTOolio di semi di girasolelievitosale paprika in polverecurcuma in polvere. Conservare a T-18°C.Consumare prefer. entro la data riportata in etichetta.Una volta scong.il prodotto deve essere consumato entro le 24 ore e non può essere ricong.Prodotto surg. allorigine da consumarsi previa cottura.Può contenere:SESAMOCROSTACEIUOVAPESCE ARACHIDIFRUTTA A GUSCIOSENAPELUPINIMOLLUSCHIANIDRIDE SOLFOROSA.",
            "plu": "051269"
        },
        {
            "_id": "853531",
            "name": "GARBO_VERDURE PASTELLATE AL KG",
            "ingredients": "INGREDIENTI: verdure in proporzione variabile (zucchine cavolfiori carciofi) pastella farina di FRUMENTO farina di mais grasso vegetale idrogenato in polvere farina di riso amido di mais agenti lievitanti (E450i; E500-ii) LATTE scremato in polvere estratti di lievito olio di semi di girasole sale. Pastellatura acqua farina di FRUMENTO farina di mais olio di girasole sale agenti lievitanti (E-450i E-500ii) esaltatore di sapidità (E621) coloranti (E-101ii E-160aii) coda di gambero (Penaeus spp sale). Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere: CROSTACEI UOVO ARACHIDI FRUTTA A GUSCIO SESAMO SENAPE LUPINI MOLLUSCHI SOIA SEDANO PESCE e ANIDRIDE SOLFOROSA.",
            "plu": "051270"
        },
        {
            "_id": "853549",
            "name": "GARBO FILET.BACCALA CONG.AL KG",
            "ingredients": "INGREDIENTI: Filetti di BACCALA(Gadus macrocephalus - zona di cattura FAO 27 Atlantico Nord Est e zone di cattura FAO 61 67 e 77 Oceano Pacifico) Y o (Gadus Morhua ? Zona FAO 21 e 27 Oceano Atlantico) X; pastella (farina di GRANO tenero tipo ?0? e ?00? sale lievito naturale e di birra) olio di semi di girasole. La presenza dell?ingrediente X o Y \u00c3\u00a8 indicata nell?ultima lettera del lotto. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere CROSTACEI UOVAARACHIDI SOIA FRUTTA A GUSCIO SEDANO SESAMO SENAPE LUPINI MOLLUSCHI ANIDRIDE SOLFOROSA e LATTE.",
            "plu": "051268"
        },
        {
            "_id": "860056",
            "name": "FILET.CERNIA 50/150 GL20%CONG",
            "ingredients": "INGREDIENTI:CERNIA indopacifica (Epinephelus Diacanthus)stabilizzante E451. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050465"
        },
        {
            "_id": "861740",
            "name": "ALICI INTERE AL KG",
            "ingredients": "INGREDIENTI: ALICI (Engraulius encrasicolus). Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "050622"
        },
        {
            "_id": "861757",
            "name": "FILETTO SGOMBRO CONGELATO A KG",
            "ingredients": "INGREDIENTI:FILETTI di SGOMBRO(Scomber scombrus) Può contenere MOLLUSCHI e CROSTACEI. AVVERTENZA:il prodotto contiene lische. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050467"
        },
        {
            "_id": "864850",
            "name": "PREPARATOXRISOTTO IQF CONG.KG",
            "ingredients": "INGREDIENTI: TOTANO australe(FAO81) VONGOLE del Pacifico sgusciate(FAO71) COZZE cilene sgusciate allevate in Cile GAMBERI argentini(FAO41)MAZZANCOLLE tropicali allevati in Thailandia. Correttori di acidità: E330 E331. Antiossidante: E223 Allergeni:MOLLUSCHI CROSTACEISOLFITI e PESCE. Conservare a -18°C. Prodotto surgelato allorigine. Non ricongelare.",
            "plu": "050206"
        },
        {
            "_id": "864868",
            "name": "PREPARATO PAELLA IQF AL KG",
            "ingredients": "INGREDIENTI:riso giallo precottopisellipomodori carotepeperonicipollezucchine ANELLI di TOTANO dellAtlantico(FAO41)VONGOLE del Pacifico(FAO71) COZZE cilene allevate in CileMAZZANCOLLE tropica- li allevati nelle acqua dolci della Thailandia stabilizzante:E452cor.acidità:E330-E331antiossi- dante:E223.Può contenere SOLFITI",
            "plu": "050205"
        },
        {
            "_id": "870733",
            "name": "FILETTI S.PIETRO 60/200",
            "ingredients": "INGREDIENTI: PESCE SAN PIETRO correttori di acidità E330 E331. ALLERGENI: PESCE. Conservare a temperatura non superiore a -18°C una volta scongelato non ricongelare e consumare entro 24h previa cottura.",
            "plu": "050468"
        },
        {
            "_id": "873356",
            "name": "CUORI MERLUZZO AL KG",
            "ingredients": "INGREDIENTI: MERLUZZO sudafricano (Merluccius capensis/paradoxus) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura. Possono essere presenti lische.",
            "plu": "050757"
        },
        {
            "_id": "881896",
            "name": "ANELLI ALLA ROMANA CONG.AL KG",
            "ingredients": "INGREDIENTI: TOTANO atlantico acqua farina di FRUMENTO olio di girasole farina di mais agenti lievitanti: E450-E500 lievito aglio(SOLFITI) sciroppi di glucosio addensante:E412 aroma di limone correttore di acidità:E330 antiossidante:E300 colorante:E101 stabilizzante:E461-E452. Può contenere SOIASENAPE. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. consumarsi solo previa cottura.",
            "plu": "051011"
        },
        {
            "_id": "881912",
            "name": "ASTICE CRUDO 250/350 CONG.KG",
            "ingredients": "INGREDIENTI: ASTICE (Homarus americanus). Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. ALLERGENI: SOLFITI.",
            "plu": "050316"
        },
        {
            "_id": "881920",
            "name": "CAPPESANTE 1/2 GUSCIO KG.1",
            "ingredients": "INGREDIENTI: CAPPASANTA (Pecten Maximus)acqua. Può contenere tracce di CROSTACEI e SOLFITI. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050623"
        },
        {
            "_id": "881953",
            "name": "FRIT.MISTO ITTICO PAST.CONG.KG",
            "ingredients": "INGREDIENTI: TOTANO atlantico NASELLO GAMBERI (metabiSOLFITO di sodio) far.di FRUMENTO acqua olio di girasole far.di maissale amido di mais E450-E500 lievito aglio(SOLFITI)E412GLUTINE di FRUMENTO sciroppi di glucosioaroma di limone naturale E461-E452 E330 E300 E101. Può contenere SOIASENAPE.",
            "plu": "051012"
        },
        {
            "_id": "881961",
            "name": "CODE GAMBERI ARGENTINI C1 KG",
            "ingredients": "INGREDIENTI:GAMBERI argentini (Pleoticus muelleri) antiossidante E223. Contiene SOLFITI. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura. Codice Descrizione/Ingredienti Plu bilancia",
            "plu": "050317"
        },
        {
            "_id": "881995",
            "name": "GRANCIPORRO 400/600 COT.SV.KG",
            "ingredients": "INGREDIENTI: GRANCIPORRO (Cancer pagurus) ALLERGENI: può contenere MOLLUSCHI Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050320"
        },
        {
            "_id": "882027",
            "name": "RANA PESCATRICE S/A M AL KG",
            "ingredients": "INGREDIENTI: RANA PESCATRICE (Lophius vomerinus) Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050472"
        },
        {
            "_id": "882035",
            "name": "FILET.SCORF.C/PELLE 80/250 con",
            "ingredients": "INGREDIENTI:SCORFANO DEL PACIFICO (Sebastes alutus) stabilizzante E452. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050473"
        },
        {
            "_id": "882043",
            "name": "SCORFANO S/T 300/500 AL KG.",
            "ingredients": "INGREDIENTI: SCORFANO (Sebastes marinus) Prodotto congelato. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050626"
        },
        {
            "_id": "882068",
            "name": "SOGLIOLA NERA 20/22 AL KG",
            "ingredients": "INGREDIENTI: SOGLIOLA (Solea vulgaris) Può contenere MOLLUSCHI e CROSTACEI. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050628"
        },
        {
            "_id": "882076",
            "name": "SPIEDINI MARE CONG.A KG.",
            "ingredients": "INGREDIENTI: TOTANO australe (Nototodarus sloanii) (FAO81) GAMBERO ARGENTINO (Pleoticus muelleri) (FAO41). Correttori di acidità: E330 E331. Antiossidante: E223. Può contenere PESCESOLFITI. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta.Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi previa cottura.",
            "plu": "050876"
        },
        {
            "_id": "882134",
            "name": "RANA PESCATRICE 200/400 800GR",
            "ingredients": "INGREDIENTI:RANA PESCATRICE orientale.Correttore di acidità:E330E331. Conservare a T-18°C. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050474"
        },
        {
            "_id": "887497",
            "name": "GELA CROCCHE GRANDI CONG.A KG",
            "ingredients": "INGREDIENTI: Acqua fiocchi di patate (SOLFITI) PANE grattugiato (GLUTINE) formaggio fresco (LATTE sale caglio) farina di FRUMENTO prosciutto cotto (coscia di suino sale aromi destrosio saccarosio antiossidante: ascorbato di sodio conservante :nitrito di sodio) olio di semi di girasole formaggio grattugiato (LATTE sale caglio Lisozima da UOVO) MOZZARELLA vaccina (LATTE sale caglio FERMENTI LATTICI) sale amido modificato di FRUMENTO prezzemolo zucchero pepe. Può contenere SOIA. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere SOIA SENAPE.",
            "plu": "051276"
        },
        {
            "_id": "887505",
            "name": "GELA CROCCHE MEDI CONG. AL KG",
            "ingredients": "INGREDIENTI: Acqua fiocchi di patate pane grattugiato (farina di FRUMENTO lievito di birra sale) formaggio fresco (LATTE caglio sale) prosciutto cotto coscia di suino sale aromi destrosio saccarosio antiossidante (E301) conservante (E250) olio di semi di girasole formaggio grattugiato (LATTE sale caglio Lisozima da UOVO) mozzarella vaccina (LATTE sale caglio FERMENTI LATTICI) sale amido modificato di FRUMENTO prezzemolo zuccheropepe. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere SOIASENAPE.",
            "plu": "051277"
        },
        {
            "_id": "891085",
            "name": "CIUFFI CALAMARO CONG.AL KG",
            "ingredients": "INGREDIENTI: ciuffi di CALAMARI (Loligo chinensis).Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "050880"
        },
        {
            "_id": "891093",
            "name": "CIUFFI TOTANO CONG.AL KG",
            "ingredients": "INGREDIENTI: TOTANO atlantico (Illex argentinus) correttori di acidità E330 E331 Può contenere CROSTACEI e PESCE. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050881"
        },
        {
            "_id": "893156",
            "name": "FUNGHI CHAMPIGNON CONG.AL KG",
            "ingredients": "INGREDIENTI:Funghi Champignon(Agaricus bisporus) congelati. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "051455"
        },
        {
            "_id": "893164",
            "name": "FUNGHI CHIODINI CONG. AL KG",
            "ingredients": "INGREDIENTI:Funghi famigliola gialla (Pholiota nameko) congelati. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura",
            "plu": "051454"
        },
        {
            "_id": "893172",
            "name": "FUNGHI FANTASIA C/PORC.CONG.KG",
            "ingredients": "INGREDIENTI: Funghi Champignon (Angaricus bisporus) Famigliola Gialla (Pholiota nameko) Shitake (Lentinus edodes)Orecchinoni (Pleurotus ostreatus) Porcini (Boletus edulis e r. g.) in proporzione variabile.Conservare a T -18°C. Non ricongelare e consumare previa cottura.",
            "plu": "051453"
        },
        {
            "_id": "893180",
            "name": "FUNGHI PORCINI CUBET.CONG.A KG",
            "ingredients": "INGREDIENTI: Boletus edulis e relativo gruppo.Conservare a T -18°C. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "051452"
        },
        {
            "_id": "893198",
            "name": "FUNGHI PORCINI INTERI CONG.KG",
            "ingredients": "INGREDIENTI:Funghi porcini interi congelati (Boletus edulis e r.g.). Conservare a T -18°c.Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "051451"
        },
        {
            "_id": "961055",
            "name": "NUGGETS DI MERLUZZO CONG.AL KG",
            "ingredients": "INGREDIENTI:MERLUZZO dAlaska Theragra Chalcogramma PANgrattato(farina di FRUMENTOacqua lievitosale) olio di colzafarina di FRUMENTOacquariso macinatoamido di FRUMENTO amido di riso lievito in polvere(difosfaticarbonato di sodioamido di FRUMENTO) zuccheroSENAPE(acquasemi di SENAPEaceto di brandy(SOLFITI)salespezie)sale siero di LATTE dolce in polvere.Può contenere SOIA.",
            "plu": "051015"
        },
        {
            "_id": "961056",
            "name": "BASTONCINI DI PESCE CONG.AL KG",
            "ingredients": "INGREDIENTI: MERLUZZO dAlaska (Theragra Chalcogramma - FAO67-61) PANgrattato (farina di FRUMENTO acqua sale spezie lievito) olio di colza farina di FRUMENTO acqua amido di FRUMENTO sale semolino di riso. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere SOIASENAPE.",
            "plu": "051014"
        },
        {
            "_id": "962448",
            "name": "RISPO FRITTO MISTO NAP.1KG",
            "ingredients": "INGREDIENTI:fiocchi di patate(patateE471E450iE304spezieE223 aroma natE330)semil. per impan(farina di FRUMENTO lievito di birracurcuma polverepaprica in polvereerbe aromatiche: prezzemolobasilicopepenero polvereannattocurcumina e zucc.caram) MOZZARELLA vaccina(LATTE sale cagliofermenti LATTICIE330E202) pasta di semola di GRANO durorisopolpa di pomodoroprep.per bescia- mella(amido di mais modifLATTE screm. in polvereLATTOSIOproteine del LATTEBURRO in polvere grassi veg.in polvere-da cocco) carne bovina prosc.cotto(carne suinadestrosiosaccarosioproteine del LATTEE330 Grana Padano DOP(LATTEsalecagliolisozima da UOVO)FORMAGGIO pecorinoPROVOLONEpiselliolio di oliva e di semi girasoleSEDANO carotacipollaprep.per brodo(sale iodatofecola di patateestr.di lie vitograsso vegetale di palmaaromispezie)farina di FRUMENTObasili- coE503-ii barbabietola rossa polvere aglio(SOLFITI) noce moscataalloro. Può contenere:PESCESOIASENAPE. Conservare a -18°C. Non ricongelare.",
            "plu": "051286"
        },
        {
            "_id": "962450",
            "name": "RISPO RUSTICI MIGN.WURST.2500G",
            "ingredients": "INGREDIENTI:farina di GRANO 00margarina vegetale acqua sale strutto. Ripieno:wurstel Può contenere SEDANO UOVA PESCE LATTE SOIASENAPE. Conservare a temperatura non superiore a -18°C una volta scongelato non ricongelare e consumare entro 24h previa cottura.",
            "plu": "051285"
        },
        {
            "_id": "963853",
            "name": "*PANPIZZA MEZZALUNA GR.100",
            "ingredients": "INGREDIENTI:farina di GRANO tenero tipo 0(GLUTINE) acqua sale olio di semi di girasole MALTO lievito naturale. Può contenere tracce di SOIA FRUTTA a GUSCIO PESCE.",
            "plu": "051291"
        },
        {
            "_id": "964308",
            "name": "ANELLI DI CALAMARO CONG.AL KG",
            "ingredients": "INGREDIENTI: CALAMARO indiano (Loligo Duvauceli) correttori di acidità E330 E331 acqua sale. ALLERGENI:MOLLUSCHI. Prodotto congelato. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050888"
        },
        {
            "_id": "964522",
            "name": "TELLINE C/GUSCIO CONG.AL KG",
            "ingredients": "INGREDIENTI: TELLINE (Donax trunculus) acqua. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050889"
        },
        {
            "_id": "964726",
            "name": "FILENI SPIEDINI A KG",
            "ingredients": "INGREDIENTI: Salsiccia Carne di pollo pancetta di suino acqua carne di tacchino fibre vegetali sale iodato (sale iodato di potassio) aromi naturali estratto di barbabietola antiossidanti (E301 E300) pepe bianco rosmarino. Budello edibile (alginato di sodio) carne di pollo acqua pancetta di suino peperoni amidi sale destrosio rosmarinoaglio (SOLFITI) salvia aromi naturali pepe antiossidante (E301) gelificante (E407a). Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura. Può contenere GLUTINE LATTE UOVA.",
            "plu": "050189"
        },
        {
            "_id": "980261",
            "name": "CANNOLICCHI CONG.AL KG",
            "ingredients": "INGREDIENTI: CANNOLICCHIO (Ensis directus) acqua. Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "050476"
        },
        {
            "_id": "980264",
            "name": "TRANCE SMERIGLIO CONG.",
            "ingredients": "INGREDIENTI: SMERIGLIO (Isurus oxyrhincus) Prodotto congelato. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050759"
        },
        {
            "_id": "980265",
            "name": "TRANCE TONNO CONG.",
            "ingredients": "INGREDIENTI: TONNO (Thunnus albacares) Prodotto congelato. Conservare a T -18°c. Una volta scongelato non ricongelare il prodotto e consumare previa cottura.",
            "plu": "050758"
        },
        {
            "_id": "980275",
            "name": "FILET.SALMONE CONG.800UP",
            "ingredients": "INGREDIENTI: filetti di SALMONE KETA (Oncorhynchus keta). Conservare a T-18°C. Consumare preferibilmente entro la data riportata in etichetta. Una volta scongelato il prodotto deve essere consumato entro le 24 ore e non può essere ricongelato. Prodotto surgelato allorigine da consumarsi solo previa cottura.",
            "plu": "050477"
        }
    ],
    "PANETTERIA": [
        {
            "_id": "806668",
            "name": "PANE TIPO PANINI NAPOLETANI",
            "ingredients": "INGREDIENTI: FARINA DI GRANO TENERO TIPO 00, FARINA INTEGRALE, SALE, ACQUA, LIEVITO DI BIRRA, SALUMI, FORMAGGI",
            "plu": "70307"
        },
        {
            "_id": "751842",
            "name": "PANINI SOIA",
            "ingredients": "INGREDIENTI: FARINA DI GRANO 00, FARINA SOIA, SALE, ACQUA, LIEVITO DI BIRRA",
            "plu": "92103"
        },
        {
            "_id": "706445",
            "name": "PANE TIPO CASERECCHIO",
            "ingredients": "INGREDIENTI: FARINA DI GRANO TENERO TIPO 00, FARINA INTEGRALE, SALE, ACQUA, LIEVITO NATURALE",
            "plu": "31745"
        },
        {
            "_id": "488728",
            "name": "PANE TIPO SEMOLA",
            "ingredients": "INGREDIENTI: FARINA DI SEMOLA RIMACINATA, SALE, ACQUA, LIEVITO DI BIRRA",
            "plu": "43288"
        },
        {
            "_id": "199311",
            "name": "PANINI INTEGRALI",
            "ingredients": "INGREDIENTI: FARINA DI GRANO 00, FARINA INTEGRALE, SALE, ACQUA, LIEVITO DI BIRRA",
            "plu": "96903"
        },
        {
            "_id": "588260",
            "name": "PANE TIPO MULTICEREALI",
            "ingredients": "INGREDIENTI: FARINA DI GRANO 00, FARINA MULTICEREALI, SALE, ACQUA, LIEVITO DI BIRRA",
            "plu": "20700"
        }
    ]
}

export default ProductsList