import React, { useState } from 'react'
import { categories } from './categories'


const LeftMenu = ( { changeCategory} ) => {

    const [selectedCategory, setSelectedCategory] = useState( 0 )
    const [category, setCategory] = useState( categories[0] )

    function handleCategoryChange(id) {
        document.querySelector('.products').scrollTo({top: 0, behavior: 'instant'});
        setSelectedCategory(id)
        setCategory(categories[id])
        changeCategory(categories[id].name)
    }

    return (
        <div className="left-menu">
            <img className="logo" src="/imgs/logo_infante.png"  onClick={() => window.location.reload(true)}/>

            <div className="menu">
                {
                    categories.map((category) => (
                        <h1 key={category.id} className={selectedCategory === category.id ? 'cat cat-selected' : 'cat cat-unselected'} onClick={() => handleCategoryChange(category.id)}>{category.name}</h1>
                    ))
                }
            </div>

            <img className="image" src={category.image} />
        </div>
    );
}
 
export default LeftMenu