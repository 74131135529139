import React, { useState, useEffect } from 'react';
import { AiFillCaretRight } from "react-icons/ai";

const Products = ( {products} ) => {

    const [selectedProduct, setSelectedProduct] = useState( -1 )

    function scrollToTop() {
        document.querySelector('.products').scrollTo({top: 0, behavior: 'smooth'});
    }

    useEffect(() => {
        document.querySelector('.products').scrollTo({top: 0, behavior: 'smooth'})
      }, [products]);

    function handleProductChange(id) {        
        if (selectedProduct === id) {
            setSelectedProduct(-1)
            return
        }
        setSelectedProduct(id)
    }

    return ( 
        <div>
            <div className="products">
                {
                    products.slice().sort((a, b) => a.name.localeCompare(b.name)).map((product) => (
                        <div className={selectedProduct == product['_id'] ? 'product product-selected' : 'product product-unselected'} key={product['_id']} onClick={ () => handleProductChange(product['_id']) }>
                            <div className="name">
                                <h1>{product.name}</h1>
                                <div className="details"> <AiFillCaretRight className={selectedProduct == product['_id'] ? 'details-selected' : ''}/> <p>DETTAGLI</p> </div>
                            </div>
                            <div className="ingredients">
                                <h5>{product.ingredients}</h5>
                            </div>
                        </div>
                    ))
                }
            </div>
            
            <img src="/imgs/scroll_top.png" className='scroll-top' onClick={scrollToTop}/>
        </div>
     );
}
 
export default Products